import React, { useRef, useState } from "react";
import { Section, Main } from "../../../components/content";
import { PERMISSIONS } from "../../../utils/permissions";
import SelectCustom from "../../../components/customSelect/CustomSelect";
import LoadingPlaceholder from "../../../components/loadingPlaceholder/LoadingPlaceholder";
import { getGroceryPermissions } from "../../../middleware";
import { useLocation } from "react-router-dom";
import { slugify, slugifyReplaceAll } from "../../../helpers/helpers";
import { useAuth } from "../../../context/useAuth";
import { useCallback, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Table from "./Table";
import Loading from "../../../components/loading/Loading";
import SidebarModal from "../../../components/sidebarModal/SidebarModal";
import { DatePicker, Select, notification } from "antd";
import Modal from "../../../components/modal/Modal";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { SERVER_URL } from "../../../config/index";
import axios from "axios";
import { notificationMessages } from "../../../helpers/notificationMessages";
import moment from "moment";

const options = [5, 10, 20, 50, 100];

const Coupons = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (
        location.pathname.includes(
          `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
        )
      ) {
        if (
          Object.keys(permissions).some((permission) =>
            permission.includes(PERMISSIONS.grocery)
          )
        ) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({
    query: "",
  });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  // PAGE
  const [readData, setReadData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // READ
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: "" });
    setReadDataRefetch((prevState) => !prevState);
  };
  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .get(
          `${SERVER_URL}/${
            AXIOS_API_CALL.getCoupons
          }/${currentPage}/${limitPage}/${search || null}`,
          {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${token}`,
              department: PERMISSIONS.grocery,
            },
          }
        )
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => console.error(err))
        .finally();

      return response;
    } catch (err) {}
  }, [user, currentPage, limitPage, search]);

  useEffect(() => {
    let isMounted = true;
    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current - 1 < Date.now();
  }

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // CREATE - (coupon)
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({
    name: "",
    slug: "",
    discount: "",
    couponType: "",
    expirationDate: "",
  }); // FORM data
  const [createFormError, setCreateFormError] = useState({
    name: false,
    discount: false,
    expirationDate: false,
    couponType: false,
  }); // ERROR handling

  // CREATE COUPON
  const [toggleCreateCoupon, setToggleCreateCoupon] = useState(false);

  useEffect(() => {
    if (!!toggleCreateCoupon) {
      setToggleCreateCoupon(false);
    }
  }, [toggleCreate]);

  // CREATE - (unit)
  const createUnitFormRef = useRef(null);
  const [toggleCreateUnit, setToggleCreateUnit] = useState(false);
  const [createUnitForm, setCreateUnitForm] = useState({ name: "" }); // FORM data

  // UPDATE - (coupon)
  const updateFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateForm, setUpdateForm] = useState({
    name: "",
    slug: "",
    discount: "",
    couponType: "",
    expirationDate: "",
  }); // FORM data
  const [updateFormError, setUpdateFormError] = useState({
    name: false,
    discount: false,
    expirationDate: false,
    couponType: false,
  }); // ERROR handling
  const [updateFormId, setUpdateFormId] = useState({ id: "" }); // ID for fetch
  const [updateLoadingForm, setUpdateLoadingForm] = useState(true);

  // DELETE
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: "" }); // ID for fetch

  // DELETE BULK
  const deleteBlukModalFormRef = useRef(null);
  const [toggleDeleteBulk, setToggleDeleteBulk] = useState(false);

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };
  // FILTERS
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  const handleToggle = (props) => {
    const { name, data } = props;

    switch (name) {
      case "create":
        setToggleCreate(true);
        break;
      case "update":
        setToggleUpdate(true);
        setUpdateFormId({ id: data._id });
        break;
      case "delete":
        setToggleDelete(true);
        setDeleteFormId({ id: data._id });
        break;
      case "deleteBulk":
        setToggleDeleteBulk(true);
        break;
      default:
        console.warn("Default of: handleToggle function");
    }
  };
  const handleOnBlur = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "createName":
        if (value.length >= 5 && value.length <= 20) {
          setCreateFormError({ ...createFormError, name: false });
        } else {
          toggleCreate
            ? setCreateFormError({ ...createFormError, name: true })
            : setCreateFormError({ ...createFormError, name: false });
        }
        break;
      case "createDiscount":
        if (value.length > 0) {
          setCreateFormError({ ...createFormError, discount: false });
        } else {
          toggleCreate
            ? setCreateFormError({ ...createFormError, discount: true })
            : setCreateFormError({ ...createFormError, discount: false });
        }
        break;
      case "createExpirationDate":
        if (value) {
          setCreateFormError({ ...createFormError, expirationDate: false });
        } else {
          toggleCreate
            ? setCreateFormError({ ...createFormError, expirationDate: true })
            : setCreateFormError({ ...createFormError, expirationDate: false });
        }
        break;
      case "createCouponType":
        if (value.length > 0) {
          setCreateFormError({ ...createFormError, couponType: false });
        } else {
          toggleCreate
            ? setCreateFormError({ ...createFormError, couponType: true })
            : setCreateFormError({ ...createFormError, couponType: false });
        }
        break;
      case "updateName":
        if (value.length >= 5 && value.length <= 20) {
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, name: true })
            : setUpdateFormError({ ...updateFormError, name: false });
        }
        break;
      case "updateDiscount":
        if (value.length > 0) {
          setUpdateFormError({ ...updateFormError, discount: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, discount: true })
            : setUpdateFormError({ ...updateFormError, discount: false });
        }
        break;
      case "updateExpirationDate":
        if (value) {
          setUpdateFormError({ ...updateFormError, expirationDate: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, expirationDate: true })
            : setUpdateFormError({ ...updateFormError, expirationDate: false });
        }
        break;
      case "updateCouponType":
        if (value.length > 0) {
          setUpdateFormError({ ...updateFormError, couponType: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, couponType: true })
            : setUpdateFormError({ ...updateFormError, couponType: false });
        }
        break;
      default:
        console.warn("Default of: handleOnBlur function!");
    }
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "createName":
        const slug = slugify(value);
        if (value.includes(" ")) return;
        setCreateForm({ ...createForm, name: value, slug: slug });
        break;
      case "createDiscount":
        if (value > 100 || value < 0) {
          return;
        }
        setCreateForm({ ...createForm, discount: value });
        break;
      case "createCouponType":
        setCreateForm({ ...createForm, couponType: value });
        break;
      case "updateName":
        setUpdateForm({ ...updateForm, name: value });
        break;
      case "updateDiscount":
        if (value > 100 || value < 0) {
          return;
        }
        setUpdateForm({ ...updateForm, discount: value });
        break;
      case "updateCouponType":
        setUpdateForm({ ...updateForm, couponType: value });
        break;
      default:
        console.warn("Default of: handleOnChange function!");
    }
  };

  /**
   * Handle validate function
   * @param {name}
   * @param {form}
   */
  const handleOnValidate = ({ name, form }) => {
    let checkCase = false;

    let checkCreateName = false;
    let checkCreateDiscount = false;
    let checkCreateDate = false;
    let checkCouponType = false;

    let checkUpdateName = false;
    let checkUpdateDiscount = false;
    let checkUpdateDate = false;
    let checkUpdateCouponType = false;

    switch (name) {
      case "create":
        if (form.data.name.length >= 5 && form.data.name.length <= 20) {
          checkCreateName = true;
          setCreateFormError((prev) => {
            return {
              ...prev,
              name: false,
            };
          });
        } else {
          checkCreateName = false;
          toggleCreate
            ? setCreateFormError((prev) => {
                return {
                  ...prev,
                  name: true,
                };
              })
            : setCreateFormError((prev) => {
                return {
                  ...prev,
                  name: true,
                };
              });
        }

        if (form.data.discount) {
          checkCreateDiscount = true;
          setCreateFormError((prev) => {
            return {
              ...prev,
              discount: false,
            };
          });
        } else {
          checkCreateDiscount = false;
          toggleCreate
            ? setCreateFormError((prev) => {
                return {
                  ...prev,
                  discount: true,
                };
              })
            : setCreateFormError((prev) => {
                return {
                  ...prev,
                  discount: true,
                };
              });
        }

        if (form.data.expirationDate) {
          checkCreateDate = true;
          setCreateFormError((prev) => {
            return {
              ...prev,
              expirationDate: false,
            };
          });
        } else {
          checkCreateDate = false;
          toggleCreate
            ? setCreateFormError((prev) => {
                return {
                  ...prev,
                  expirationDate: true,
                };
              })
            : setCreateFormError((prev) => {
                return {
                  ...prev,
                  expirationDate: true,
                };
              });
        }

        if (form.data.couponType) {
          checkCouponType = true;
          setCreateFormError((prev) => {
            return {
              ...prev,
              couponType: false,
            };
          });
        } else {
          checkCouponType = false;
          toggleCreate
            ? setCreateFormError((prev) => {
                return {
                  ...prev,
                  couponType: true,
                };
              })
            : setCreateFormError((prev) => {
                return {
                  ...prev,
                  couponType: true,
                };
              });
        }

        if (
          !checkCreateName &&
          !checkCreateDiscount &&
          !checkCreateDate &&
          !checkCouponType
        ) {
          setCreateFormError({
            ...createFormError,
            name: true,
            discount: true,
            expirationDate: true,
            couponType: true,
          });
        }

        if (
          checkCreateName &&
          checkCreateDiscount &&
          checkCreateDate &&
          checkCouponType
        ) {
          checkCase = true;
        }
        break;
      case "update":
        if (form.data.name.length >= 5 && form.data.name.length <= 20) {
          checkUpdateName = true;
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          checkUpdateName = false;
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, name: true })
            : setUpdateFormError({ ...updateFormError, name: false });
        }

        if (form.data.discount) {
          checkUpdateDiscount = true;
          setUpdateFormError({ ...updateFormError, discount: false });
        } else {
          checkUpdateDiscount = false;
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, discount: true })
            : setUpdateFormError({ ...updateFormError, discount: false });
        }
        if (form.data.expirationDate) {
          checkUpdateDate = true;
          setUpdateFormError({ ...updateFormError, expirationDate: false });
        } else {
          checkUpdateDate = false;
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, expirationDate: true })
            : setUpdateFormError({ ...updateFormError, expirationDate: false });
        }

        if (form.data.couponType) {
          checkUpdateCouponType = true;
          setUpdateFormError({ ...updateFormError, couponType: false });
        } else {
          checkUpdateCouponType = false;
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, couponType: true })
            : setUpdateFormError({ ...updateFormError, couponType: false });
        }

        if (
          !checkUpdateName &&
          !checkUpdateDiscount &&
          !checkUpdateDate &&
          !checkUpdateCouponType
        ) {
          setUpdateFormError({
            ...updateFormError,
            name: true,
            discount: true,
            expirationDate: true,
            couponType: true,
          });
        }

        if (
          checkUpdateName &&
          checkUpdateDiscount &&
          checkUpdateDate &&
          checkUpdateCouponType
        ) {
          checkCase = true;
        }
        break;
      case "delete":
        checkCase = true;
        break;
      case "deleteBulk":
        checkCase = true;
        break;
      default:
        console.warn("Default of: handleOnValidate function!");
    }
    return checkCase;
  };

  /**
   * Form submit function
   * @param {*} props
   */
  async function handleOnSubmit({ event, form }) {
    event.preventDefault();

    const { name } = event.target;

    const { token } = user;

    switch (name) {
      case "create":
        setCreateBtnLoader(true);
        const createValidate = handleOnValidate({ name: name, form: form });

        if (createValidate) {
          let createPayload = {
            department: PERMISSIONS.grocery,
            value: createForm.name,
            valid_thru: createForm.expirationDate._d,
            discount_percentage: +createForm.discount,
            type: createForm.couponType.toLocaleLowerCase(),
          };
          try {
            axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.createCoupons}`,
                createPayload,
                {
                  withCredentials: false,
                  headers: {
                    department: PERMISSIONS.grocery,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  form.onToggle(false);
                  form.onData({
                    name: "",
                    slug: "",
                    discount: "",
                    couponType: "",
                    expirationDate: "",
                  });
                  form.formRef.current.reset();

                  // notification
                  notification.success({
                    message: notificationMessages.successCreateCoupon,
                    placement: "bottomLeft",
                  });
                  setTimeout(() => {
                    setCreateBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        } else {
          setCreateBtnLoader(false);
        }
        break;
      case "update":
        setEditBtnLoader(true);
        const updateValidate = handleOnValidate({ name: name, form: form });

        if (updateValidate) {
          const updateID = updateFormId.id;
          let updatePayload = {
            department: PERMISSIONS.grocery,
            value: updateForm.name,
            valid_thru: updateForm.expirationDate,
            discount_percentage: +updateForm.discount,
            type: updateForm.couponType.toLocaleLowerCase(),
          };
          try {
            axios
              .put(
                `${SERVER_URL}/${AXIOS_API_CALL.updateCoupon}/${updateID}`,
                { ...updatePayload },
                {
                  withCredentials: false,
                  headers: {
                    department: PERMISSIONS.grocery,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setUpdateFormId({ id: "" });
                  form.onData({
                    name: "",
                    slug: "",
                    discount: "",
                    couponType: "",
                    expirationDate: "",
                  });
                  form.onToggle(false);

                  notification.success({
                    message: res.data.message,
                    placement: "bottomLeft",
                  });
                  setTimeout(() => {
                    setEditBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setEditBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setEditBtnLoader(false);
        }
        break;
      case "delete":
        setDeleteBtnLoader(true);
        const deleteValidate = handleOnValidate({ name: name, form: form });

        if (deleteValidate) {
          const deleteID = deleteFormId.id;

          try {
            await axios
              .delete(
                `${SERVER_URL}/${AXIOS_API_CALL.deleteCoupon}/${deleteID}`,
                {
                  withCredentials: false,
                  headers: {
                    department: PERMISSIONS.grocery,
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setDeleteFormId({ id: "" });
                  form.onToggle(false);
                  form.formRef.current.reset();

                  notification.success({
                    message: res.data.message,
                    placement: "bottomLeft",
                  });
                  // setDeleteBtnLoader(false);
                  setTimeout(() => {
                    setDeleteBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                setDeleteBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        } else {
          setDeleteBtnLoader(false);
        }
        break;
      case "deleteBulk":
        setDeleteBtnLoader(true);
        const isValidateBulkDelete = handleOnValidate({
          name: name,
          form: form,
        });
        const bulkPayload = {
          coupons: checkedRow,
        };
        if (isValidateBulkDelete) {
          await axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.deleteBulkCoupons}`,
              bulkPayload,
              {
                withCredentials: false,
                headers: {
                  Authorization: `Bearer ${token}`,
                  department: PERMISSIONS.grocery,
                },
              }
            )
            .then((res) => {
              if (res.status === 201) {
                setReadDataRefetch((prev) => !prev);
                setToggleDeleteBulk(false);
                setTimeout(() => {
                  setDeleteBtnLoader(false)
                },400)
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
        break;
      default:
        console.warn("Default of: handleOnSubmit function!");
    }
  }
  const getUpdateCoupons = useCallback(
    (props) => {
      const { id } = props;

      const { token } = user;

      try {
        const response = axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.updateCoupon}/${id}`, {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${token}`,
              department: PERMISSIONS.grocery,
            },
          })
          .then((res) => {
            setTimeout(() => {
              setUpdateLoadingForm(false);
            }, 700);
            return res;
          })
          .catch((err) => {});
        return response;
      } catch (err) {}
    },
    [user]
  );

  useEffect(() => {
    const updateID = updateFormId.id;

    let isMounted = true;

    if (!!updateID) {
      new Promise((resolve, reject) => {
        setUpdateLoadingForm(true);
        setTimeout(() => {
          resolve(getUpdateCoupons({ id: updateID }));
        }, 200);
      }).then((res) => {
        if (isMounted) {
          const expirationDate = moment(
            new Date(res.data.coupon.valid_thru),
            "YYYY-MM-DD"
          );
          const responseUpdateData = {
            name: res.data.coupon.value,
            slug: res.data.coupon.value + "-slug",
            discount: res.data.coupon.discount_percentage,
            couponType: res.data.coupon.type,
            expirationDate: expirationDate,
          };
          setUpdateForm((prevState) => {
            return { ...prevState, ...responseUpdateData };
          });
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [updateFormId, setUpdateForm, getUpdateCoupons]);

  // CREATE COUPON PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
    data: createForm,
    onData: setCreateForm,
  };

  // CREATE UNIT PROPS
  const createUnitProps = {
    active: toggleCreateUnit,
    onToggle: setToggleCreateUnit,
    formRef: createUnitFormRef,
    data: createUnitForm,
    onData: setCreateUnitForm,
  };

  // UPDATE COUPON PROPS
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    data: updateForm,
    onData: setUpdateForm,
    loading: updateLoadingForm,
  };

  // DELETE PROPS
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData?.data?.pagesCount,
    totalCount: readData?.data?.totalCount,
  };

  // TABLE PROPS
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,

    createUnitProps: createUnitProps,
    updateProps: updateProps,
    deleteProps: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  // delete bulk
  const deleteBlukProps = {
    active: toggleDeleteBulk,
    onToggle: setToggleDeleteBulk,
    formRef: deleteBlukModalFormRef,
  };

  return (
    <>
      <h2 className="page-title">Coupons</h2>
      <Section className="section__wrapper section__coupons">
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span
                style={{
                  color: "#333",
                  fontWeight: "500",
                }}
              >
                Show:
              </span>
              <SelectCustom
                options={options}
                label={"Items"}
                limit={limitPage}
                setLimit={setLimitPage}
                setRefetch={setReadDataRefetch}
                loading={readDataLoading}
              />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: "155.5px", height: "50px" }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form
                className="search-form"
                onSubmit={(e) => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Search"
                    />
                  </span>
                  <input
                    value={searchForm.query || ""}
                    type="text"
                    data-cy="search-input-field"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${
                      !!searchForm.query && searchForm.query.length > 0
                        ? "isActive"
                        : ""
                    }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder
              style={{
                width: "100%",
                maxWidth: "850px",
                height: "50px",
              }}
            />
          )}

          <div className="actions">
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.deleteCoupons
            ) && (
              <>
                {!readDataLoading ? (
                  <button
                    type="button"
                    onClick={() =>
                      handleToggle({
                        name: "deleteBulk",
                        state: deleteBlukProps,
                      })
                    }
                    disabled={disabledButton ? false : true}
                    data-cy="delete-products-btn"
                    className="btn btn-primary-outline pl-4 pr-4"
                  >
                    <span className="text">Delete Coupons</span>
                  </button>
                ) : (
                  <LoadingPlaceholder
                    style={{ width: "189px", height: "50px" }}
                  />
                )}
              </>
            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.createCoupons
            ) && (
              <>
                <div className="actions">
                  {!readDataLoading ? (
                    <button
                      type="button"
                      onClick={() =>
                        handleToggle({
                          name: "create",
                          state: createProps,
                        })
                      }
                      data-cy="create-attribute-btn"
                      className="btn btn-primary pl-4 pr-4"
                    >
                      <span className="text">Create Coupon</span>
                    </button>
                  ) : (
                    <LoadingPlaceholder
                      style={{
                        width: "191px",
                        height: "50px",
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </header>
        <Main className="section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <Table
              data={readData.data?.Data}
              {...tableProps}
              {...tableActionsProps}
              pagination={paginationProps}
            />
          )}
        </Main>

        {/* CREATE COUPON */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.createCoupons
        ) && (
          <SidebarModal
            {...createProps}
            className="sidebar__modal--right"
            title="Create Coupon"
          >
            <form
              name="create"
              ref={createFormRef}
              className="ant-form ant-form-horizontal mb-2"
              onSubmit={(event) =>
                handleOnSubmit({ event: event, form: createProps })
              }
            >
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createName">
                  <b className="required">Coupon Value:</b>
                </label>

                <input
                  type="text"
                  placeholder="Name"
                  name="createName"
                  id="createName"
                  data-cy="create-attribute-name-input"
                  value={createForm.name || ""}
                  onChange={(event) => handleOnChange(event)}
                  onBlur={(event) => handleOnBlur(event)}
                  className={`input ${
                    createFormError.name ? "input-error" : ""
                  }`}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    createFormError.name ? "error" : ""
                  }`}
                >
                  * This field is required! (5 - 20 characters)
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createDiscount">
                  <b className="required">Discount %:</b>
                </label>

                <input
                  type="number"
                  placeholder="Discount"
                  name="createDiscount"
                  id="createDiscount"
                  data-cy="create-attribute-name-input"
                  value={createForm.discount || ""}
                  onChange={(event) => handleOnChange(event)}
                  onBlur={(event) => handleOnBlur(event)}
                  className={`input ${
                    createFormError.discount ? "input-error" : ""
                  }`}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    createFormError.discount ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              <div className="form-group relative">
                <label
                  className="label mb-1 d-block"
                  htmlFor="createExpirationDate"
                >
                  <b className="required">Expiration Date:</b>
                </label>

                <DatePicker
                  className={`couponDatePicker ${
                    createFormError.expirationDate ? "input-error" : ""
                  }`}
                  onBlur={(event) => handleOnBlur(event)}
                  name="createExpirationDate"
                  id="createExpirationDate"
                  defaultValue={createForm.expirationDate || ""}
                  value={createForm.expirationDate}
                  allowClear={false}
                  showToday={false}
                  onChange={(event) =>
                    setCreateForm({ ...createForm, expirationDate: event })
                  }
                  disabledDate={disabledDate}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    createFormError.expirationDate ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              <div className={`form-group relative `}>
                <label
                  className="label mb-1 d-block"
                  htmlFor="createCouponType"
                >
                  <b className="required">Type:</b>
                </label>

                <select
                  onBlur={(event) => handleOnBlur(event)}
                  onChange={(event) => handleOnChange(event)}
                  name="createCouponType"
                  id="createCouponType"
                  className={`select select-full couponSelect ${
                    createFormError.couponType ? "input-error" : ""
                  }`}
                >
                  <option value={""}>Select Type</option>

                  <option value="Product">Product</option>
                  <option value="Shipping">Shipping</option>
                </select>

                <p
                  className={`error__onblur ${
                    createFormError.couponType ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              {!toggleCreateCoupon && (
                <div className="form-group form-group-sidebar">
                  <button
                    data-cy="modal-create-cancel-btn"
                    type="button"
                    className="btn btn-primary-link"
                    onClick={() => setToggleCreate(false)}
                  >
                    <span className="text">Cancel</span>
                  </button>

                  {!createBtnLoader ? (
                    <button
                      data-cy="modal-create-submit-btn"
                      type="submit"
                      className="btn btn-primary"
                    >
                      <span className="text">Create Coupon</span>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary-outline">
                      <span className="text">Creating...</span>
                    </button>
                  )}
                </div>
              )}
            </form>
          </SidebarModal>
        )}

        {/* UPDATE COUPON */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.updateCoupons
        ) && (
          <SidebarModal
            {...updateProps}
            className="sidebar__modal--right"
            title="Update Coupon"
          >
            <form
              name="update"
              ref={updateFormRef}
              className="ant-form ant-form-horizontal mb-2"
              onSubmit={(event) =>
                handleOnSubmit({ event: event, form: updateProps })
              }
            >
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateName">
                  <b className="required">Coupon Value:</b>
                </label>

                <input
                  type="text"
                  placeholder="Name"
                  name="updateName"
                  id="updateName"
                  data-cy="create-attribute-name-input"
                  value={updateForm.name || ""}
                  onChange={(event) => handleOnChange(event)}
                  onBlur={(event) => handleOnBlur(event)}
                  className={`input ${
                    updateFormError.name ? "input-error" : ""
                  }`}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    updateFormError.name ? "error" : ""
                  }`}
                >
                  * This field is required! (5 - 20 characters)
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateDiscount">
                  <b className="required">Discount %:</b>
                </label>

                <input
                  type="number"
                  placeholder="Discount"
                  name="updateDiscount"
                  id="updateDiscount"
                  data-cy="create-attribute-name-input"
                  value={updateForm.discount || ""}
                  onChange={(event) => handleOnChange(event)}
                  onBlur={(event) => handleOnBlur(event)}
                  className={`input ${
                    updateFormError.discount ? "input-error" : ""
                  }`}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    updateFormError.discount ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              <div className="form-group relative">
                <label
                  className="label mb-1 d-block"
                  htmlFor="updateExpirationDate"
                >
                  <b className="required">Expiration Date:</b>
                </label>

                <DatePicker
                  className={`couponDatePicker ${
                    updateFormError.expirationDate ? "input-error" : ""
                  }`}
                  onBlur={(event) => handleOnBlur(event)}
                  name="updateExpirationDate"
                  id="updateExpirationDate"
                  defaultValue={updateForm.expirationDate || ""}
                  value={updateForm.expirationDate}
                  allowClear={false}
                  showToday={false}
                  onChange={(event) =>
                    setUpdateForm({ ...updateForm, expirationDate: event })
                  }
                  disabledDate={disabledDate}
                />

                <p
                  data-cy="modal-create-attribute-name-error"
                  className={`error__onblur ${
                    updateFormError.expirationDate ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              <div className={`form-group relative `}>
                <label htmlFor="updateCouponType">Type:</label>

                <select
                  value={updateForm.couponType}
                  onBlur={(event) => handleOnBlur(event)}
                  onChange={(event) => handleOnChange(event)}
                  name="updateCouponType"
                  id="updateCouponType"
                  className={`select select-full couponSelect ${
                    updateFormError.couponType ? "input-error" : ""
                  }`}
                >
                  <option value={""}>Select Type</option>

                  <option value="product">Product</option>
                  <option value="shipping">Shipping</option>
                </select>

                <p
                  className={`error__onblur ${
                    updateFormError.couponType ? "error" : ""
                  }`}
                >
                  * This field is required!
                </p>
              </div>

              {toggleUpdate && (
                <div className="form-group form-group-sidebar">
                  <button
                    data-cy="modal-create-cancel-btn"
                    type="button"
                    className="btn btn-primary-link"
                    onClick={() => setToggleUpdate(false)}
                  >
                    <span className="text">Cancel</span>
                  </button>

                  {!editBtnLoader ? (
                    <button
                      data-cy="modal-create-submit-btn"
                      type="submit"
                      className="btn btn-primary"
                    >
                      <span className="text">Update Coupon</span>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary-outline">
                      <span className="text">Updating...</span>
                    </button>
                  )}
                </div>
              )}
            </form>
          </SidebarModal>
        )}

        {/* Delete Bulk Modal */}
        <Modal {...deleteBlukProps} className="sidebar__modal--center" title="">
          <form
            name="deleteBulk"
            onSubmit={(event) =>
              handleOnSubmit({
                event: event,
                name: "deleteBulk",
                data: checkedRow,
              })
            }
          >
            <h2 className="text-center mb-4">
              Are you sure you want to remove selected items ?
            </h2>

            <div className="form-group form-group-modal mb-4">
              <button
                data-cy="modal-delete-bulk-submit-btn"
                type="submit"
                className="btn btn-primary-link"
              >
                <span className="text">Yes</span>
              </button>

              <button
                data-cy="modal-delete-bulk-cancel-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => setToggleDeleteBulk(false)}
              >
                <span className="text">No</span>
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.deleteCoupons
        ) && (
          <Modal {...deleteProps} className="sidebar__modal--center" title="">
            <form
              name="delete"
              ref={deleteFormRef}
              className="ant-form ant-form-horizontal"
              onSubmit={(event) =>
                handleOnSubmit({ event: event, form: deleteProps })
              }
            >
              <h2 className="text-center mb-4">
                Are you sure you want to delete the selected item ?
              </h2>

              <div className="form-group form-group-modal mb-4">
                {!deleteBtnLoader ? (
                  <button
                    data-cy="modal-delete-submit-btn"
                    type="submit"
                    className="btn btn-primary-link"
                  >
                    <span className="text">Yes</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Deleting...</span>
                  </button>
                )}

                <button
                  data-cy="modal-delete-cancel-btn"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setToggleDelete(false)}
                >
                  <span className="text">No</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
      </Section>
    </>
  );
};
export default Coupons;
