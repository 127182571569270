import React from 'react'

function CheckMark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23px" height="25px" viewBox="0 0 256 256">
        <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
            <g transform="scale(5.33333,5.33333)">
            <path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#d0eded"></path>
            <path d="M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z" fill="#4caf50"></path>
            </g>
        </g>
    </svg>

  )
}

export default CheckMark
