export default function Counter({ value, setValue }) {
    const handleCount = ({ actions }) => {
        switch (actions.type) {
            case 'input-value':
                setValue((prev) => {
                    return {
                        ...prev,
                        slot: +actions.value
                    }
                });
                break;                
            default:
        }
    }

    return (
        <div className='counter'>
            <input className="input-value" value={value || 0} name="input-value" type="number" onChange={(e) => handleCount({ actions: { type: 'input-value', value: e.target.value } })} />
        </div>
    )
}