import axios from "axios";
import React, { Fragment, useState } from "react";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { SERVER_URL } from "../../../config";
import { useAuth } from "../../../context/useAuth";
import { PERMISSIONS } from "../../../utils/permissions";
import { notification } from "antd";
import WicCart from "../WicCart";
import ToggleSwitch from "../../customToggleSwitch/ToggleSwitch";
import LoadingPlaceholder from "../../loadingPlaceholder/LoadingPlaceholder";
import { CartDelivery } from "../cartDelivery/CartDelivery";
import ImageUpload from "../imageUpload/ImageUpload";

function AddressesTable(props) {
  const { shippingProps, data } = props;

  const {
    stateUS,
    customerId,
    setReadDataRefetch,
    items,
    onClick,
    selectedWic,
    readAddress,
    onChangeToggleSwitch,
    isWicCart,
    isSplitShipping,
    isAddressConfirmed,
    toggleDeliveryServices,
    onConfirmSplitAddress,
    isServicesConfirmed,
    isLengthSelectedWic,
    onConfirmAddresses,
    loadingSingleShipmentRates,
    onConfirmServices,
    singleShipmentRatesError,
    address,
    selectSingleShipment,
    singleShipmentRates,
    serviceConfirmedError,
    ratePrice,
    totalAmountValue,
    onDisproveAddresses,
    renderBenjaminsDelivery,
    products,
    onSelected,
    renderAddress,
    BENJAMINS_DELIVERY,
    BENJAMINS_PHONE_NUMBER,
    resetDelivery,
    setSingleShipmentRatesError,
    multiShipmentRatesError,
    setMultiShipmentRatesError,
    selectedShipmentRates,
    onConfirmSplitService,
    paymentMethodError,
    checkPaymentErrorMessage,
    onDisproveSplitAddress,
    geoLocationErrorWIC,
    zipArrayValidation,
    geoLocations,
    geoLocationError,
    setHideUploadWicImage,
    hideUploadWicImage,
    handleImageSelect,
  } = shippingProps;

  const { user } = useAuth();

  // accordion
  const [openCard, setOpenCard] = useState(null);

  // creating account loader
  const [loadingButton, setLoadingButton] = useState(false);

  // form
  const [form, setForm] = useState({
    street: "",
    zip_code: "",
    city: "",
    state: "",
  });

  // error handling
  const [formError, setFormError] = useState({
    street: false,
    zip_code: false,
    city: false,
    state: false,
  });

  const handleOpenCard = (item) => {
    setOpenCard((prev) => (prev === item ? null : item));
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "street":
        if (value.length > 2) {
          setFormError((prev) => ({
            ...prev,
            street: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            street: true,
          }));
        }
        break;
      case "zip_code":
        if (value.length === 5) {
          setFormError((prev) => ({
            ...prev,
            zip_code: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            zip_code: true,
          }));
        }
        break;
      case "city":
        if (value.length > 2) {
          setFormError((prev) => ({
            ...prev,
            city: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            city: true,
          }));
        }
        break;
      case "state":
        if (value) {
          setFormError((prev) => ({
            ...prev,
            state: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            state: true,
          }));
        }
        break;
      default:
        console.log("Default state");
    }
  };

  const handleValidate = (form) => {
    const { street, zip_code, city, state } = form;
    let isCheckCase = false;

    let checkStreet = false;
    let checkZipCode = false;
    let checkCityState = false;
    let checkState = false;

    if (street.length > 2) {
      checkStreet = true;
      setFormError((prev) => ({
        ...prev,
        street: false,
      }));
    } else {
      checkStreet = false;
      setFormError((prev) => ({
        ...prev,
        street: true,
      }));
    }

    if (zip_code.length === 5) {
      checkZipCode = true;
      setFormError((prev) => ({
        ...prev,
        zip_code: false,
      }));
    } else {
      checkZipCode = false;
      setFormError((prev) => ({
        ...prev,
        zip_code: true,
      }));
    }

    if (city.length > 2) {
      checkCityState = true;
      setFormError((prev) => ({
        ...prev,
        city: false,
      }));
    } else {
      checkCityState = false;
      setFormError((prev) => ({
        ...prev,
        city: true,
      }));
    }

    if (state) {
      checkState = true;
      setFormError((prev) => ({
        ...prev,
        state: false,
      }));
    } else {
      checkState = false;
      setFormError((prev) => ({
        ...prev,
        state: true,
      }));
    }

    if (!checkStreet && !checkZipCode && !checkCityState && !checkState) {
      setFormError((prev) => {
        return {
          ...prev,
          street: true,
          zip_code: true,
          city: true,
          state: true,
        };
      });
    }

    if (checkStreet && checkZipCode && checkCityState && checkState) {
      isCheckCase = true;
    }

    return isCheckCase;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token } = user;
    const isChecked = handleValidate(form);

    if (isChecked) {
      setLoadingButton(true);
      let updatePayload = {
        userType: "customer",
        authType: "basic",
        department: PERMISSIONS.grocery,
      };

      const newAddress = {
        address: form.street,
        zip_code: form.zip_code,
        city: form.city,
        state: form.state,
        isDefault: false,
      };
      const addressPayload = {
        address: [...data, newAddress],
      };

      const responseAddress = await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.createDashboardAddresses}/${customerId}`,
          { ...addressPayload },
          {
            withCredentials: false,
            headers: {
              department: PERMISSIONS.grocery,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(setTimeout(() => {}, 700));

      if (responseAddress.status === 200) {
        try {
          let responseUpdate = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${customerId}`,
            { ...updatePayload },
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (responseUpdate.status === 200) {
            setTimeout(() => {
              setLoadingButton(false);
            }, 700);
            setForm({
              street: "",
              zip_code: "",
              city: "",
              state: "",
            });
            setReadDataRefetch((prev) => !prev);
            // notification
            notification.success({
              message: responseUpdate.data.message.message,
              placement: "bottomLeft",
            });
          }
        } catch (err) {
          console.error(err);
          setLoadingButton(false);
        }
      }
    }
  };

  const wicCartProps = {
    selected: selectedWic,
    isSplitShipping: isSplitShipping,
    readAddress: readAddress,
    isAddressConfirmed: isAddressConfirmed,
    onClick: onClick,
  };

  return (
    <div className="customers__table create__table">
      <header className="header">
        <div>Shipping Address</div>
      </header>

      <div className="accordion">
        <div
          onClick={() => handleOpenCard(0)}
          name="existingAccount"
          className="accordion__header topRadius"
        >
          <div className="title">
            <h3>Existing Address</h3>
          </div>
          <div className="arrow">
            <img
              src="/assets/icons/arrow-down-table-filters.svg"
              width={18}
              height={9}
            />
          </div>
        </div>
        <div className={`accordion__body ${openCard === 0 ? "isActive" : ""}`}>
          {data && data.length > 0 ? (
            <div className="existingAddress">
              {data.map((item, index) => {
                const { city, state, zip_code, address, _id } = item;
                return (
                  <div key={index} className="row">
                    <div className="address__name">
                      <input
                        disabled={isSplitShipping || isAddressConfirmed}
                        value={_id}
                        onChange={(e) => {
                          onClick({
                            state: null,
                            action: { type: "globalAddressChanged", event: e },
                          });
                        }}
                        id={`address_${index + 1}`}
                        type="radio"
                        name="address"
                      />
                      <label htmlFor={`address_${index + 1}`}>
                        Address {index + 1}:
                      </label>
                    </div>

                    <div className="address__info">
                      <div>
                        <span>Street:</span>
                        <input readOnly value={address} type="text" />
                      </div>
                      <div>
                        <span>Zip Code:</span>
                        <input readOnly value={zip_code} type="number" />
                      </div>
                      <div>
                        City, State:
                        <input
                          readOnly
                          value={`${city},${state}`}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="noExistingAddress">
              <div className="message">
                <div>
                  <img
                    src="/assets/icons/mailIconAddress.svg"
                    width={30}
                    height={30}
                    alt="address icon"
                  />
                </div>
                <span>There are yet No Addresses</span>
              </div>
              <div className="button__container">
                <button
                  onClick={() => handleOpenCard(1)}
                  type="button"
                  className="createBtn btn btn-primary"
                >
                  Add Address
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="accordion">
        <div
          name="newAccount"
          onClick={() => handleOpenCard(1)}
          className={`accordion__header ${
            openCard === 1 ? "" : "bottomRadius"
          }`}
        >
          <div className="title">
            <h3>New Address</h3>
          </div>
          <div className="arrow">
            <img
              src="/assets/icons/arrow-down-table-filters.svg"
              width={18}
              height={9}
            />
          </div>
        </div>
        <div
          className={`accordion__body ${
            openCard === 1 ? "isActive borderBottom" : ""
          }`}
        >
          <form onSubmit={(e) => handleSubmit(e)} className="newAddress">
            <div className="row">
              <label>Street:</label>
              <input
                onBlur={(e) => handleOnBlur(e)}
                name="street"
                value={form.street}
                onChange={(e) => handleChangeInput(e)}
                type="text"
                placeholder="Enter a Street"
              />
              <p className={`errorMsg ${formError.street ? "active" : ""}`}>
                *Street is required.
              </p>
            </div>

            <div className="row">
              <label>Zip Code:</label>
              <input
                onBlur={(e) => handleOnBlur(e)}
                name="zip_code"
                value={form.zip_code}
                onChange={(e) => handleChangeInput(e)}
                type="number"
                placeholder="Enter a Zip Code"
              />
              <p className={`errorMsg ${formError.zip_code ? "active" : ""}`}>
                *Zip Code is required. (must be 5 characters)
              </p>
            </div>

            <div className="row">
              <label>City:</label>
              <input
                onBlur={(e) => handleOnBlur(e)}
                name="city"
                value={form.city}
                onChange={(e) => handleChangeInput(e)}
                type="text"
                placeholder="Enter a City"
              />
              <p className={`errorMsg ${formError.city ? "active" : ""}`}>
                *City is required.
              </p>
            </div>

            <div className="row">
              <label>State:</label>
              <select
                onBlur={(e) => handleOnBlur(e)}
                name="state"
                value={form.state}
                onChange={(e) => handleChangeInput(e)}
                className={`select select-full`}
              >
                <option value="">Choose State</option>
                {stateUS &&
                  stateUS.length > 0 &&
                  stateUS.map((state, idx) => {
                    return (
                      <option value={state.abbreviation} key={idx}>
                        {state.name}
                      </option>
                    );
                  })}
              </select>
              <p className={`errorMsg ${formError.state ? "active" : ""}`}>
                *State is required.
              </p>
            </div>

            <div className="row">
              {loadingButton ? (
                <button type="button" className="btn btn-primary-outline">
                  Loading...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add Address
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="header mb-2 mt-2">
        <div>Shipping Options:</div>
      </div>

      <ToggleSwitch
        disabled={readAddress && readAddress.length < 1 ? true : false}
        checked={isSplitShipping}
        label="Use Split Shipment"
        onChange={onChangeToggleSwitch}
      />

      <ToggleSwitch
        disabled={items && items.wic && items.wic.length > 0 ? false : true}
        checked={isWicCart}
        text={`(Selected items: <b>${selectedWic.length}</b>)`}
        label="WIC Purchase"
        onChange={onChangeToggleSwitch}
      />

      {isWicCart && (
        <>
          <WicCart
            className={"wicCart"}
            products={items.wic}
            {...wicCartProps}
          />
          <div
            className="uploadWicImage"
            style={{
              display: selectedWic && selectedWic.length > 0 ? "block" : "none",
            }}
          >
            <div className="upload-image upload-wic-card">
              <div className="upload-wic-checkbox">
                <input
                  value={hideUploadWicImage}
                  id="upload-wic-checkobx"
                  type="checkbox"
                  onChange={(e) => setHideUploadWicImage(e.target.checked)}
                />
                <label htmlFor="upload-wic-checkobx">
                  I will send screenshot of my WIC Benefits photo to{" "}
                  <a
                    href={`tel:${BENJAMINS_PHONE_NUMBER}`}
                    className="link link-primary"
                  >
                    +1 718 405 9111
                  </a>
                  number.
                </label>
              </div>

              {!hideUploadWicImage ? (
                <div className="upload-wic-wrapper">
                  <ImageUpload
                    className="image-upload-grid"
                    onImageSelect={handleImageSelect}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}

      {isSplitShipping ? (
        <Fragment>
          {toggleDeliveryServices && isAddressConfirmed ? (
            <div
              className={`shipment-rates ${
                paymentMethodError.multiPayment || checkPaymentErrorMessage
                  ? "error"
                  : ""
              }`}
            >
              <div className="your-service">
                <div className="form-group">
                  <CartDelivery
                    geoLocationErrorWIC={geoLocationErrorWIC}
                    zipArrayValidation={zipArrayValidation}
                    geoLocations={geoLocations}
                    data={products.data}
                    benjaminsDelivery={BENJAMINS_DELIVERY}
                    isServicesConfirmed={isServicesConfirmed}
                    isAddressConfirmed={isAddressConfirmed}
                    user={user}
                    resetDelivery={resetDelivery}
                    onSelected={onSelected}
                    readAddress={readAddress}
                    renderAddress={renderAddress}
                    singleShipmentRatesError={singleShipmentRatesError}
                    setSingleShipmentRatesError={setSingleShipmentRatesError}
                    multiShipmentRatesError={multiShipmentRatesError}
                    setMultiShipmentRatesError={setMultiShipmentRatesError}
                    selectedShipmentRates={selectedShipmentRates}
                    renderBenjaminsDelivery={renderBenjaminsDelivery}
                  />
                </div>
              </div>

              {geoLocationError && (
                <div className="alert-box mt-2">
                  <p style={{ color: "red" }} className="text-center">
                    * We are unable to make delivery to some addresses, please
                    select another services.
                  </p>
                  <p style={{ color: "red" }} className="text-center">
                    WIC purchases only supports BenjaminsDelivery .
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="shipment-rates">
              <p className="text-center">
                Please confrim your addresses for delivery.
              </p>
            </div>
          )}

          <footer className="rest-cart-footer">
            <div style={{ display: "flex", gridGap: "15px" }}>
              {!isAddressConfirmed ? (
                <button
                  onClick={() => {
                    onConfirmSplitAddress();
                  }}
                  className="btn btn-primary"
                  type="button"
                >
                  <span className="text">Calculate Shipment Cost</span>
                </button>
              ) : (
                <Fragment>
                  <button
                    onClick={() => onDisproveSplitAddress()}
                    className="btn btn-primary-link"
                    type="button"
                  >
                    <span className="text">Return</span>
                  </button>

                  {!isServicesConfirmed ? (
                    <button
                      disabled={false}
                      onClick={() => onConfirmSplitService()}
                      className="btn btn-primary"
                      type="button"
                    >
                      <span className="text">Confirm Service</span>
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="btn btn-primary"
                      type="button"
                    >
                      <span className="text">Confirm Service</span>
                    </button>
                  )}
                </Fragment>
              )}
            </div>
          </footer>
        </Fragment>
      ) : (
        <Fragment>
          {isLengthSelectedWic ? (
            <Fragment>
              <div
                className={`shipment-rates ${
                  singleShipmentRatesError ? "error" : ""
                }`}
              >
                <div className="your-service">
                  <div className="form-group">
                    <div className="your-address">
                      <span className="address-label">Delivery type:</span>
                      <div
                        className={`address-value ${
                          isAddressConfirmed ? "disabled" : ""
                        }`}
                      >
                        <p>{renderBenjaminsDelivery()}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {geoLocationError && (
                  <div className="alert-box mt-2">
                    <p style={{ color: "red" }} className="text-center">
                      * We are unable to make delivery to entered address,
                      please change your address.
                    </p>
                    <p style={{ color: "red" }} className="text-center">
                      WIC purchases only supports BenjaminsDelivery .
                    </p>
                  </div>
                )}

                {singleShipmentRatesError && (
                  <div className="alert-box mt-2">
                    <p className="text-center">
                      * There was a problem while trying to calculate shipment
                      service cost! Please check you address and zip code.
                    </p>
                  </div>
                )}
              </div>

              <footer className="rest-cart-footer">
                <div style={{ display: "flex", gridGap: "15px" }}>
                  {isAddressConfirmed ? (
                    <Fragment>
                      <button
                        onClick={() => onDisproveAddresses()}
                        className="btn btn-primary-link"
                        type="button"
                      >
                        <span className="text">Return</span>
                      </button>

                      <button
                        disabled={true}
                        className="btn btn-primary"
                        type="button"
                      >
                        <span className="text">Calculate Shipment Cost</span>
                      </button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <button
                        onClick={() => {
                          onConfirmAddresses();
                        }}
                        className="btn btn-primary"
                        type="button"
                      >
                        <span className="text">Calculate Shipment Cost</span>
                      </button>
                    </Fragment>
                  )}
                </div>
              </footer>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className={`shipment-rates ${
                  singleShipmentRatesError ? "error" : ""
                }`}
              >
                <div className="form-group">
                  {isAddressConfirmed ? (
                    <Fragment>
                      {!loadingSingleShipmentRates ? (
                        <Fragment>
                          {isServicesConfirmed ? (
                            <Fragment>
                              <label htmlFor="selectSingleShipmentRatesDisabled">
                                Choose Service:
                              </label>

                              <select
                                disabled={true}
                                id="selectSingleShipmentRatesDisabled"
                                autoComplete="off"
                                name="selectSingleShipmentRatesDisabled"
                                value={selectSingleShipment.id || ""}
                                className={`select select-services ${
                                  serviceConfirmedError ? "isError" : ""
                                }`}
                              >
                                <option value="none">Select Service</option>

                                {singleShipmentRates.map((item) => {
                                  const {
                                    id,
                                    carrier,
                                    service,
                                    est_delivery_days,
                                    rate,
                                  } = item;

                                  return (
                                    <option value={id} key={id}>
                                      {service}, {carrier},{" "}
                                      {est_delivery_days &&
                                        est_delivery_days + ", "}
                                      {ratePrice({
                                        rate: item,
                                        details: totalAmountValue,
                                      })}
                                    </option>
                                  );
                                })}
                              </select>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <label htmlFor="selectSingleShipmentRates">
                                Choose Service:
                              </label>
                              <select
                                style={{
                                  borderColor: `${
                                    geoLocationError
                                      ? "hsl(354deg, 82%, 61%)"
                                      : ""
                                  }`,
                                  boxShadow: `${
                                    geoLocationError
                                      ? "0 0 0 3px rgba(237, 74, 90, 0.3)"
                                      : "none"
                                  }`,
                                }}
                                disabled={false}
                                id="selectSingleShipmentRates"
                                autoComplete="off"
                                name="selectSingleShipmentRates"
                                value={selectSingleShipment.id || ""}
                                onChange={(e) =>
                                  onClick({
                                    state: null,
                                    action: {
                                      type: "selectSingleShipmentRates",
                                      event: e,
                                    },
                                  })
                                }
                                className={`select select-services ${
                                  serviceConfirmedError ? "isError" : ""
                                }`}
                              >
                                <option value="none">Select Service</option>

                                {singleShipmentRates.map((item) => {
                                  const {
                                    id,
                                    carrier,
                                    service,
                                    est_delivery_days,
                                    rate,
                                  } = item;
                                  return (
                                    <option value={id} key={id}>
                                      {service}, {carrier},{" "}
                                      {est_delivery_days &&
                                        est_delivery_days + ", "}
                                      {ratePrice({
                                        rate: item,
                                        details: totalAmountValue,
                                      })}
                                    </option>
                                  );
                                })}
                              </select>
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="d-block mb-0 label">
                            Choose Service:
                          </span>
                          <LoadingPlaceholder
                            style={{ height: "40px", flex: "1" }}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <label htmlFor="selectGlobalShipmentRatesDisable">
                        Choose Service:
                      </label>

                      <select
                        disabled={true}
                        id="selectGlobalShipmentRatesDisable"
                        autoComplete="off"
                        name="selectGlobalShipmentRatesDisable"
                        value={"none"}
                        className="select select-services"
                      >
                        <option value="none">Select Service</option>
                      </select>
                    </Fragment>
                  )}
                </div>

                {geoLocationError && (
                  <div className="alert-box mt-2">
                    <p style={{ color: "red" }} className="text-center">
                      * We are unable to make delivery to entered address,
                      please{" "}
                      {selectedWic && selectedWic.length > 0
                        ? "change your zip code."
                        : "choose another service."}
                    </p>
                    <p style={{ color: "red" }} className="text-center">
                      WIC purchases only supports BenjaminsDelivery .
                    </p>
                  </div>
                )}

                {singleShipmentRatesError && (
                  <div className="alert-box mt-2">
                    <p className="text-center">
                      * There was a problem while trying to calculate shipment
                      service cost! Please check you address and zip code.
                    </p>
                  </div>
                )}
              </div>

              <footer className="rest-cart-footer">
                <div
                  style={{
                    display: "flex",
                    gridGap: "15px",
                  }}
                >
                  {isAddressConfirmed && (
                    <button
                      onClick={() => onDisproveAddresses()}
                      className="btn btn-primary-link"
                      type="button"
                    >
                      <span className="text">Return</span>
                    </button>
                  )}

                  {isAddressConfirmed ? (
                    <Fragment>
                      {loadingSingleShipmentRates ? (
                        <button
                          className="btn btn-confirm btn-primary"
                          type="button"
                        >
                          <span className="text">Confirm Service</span>
                        </button>
                      ) : (
                        <button
                          disabled={isServicesConfirmed}
                          onClick={() => onConfirmServices()}
                          className="btn btn-primary"
                          type="button"
                        >
                          <span className="text">Confirm Service</span>
                        </button>
                      )}
                    </Fragment>
                  ) : (
                    <button
                      onClick={() => {
                        onConfirmAddresses();
                      }}
                      className="btn btn-primary"
                      type="button"
                    >
                      <span className="text">Calculate Shipment Cost</span>
                    </button>
                  )}
                </div>
              </footer>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default AddressesTable;
