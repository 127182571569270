const PERMISSIONS = {
  // STORE
  store: "store",

  // PRODUCTS
  products: "products",
  createProducts: "create_products",
  readProducts: "read_products",
  updateProducts: "update_products",
  deleteProducts: "delete_products",
  createProduct: "create-product",
  updateProduct: "update-product",
  productDetails: "product-details",

  // ORDERS
  orders: "orders",
  createOrders: "create_orders",
  createOrder: "create-order",
  readOrders: "read_orders",
  updateOrders: "update_orders",
  deleteOrders: "delete_orders",
  accountSettings: "account-settings",
  shippingAddress: "shipping-address",

  // VARIATION PRODUCT
  variation: "variation",

  // DELIVERY
  delivery: "delivery",
  readDelivery: 'read_delivery_slot_settings',
  createDelivery: 'create_delivery_slot_settings',
  updateDelivery: 'update_delivery_slot_settings',
  deleteDelivery: 'delete_delivery_slot_settings',

  // GALLERY
  gallery: "gallery",
  readGallery: "read_images",
  createGallery: "create_images",
  updateGallery: "update_images",
  deleteGallery: "delete_images",

  // ATTRIBUTES
  attributes: "attributes",
  createAttributes: "create_attributes",
  readAttributes: "read_attributes",
  updateAttributes: "update_attributes",
  deleteAttributes: "delete_attributes",

  // ROLES
  roles: "roles",
  createRoles: "create_roles",
  readRoles: "read_roles",
  updateRoles: "update_roles",
  deleteRoles: "delete_roles",

  // CUSTOMERS
  customers: "customers",
  createCustomers: "create_customers",
  readCustomers: "read_customers",
  updateCustomers: "update_customers",
  deleteCustomers: "delete_customers",

  // EMPLOYEES
  employees: "employees",
  createEmployees: "create_employees",
  readEmployees: "read_employees",
  updateEmployees: "update_employees",
  deleteEmployees: "delete_employees",

  // CATEGORIES
  categories: "categories",
  createCategories: "create_categories",
  readCategories: "read_categories",
  updateCategories: "update_categories",
  deleteCategories: "delete_categories",

  // ACTIVITY
  activity: "activity",
  readActivity: "read_logs",

  // PROFILE
  profile: "profile",
  readProfile: "read_profile",
  updateProfile: "update_profile",
  deleteProfile: "delete_profile",

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: "employee-email-verification",

  // AUTH TYPE
  authType: "basic",

  // SIGN IN PATH
  signInPath: "dashboard",

  // LOGIN
  login: "login",

  // FORGOT PASSWORD
  forgotPassword: "forgot-password",

  // PASSWORD RESET
  passwordReset: "password-reset",

  // DASHBOARD
  dashboard: "admin",

  // GROCERY
  grocery: "grocery",
  departmentGrocery: "grocery",

  // PHARMACY
  pharmacy: "pharmacy",
  departmentPharmacy: "pharmacy",

  // COUPONS
  coupons: "coupons",
  readCoupons: "read_coupons",
  updateCoupons: "update_coupons",
  deleteCoupons: "delete_coupons",
  createCoupons: "create_coupons",

  // GEO LOCATION
  geoLocations: "geo-locations",
  readGeoLocations: "read_allowed_zip_codes",
  updateGeoLocations: "update_allowed_zip_codes",
  deleteGeoLocations: "delete_allowed_zip_codes",
  createGeoLocations: "create_allowed_zip_codes",
};

const USER = "user";

const SUPER_ADMIN = "Super Admin";

export { PERMISSIONS, USER, SUPER_ADMIN };
