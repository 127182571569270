import React, { Fragment } from "react";
import { useCart } from "../../context/cart";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { SimpleProduct, VariableProduct } from "./product/Product";
import { useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../utils/permissions";

function Cart(props) {
  const { handleClearCart, cart } = useCart();
  const navigate = useNavigate();
  
  const departmentBackStep = window.location.href.includes('grocery') ? PERMISSIONS.grocery : PERMISSIONS.pharmacy

  function NextStepButton() {
    if (typeof window !== "undefined") {
      if (window.location.pathname.endsWith("create-order")) {
        return (
          <button
            onClick={() => navigate(`${PERMISSIONS.accountSettings}`)}
            disabled={cart && cart.length <= 0}
            className={"btn btn-primary"}
            style={{ width: "250px" }}
          >
            Next Step
          </button>
        );
      } else if (window.location.pathname.endsWith("account-settings")) {
        const noCustomer = props.customer ? false : true;
        const noCart = cart && cart.length >= 1;
        return (
          <div
            style={{
              display: "grid",
              columnGap: "20px",
              gridTemplateColumns: "repeat(2,1fr)",
            }}
          >
            <button
              onClick={() =>
                navigate(
                  `/${PERMISSIONS.dashboard}/${departmentBackStep}/${PERMISSIONS.createOrder}`
                )
              }
              className="btn btn-primary-outline"
            >
              Back
            </button>

            <button
              onClick={() => navigate(`${PERMISSIONS.shippingAddress}`)}
              disabled={noCustomer || !noCart}
              className={"btn btn-primary"}
            >
              Next Step
            </button>
          </div>
        );
      }
    }
  }

  return (
    <div className="cart__order">
      <header className="cart__header">
        <span>Your cart</span>
        <div className="button__container">
          <button onClick={handleClearCart} type="button" className="button">
            <img src="/assets/icons/delete.svg" />
            Clear cart
          </button>
        </div>
      </header>

      <div className="cartItems">
        {cart && cart.length > 0 ? (
          cart.map((item, idx) => {
            const { productType } = item;
            return productType === "simple_product" ? (
              <Fragment key={idx}>
                <SimpleProduct item={item} index={idx} />
              </Fragment>
            ) : (
              <Fragment key={idx}>
                <VariableProduct item={item} index={idx} />
              </Fragment>
            );
          })
        ) : (
          <div className="emptyCart">
            <div>
              <ShoppingCartOutlined
                style={{
                  fontSize: "25px",
                  padding: "8px 9px 8px 8px",
                  borderRadius: "8px",
                  color: window.location.href.includes('grocery') ? "#4762E6" : 'hsl(165, 150%, 35%)',
                }}
              />
              <span style={{color: window.location.href.includes('grocery') ? "#4762E6" : 'hsl(165, 150%, 35%)'}}>Cart is Empty</span>
            </div>
          </div>
        )}
      </div>

      <div className="step__button">
        <NextStepButton />
      </div>
    </div>
  );
}

export default Cart;
