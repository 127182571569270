import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { PERMISSIONS, SUPER_ADMIN } from '../../../utils/permissions';

const Table = (props) => {
  // TABLE
  const { data, handleCheckedRows, handleDisabledButton, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;

  // CUSTOM FOR ACTIONS
  const { updateActions, deleteActions, location, pharmacyPermissions, getPharmacyPermissions } = props;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [handleCheckedRows, isCheck]);

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>{Object.values(pharmacyPermissions).some((key) => [PERMISSIONS.deleteRoles].includes(key.name)) && <Checkbox data-cy="checkbox-all-roles" type="checkbox" name="select-all-roles" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />}</th>
                <th>Role Name</th>
                <th>
                  <div className="table-grid-layout">
                    <span>Store</span>
                    <span>Permissions</span>
                  </div>
                </th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {data &&
                data.length > 0 &&
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{!item.name.includes(SUPER_ADMIN) && Object.values(pharmacyPermissions).some((key) => [PERMISSIONS.updateRoles, PERMISSIONS.deleteRoles].includes(key.name)) && <Checkbox key={item._id} type="checkbox" name={item.name.replace(' ', '-').toLowerCase()} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />}</td>
                      <td>
                        <span style={{ textTransform: 'capitalize' }} className="d-block" data-cy="role-name">
                          {item.name}
                        </span>
                      </td>
                      <td className="permissions">
                        {Object.entries(item.permissions)
                          .filter((item) => item.includes('grocery'))
                          .map((item, idx) => {
                            const department = Object.values(item)[0];
                            const permissions = Object.values(item)[1];

                            return (
                              <div className="table-grid-layout" key={idx}>
                                <div className="first">
                                  <span style={{ textTransform: 'capitalize' }} className="d-block mb-2">
                                    {department}
                                  </span>
                                </div>

                                <div className="group">
                                  {Object.entries(permissions).map((item, idx) => {
                                    const label = Object.values(item)[0];
                                    const list = Object.values(item)[1];

                                    return (
                                      <div key={idx}>
                                        <span style={{ textTransform: 'capitalize' }} className="d-block mb-2">
                                          {label}
                                        </span>

                                        <div className="d-grid gap-y-1 mb-2" data-cy={`permissions-grocery-${label}`}>
                                          {list.map((item, idx) => {
                                            return (
                                              <div className="d-flex gap-x-2" key={idx}>
                                                <span className={`fake-checkbox ${item.checked ? 'checked' : ''}`}></span>
                                                <span style={{ textTransform: 'capitalize' }} className="d-block">
                                                  {item.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}

                        {Object.entries(item.permissions).length > 1 && <hr className="hr" />}

                        {Object.entries(item.permissions)
                          .filter((item) => item.includes('pharmacy'))
                          .map((item, idx) => {
                            const department = Object.values(item)[0];
                            const permissions = Object.values(item)[1];

                            return (
                              <div className="table-grid-layout" key={idx}>
                                <div className="first">
                                  <span style={{ textTransform: 'capitalize' }} className="d-block mb-2">
                                    {department}
                                  </span>
                                </div>

                                <div className="group">
                                  {Object.entries(permissions).map((item, idx) => {
                                    const label = Object.values(item)[0];
                                    const list = Object.values(item)[1];

                                    return (
                                      <div key={idx}>
                                        <span style={{ textTransform: 'capitalize' }} className="d-block mb-2">
                                          {label}
                                        </span>

                                        <div className="d-grid gap-y-1 mb-2" data-cy={`permissions-pharmacy-${label}`}>
                                          {list.map((item, idx) => {
                                            return (
                                              <div className="d-flex gap-x-2" key={idx}>
                                                <span className={`fake-checkbox ${item.checked ? 'checked' : ''}`}></span>
                                                <span style={{ textTransform: 'capitalize' }} className="d-block">
                                                  {item.label}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                      </td>
                      <td>
                        {!item.name.includes(SUPER_ADMIN) && Object.values(pharmacyPermissions).some((key) => [PERMISSIONS.updateRoles, PERMISSIONS.deleteRoles].includes(key.name)) && (
                          <Popover
                            className="tbody-actions"
                            placement="left"
                            trigger="click"
                            content={() => (
                              <div className="actions" data-cy="actions-popover-roles">
                                {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && (
                                  <>
                                    {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.updateRoles) && (
                                      <button
                                        className="btn btn-actions"
                                        type="button"
                                        data-cy="actions-edit-roles"
                                        onClick={() =>
                                          handleToggle({
                                            name: 'update',
                                            state: updateActions,
                                            data: item._id,
                                            status: item
                                          })
                                        }
                                      >
                                        <span className="icon">
                                          <img alt="Delete" src="/assets/icons/edit-user.svg" />
                                        </span>
                                        <span className="text">
                                          <strong>Edit</strong>
                                        </span>
                                      </button>
                                    )}

                                    {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.deleteRoles) && (
                                      <button data-cy="actions-delete-roles" className="btn btn-actions" type="button" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
                                        <span className="icon">
                                          <img alt="Delete" src="/assets/icons/delete.svg" />
                                        </span>
                                        <span className="text">
                                          <strong>Delete</strong>
                                        </span>
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          >
                            <button className="btn" type="button" data-cy="actions-toggle-roles">
                              <span className="icon">
                                <img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
                              </span>
                            </button>
                          </Popover>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <Pagination {...pagination} />
        </>
      ) : (
        <Message className="content-message">
          <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
        </Message>
      )}
    </>
  );
};

export default Table;
