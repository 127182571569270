import React, { useState, useRef, useEffect } from "react";
import { useProducts } from "../../../../../../../../context/products";
import Modal from "../../../../../../../../components/modal/Modal";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../../../context/useAuth";
import axios from "axios";
import { SERVER_URL } from "../../../../../../../../config";
import { AXIOS_API_CALL } from "../../../../../../../../utils/endpoint";
import { PERMISSIONS } from "../../../../../../../../utils/permissions";

export const FormDynamic = (props) => {
  const {
    index,
    form,
    handleToggleGallery,
    setToggleGalleryModalIndex,
    handleFormChange,
    attributes,
    removeFields,
  } = props;

  const { formError } = useProducts();
  const { id: productId } = useParams();

  const dropdownRefs = useRef(null);
  const [height, setHeight] = useState(0);
  const [toggle, setToggle] = useState(false);
  const { user } = useAuth();

  const deleteModalFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);

  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
  };

  const handleToggle = () => {
    setToggle((p) => !p);
    if (!toggle) {
      setHeight(dropdownRefs.current.scrollHeight);
    } else {
      setHeight(0);
    }
  };

  useEffect(() => {
    if (toggle) {
      setHeight(dropdownRefs.current.scrollHeight);
    }
  }, [toggle, dropdownRefs, setHeight, formError]);

  const onSubmit = async () => {
    const { token } = user;

    if (form.variant_id && productId) {
      await axios
        .delete(
          `${SERVER_URL}/${AXIOS_API_CALL.deleteVariation}/${form.variant_id}/${productId}`,
          {
            withCredentials: false,
            headers: {
              department: PERMISSIONS.grocery,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            removeFields(form._id);
            setToggleDelete(false);
          }
        })
        .catch((err) => {
          console.error({ err });
        });
    } else {
      removeFields(form._id);
      setToggleDelete(false);
    }
  };

  return (
    <div className={`form-wrap ${toggle ? "isActive" : ""}`} key={index}>
      <div className="form-row">
        <span>
          <b>
            # <small>{form._id}</small>
          </b>
        </span>

        <div className="form-group form-group-info">
          <div className="select-area">
            {attributes.map((attr) => {
              const options = attr.selectedUnits;

              return (
                <select
                  onChange={(event) =>
                    handleFormChange({
                      event: event,
                      index: index,
                      attributes: true,
                      name: attr,
                    })
                  }
                  name={attr.name}
                  className="select"
                  key={attr._id}
                >
                  <option value="none">Choose {attr.name}</option>

                  {options.map((item) => {
                    const selectedUnit = form.units.find((unit) => {
                      const value = Object.values(unit)[0];
                      return value === item._id;
                    });

                    if (selectedUnit) {
                      return (
                        <option value={item._id} selected={true} key={item._id}>
                          {item.value}
                        </option>
                      );
                    } else {
                      return (
                        <option value={item._id} key={item._id}>
                          {item.value}
                        </option>
                      );
                    }
                  })}
                </select>
              );
            })}
          </div>
        </div>

        <div className="form-group form-group-actions">
          <button
            type="button"
            className="btn btn-danger-link"
            onClick={() => {
              setToggleDelete(true);
            }}
          >
            <span className="icon">
              <img
                src="/assets/icons/delete.svg"
                className="img"
                alt="Delete"
              />
            </span>
          </button>

          <button
            type="button"
            className={`btn btn-danger-link btn-expand ${toggle ? "isActive" : ""
              }`}
            onClick={() => handleToggle()}
          >
            <span className="icon">
              <img
                src="/assets/icons/arrow-dark.svg"
                className="img"
                alt="Arrow Down"
              />
            </span>
          </button>
        </div>
      </div>
      <div className={`separator ${toggle ? "isActive" : ""}`} />
      <div
        ref={dropdownRefs}
        className={`hidden-form ${toggle ? "isActive" : ""}`}
        style={{ height: height }}
      >
        <div className="block">
          <div className="block-top">
            <div
              className="image-area"
              onClick={() => {
                handleToggleGallery(index, form?.variantImage);
                setToggleGalleryModalIndex(index);
              }}
            >
              {form?.variantImage?.url !== "" && (
                <img src={form?.variantImage?.url} alt="" className="img" />
              )}
            </div>

            <div className="form-group">
              <label className="label" htmlFor="variantSku">
                <b>SKU:</b>
              </label>
              <input
                onChange={(event) =>
                  handleFormChange({
                    event: event,
                    index: index,
                    attributes: false,
                  })
                }
                value={form.variantSku || ""}
                name="variantSku"
                id="variantSku"
                type="text"
                placeholder="SKU"
                className="input"
              />
            </div>
          </div>
          <div className="block-bottom">
            <div className="form-group">
              <label className="label" htmlFor="variantRegularPrice">
                <b>Regular Price:</b>
              </label>
              <input
                onChange={(event) =>
                  handleFormChange({
                    event: event,
                    index: index,
                    attributes: false,
                  })
                }
                value={form.variantRegularPrice || ""}
                name="variantRegularPrice"
                id="variantRegularPrice"
                type="text"
                placeholder="Regular price"
                inputMode="decimal"
                className={`input ${formError.productVariations[index]?.regularPrice?.empty ||
                    formError.productVariations[index]?.regularPrice?.format
                    ? "input-error"
                    : ""
                  }`}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label" htmlFor="variantSalePrice">
                <b>Sale Price:</b>
              </label>
              <input
                onChange={(event) =>
                  handleFormChange({
                    event: event,
                    index: index,
                    attributes: false,
                  })
                }
                value={form.variantSalePrice || ""}
                name="variantSalePrice"
                id="variantSalePrice"
                type="text"
                placeholder="Sale price"
                inputMode="decimal"
                className={`input ${formError.productVariations[index]?.salePrice?.bigger ||
                    formError.productVariations[index]?.salePrice?.format
                    ? "input-error"
                    : ""
                  }`}
                min={0}
                max={form.variantRegularPrice}
              />
            </div>
            <div className="form-group">
              <label className="label" htmlFor="productWeight">
                <b>Weight:</b>
              </label>

              <input
                onChange={(event) =>
                  handleFormChange({
                    event: event,
                    index: index,
                    attributes: false,
                  })
                }
                name="variantWeight"
                type="text"
                value={form.variantWeight}
                placeholder="Weight"
                id="variantWeight"
                inputMode="numeric"
                className={`input ${formError.productVariations[index]?.variantWeight?.empty ||
                    formError.productVariations[index]?.variantWeight?.format
                    ? "input-error"
                    : ""
                  }`}
              />
            </div>
            <div className="form-group">
              <label className="label" htmlFor="variantQuantity">
                <b>Quantity:</b>
              </label>
              <input
                onChange={(event) =>
                  handleFormChange({
                    event: event,
                    index: index,
                    attributes: false,
                  })
                }
                value={form.variantQuantity || ""}
                name="variantQuantity"
                id="variantQuantity"
                type="text"
                pattern="[0-9]*"
                placeholder="Quantity"
                className="input"
              />
            </div>
          </div>
          {formError.productVariations[index]?.regularPrice?.empty ? (
            <p data-cy="" className="relative-error error__onblur error">
              * Regular price is a required field!
            </p>
          ) : (
            formError.productVariations[index]?.regularPrice?.format && (
              <p data-cy="" className="relative-error error__onblur error">
                * Regular price must have none or two decimal points!
              </p>
            )
          )}
          {formError.productVariations[index]?.salePrice?.bigger && (
            <p data-cy="" className="relative-error error__onblur error">
              * Sale price must be lower that regular price!
            </p>
          )}

          {formError.productVariations[index]?.salePrice?.format && (
            <p data-cy="" className="relative-error error__onblur error">
              * Sale price must have none or two decimal points!
            </p>
          )}
          {formError.productVariations[index]?.variantWeight?.empty ? (
            <p data-cy="" className="relative-error error__onblur error">
              * Weight is a required field!
            </p>
          ) : (
            formError.productVariations[index]?.variantWeight?.format && (
              <p data-cy="" className="relative-error error__onblur error">
                * Weight must have none or one decimal points!
              </p>
            )
          )}
        </div>
      </div>

      {(formError.productVariations[index]?.regularPrice?.empty ||
        formError.productVariations[index]?.regularPrice?.format ||
        formError.productVariations[index]?.salePrice?.bigger ||
        formError.productVariations[index]?.variantWeight?.empty ||
        formError.productVariations[index]?.variantWeight?.format ||
        formError.productVariations[index]?.salePrice?.format) &&
        !toggle && (
          <p data-cy="" className="relative-error error__onblur error">
            * This variation has errors!
          </p>
        )}

      {/* Delete Modal */}
      <Modal {...deleteProps} className="sidebar__modal--center" title="">
        <h2 className="text-center mb-4">
          Are you sure you want to remove the selected variation ?
        </h2>

        <div className="form-group form-group-modal mb-4">
          <button
            data-cy="modal-delete-submit-btn"
            type="button"
            className="btn btn-primary-link"
            onClick={() => onSubmit()}
          >
            <span className="text">Yes</span>
          </button>

          <button
            data-cy="modal-delete-cancel-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => setToggleDelete(false)}
          >
            <span className="text">No</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};
