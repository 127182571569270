import { Fragment } from "react";
import { useCart } from "../../../context/cart";
import { sumFormatPrice } from "../../../helpers/helpers";

const SimpleProduct = ({ item, index }) => {
  const { handleQuantity, cart } = useCart();

  return (
    <Fragment>
      <div className="cartItem">
        <div className="imageContainer">
          {item?.featureImage ? (
            <img
              src={
                item?.featureImage?.url
                  ? item.featureImage.url
                  : item.featureImage
              }
              alt={item?.title}
              width={80}
              height={80}
            />
          ) : (
            <img
              alt="placeholder"
              width={80}
              height={80}
              src="/assets/images/product-placeholder.svg"
            />
          )}
        </div>

        <div className="descriptionContainer">
          <div className="aboutItem">
            <span>{item?.title}</span>
          </div>

          <div className="actions">
            <div className="quantity">
              <button
                onClick={() =>
                  handleQuantity({
                    action: "decrement-simple",
                    payload: { productId: item._id, product: item },
                  })
                }
                className="quantityButton"
                type="button"
              >
                <span className="icon">
                  <img
                    width={14}
                    height={14}
                    src="/assets/icons/minus-icon-white.svg"
                  />
                </span>
              </button>

              <span className="number">{item?.qty}</span>

              <button
                onClick={() =>
                  handleQuantity({
                    action: "increment-simple",
                    payload: { productId: item._id, product: item },
                  })
                }
                className="quantityButton"
                type="button"
              >
                <span className="icon">
                  <img
                    src="/assets/icons/plus-icons-white.svg"
                    width={14}
                    height={14}
                    alt="plusIcon"
                  />
                </span>
              </button>
            </div>

            <div className="d-flex" style={{ gap: "10px" }}>
              {item &&
                item.eligible.length > 0 &&
                item.eligible.map((item, idx) => {
                  return (
                    <span
                      key={idx}
                      className={`badge badge-single badge-single-${item?.toLowerCase()}`}
                    >
                      {item}
                    </span>
                  );
                })}
            </div>

            <div className="price">
              <span>{sumFormatPrice(item)}</span>
            </div>
          </div>
        </div>
      </div>

      {index === cart.length - 1 ? null : (
        <div
          style={{
            height: "1px",
            background: "lightgray",
            width: "100%",
            margin: "10px 0px",
          }}
        />
      )}
    </Fragment>
  );
};

const VariableProduct = ({ item, index }) => {
  const { title, variations, eligible, url } = item;
  const {
    handleQuantity,
    cart,
  } = useCart();

  const reverseVariations = [...variations]?.reverse();

  return (
    <div className={`item-variable`}>
      {reverseVariations &&
        reverseVariations.length > 0 &&
        reverseVariations.map((variation, idx) => {
          let params = "?";
          const { qty, variationId, units, _id, image } = variation;
          units.map((item, index, arr) => {
            params += `${item.units.name.toLowerCase()}=${item.units._id}`;
            index < arr.length - 1 ? (params += `&`) : null;
          });

          let isOnSale = false;

          if (variation.SalePrice !== "" && variation.SalePrice !== null) {
            if (+variation.SalePrice < +variation.RegularPrice) isOnSale = true;
          }

          const payload = {
            ...variation,
            isOnSale,
            productType: "variable_product",
          };

          return (
            <Fragment key={idx}>
              <div className="cartItem">
                <div className="imageContainer">
                  {item?.featureImage ? (
                    <img
                      src={
                        item?.featureImage?.url
                          ? item.featureImage.url
                          : item.featureImage
                      }
                      alt={item?.title}
                      width={80}
                      height={80}
                    />
                  ) : (
                    <img
                      alt="placeholder"
                      width={80}
                      height={80}
                      src="/assets/images/product-placeholder.svg"
                    />
                  )}
                </div>

                <div className="descriptionContainer">
                  <div className="aboutItem">
                    <span>{item?.title}</span>
                  </div>

                  <div className="actions">
                    <div className="quantity">
                      <button
                        onClick={() =>
                          handleQuantity({
                            action: "decrement-variable",
                            payload: {
                              productId: item._id,
                              variationId: variationId,
                              product: variation,
                            },
                          })
                        }
                        className="quantityButton"
                        type="button"
                      >
                        <span className="icon">
                          <img
                            width={14}
                            height={14}
                            src="/assets/icons/minus-icon-white.svg"
                          />
                        </span>
                      </button>

                      <span className="number">{qty}</span>

                      <button
                        onClick={() =>
                          handleQuantity({
                            action: "increment-variable",
                            payload: {
                              productId: item._id,
                              variationId: variationId,
                              product: variation,
                            },
                          })
                        }
                        className="quantityButton"
                        type="button"
                      >
                        <span className="icon">
                          <img
                            src="/assets/icons/plus-icons-white.svg"
                            width={14}
                            height={14}
                            alt="plusIcon"
                          />
                        </span>
                      </button>
                    </div>

                    <div className="d-flex" style={{ gap: "10px" }}>
                      {item &&
                        item.eligible.length > 0 &&
                        item.eligible.map((item, idx) => {
                          return (
                            <span
                              key={idx}
                              className={`badge badge-single badge-single-${item?.toLowerCase()}`}
                            >
                              {item}
                            </span>
                          );
                        })}
                    </div>

                    <div className="price">
                      <span>{sumFormatPrice({ ...payload })}</span>
                    </div>
                  </div>
                </div>
              </div>

              {index === cart.length - 1 ? null : (
                <div
                  style={{
                    height: "1px",
                    background: "lightgray",
                    width: "100%",
                    margin: "10px 0px",
                  }}
                />
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export { SimpleProduct, VariableProduct };
