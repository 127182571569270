import React, { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../../config";
import { useCart } from "../../context/cart";
import { formatPrice } from "../../helpers/helpers";

function VariationProduct({ id, onToggle }) {
  const {
    setQty,
    qty,
    handleCartProduct,
    selectedAttributesUnit,
    setSelectedAttributesUnit,
  } = useCart();

  const [variableProduct, setVariableProduct] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [selected, setSelected] = useState({});

  //animation
  const [animate, setAnimate] = useState(false);

  const handleAddQuantity = () => {
    setQty((prev) => prev + 1);
  };
  const handleRemoveQuantity = () => {
    setQty((prev) => {
      if (prev <= 1) {
        return 1;
      } else {
        return prev - 1;
      }
    });
  };

  const handleAnimate = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 450);
  };

  useEffect(() => {
    const getReadData = async () => {
      const units = selected && Object.values(selected);

      await axios
        .post(`${SERVER_URL}/get-product-data/${id}`, { units: units })
        .then((res) => {
          setVariableProduct(res.data?.message);
          setAttributes(res.data?.message?.productGlobalData?.attributes);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    if (id) {
      getReadData();
    }

    return () => {};
  }, [id, selected, setSelected]);

  const handleSelectOptions = (event) => (id) => (item) => {
    const { name, value } = event.target;
    setSelected((prev) => {
      const keyName = name.toLowerCase();
      if (value !== "none") {
        return { ...prev, [keyName]: value };
      } else {
        delete prev[keyName];
        return { ...prev };
      }
    });

    setSelectedAttributesUnit((prev) => {
      if (value !== "none") {
        const findUnitName = item.values.find((unit) => unit._id === value);
        const findElement = prev.findIndex((item) => item._id === id);
        let newArr = {};
        if (findElement === -1) {
          const elements = {
            name: name,
            _id: id,
            units: { _id: value, name: name, value: findUnitName.value },
          };
          return [...prev, elements];
        } else {
          return prev.map((item) => {
            if (item._id === id) {
              if (findUnitName._id === value) {
                newArr = {
                  ...item,
                  units: { _id: value, name: name, value: findUnitName.value },
                };
              }
              return {
                ...item,
                ...newArr,
              };
            } else {
              return {
                ...item,
              };
            }
          });
        }
      } else {
        return prev.filter((item) => item._id !== id);
      }
    });
  };

  const product = variableProduct.variantData && variableProduct.variantData[0];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <div style={{ height: "100%" }}>
          <div className="variation" style={{ padding: "20px" }}>
            <div className="variation__description">
              <div className="productInfo">
                <h2 className="title">
                  {variableProduct?.productGlobalData?.title}
                </h2>
                <div className="attribute-items">
                  {attributes &&
                    attributes.length > 0 &&
                    attributes.map((item) => {
                      const { Attribute, values } = item;
                      const attributeLength = Attribute && Attribute.length - 1;
                      const attributeName = Attribute[attributeLength]?.name;
                      const unitValue = selected.hasOwnProperty(
                        attributeName?.toLowerCase()
                      )
                        ? selected[attributeName?.toLowerCase()]
                        : "";
                      return (
                        <div key={item._id}>
                          <select
                            onChange={(event) =>
                              handleSelectOptions(event)(item._id)(item)
                            }
                            value={unitValue}
                            name={attributeName}
                          >
                            <option value="none">Choose {attributeName}</option>
                            {values.map((val) => {
                              return (
                                <option key={val._id} value={val._id}>
                                  {val.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      );
                    })}
                </div>
              </div>
              {product && (
                <div className="productActions">
                  <div className="actions-wrapper">
                    <div className="left">
                      <div>
                        <h4>{formatPrice(product)}</h4>
                      </div>
                      <div>
                        <div className="quantity">
                          <button
                            onClick={() => handleRemoveQuantity()}
                            className="quantityButton"
                            type="button"
                          >
                            <span className="icon">
                              <img
                                width={14}
                                height={14}
                                src="/assets/icons/minus-icon-white.svg"
                              />
                            </span>
                          </button>

                          <span className="number">{qty}</span>

                          <button
                            onClick={() => handleAddQuantity()}
                            className="quantityButton"
                            type="button"
                          >
                            <span className="icon">
                              <img
                                src="/assets/icons/plus-icons-white.svg"
                                width={14}
                                height={14}
                                alt="plusIcon"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <button
                        onClick={() => {
                          handleAnimate();
                          handleCartProduct({
                            productType: "variable",
                            product: variableProduct.productGlobalData,
                            productVariation: variableProduct.variantData,
                            qty: qty,
                            units: selectedAttributesUnit,
                          });
                          setTimeout(() => {
                            onToggle(false);
                          }, [300]);
                        }}
                        className={`add-to-cart ${animate ? "bounce" : ""}`}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VariationProduct;
