import React, {useState, useEffect, useCallback} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom';
import { Section, Main } from '../../../components/content';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { PERMISSIONS } from '../../../utils/permissions';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { formatTime, formatDate } from '../../../helpers/helpers';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import { getPharmacyPermissions } from '../../../middleware';

const OrderDetails = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const location = useLocation();
  const [pharmacyPermissions, setPharmacyPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.pharmacy))) {
            setPharmacyPermissions(permissions.pharmacy);
        }
      }
    }
  }, [location, user]);

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    location: location,
    pharmacyPermissions: pharmacyPermissions,
    getPharmacyPermissions: getPharmacyPermissions
  };

  // READ
  const [readData, setReadData] = useState([]);
  const [timeDate, setTimeDate] = useState("");
  const [orderId, setOrderId] = useState("");
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.orderDetails}/${id}`, { department: PERMISSIONS.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          return res;
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200)
        );

      return response;
    } catch (err) {}
  }, [user, id]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
        setTimeDate(formatTime(res?.data.data).slice(0, 5) + " / " + formatDate(res?.data.data).replaceAll('/', '.') + ".");
        setOrderId(res?.data.data.orderNumber);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData]);







  return (
    <>
      <h2 className="page-title order-details">
        <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}`}>Orders</Link> 
        <img src="/assets/icons/right-arrow.svg" alt="arrow" />
        Order Details      
      </h2>
      <Section className="section__wrapper section__order-details">
        <header className="section__header">
          <div className="title">Order Details</div>
          {!readDataLoading ?
            <>
              <div className="title-details">Order Time/Date: <span className='order-date-id'>{timeDate}</span></div>
              <div className="title-details">Order Id: <span className='order-date-id'>{orderId}</span></div> 
            </>
            : <LoadingPlaceholder style={{ width: '500px', height: '50px' }} />
          }
        </header>
        {/* Main Content */}
        <Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData.data} {...tableActionsProps} />}</Main>
      </Section>
    </>
  )
}

export default OrderDetails