import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { AXIOS_API_CALL } from "../../utils/endpoint";
import { SERVER_URL } from "../../config";
import axios from "axios";
import { useAuth } from "../useAuth";
import { notification } from "antd";
import { PERMISSIONS } from "../../utils/permissions";

const CartContext = createContext(null);

const CartProvider = ({ children }) => {
  const { user } = useAuth();

  let customerId = window.localStorage.getItem("dashboardCustomerId")
    ? JSON.parse(window.localStorage.getItem("dashboardCustomerId"))
    : "";

  const [qty, setQty] = useState(1);
  const [refetch, setRefetch] = useState(false);
  const [refetchCard, setRefetchCard] = useState(false);
  const [selectedAttributesUnit, setSelectedAttributesUnit] = useState([]);
  // open split shipping
  const [isSplitShipping, setIsSplitShipping] = useState(false);
  const [cart, setCart] = useState(() => {
    if (typeof window !== "undefined") {
      const cartData = JSON.parse(window.localStorage.getItem("dashboardCart"));

      if (cartData !== null) {
        return cartData || [];
      }
    }
    return [];
  });

  const [readDataLoading, setReadDataLoading] = useState(true);
  // customer addresses
  const [readAddress, setCustomerAddresses] = useState([]);
  // refetch
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  // customer
  const [customer, setCustomer] = useState([]);

  const addLastProduct = (product) => {
    setLastProductAdded(product);
    setLatestToLocal((prev) => !prev);
  };

  const [latestToLocal, setLatestToLocal] = useState(false);
  const [lastProductAdded, setLastProductAdded] = useState(
    typeof window !== "undefined" &&
      JSON.parse(window.localStorage.getItem("dashboardLatestProduct"))
  );
  const handleCartProduct = ({
    productType,
    product,
    productVariation,
    qty,
    units,
  }) => {
    switch (productType) {
      case "simple":
        const items = Object.assign({}, product);
        const index = cart && cart.findIndex((c) => c._id === items._id);
        const weight = items.weight || null;

        if (index === -1) {
          setCart((prev) => [
            ...prev,
            {
              ...items,
              qty: qty,
              variations: [],
              weight: weight,
            },
          ]);
          setQty(1);
        } else {
          setCart((prev) => {
            return prev.map((item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  qty: item.qty + qty,
                  variations: [],
                  weight,
                };
              }
              return item;
            });
          });
          setQty(1);
        }
        addLastProduct(product);
        break;
      case "variable":
        const itemsVariable = Object.assign({}, product);
        const indexVariable =
          cart && cart.findIndex((c) => c._id === itemsVariable._id);
        const uniqueUnitIds = units.map((item) => item.units._id);
        const variationId = itemsVariable._id + "_" + uniqueUnitIds.join("_");

        const findVariation = productVariation.find(
          (item) => item.Product_ID === itemsVariable._id
        );
        const isRegularPrice =
          findVariation && findVariation.hasOwnProperty("RegularPrice");
        const isSalePrice =
          findVariation && findVariation.hasOwnProperty("SalePrice");
        const isFeaturedImage =
          findVariation && findVariation.hasOwnProperty("Image");
        const isImage = isFeaturedImage && findVariation.Image;
        const productWeight = findVariation.weight || null;
        let selectedVariation = {};
        let addVariation = {
          ...findVariation,
          title: product.title,
          featureImage: {
            url:
              findVariation && findVariation.Image && findVariation.Image.url
                ? findVariation.Image.url
                : product &&
                  product.featureImage &&
                  product.featureImage !== null &&
                  product.featureImage.url &&
                  product.featureImage.url.length > 0
                ? product.featureImage.url
                : "",
          },
        };
        addLastProduct(addVariation);
        if (isRegularPrice) {
          const regularPrice = findVariation.RegularPrice;
          selectedVariation = {
            ...selectedVariation,
            RegularPrice: regularPrice,
          };
        }

        if (isSalePrice) {
          const salePrice = findVariation.SalePrice;
          selectedVariation = {
            ...selectedVariation,
            SalePrice: salePrice,
          };
        }

        if (isImage) {
          selectedVariation = {
            ...selectedVariation,
            image: findVariation.Image,
            image: findVariation.Image,
          };
        }

        if (indexVariable === -1) {
          const variation_id = productVariation[0]._id;

          setCart((prevState) => {
            const variation = {
              qty: qty,
              variationId: variationId,
              units: units,
              ...selectedVariation,
              _id: variation_id,
              weight: productWeight,
            };
            itemsVariable.qty = qty;
            const newProduct = {
              ...itemsVariable,
              variations: [variation],
            };
            return [...prevState, newProduct];
          });

          setQty(1);
        } else {
          const variation_id = productVariation[0]._id;

          setCart((prevState) => {
            const isCurrentVatiation = prevState.map((item) =>
              item.variations.some(
                (variation) => variation.variationId === variationId
              )
            );
            const findElement = prevState.find(
              (item) => item._id === product._id
            );
            const findVariation = findElement.variations.find(
              (item) => item.variationId === variationId
            );
            const findVariations = prevState.filter(
              (item) => item._id !== product._id
            );

            if (isCurrentVatiation && findVariation) {
              findElement.qty = findElement.qty + qty;
              findVariation.qty = findVariation.qty + qty;
              findVariation.weight = productWeight;

              return [...findVariations, findElement];
            } else {
              const variation = {
                qty: qty,
                variationId: variationId,
                units: units,
                ...selectedVariation,
                _id: variation_id,
                weight: productWeight,
              };
              findElement.qty = findElement.qty + qty;
              findElement.variations.push(variation);

              return [...findVariations, findElement];
            }
          });

          setQty(1);
        }
        break;
      default:
        console.warn("This function: handleCartProduct is not valid swith!");
    }
  };

  useEffect(() => {
    window.localStorage.setItem(
      "dashboardLatestProduct",
      JSON.stringify(lastProductAdded)
    );
  }, [latestToLocal]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("dashboardCart", JSON.stringify(cart));
    }
  }, [cart]);

  const handleAddToCart = (product) => {
    addLastProduct(product);
    const items = Object.assign({}, product);
    const index = cart && cart.findIndex((c) => c._id === items._id);
    const weight = items.weight || null;

    if (index === -1) {
      setCart((prev) => {
        return [
          ...prev,
          {
            ...items,
            qty: 1,
            variations: [],
            weight: weight,
          },
        ];
      });
    } else {
      setCart((prev) => {
        return prev.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              qty: item.qty + 1,
              variations: [],
              weight: weight,
            };
          }
          return item;
        });
      });
    }
  };

  const handleClearCart = () => {
    if (cart.length < 1) {
      return;
    } else {
      setCart([]);
    }
  };

  const handleAddQty = () => {
    setQty((prev) => prev + 1);
  };
  const removeQty = () => {
    setQty((prev) => prev - 1);
    if (qty < 1) {
      return;
    }
  };

  const handleQuantity = ({ action, payload }) => {
    switch (action) {
      case "increment-simple":
        setCart((prev) => {
          const updatedState = prev.map((item, idx) => {
            if (item._id === payload.productId) {
              const qty = payload.product.qty;

              return {
                ...item,
                qty: qty + 1,
              };
            } else {
              return item;
            }
          });
          return updatedState;
        });

        setRefetch((prev) => !prev);
        break;
      case "decrement-simple":
        if (payload.product.qty <= 1) {
          const filteredCart = cart.filter(
            (itm) => itm._id !== payload.productId
          );
          setCart(filteredCart);
          return;
        } else {
          setCart((prev) => {
            const updatedState = prev.map((item, idx) => {
              if (item._id === payload.productId) {
                const qty = payload.product.qty;

                return {
                  ...item,
                  qty: qty - 1,
                };
              } else {
                return item;
              }
            });

            return updatedState;
          });
        }

        setRefetch((prev) => !prev);
        break;
      case "increment-variable":
        setCart((prevState) => {
          const updateState = prevState.map((prev) => {
            if (prev._id === payload.productId) {
              const variations = prev.variations.map((variation) => {
                if (variation.variationId === payload.variationId) {
                  const qty = variation.qty;

                  return {
                    ...variation,
                    qty: qty + 1,
                  };
                } else {
                  return variation;
                }
              });

              const sumQty = variations.reduce((a, b) => a + b.qty, 0);

              return {
                ...prev,
                qty: sumQty,
                variations: variations,
              };
            } else {
              return prev;
            }
          });

          return updateState;
        });

        setRefetch((prevState) => !prevState);
        break;
      case "decrement-variable":
        if (payload.product.qty <= 1) {
          const findProduct = cart.find(({ _id }) => _id === payload.productId);
          const variations = findProduct.variations.filter(
            ({ variationId }) => variationId !== payload.variationId
          );
          if (variations && variations.length > 0) {
            setCart((prevState) => {
              const variationStatus = prevState.map((prev) => {
                const variations = prev.variations.filter(
                  (variation) => variation.variationId !== payload.variationId
                );

                if (prev._id === payload.productId) {
                  const sumQty = variations.reduce((a, b) => a + b.qty, 0);

                  return {
                    ...prev,
                    qty: sumQty,
                    variations: variations,
                  };
                } else {
                  return prev;
                }
              });

              return variationStatus;
            });

            setRefetch((prevState) => !prevState);
          } else {
            setCart((prevState) => {
              const updateState = prevState.filter(
                ({ _id }) => _id !== payload.productId
              );
              return updateState;
            });

            setRefetch((prevState) => !prevState);
          }
          return;
        } else {
          setCart((prevState) => {
            const updateState = prevState.map((prev) => {
              if (prev._id === payload.productId) {
                const variations = prev.variations.map((variation) => {
                  if (variation.variationId === payload.variationId) {
                    const qty = variation.qty;

                    return {
                      ...variation,
                      qty: qty - 1,
                    };
                  } else {
                    return variation;
                  }
                });

                const sumQty = variations.reduce((a, b) => a + b.qty, 0);

                return {
                  ...prev,
                  qty: sumQty,
                  variations: variations,
                };
              } else {
                return prev;
              }
            });

            return updateState;
          });
        }

        setRefetch((prevState) => !prevState);
        break;
      default:
        console.log("Default state");
    }
  };

  const getAddressData = async () => {
    if(!user){
      return
    }
    
    const { token } = user;
    try {
      setReadDataLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getCustomers}/${customerId}`,
          {
            department: PERMISSIONS.grocery,
          },
          {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            const addresses = res.data?.data[0]?.address;
            const sortedByDefault = addresses.sort(
              (a, b) => b.isDefault - a.isDefault
            );
            setCustomerAddresses(sortedByDefault);
            setCustomer(res.data?.data);
            setTimeout(() => {
              setReadDataLoading(false);
            }, 700);
          }
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: "Error with customer data",
            placement: "bottomLeft",
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (customerId) {
      getAddressData();
    }
  }, [customerId, readDataRefetch, isSplitShipping]);

  const getDefaultAddress = useCallback(() => {
    const userAddress =
      readAddress &&
      readAddress.length > 0 &&
      readAddress.find((p) => p.isDefault);

    if (userAddress) {
      return {
        user_id: userAddress.user_id,
        _id: userAddress._id,
      };
    }

    return null;
  }, [readAddress, customerId]);

  const [cartProducts, setCartProducts] = useState({
    wic: [],
    rest: [],
  });

  useEffect(() => {
    if (cart && cart.length > 0) {
      const products = cart.toReversed();
      const userAddress = getDefaultAddress();

      let defaultAddress = userAddress;

      // Wic products
      let wic = [];

      for (const product of products) {
        const isWicProduct = product.eligible.includes("WIC");

        if (isWicProduct) {
          const isVariableProductType =
            product.productType === "variable_product";

          if (isVariableProductType) {
            const productVariations = product.variations;

            let variations = [];

            if (productVariations && productVariations.length > 0) {
              for (const variation of productVariations) {
                variation.isSelected = false;
                variation.isChecked = false;

                const variationsPayload = {
                  ...variation,
                  currentQty: +variation.qty,
                  featureImage: variation.featureImage || null,
                  defaultAddress,
                };

                variations.push(variationsPayload);
              }
            }

            let currentQty = variations.reduce((total, variation) => {
              return total + variation.currentQty;
            }, 0);

            let variablePayload = {
              ...product,
              currentQty: currentQty,
              variations: variations,
              defaultAddress,
            };

            wic.push(variablePayload);
          } else {
            product.isSelected = false;
            product.isChecked = false;

            const wicPayload = {
              ...product,
              currentQty: +product.qty,
              featureImage: product.featureImage || null,
              defaultAddress,
            };

            wic.push(wicPayload);
          }
        }
      }

      // Rest products
      let rest = [];
      for (const product of products) {
        const isVariableProductType =
          product.productType === "variable_product";

        if (isVariableProductType) {
          const productVariations = product.variations;

          let variations = [];

          if (productVariations && productVariations.length > 0) {
            for (const variation of productVariations) {
              variation.isSelected = true;
              variation.isChecked = true;

              const variationsPayload = {
                ...variation,
                currentQty: +variation.qty,
                featureImage: variation.featureImage || null,
                children: [],
                defaultAddress,
              };

              variations.push(variationsPayload);
            }
          }

          let currentQty = variations.reduce((total, variation) => {
            return total + variation.currentQty;
          }, 0);

          const variablePayload = {
            ...product,
            currentQty: currentQty,
            variations: variations,
            featureImage: product.featureImage || null,
            defaultAddress,
          };

          rest.push(variablePayload);
        } else {
          product.isSelected = true;
          product.isChecked = true;

          const restPayload = {
            ...product,
            currentQty: +product.qty,
            featureImage: product.featureImage || null,
            children: [],
            defaultAddress,
          };

          rest.push(restPayload);
        }
      }

      let payload = {
        rest: rest,
        wic: wic,
      };

      setCartProducts(payload);
    }
  }, [
    cart,
    window.location.href,
    getDefaultAddress,
    isSplitShipping,
    // resetAll,
  ]);

  useEffect(() => {
    setIsSplitShipping(false);
  }, [window.location.href]);

  const cartReverse = useMemo(() => {
    return [...cart].reverse();
  }, [cart]);

  const values = useMemo(() => {
    return {
      cart: cartReverse,
      setCart,
      qty,
      setQty,
      handleAddToCart,
      handleCartProduct,
      handleClearCart,
      handleAddQty,
      removeQty,
      handleQuantity,
      setSelectedAttributesUnit,
      selectedAttributesUnit,
      cartProducts,
      setCartProducts,
      setIsSplitShipping,
      isSplitShipping,
      readAddress,
      setCustomerAddresses,
      readDataRefetch,
      setReadDataRefetch,
      customer,
      setCustomer,
      readDataLoading,
      setReadDataLoading,
      customerId,
      refetch,
      setRefetch,
      setRefetchCard,
      refetchCard,
    };
  }, [
    cart,
    cartReverse,
    setCart,
    qty,
    setQty,
    handleAddToCart,
    handleCartProduct,
    handleClearCart,
    handleQuantity,
    setSelectedAttributesUnit,
    selectedAttributesUnit,
    cartProducts,
    setCartProducts,
    setIsSplitShipping,
    isSplitShipping,
    readAddress,
    setCustomerAddresses,
    readDataRefetch,
    setReadDataRefetch,
    customer,
    setCustomer,
    readDataLoading,
    setReadDataLoading,
    customerId,
    refetch,
    setRefetch,
    setRefetchCard,
    refetchCard,
  ]);

  return <CartContext.Provider value={values}>{children}</CartContext.Provider>;
};

const useCart = () => {
  return useContext(CartContext);
};

export { CartProvider, useCart };
