import React from "react";

export default function OverlayCard(props) {
  const { active, scroll, onToggle } = props;

  return (
    <div
      onClick={() => onToggle((p) => !p)}
      className={`overlay ${scroll && active ? "isActive" : ""}`}
    />
  );
}
