import React, { Fragment, useEffect } from "react";
import OverlayCard from "../overlay/OverlayCard";
import { scrollFreeze, scrollUnfreeze } from "../../helpers/helpers";

const SidebarCard = ({ title, className, toggle, onToggle, children }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      !toggle ? scrollUnfreeze() : scrollFreeze();
    }
  }, [toggle]);

  return (
    <Fragment>
      <aside className={`gl__sidebar ${className} ${toggle ? "isActive" : ""}`}>
        <header className="gl__sidebar-header">
          <h4>{title}</h4>

          <button
            className={`btn btn-reset btn-menu`}
            type="button"
            aria-label="Menu times"
            title="Menu close"
            onClick={() => onToggle((p) => !p)}
          >
            <span className="icon icon-times" />
          </button>
        </header>

        <div className="gl__sidebar-content">{children}</div>
      </aside>

      <OverlayCard active={toggle} scroll={true} onToggle={onToggle} />
    </Fragment>
  );
};

export default SidebarCard;
