import { useProducts } from "../../context/products";

const ActionTypeButton = (props) => {
    const { gallerySelect, handleOnSelect, handleOnSelected, setToggleGallery } = useProducts();
    const { select, selected } = props;
 
    return gallerySelect === 'multiselect' ? (
        <button
            onClick={() => {
                handleOnSelected(selected);
                setToggleGallery((prevState) => !prevState); 
            }}
            className="btn btn-primary pl-2 pr-2"
            type="button"
        >
            <span className="text">Save changes</span>
        </button>
    ) : gallerySelect === 'singleselect' && (
        <button
            onClick={() => {
                handleOnSelect(select);
                setToggleGallery((prevState) => !prevState);
            }}
            className="btn btn-primary pl-2 pr-2"
            type="button"
        >
            <span className="text">Save changes</span>
        </button>
    );
};


export default ActionTypeButton;