import React, { Fragment } from "react";
import {
  isWic,
  isCheckImage,
  isDisabled,
  sumCurrentFormatPrice,
} from "../../../helpers/helpers";
import { stateUS } from "../../../helpers/state";
import CartProductsSelect from "../../customSelect/CartProductsSelect";

const CartSimpleProduct = (props) => {
  if (props.from === "wic") {
    return <WicProduct {...props} />;
  }

  return <RestProduct {...props} />;
};

function WicProduct(props) {
  const { product, selected, onClick, isAddressConfirmed } = props;

  function isCheckPriceSum(price) {
    return <span className="price">{sumCurrentFormatPrice(price)}</span>;
  }

  return (
    <div className="product-item">
      <div className="product product-simple">
        <div className="item">
          <div className="item-wrapper">
            <div className="product-info">
              {isAddressConfirmed ? (
                <Fragment>
                  <label
                    htmlFor="checkboxProductWicSimpleDisable"
                    style={{ display: "none" }}
                  >
                    <span>Checkbox</span>
                  </label>
                  <input
                    disabled={true}
                    id="checkboxProductWicSimpleDisable"
                    name="checkboxProductWicSimpleDisable"
                    className="checkbox"
                    checked={selected.includes(product._id)}
                    value={product._id}
                    type="checkbox"
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <label
                    htmlFor={product._id + "_wic_simple"}
                    style={{ display: "none" }}
                  >
                    <span>Checkbox</span>
                  </label>
                  <input
                    disabled={false}
                    id={product._id + "_wic_simple"}
                    name="selectedSimple"
                    className="checkbox"
                    checked={selected.includes(product._id)}
                    value={product._id}
                    type="checkbox"
                    onChange={(e) =>
                      onClick({
                        state: product,
                        action: { type: "selectedSimple", event: e },
                      })
                    }
                  />
                </Fragment>
              )}

              <div className="product-wrapper">
                <div className="product-image">
                  <div className="image">
                    {isCheckImage({
                      action: "simple",
                      payload: { data: product },
                    })}
                  </div>
                </div>

                <div className="product-group">
                  <div className="product-details">
                    <div className="details">
                      <h4>
                        {product.title} {isWic(product.eligible)}
                      </h4>
                    </div>

                    <span className="price-wrapper">
                      <span className="text-right">
                        <b className="d-block">Subtotal:</b>
                        {isCheckPriceSum(product)}
                      </span>
                    </span>
                  </div>
                  <div className="product-actions">
                    <div className="controls">
                      {isAddressConfirmed ? (
                        <Fragment>
                          <CartProductsSelect
                            disabled={true}
                            product={product}
                            parentProduct={product}
                            onClick={() => {}}
                            name="quantityWic"
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <CartProductsSelect
                            disabled={isDisabled(
                              selected.includes(product._id)
                            )}
                            product={product}
                            parentProduct={product}
                            onClick={onClick}
                            name="quantityWic"
                          />
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RestProduct(props) {
  const {
    product,
    onClick,
    children,
    isSplitShipping,
    isAddressConfirmed,
    readAddress,
  } = props;

  const isChildrenLength = children && children.length > 0;

  const getAddressStateName = (state) => {
    const stateResult = stateUS.find((item) => item.abbreviation === state);
    return stateResult.name;
  };

  const checkDefaultAddress = (isDefault) => {
    if (isDefault) {
      return "(default)";
    } else {
      return "";
    }
  };

  if (!product.isSelected) {
    return null;
  }

  function isCheckPriceSum(price) {
    return <span className="price">{sumCurrentFormatPrice(price)}</span>;
  }

  const defaultAddress = product?.defaultAddress?._id;

  return (
    <div className="product-item">
      <div className="product product-simple--rest">
        <div className="item item-rest">
          <div className="parent">
            <div className="item-wrapper">
              <div className="product-info">
                <div className="product-wrapper">
                  <div className="product-image">
                    <div className="image">
                      {isCheckImage({
                        action: "simple",
                        payload: { data: product },
                      })}
                    </div>
                  </div>

                  <div className="product-group">
                    <div className="product-details">
                      <div className="details">
                        <h4>
                          {product.title} {isWic(product.eligible)}
                        </h4>
                      </div>

                      <span className="price-wrapper">
                        <span className="text-right">
                          <b className="d-block">Subtotal:</b>
                          {isCheckPriceSum(product)}
                        </span>
                      </span>
                    </div>
                    <div className="product-actions">
                      <div className="controls">
                        {isSplitShipping && (
                          <Fragment>
                            {isAddressConfirmed ? (
                              <button
                                disabled={true}
                                type="button"
                                className="btn-link-info"
                                title="Split Product"
                              >
                                <span className="text">Split Items</span>
                              </button>
                            ) : (
                              <button
                                disabled={false}
                                type="button"
                                className="btn-link-info"
                                onClick={(e) =>
                                  onClick({
                                    state: product,
                                    action: {
                                      type: "createRestSimpleChildren",
                                      event: e,
                                    },
                                  })
                                }
                                title="Split Product"
                              >
                                <span className="text">Split Items</span>
                              </button>
                            )}
                          </Fragment>
                        )}

                        <CartProductsSelect
                          disabled={isAddressConfirmed}
                          product={product}
                          parentProduct={product}
                          onClick={onClick}
                          name="quantityRest"
                        />

                        {isSplitShipping && (
                          <div className="shipping-actions">
                            <div className="group">
                              {isAddressConfirmed ? (
                                <Fragment>
                                  <label
                                    htmlFor={product._id + "disable"}
                                    style={{ display: "none" }}
                                  >
                                    <span>Address</span>
                                  </label>

                                  <select
                                    id={product._id + "disable"}
                                    name={`simpleAddressDisable_${product._id}`}
                                    disabled={true}
                                    value={defaultAddress || "none"}
                                    className="select select-address"
                                  >
                                    {readAddress.map((item) => {
                                      const {
                                        address,
                                        city,
                                        state,
                                        isDefault,
                                        _id,
                                        zip_code,
                                      } = item;

                                      return (
                                        <option value={_id} key={_id}>
                                          {address}, {city}, {zip_code},{" "}
                                          {getAddressStateName(state)}{" "}
                                          {checkDefaultAddress(isDefault)}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <label
                                    htmlFor={product._id + "enable"}
                                    style={{ display: "none" }}
                                  >
                                    <span>Address</span>
                                  </label>

                                  <select
                                    id={product._id + "enable"}
                                    name={`simpleAddressEnable_${product._id}`}
                                    disabled={false}
                                    value={defaultAddress || "none"}
                                    onChange={(e) =>
                                      onClick({
                                        state: product,
                                        action: {
                                          type: "simpleRestAddressChanged",
                                          event: e,
                                        },
                                      })
                                    }
                                    className="select select-address"
                                  >
                                    {readAddress.map((item) => {
                                      const {
                                        address,
                                        city,
                                        state,
                                        isDefault,
                                        _id,
                                        zip_code,
                                      } = item;

                                      return (
                                        <option value={_id} key={_id}>
                                          {address}, {city}, {zip_code},{" "}
                                          {getAddressStateName(state)}{" "}
                                          {checkDefaultAddress(isDefault)}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        )}

                        {isAddressConfirmed ? (
                          <button
                            disabled={true}
                            title="Remove item"
                            className="btn-remove"
                            type="button"
                          >
                            <span className="icon">
                              <img
                                src="/assets/icons/trash-icon-dark.svg"
                                alt="Trash Cart"
                                width={22}
                                height={22}
                              />
                            </span>
                          </button>
                        ) : (
                          <button
                            disabled={false}
                            title="Remove item"
                            onClick={(e) =>
                              onClick({
                                state: product,
                                action: { type: "removeRest", event: e },
                              })
                            }
                            className="btn-remove"
                            type="button"
                          >
                            <span className="icon">
                              <img
                                src="/assets/icons/trash-icon-dark.svg"
                                alt="Trash Cart"
                                width={22}
                                height={22}
                              />
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isChildrenLength && (
              <div className="children">
                {children.map((child) => {
                  const { splitId } = child;
                  const defaultAddressChildren = child.defaultAddress._id;

                  const childProduct = {
                    ...product,
                    currentQty: child.currentQty,
                  };

                  return (
                    <div className="child" key={splitId}>
                      <div className="item-wrapper">
                        <div className="product-info">
                          <div className="product-wrapper">
                            <div className="product-image">
                              <div className="image">
                                {isCheckImage({
                                  action: "simple",
                                  payload: { data: child },
                                })}
                              </div>
                            </div>
                            <div className="product-group">
                              <div className="product-details">
                                <div className="details">
                                  <h4>
                                    {product.title} {isWic(product.eligible)}
                                  </h4>
                                </div>

                                <span className="price-wrapper">
                                  <span className="text-right">
                                    <b className="d-block">Subtotal:</b>
                                    {isCheckPriceSum(childProduct)}
                                  </span>
                                </span>
                              </div>

                              <div className="product-actions">
                                <div className="controls">
                                  <CartProductsSelect
                                    disabled={isAddressConfirmed}
                                    product={child}
                                    parentProduct={product}
                                    onClick={onClick}
                                    name="quantityRestChildren"
                                  />

                                  {isSplitShipping && (
                                    <div className="shipping-actions">
                                      <div className="group">
                                        {isAddressConfirmed ? (
                                          <Fragment>
                                            <label
                                              htmlFor={splitId + "disable"}
                                              style={{ display: "none" }}
                                            >
                                              <span>Address</span>
                                            </label>

                                            <select
                                              id={splitId + "disable"}
                                              name={`simpleChildAddressDisable_${splitId}`}
                                              disabled={true}
                                              value={
                                                defaultAddressChildren || ""
                                              }
                                              className="select select-address"
                                            >
                                              {readAddress.map((item) => {
                                                const {
                                                  address,
                                                  city,
                                                  state,
                                                  isDefault,
                                                  _id,
                                                  zip_code,
                                                } = item;
                                                return (
                                                  <option value={_id} key={_id}>
                                                    {address}, {city},{" "}
                                                    {zip_code},{" "}
                                                    {getAddressStateName(state)}{" "}
                                                    {checkDefaultAddress(
                                                      isDefault
                                                    )}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </Fragment>
                                        ) : (
                                          <Fragment>
                                            <label
                                              htmlFor={splitId + "enable"}
                                              style={{ display: "none" }}
                                            >
                                              <span>Address</span>
                                            </label>

                                            <select
                                              id={splitId + "enable"}
                                              name={`simpleChildAddressEnable_${splitId}`}
                                              disabled={false}
                                              value={
                                                defaultAddressChildren || ""
                                              }
                                              onChange={(e) =>
                                                onClick({
                                                  state: child,
                                                  action: {
                                                    type:
                                                      "simpleRestChildrenAddressChanged",
                                                    event: e,
                                                  },
                                                })
                                              }
                                              className="select select-address"
                                            >
                                              {readAddress.map((item) => {
                                                const {
                                                  address,
                                                  city,
                                                  state,
                                                  isDefault,
                                                  _id,
                                                  zip_code,
                                                } = item;
                                                return (
                                                  <option value={_id} key={_id}>
                                                    {address}, {city},{" "}
                                                    {zip_code},{" "}
                                                    {getAddressStateName(state)}{" "}
                                                    {checkDefaultAddress(
                                                      isDefault
                                                    )}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </Fragment>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {isAddressConfirmed ? (
                                    <button
                                      disabled={true}
                                      title="Remove item"
                                      className="btn-remove"
                                      type="button"
                                    >
                                      <span className="icon">
                                        <img
                                          src="/assets/icons/trash-icon-dark.svg"
                                          alt="Trash Cart"
                                          width={22}
                                          height={22}
                                        />
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      disabled={false}
                                      title="Remove item"
                                      onClick={(e) =>
                                        onClick({
                                          state: product,
                                          action: {
                                            type: "removeRestChildren",
                                            event: e,
                                            payload: { _id: splitId },
                                          },
                                        })
                                      }
                                      className="btn-remove"
                                      type="button"
                                    >
                                      <span className="icon">
                                        <img
                                          src="/assets/icons/trash-icon-dark.svg"
                                          alt="Trash Cart"
                                          width={22}
                                          height={22}
                                        />
                                      </span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartSimpleProduct;
