import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../../../../components/content';
import { PERMISSIONS } from '../../../../../utils/permissions';

export const ProductBreadcrumb = () => {
  return (
    <Breadcrumbs>
        <ul>
          <li>
            <Link
              to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`}
              className="link"
            >
              <span className="text">Products</span>
            </Link>
          </li>

          <li>
            <span className="link">
              <span className="text">Edit Product</span>
            </span>
          </li>
        </ul>
      </Breadcrumbs>
  );
};
