import React from 'react';
import { Popover } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { formatTime, formatDate, removeDuplicates, formatCurrencyPrice } from '../../../helpers/helpers';
import { Link } from 'react-router-dom';

const Table = (props) => {
  // TABLE
  const { data, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, rescheduledActions, deleteRescheduledActions, location, pharmacyPermissions, getPharmacyPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const checkType = (item) => {
    if (!Array.isArray(item)) {
      return 'obj';
    } else {
      return 'arr';
    }
  };

  const checkObjectProperty = (item) => {
    if (!item) {
      return;
    }

    const isArray = checkType(item);

    if (isArray) {
      const city = item[0].hasOwnProperty('city');
      const address = item[0].hasOwnProperty('address');
      const zipCode = item[0].hasOwnProperty('zip_code');
      const state = item[0].hasOwnProperty('state');
      if (city && address && zipCode && state) {
        return `${item[0].city}, ${item[0].zip_code}, ${item[0].address}, ${item[0].state}`;
      }
    }
  };

  const checkAddress = (data) => {
    if (!data) {
      return false;
    }

    if (Array.isArray(data)) {
      if (data && data.length && data.length > 0) {
        for (const arr of data) {
          if (typeof arr === 'string') {
            return arr;
          }

          if (typeof arr === 'object') {
            const city = data[0].hasOwnProperty('city');
            const address = data[0].hasOwnProperty('address');
            const zipCode = data[0].hasOwnProperty('zip_code');
            const state = data[0].hasOwnProperty('state');

            if (city && address && zipCode && state) {
              return `${data[0].city}, ${data[0].zip_code}, ${data[0].address}, ${data[0].state}`;
            }
          }
        }
      }
    }
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Order Number</th>
            <th>Name</th>
            <th>Order Time/Date</th>
            {/* <th>Rescheduled</th> */}
            <th>Price</th>
            <th>Left to Pay</th>
            <th>Shipping Service</th>
            <th>
              <Popover
                className="thead-actions"
                placement="bottom"
                trigger="click"
                content={() => (
                  <div className="th-actions">
                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('')}>
                      <label className="text" htmlFor="all">
                        <span>All Statuses</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('Created')}>
                      <label className="text" htmlFor="ordered">
                        <span>Created</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('Ordered')}>
                      <label className="text" htmlFor="ordered">
                        <span>Ordered</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('In Progress')}>
                      <label className="text" htmlFor="in-progress">
                        <span>Fulfillment</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('On Delivery')}>
                      <label className="text" htmlFor="on-delivery">
                        <span>Out For Delivery</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('Delivered')}>
                      <label className="text" htmlFor="delivered">
                        <span>Delivered</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('Failed')}>
                      <label className="text" htmlFor="canceled">
                        <span>Failed</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('Canceled')}>
                      <label className="text" htmlFor="canceled">
                        <span>Canceled</span>
                      </label>
                    </button>
                  </div>
                )}
              >
                <button className="btn block status-btn" type="button">
                  <strong>Status</strong>
                  <span className="icon">
                    <img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/arrow-down-table-filters.svg" />
                  </span>
                </button>
              </Popover>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length && data.length > 0 ? (
            data?.map((item, idx) => {
              const name = `${item?.userData[0]?.firstName} ${item?.userData[0]?.lastName}`;
              const totalPrice = parseFloat(item?.totalPrice);
              const leftToPay = item && item.left_to_pay && item.left_to_pay !== null && item.left_to_pay !== 0 ? item.left_to_pay : 0;
              const shippingTotalPrice = item && item.shipping_total_price && item.shipping_total_price !== null ? `$${item.shipping_total_price}` : '/';
              let shippingServiceName = '';
              if (item && item.shipping_services && item.shipping_services.length > 0) {
                if (Array.isArray(item.shipping_services)) {
                  removeDuplicates(item.shipping_services).map((service, index, arr) => {
                    const indexOfDash = service.indexOf('_');
                    if (indexOfDash < 0) {
                      shippingServiceName = service;
                    } else {
                      shippingServiceName += service.substring(0, indexOfDash);
                    }
                    shippingServiceName += arr.length - 1 === index ? '' : ', ';
                  });
                } else {
                  const serviceNamePart = item.shipping_services.includes('_') ? item.shipping_services.split('_') : item.shipping_services;
                  shippingServiceName = serviceNamePart[0];
                }
              }

              return (
                <tr key={item._id} className={item && item.status && item.status === 'Ordered' ? 'status-ordered' : ''}>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span className={item && item.status && item.status === 'Ordered' ? 'dot' : ''}></span>
                      <span>{item && item.orderNumber ? item.orderNumber : ''}</span>
                    </div>
                  </td>
                  <td>{name}</td>
                  <td>
                    {item && formatTime(item)} &nbsp;&nbsp; {item && formatDate(item)}
                  </td>
                  {/* <td className="rescheduled">
                    {item.hasOwnProperty('rescheduled') && item.rescheduled ? (
                      item.status === 'Ordered' || item.status === 'In Progress' ? (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {item.rescheduled} <img style={{ cursor: 'pointer' }} alt="Update" src="/assets/icons/edit.svg" onClick={() => handleToggle({ name: 'delete-rescheduled', state: deleteRescheduledActions, data: { id: item._id, status: item.status } })} />
                        </span>
                      ) : (
                        item.rescheduled
                      )
                    ) : item.status !== 'Ordered' && item.status !== 'In Progress' ? (
                      '/'
                    ) : (
                      <button onClick={() => handleToggle({ name: 'rescheduled', state: rescheduledActions, data: { id: item._id, status: item.status, canceled_reason: item.canceled_reason && item.canceled_reason, rescheduled: '' } })}>Reschedule delivery</button>
                    )}
                  </td> */}
                  <td>{formatCurrencyPrice(totalPrice)}</td>
                  <td className={leftToPay && leftToPay > 0 ? 'left-to-pay' : ''}>{formatCurrencyPrice(Math.abs(+leftToPay))}</td>
                  <td className="shipping-service-name">{shippingServiceName}</td>
                  <td className="status-cell">
                    {item && item.status && item.status === 'Canceled' ? (
                      <Popover
                        className="thead-actions"
                        placement="bottom"
                        trigger="click"
                        content={() => (
                          <div>
                            <p className="status-canceled-wrap" style={{ wordWrap: 'break-word', minWidth: '100px', maxWidth: '600px' }}>
                              {item && item.canceled_reason ? item.canceled_reason : ''}
                            </p>
                          </div>
                        )}
                      >
                        <button className="btn block canceled-btn" type="button">
                          <span className="status-canceled">Canceled</span>
                          <img className="icon" alt="arrow" src="/assets/icons/arrow-down-table-filters.svg" />
                        </button>
                      </Popover>
                    ) : item && item.status && item.status === 'On Delivery' ? (
                      'Out For Delivery'
                    ) : item && item.status && item.status === 'In Progress' ? (
                      'Fulfillment'
                    ) : (
                      item && item.status
                    )}
                  </td>

                  <td className="y-top">
                    {Object.values(pharmacyPermissions).some((key) => [PERMISSIONS.readOrders, PERMISSIONS.updateOrders].includes(key.name)) && (
                      <Popover
                        className="tbody-actions"
                        placement="left"
                        trigger="click"
                        content={() => (
                          <div className="actions" data-cy="actions-popover-customers">
                            {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && (
                              <>
                                {Object.values(pharmacyPermissions).some((key) => [PERMISSIONS.readOrders].includes(key.name)) && (
                                  <div className="actions-group">
                                    {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.readOrders) && (
                                      <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}/${item._id}`} className="btn btn-actions" type="button" data-cy="actions-read-customers-details">
                                        <span className="icon">
                                          <img alt="details" src="/assets/icons/details.svg" />
                                        </span>
                                        <span className="text">
                                          <strong>ORDER DETAILS</strong>
                                        </span>
                                      </Link>
                                    )}
                                  </div>
                                )}

                                {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.updateOrders) && (
                                  <button className="btn btn-actions" type="button" data-cy="actions-delete-customer" onClick={() => handleToggle({ name: 'update', state: updateActions, data: { id: item._id, status: item.status, canceled_reason: item.canceled_reason, rescheduled: item.rescheduled } })}>
                                    <span className="icon">
                                      <img alt="Update" src="/assets/icons/edit.svg" />
                                    </span>
                                    <span className="text">
                                      <strong>UPDATE ORDER</strong>
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      >
                        <button className="btn" type="button" data-cy="actions-toggle-customers">
                          <span className="icon">
                            <img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
                          </span>
                        </button>
                      </Popover>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} className="data-not-found-cell">
                <Message className="content-message">
                  <h2>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
