import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useProducts } from "../../../../../../context/products";

// DANIJEL'S KEY COMMENTED
const apiKey = "90yw8l16myyaolbcxwknry8ols5c4m4ty4p9jp348tbg1b12"; // "1567o1nwu4i84sd8t4269uvci7n84xlq1nm210qvss2sxmbn";
export const Description = (props) => {
  const {
    form,
    setForm,
    productEdit,
    description,
    setDescription,
  } = useProducts();
  const { label, className } = props;

  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  useEffect(() => {
    // if (description && description.length > 0) {}

    setForm((prevState) => {
      return { ...prevState, productContent: description };
    });
  }, [description, setForm]);

  useEffect(() => {
    if (productEdit && form.productContent !== "") {
      setDescription(form.productContent);
    }
  }, []);

  return (
    <div className="custom-select">
      <span className="label">
        <b>{label}:</b>
      </span>

      <div className="select select-result">
        <Editor
          apiKey={apiKey}
          onEditorChange={(newValue, editor) =>
            getTextValue(editor.getContent())
          }
          init={{
            height: 600,
            menubar: true,
            plugins: "table",
          }}
          value={description}
        />
      </div>
    </div>
  );
};
