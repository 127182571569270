import React, { Fragment } from "react";
import Message from "../../../components/message/Message";
import { contentMessage } from "../../../helpers/contentMessage";
import {
  replaceAll,
  printImage,
  formatCurrencyPrice,
} from "../../../helpers/helpers";
import { AmazonCircleFilled } from "@ant-design/icons";

const Table = (props) => {
  const { data } = props;
  const orderStatus = data && data.status;
  const totalPrice = data && data.totalPrice;
  const shippingPrice = data && data.shipping_total_price;
  let subtotal = 0;
  let shipping = "";
  let wicProducts = [];
  data &&
    data.paymentMethodsUsed &&
    Array.isArray(data.paymentMethodsUsed) &&
    data.paymentMethodsUsed.map((payment, index) => {
      if (payment.Type.toLowerCase() === "wic") {
        payment.Product &&
          Array.isArray(payment.Product) &&
          payment.Product.map((prod) => {
            if (prod && typeof prod === "object" && prod.variation_id) {
              wicProducts.push(prod.variation_id);
            } else if (prod && typeof prod === "object") {
              wicProducts.push(prod.product_id);
            }
          });
      }
    });

  const isProductImage = (item) => {
    const isCheckFeaturedImage =
      item && item.hasOwnProperty("featureImage") && item.featureImage;
    if (isCheckFeaturedImage) {
      const featureName = item.featureImage.originalname;
      const featureImage = item.featureImage.url;

      return <img src={featureImage} alt={featureName} />;
    }
    return (
      <img
        className="img"
        src="/assets/images/product-placeholder.svg"
        alt={item.title}
        width={40}
        style={{
          borderRadius: "0.25rem",
        }}
      />
    );
  };
  console.log(data);
  return (
    <>
      <div className="status-details">
        <h3 className="status-title">Order Status</h3>
        <div className="status-details-info">
          <span
            className={`${data && data.status && data.status.toLowerCase()}`}
          >
            {data && data.status && data.status === "On Delivery"
              ? "Out For Delivery"
              : data && data.status && data.status === "In Progress"
              ? "Fulfillment"
              : data && data.status}
          </span>
          {data && data.status && data.status === "Failed" ? (
            <span className="finish">
              Please click the link to{" "}
              <a
                href={`https://www.benjaminspharmacy.com/finish-failed-order/${data &&
                  data.orderNumber}`}
                target="_blank"
              >
                finish the order!
              </a>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="customer-details">
        <h3 className="customer-title">Customer Details</h3>
        <div className="customer-details-info">
          <label>Name:</label>{" "}
          <span>{data && data?.userData[0].firstName}</span>
          <label>Surname:</label>{" "}
          <span>{data && data?.userData[0].lastName}</span>
          <label>Phone:</label> <span>{data && data?.userData[0].phone}</span>
        </div>
      </div>

      {data && data?.coupon && (
        <div className="coupon-details">
          <h3 className="coupon-title">Coupon Details</h3>
          <div className="coupon-details-info">
            <span>
              A coupon <b>{data && data?.coupon?.value}</b> is used in this
              order. Discount <b>{data && data?.coupon?.discount}%</b> is
              applied for{" "}
              {data && data?.coupon && data?.coupon?.type === "shipping"
                ? "shipping"
                : "product"}
              .
            </span>
          </div>
        </div>
      )}

      <div className="payment-details">
        <h3 className="payment-title">Payment Details</h3>
        <div>
          <div className="payment-details-info">
            {data &&
              data.paymentMethodsUsed &&
              Array.isArray(data.paymentMethodsUsed) &&
              data.paymentMethodsUsed.map((method, index) => {
                const amount = +method.Amount;
                return (
                  <Fragment key={index}>
                    <div>
                      {index + 1 + ". "}
                      {method &&
                      method.xCardType &&
                      method.xMaskedCardNumber ? (
                        <span>
                          <label>{method.xCardType}</label>{" "}
                          <label className="masked-number">
                            ({method.xMaskedCardNumber})
                          </label>
                        </span>
                      ) : (
                        <label>{method && method.Type}</label>
                      )}
                      {method.Type === "WIC"
                        ? ""
                        : `: ${formatCurrencyPrice(amount)}`}
                    </div>
                    <div>
                      {method.Type === "WIC" ? (
                        <span className="wic-info">
                          {data &&
                          data.status &&
                          data.status === "Delivered" ? (
                            <span>
                              Paid with{" "}
                              <span className="badge badge-single badge-single-wic">
                                WIC
                              </span>
                            </span>
                          ) : (
                            <span>
                              <span className="inverted-wic-card">
                                Pay upon delivery
                              </span>
                            </span>
                          )}
                        </span>
                      ) : data &&
                        data.left_to_pay &&
                        data.left_to_pay !== null &&
                        data.left_to_pay !== 0 ? (
                        // <span className="not-paid">
                        //   <label>Left to Pay:</label> {formatCurrencyPrice(Math.abs(+data.left_to_pay))}
                        // </span>
                        ""
                      ) : (
                        <span className="paid">Paid</span>
                      )}
                    </div>
                    <div>
                      <label>RefNum:</label>
                      {method.Type === "WIC" ? " / " : ` ${method.RefNum}`}
                      {method.Type === "WIC" &&
                        data &&
                        data.wic_card_photo_data_uri && (
                          <div>
                            <img
                              src={`data:image/png;base64,${data &&
                                data.wic_card_photo_data_uri}`}
                              alt="Blob"
                              style={{
                                display: "block",
                                width: "100%",
                                maxWidth: "300px",
                                height: "200px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </Fragment>
                );
              })}
          </div>
          {data &&
          data.left_to_pay &&
          data.left_to_pay !== null &&
          data.left_to_pay !== 0 ? (
            <p className="left-to-pay">
              <span className="not-paid">
                <label>Left to Pay:</label>{" "}
                {formatCurrencyPrice(Math.abs(+data.left_to_pay))}
              </span>
            </p>
          ) : null //<span className="paid">Paid</span>
          }
        </div>
      </div>
      <div className="product-details">
        <h3 className="product-title">Product Details</h3>
        <div className="table-wrap">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Title</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {data && data.order && data.order.length > 0 ? (
                data?.order.map((item, index) => {
                  const isWic = wicProducts.find((w) =>
                    item.productType === "simple_product"
                      ? w === item._id
                      : w === item.variation_id
                  );
                  shipping =
                    item && item.shipping
                      ? item.shipping
                      : "Benjamin's Pharmacy & Surgical";
                  subtotal =
                    subtotal + parseFloat(replaceAll(item.totalPrice, ",", ""));
                  let itemTitle = "";
                  if (item && item.Product_ID && item.Units) {
                    itemTitle += item.title;
                    itemTitle += " (";
                    item.Units.map((unit, index, arr) => {
                      itemTitle += " " + unit.value;
                      arr.length - 1 !== index ? (itemTitle += ", ") : "";
                    });
                    itemTitle += " )";
                  } else {
                    itemTitle = item.title;
                  }
                  return (
                    <tr key={index}>
                      <td>
                        <div className="product-wic-badge-wrap">
                          {itemTitle}
                          {isWic && (
                            <span className="badge badge-single badge-single-wic">
                              WIC
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="price-cell">${item.formatted_price}</td>
                      <td className="quantity-cell">{item.quantity}</td>
                      <td className="total">
                        {isWic &&
                        data &&
                        data.status &&
                        data.status === "Delivered" ? (
                          <p>Paid with WIC card</p>
                        ) : isWic &&
                          data &&
                          data.status &&
                          data.status !== "Delivered" ? (
                          <p>Pay upon delivery</p>
                        ) : (
                          ""
                        )}
                        <span className={`${isWic ? "wic-price" : ""}`}>
                          ${item.totalPrice}
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="no-products"
                    colSpan={20}
                    style={{
                      backgroundColor: "#fff",
                      height: "200px",
                      border: "0",
                    }}
                  >
                    <Message className="content-message">
                      <h2 style={{ color: "#666" }}>
                        {contentMessage.dataNotFound}
                      </h2>
                    </Message>
                  </td>
                </tr>
              )}
              {data && data.order && data.order.length > 0 ? (
                <>
                  <tr className="subtotal-row">
                    <td colSpan={4}>
                      Subtotal:{" "}
                      {data.coupon && data.coupon.type === "product"
                        ? formatCurrencyPrice(data.product_total_price)
                        : formatCurrencyPrice(subtotal)}
                    </td>
                  </tr>
                  {data && data.status && data.status !== "Failed" && (
                    <>
                      <tr className="total-shipping-row">
                        <td colSpan={4}>
                          Shipping: {formatCurrencyPrice(shippingPrice)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan={4}>
                          <span>Total: {formatCurrencyPrice(totalPrice)}</span>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
      <div className="shipping-details">
        <h3 className="shipping-title">Shipment Details</h3>
        {orderStatus !== "Failed" ? (
          <div className="shipping-details-info">
            {data &&
              data.deliveryInfo &&
              Array.isArray(data.deliveryInfo) &&
              data.deliveryInfo.length > 0 &&
              data.deliveryInfo.map((address, index) => {
                const labelUrl = address.shipment?.postage_label?.label_url;
                const postageLabelId = address.shipment?.postage_label?.id;
                const scheduledDeliveryDate = address && address.scheduled_delivery && address.scheduled_delivery.date ? address.scheduled_delivery.date : null;

                return (
                  <div className="address-product-wrap" key={index}>
                    <div className="address-info">
                      <div className="address">
                        <label>
                          {index + 1}. {address.address.address},{" "}
                          {address.address.city}, {address.address.zip_code},{" "}
                          {address.address.state}

                          {scheduledDeliveryDate && (
                            <span style={{ color: 'green'}}>
                              {" "} / Expected delivery date: ({scheduledDeliveryDate})
                            </span>
                          )}
                        </label>
                      </div>
                      {address.carrier !==
                      "benjamins_pharmacy_shipment_service" ? (
                        <div className="address-shipping-info">
                          <label>Status: </label>
                          <span>{address.shipment_status}</span>
                          <label>Shipping Cost: </label>{" "}
                          <span>${address.item_shipping_total_price}</span>
                          <a
                            href={address.shipment_tracking_url}
                            target="_blank"
                          >
                            <button className="btn btn-primary-outline pl-4 pr-4">
                              Track Package
                            </button>
                          </a>
                        </div>
                      ) : (
                        <div>
                          {address.carrier &&
                            address.carrier ===
                              "benjamins_pharmacy_shipment_service" && (
                              <span className="benjamin-shipping-price">
                                <label>Shipping cost:</label> $
                                {address.item_shipping_total_price}
                              </span>
                            )}
                        </div>
                      )}
                      <div className="carrier">({address.carrier_string})</div>
                    </div>
                    <div className="product-list-wrap">
                      <div>
                        {address.product.map((product) => {
                          const variationId =
                            product.productType !== "simple_product"
                              ? product.variation_id
                              : null;
                          const isWic = wicProducts.find((w) =>
                            product &&
                            product.productData &&
                            product.productData.productType === "simple_product"
                              ? w === product.product_id
                              : w === product.variation_id
                          );
                          let productTitle = "";
                          if (variationId) {
                            const variabileProduct =
                              data &&
                              data?.order.find(
                                (item) => item.variation_id === variationId
                              );
                            productTitle += variabileProduct.title;
                            productTitle += " (";
                            variabileProduct.Units.map((unit, index, arr) => {
                              productTitle += unit.value;
                              arr.length - 1 !== index
                                ? (productTitle += ", ")
                                : "";
                            });
                            productTitle += ")";
                          } else {
                            productTitle =
                              product &&
                              product.productData &&
                              product.productData.title;
                          }
                          return (
                            <div
                              className="product-info"
                              key={
                                product &&
                                product.productData &&
                                product.productData.productType ===
                                  "simple_product"
                                  ? product.product_id
                                  : product.variation_id
                              }
                            >
                              <span className="quantity">
                                {product.quantity} x
                              </span>
                              <span className="product-wic-badge-wrap">
                                {productTitle}{" "}
                                {isWic && (
                                  <span className="badge badge-single badge-single-wic">
                                    WIC
                                  </span>
                                )}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      {labelUrl ? (
                        <img
                          className="postage-label"
                          title="print preview"
                          src={labelUrl}
                          alt="label image"
                          width={200}
                          id={postageLabelId}
                          onClick={() => printImage(postageLabelId)}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="no-info">
            No shipment details because order status is "Failed"
          </div>
        )}
      </div>
      {data && data.comment ? (
        <div className="order-comment">
          <h3 className="comment-title">Comment</h3>
          <div className="comment-info">
            {data && data.comment ? data.comment : ""}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Table;
