import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { SERVER_URL } from '../config/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useSessionStorage';
import { notificationMessages } from '../helpers/notificationMessages';
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [refetchToken, setRefetchToken] = useState(false);
  const [user, setUser] = useLocalStorage(USER, null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRefetchToken = () => {
    setRefetchToken((prevState) => !prevState);
  };

  // HANDLE LOGIN
  const login = useCallback(
    async (data) => {
      const formPayload = {
        email: data.email,
        password: data.password,
        authType: PERMISSIONS.authType,
        signInPath: PERMISSIONS.signInPath
      };

      try {
        const fetchLogin = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.login}`, { ...formPayload }, { withCredentials: false });

        if (fetchLogin.status === 200) {
          setUser(fetchLogin.data);
          navigate(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`, { replace: true });

          // notification
          notification.success({
            message: notificationMessages.successLogin,
            placement: 'bottomLeft'
          });
        }
      } catch (err) {
        setUser(null);
        navigate(`/${PERMISSIONS.login}`, { replace: true });

        // notification
        notification.error({
          message: notificationMessages.failedLogin,
          placement: 'bottomLeft'
        });
        return false;
      }
    },
    [navigate, setUser]
  );

  // HANDLE LOGOUT
  const logout = useCallback(() => {
    setUser(null);
    navigate(`/${PERMISSIONS.login}`, { replace: true });

    // notification
    notification.success({
      message: notificationMessages.successLogout,
      placement: 'bottomLeft'
    });
  }, [navigate, setUser]);

  // REDIRECT - if user expires data
  useEffect(() => {
    const interval = setInterval(timeEnd, 1000);

    function timeEnd() {
      if (user !== null) {
        if (new Date() > new Date(user.expires)) {
          setUser(null);
          navigate(`/${PERMISSIONS.login}`, { replace: true });
          // window.location.reload();
          clearInterval(interval);
        }
      }
    }
  }, [user, location, refetchToken, navigate, setUser]);

  useEffect(() => {
    const interval = setInterval(timeEnd, 1000);

    function timeEnd() {
      if (user && user !== null) {
        const currentDate = new Date();
        const expiresDate = new Date(user.expires);

        // function millisToMinutesAndSeconds(millis) {
        //   var minutes = Math.floor(millis / 60000);
        //   var seconds = ((millis % 60000) / 1000).toFixed(0);
        //   return seconds == 60 ? minutes + 1 + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        // }

        // const time = millisToMinutesAndSeconds(expiresDate - currentDate - 5 * 60 * 1000);


        if (currentDate >= expiresDate) {
          setUser(null);
          navigate(`/${PERMISSIONS.login}`, { replace: true });
          // window.location.reload();
          clearInterval(interval);
        }
      }
    }
  }, [user, location, refetchToken, navigate, setUser]);

  // REDIRECT - if user null or not length
  if (!!user && Object.keys(user).length === 0 && Object.values(user).length === 0) {
    const interval = setInterval(timeEnd, 1000);

    function timeEnd() {
      setUser(null);
      navigate(`/${PERMISSIONS.login}`, { replace: true });
      // window.location.reload();
      clearInterval(interval);
    }
  }

  const value = useMemo(() => ({ user, login, logout, refetchToken, handleRefetchToken }), [user, refetchToken, login, logout]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
