import React, { useState, useEffect, Fragment, useRef } from "react";

const numberRegex = /^[0-9]+$/;

export default function CustomInput({
  quantity,
  product,
  parentProduct,
  parentVariation,
  disabled,
  name,
  onClick,
}) {
  const [value, setValue] = useState(+quantity);
  const [isChanged, setIsChanged] = useState(false);
  const [isError, setIsError] = useState(false);

  const buttonRef = useRef(null);

  const onKeyDown = (event) => {
    let isNotAllowed = [10, 16, 17, 65, 67, 86];

    if (event.ctrlKey === true || isNotAllowed.includes(event.which)) {
      event.preventDefault();
      return false;
    }
  };

  const handleOnValue = (event) => {
    const { value } = event.target;

    if (value === "") {
      setValue("");
    }

    if (!numberRegex.test(value)) {
      event.preventDefault();
      return false;
    }

    setValue(+value);
  };

  useEffect(() => {
    setValue(+quantity);
  }, [quantity]);

  useEffect(() => {
    const updateQty = +value;
    const currentQty = +product.currentQty;

    if (updateQty === currentQty) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }

    if (value === "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [value, product.currentQty]);

  useEffect(() => {
    // TODO: clear
    const executingFunction = () => {};
    let intervalId = setInterval(executingFunction, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [value]);

  return (
    <Fragment>
      <input
        name="input-number-quantity"
        value={value}
        disabled={disabled}
        onChange={(e) => {
          handleOnValue(e);
        }}
        className={`input input-amount ${isError ? "isError" : ""}`}
        onPaste={(e) => e.preventDefault()}
        onKeyDown={(e) => onKeyDown(e)}
        type="text"
      />

      {isChanged && (
        <button
          ref={buttonRef}
          onClick={(e) =>
            onClick({
              state: product,
              action: {
                type: name + "InputUpdate",
                value: value,
                event: e,
                payload: {
                  parentProduct: parentProduct,
                  parentVariation: parentVariation,
                },
              },
            })
          }
          type="button"
          disabled={disabled}
          className="btn-update"
        >
          <span className="text">Update</span>
        </button>
      )}
    </Fragment>
  );
}
