import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import {
  validateExpiryDate,
  validatePaymentCardNumber,
  validateCvv,
  detectCardType,
} from "../../../helpers/helpers";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { SERVER_URL } from "../../../config";
import { PERMISSIONS } from "../../../utils/permissions";

const PaymentFormCreate = (props) => {
  const {
    active,
    toggle,
    onToggle,
    loading,
    onLoading,
    user,
    className,
    onRefetch,
    customerId,
    setShowPayment,
    setAllowPaymentStep,
    setRefetchCard,
  } = props;

  // CREATE
  const formInitialState = {
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    street: "",
    zip: "",
    fullName: "",
    setDefault: false,
  };

  const [form, setForm] = useState(formInitialState);

  const [cardNumberError, setCardNumberError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [cvvError, setCvvError] = useState(false);

  const onChange = (event) => {
    let { name, value } = event.target;
    name === "setDefault" ? (value = !!value) : null;
    setForm((prev) => {
      if (name === "expiryDate") {
        if (value.length === 2 && prev.expiryDate.length === 1) {
          value += " / ";
        } else if (
          (value.length === 5 && prev.expiryDate.length === 6) ||
          (value.length === 4 && prev.expiryDate.length === 5)
        ) {
          value = value.slice(0, 2);
        } else if (
          value.length === 3 &&
          value.indexOf("/") === -1 &&
          prev.expiryDate.length === 2
        ) {
          value = [value.slice(0, 2), " / ", value.slice(2)].join("");
        }
      }
      return {
        ...form,
        [name]: value,
      };
    });
  };

  const onBlur = (event) => {
    const { name, value } = event.target;

    name === "cardNumber"
      ? validatePaymentCardNumber(value)
        ? setCardNumberError(false)
        : setCardNumberError(true)
      : null;
    name === "fullName" || name === "Name"
      ? value.length >= 2
        ? setFullNameError(false)
        : setFullNameError(true)
      : null;
    name === "expiryDate"
      ? validateExpiryDate(value)
        ? setExpiryDateError(false)
        : setExpiryDateError(true)
      : null;
    name === "street" || name === "Street"
      ? value.length >= 2
        ? setStreetError(false)
        : setStreetError(true)
      : null;
    name === "zip" || name === "Zip"
      ? value.length >= 2
        ? setZipError(false)
        : setZipError(true)
      : null;
    name === "cvv"
      ? validateCvv(value)
        ? setCvvError(false)
        : setCvvError(true)
      : null;
  };

  const onFocus = (event) => {
    const { name, value } = event.target;

    name === "cardNumber" ? setCardNumberError(false) : null;
    name === "fullName" || name === "Name" ? setFullNameError(false) : null;
    name === "expiryDate" ? setExpiryDateError(false) : null;
    name === "street" || name === "Street" ? setStreetError(false) : null;
    name === "zip" || name === "Zip" ? setZipError(false) : null;
    name === "cvv" ? setCvvError(false) : null;
  };

  const handleFormReset = () => {
    setTimeout(() => {
      // Form restart
      setForm(formInitialState);
      setCardNumberError(false);
      setFullNameError(false);
      setExpiryDateError(false);
      setStreetError(false);
      setZipError(false);
      setCvvError(false);
    }, 300);
  };

  const handleIsValidate = (data) => {
    let checkIsValidCard = false;
    let checkIsValidName = false;
    let checkIsValidExpiry = false;
    let checkIsValidStreet = false;
    let checkIsValidZip = false;
    let checkIsValidCvv = false;

    if (validatePaymentCardNumber(data.cardNumber)) {
      checkIsValidCard = true;
    } else {
      setCardNumberError(true);
      checkIsValidCard = false;
    }

    if (data.fullName.length >= 2) {
      checkIsValidName = true;
    } else {
      setFullNameError(true);
      checkIsValidName = false;
    }

    if (validateExpiryDate(data.expiryDate)) {
      checkIsValidExpiry = true;
    } else {
      setExpiryDateError(true);
      checkIsValidExpiry = false;
    }

    if (data.street.length >= 2) {
      checkIsValidStreet = true;
    } else {
      setStreetError(true);
      checkIsValidStreet = false;
    }

    if (data.zip.length >= 2) {
      checkIsValidZip = true;
    } else {
      setZipError(true);
      checkIsValidZip = false;
    }

    if (validateCvv(data.cvv)) {
      setCvvError(false);
      checkIsValidCvv = true;
    } else {
      setCvvError(true);
      checkIsValidCvv = false;
    }

    return (
      checkIsValidCard &&
      checkIsValidName &&
      checkIsValidExpiry &&
      checkIsValidStreet &&
      checkIsValidZip &&
      checkIsValidCvv
    );
  };

  const createPayment = useCallback(async (form) => {
    const token = user.token;

    try {
      onLoading(true);
      const userPaymentPayload = {
        SetAsDefault: false,
        xCardNum: form.cardNumber,
        xCvv: form.cvv,
        xExp: form.expiryDate.replaceAll(" ", "").replace("/", ""),
        xName: form.fullName,
        xStreet: form.street,
        xZip: form.zip,
        xCardType: detectCardType(form.cardNumber)
      };

      const responsePaymentCard = await axios.post(
        `${SERVER_URL}/dashboard/customers/${AXIOS_API_CALL.createPaymentMethod}/${customerId}`,
        { ...userPaymentPayload },
        {
          withCredentials: false,
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (responsePaymentCard.status === 200) {
        onLoading(false);
        onRefetch((p) => !p);
        setForm(formInitialState);
        onToggle(false);
        setRefetchCard((prev) => !prev);

        // notification
        notification.success({
          message: responsePaymentCard.data.message.message,
          placement: "bottomLeft",
        });

        // reset after deleting the card
        setTimeout(() => {
          window.location.reload()
        },1000)
      }
    } catch (err) {
      console.error(err.message);
      // notification
      notification.error({
        message: "Please enter valid card data.",
        placement: "bottomLeft",
      });

      onLoading(false);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const isValid = handleIsValidate(form);

    if (isValid) {
      createPayment(form);
    }
  };

  const onCancel = () => {
    handleFormReset();
    onToggle(false);
  };

  useEffect(() => {
    if (!active) {
      onCancel();
    }
  }, [active]);

  if (!toggle) {
    return (
      <button
        onClick={() => onToggle((p) => !p)}
        className="btn btn-primary-outline btn-toggle-create-payment"
        type="button"
      >
        <span className="icon"></span>
        <span className="text">Add Credit or Debit Card</span>
      </button>
    );
  }

  return (
    <form
      onSubmit={onSubmit}
      name="create"
      className={`form form-create-card ${className}`}
    >
      <div className="mb-4">
        <h3 className="title">Add Credit or Debit Card</h3>
        <img
          src="/assets/images/cards.png"
          alt="Cards"
          width={488}
          height={35}
        />
      </div>

      <div className="form-group-wrapper">
        <div className="form-group">
          <input
            disabled={loading}
            type="number"
            className={` ${cardNumberError ? "error" : ""}`}
            name="cardNumber"
            id="cardNumber"
            data-cy="create-card-number"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.cardNumber || ""}
          />
          <label className="required" htmlFor="cardNumber">
            Card number:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${cardNumberError ? "error" : ""}`}
          >
            Requires 8 to 19 digits (valid card number) !
          </p>
        </div>

        <div className="form-group item2">
          <input
            disabled={loading}
            type="text"
            className={` ${fullNameError ? "error" : ""}`}
            name="fullName"
            maxLength={30}
            id="fullName"
            data-cy="create-full-name"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.fullName || ""}
          />
          <label className="required" htmlFor="fullName">
            Name on card:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${fullNameError ? "error" : ""}`}
          >
            This field is required !
          </p>
        </div>

        <div className="form-group item3">
          <input
            disabled={loading}
            type="text"
            className={` ${expiryDateError ? "error" : ""}`}
            name="expiryDate"
            id="expiryDate"
            data-cy="create-expiry-date"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.expiryDate || ""}
          />
          <label className="required" htmlFor="expiryDate">
            Expiration date:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${expiryDateError ? "error" : ""}`}
          >
            Requires a 4 digit future date (mm / YY) !
          </p>
        </div>

        <div className="form-group item4">
          <input
            disabled={loading}
            type="text"
            className={` ${streetError ? "error" : ""}`}
            name="street"
            maxLength={50}
            id="street"
            data-cy="create-street"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.street || ""}
          />
          <label className="required" htmlFor="street">
            Street:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${streetError ? "error" : ""}`}
          >
            This field is required !
          </p>
        </div>

        <div className="form-group item5">
          <input
            disabled={loading}
            type="text"
            className={` ${zipError ? "error" : ""}`}
            name="zip"
            maxLength={30}
            id="zip"
            data-cy="create-zip"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.zip || ""}
          />
          <label className="required" htmlFor="zip">
            ZIP:
          </label>
          <p data-cy="" className={`error__onblur ${zipError ? "error" : ""}`}>
            This field is required !
          </p>
        </div>

        <div className="form-group item6">
          <input
            disabled={loading}
            type="number"
            className={` ${cvvError ? "error" : ""}`}
            name="cvv"
            id="cvv"
            data-cy="create-cvv"
            required={false}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(event) => onChange(event)}
            value={form.cvv}
          />
          <label className="required" htmlFor="cvv">
            CVV:
          </label>
          <p data-cy="" className={`error__onblur ${cvvError ? "error" : ""}`}>
            Requires 3 - 4 digits (valid cvv) !
          </p>
        </div>
      </div>

      <div className="form-group actions mt-2">
        {!loading ? (
          <button
            onClick={() => onCancel()}
            className="btn btn-primary-outline"
            type="button"
          >
            <span className="icon"></span>
            <span className="text">Cancel</span>
          </button>
        ) : (
          <button disabled className="btn btn-primary-link" type="button">
            <span className="icon"></span>
            <span className="text">Cancel</span>
          </button>
        )}

        {!loading ? (
          <button className="btn btn-primary" type="submit">
            <span className="icon"></span>
            <span className="text">Add Card</span>
          </button>
        ) : (
          <button disabled className="btn btn-primary" type="button">
            <span className="icon"></span>
            <span className="text">Loading...</span>
          </button>
        )}
      </div>
    </form>
  );
};

export default PaymentFormCreate;
