import React from 'react';
import Pagination from '../../../components/pagination/Pagination';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import { PERMISSIONS } from '../../../utils/permissions';

const Table = (props) => {
  // TABLE
  const { data, handleToggle } = props;
  // ACTIONS
  const { pharmacyPermissions, getPharmacyPermissions } = props;
  // PAGINATION
  const { pagination } = props;
  const { createUnitProps, updateProps, deleteProps } = props;

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Name</th>
                <th>Units</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {data && data.length > 0 ? (
                data.map((item, idx) => {
                  const { units } = item;

                  return (
                    <tr key={idx}>
                      <td>{item.name}</td>
                      <td>
                        <div className="d-flex gap-x-2">
                          {units &&
                            units.length > 0 &&
                            units.map((item) => {
                              return (
                                <div className="badge badge-silver" key={item._id}>
                                  <span className="text">{item.value}</span>
                                </div>
                              );
                            })}
                        </div>
                      </td>
                      <td>
                        <div className="actions">
                          {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.createAttributes) && (
                            <button className="btn btn-actions" type="button" data-cy="actions-create-unit" onClick={() => handleToggle({ name: 'create-unit', state: createUnitProps, data: item })}>
                              <span className="icon">
                                <img alt="Create" src="/assets/icons/plus.svg" />
                              </span>
                              <span className="text">
                                <strong></strong>
                              </span>
                            </button>
                          )}

                          {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.updateAttributes) && (
                            <button className="btn btn-actions" type="button" data-cy="actions-update-attributes" onClick={() => handleToggle({ name: 'update', state: updateProps, data: item })}>
                              <span className="icon">
                                <img alt="Update" src="/assets/icons/edit-silver.svg" />
                              </span>
                              <span className="text">
                                <strong></strong>
                              </span>
                            </button>
                          )}

                          {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.deleteAttributes) && (
                            <button className="btn btn-actions" type="button" data-cy="actions-delete-attributes" onClick={() => handleToggle({ name: 'delete', state: deleteProps, data: item })}>
                              <span className="icon">
                                <img alt="Delete" src="/assets/icons/delete.svg" />
                              </span>
                              <span className="text">
                                <strong></strong>
                              </span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                    <h2 style={{ color: '#666' }}>Data not found!</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination {...pagination} />
        </>
      ) : (
        <Message className="content-message">
          <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
        </Message>
      )}
    </>
  );
};

export default Table;
