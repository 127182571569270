const RemovePreview = ({ selectedImage, onRemoveImage }) => {
  if (!selectedImage) {
    return null;
  }

  return (
    <button
      onClick={onRemoveImage}
      className="btn btn-remove"
      title="Remove WIC image"
    >
      <img
        src="/assets/icons/trash-icon-dark.svg"
        alt="Trash Cart"
        width={22}
        height={22}
      />
    </button>
  );
};

const ImagePreview = ({ selectedImage, onRemoveImage }) => {
  if (!selectedImage) {
    return (
      <div className="info">
        <p>
          Please upload an image: max size{" "}
          <span style={{ color: "red" }}>(5mb)</span>
        </p>
        <p>
          Allowed format ('.jpg', '.jpeg', '.png', '.gif', '.webp' and '.heic')
        </p>
      </div>
    );
  }

  return (
    <div className="upload-preview">
      <p className="mb-1">Preview your WIC image:</p>
      <figure className="img-box">
        <img
          className="image-contain"
          src={selectedImage}
          alt="Selected Wic Image"
          width={360}
          height={200}
        />

        <div className="remove-image">
          <RemovePreview
            selectedImage={selectedImage}
            onRemoveImage={onRemoveImage}
          />
        </div>
      </figure>
    </div>
  );
};

export default ImagePreview;
