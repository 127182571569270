import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import Overlay from '../overlay/Overlay';
import { Wrapper, Aside } from '../content';
import Navbar from '../navbar/Navbar';
import jwt_decode from 'jwt-decode';
import { PERMISSIONS } from '../../utils/permissions';
import { groceryMenuItems, pharmacyMenuItems } from '../../utils/navigations';

const Sidebar = (props) => {
  const { onHide, onToggle, active, className } = props;
  const { user, logout } = useAuth();
  const location = useLocation();

  const [allPermissions, setAllPermissions] = useState({});
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [pharmacyPermissions, setPharmacyPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      setAllPermissions(permissions);

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) || location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.pharmacy))) {
          setPharmacyPermissions(permissions.pharmacy);
        }
      }
    }
  }, [user, location]);

  // TOGGLE SWITCH SITE
  const [toggleSwitchSite, setToggleSwitchSite] = useState(false);
  const handleToggleSwitch = () => {
    setToggleSwitchSite((prevState) => !prevState);
  };

  // CLOSE TOGGLE SWITCH SITE
  const handleToggleClose = () => {
    onHide(false);
    setToggleSwitchSite(false);
  };

  // DROPDOWN
  const dropdownRef = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (toggleSwitchSite) {
      let currentHeight = dropdownRef.current.scrollHeight;
      setDropdownHeight(currentHeight);
    } else {
      setDropdownHeight(0);
    }
  }, [toggleSwitchSite, dropdownRef]);

  // CLOSE TOGGLE SWITCH SITE ON CLOSE
  useEffect(() => {
    if (!active) {
      setTimeout(() => {
        setToggleSwitchSite(false);
      }, 300);
    }
  }, [active]);

  // LOGOUT
  const handleLogout = () => {
    logout();
    onHide(false);
    setToggleSwitchSite(false);
  };

  useEffect(() => {
    active ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden');
  }, [active]);

  const navbarProps = {
    location: location,
  };

  const groceryProps = {
    onHide: onHide,
    handleLogout: handleLogout,
    groceryPermissions: groceryPermissions,
  };

  const pharmacyProps = {
    onHide: onHide,
    handleLogout: handleLogout,
    pharmacyPermissions: groceryPermissions,
  };

  // GROCERY Menus
  const groceryMenus = groceryMenuItems(groceryProps);
  // PHARMACY Menus
  const pharmacyMenus = pharmacyMenuItems(pharmacyProps);

  return (
    <>
      <Aside className={`gl__sidebar gl__sidebar--left gl__sidebar--layout ${active ? 'isActive' : ''} ${className !== undefined ? className : ''}`}>
        <Wrapper className="logo">
          {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && (
            <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} className="link d-block" onClick={() => onHide(false)} data-cy="sidebar-logo">
              <img className="logo-brand" alt="Benjamin" src="/assets/images/logo-brand.png" />
            </Link>
          )}

          {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && (
            <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`} className="link d-block" onClick={() => onHide(false)} data-cy="sidebar-logo">
              <img className="logo-brand" alt="Benjamin" src="/assets/images/logo-brand.png" />
            </Link>
          )}
        </Wrapper>

        {/* TODO: return after includes PHARMACY segment (don't remove) */}

        <Wrapper className={`switch-site  ${toggleSwitchSite ? 'isActive' : ''}`}>
          {Object.keys(allPermissions).some((permission) => [PERMISSIONS.grocery, PERMISSIONS.pharmacy].includes(permission)) ? (
            <button className="btn" type="button" onClick={() => handleToggleSwitch()} data-cy="toggle-site">
              <span className="icon icon-marketplace">
                <img src="/assets/icons/marketplace.svg" className="img" alt="Marketplace" />
              </span>
              <span className="text">
                {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && 'Grocery'}
                {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && 'Pharmacy'}
              </span>
              <span className="icon icon-arrow">
                <img src="/assets/icons/arrow-white.svg" className="img" alt="Arrow Down" />
              </span>
            </button>
          ) : (
            <button className="btn" type="button" data-cy="toggle-site">
              <span className="icon icon-marketplace">
                <img src="/assets/icons/marketplace.svg" className="img" alt="Marketplace" />
              </span>
              <span className="text">
                {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && 'Grocery'}
                {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && 'Pharmacy'}
              </span>
              <span className="icon icon-arrow">
                <img src="/assets/icons/arrow-white.svg" className="img" alt="Arrow Down" />
              </span>
            </button>
          )}

          <div className={`dropdown ${toggleSwitchSite ? 'isActive' : ''}`} ref={dropdownRef} style={{ height: toggleSwitchSite ? `${dropdownHeight}px` : `0px` }}>
            <ul>
              {Object.keys(allPermissions).some((permission) => permission.includes(PERMISSIONS.grocery)) && (
                <li>
                  <NavLink to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} onClick={() => handleToggleClose()} data-cy="sidebar-grocery">
                    Grocery
                  </NavLink>
                </li>
              )}
              {Object.keys(allPermissions).some((permission) => permission.includes(PERMISSIONS.pharmacy)) && (
                <li>
                  <NavLink to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`} onClick={() => handleToggleClose()} data-cy="sidebar-pharmacy">
                    Pharmacy
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </Wrapper>

        <Navbar className="navbar" {...navbarProps} menus={{ grocery: groceryMenus, pharmacy: pharmacyMenus }} />
      </Aside>

      <Overlay onToggle={onToggle} active={active} />
    </>
  );
};

export default Sidebar;
