import React, { useEffect, useState } from "react";
import SortableTree, { getTreeFromFlatData } from "react-sortable-tree";
import { useProducts } from "../../../../../../context/products";

export const Categories = (props) => {
  const {
    productCategoriesData,
    onChange,
    formError,
    form,
    productEdit,
  } = useProducts();
  const { label, className } = props;

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    let data = [];

    data = getTreeFromFlatData({
      flatData: productCategoriesData.map((node) => ({
        ...node,
        expanded: true,
      })),
      getKey: (node) => node._id,
      getParentKey: (node) => node.parent,
      rootKey: null,
    });

    setTreeData(data);
  }, [productCategoriesData]);

  const inputCheckbox = (item) => {
    return [
      <div className="form-group">
        <input
          name="productCategory"
          onChange={onChange}
          type="radio"
          defaultValue={item.node._id || ""}
          defaultChecked={productEdit && item.node._id === form.productCategory}
          id={item.node._id}
        />
        <label htmlFor={item.node._id}>{item.node.name}</label>
      </div>,
    ];
  };

  return (
    <div
      className={`custom-select ${className !== undefined ? className : ""} ${
        formError.productCategory ? "error" : ""
      }`}
    >
      <span className="label">
        <b>{label}:</b>
      </span>

      <div className="select select-result">
        <div style={{ marginLeft: "-52px" }}>
          {treeData && treeData.length > 0 ? (
            <SortableTree
              className="select-sorting"
              treeData={treeData}
              canDrag={false}
              isVirtualized={false}
              rowHeight={32}
              onChange={(data) => setTreeData(data)}
              generateNodeProps={(rowInfo) => ({
                buttons: inputCheckbox(rowInfo),
              })}
            />
          ) : null}
        </div>
      </div>

      <p
        data-cy=""
        className={`error__onblur ${formError.productCategory ? "error" : ""}`}
      >
        * This field is required!
      </p>
    </div>
  );
};
