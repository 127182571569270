import { notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { SERVER_URL } from "../../config";
import { AXIOS_API_CALL } from "../../utils/endpoint";
import { PERMISSIONS } from "../../utils/permissions";
import { useAuth } from "../../context/useAuth";
import Pagination from "../pagination/Pagination";

function CreateOrderCustomers(props) {
  const { customersProps, paginationProps, data } = props;
  const {
    searchForm,
    handleOnChangeSearch,
    handleOnClearSearch,
    handleOnSubmitSearch,
    handleChooseCustomer,
    setReadDataRefetch,
  } = customersProps;
  const { user } = useAuth();

  // accordion
  const [openCards, setOpenCards] = useState([0]);
  // creating account loader
  const [loadingButton, setLoadingButton] = useState(false);
  // form
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
  });
  // error handling
  const [formError, setFormError] = useState({
    firstName: false,
    lastName: false,
    password: false,
    email: false,
  });

  const generatePassword = (length) => {
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "#$&";

    const allChars =
      uppercaseChars + lowercaseChars + numberChars + specialChars;

    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars.charAt(randomIndex);
    }

    setForm((prev) => {
      return {
        ...prev,
        password: password,
      };
    });
  };

  const handleCopy = (e) => {
    const { name } = e.target;

    switch (name) {
      case "password":
        if (form.password) {
          navigator.clipboard.writeText(form.password);
          notification.success({
            message: "Successfully Copied!",
            placement: "bottomLeft",
          });
        }
        break;
      case "email":
        if (form.email) {
          navigator.clipboard.writeText(form.email);
          notification.success({
            message: "Successfully Copied!",
            placement: "bottomLeft",
          });
        }
        break;
      default:
        return;
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const toggleCard = (index) => {
    setOpenCards((prevOpenCards) =>
      prevOpenCards.includes(index)
        ? prevOpenCards.filter((cardIndex) => cardIndex !== index)
        : [...prevOpenCards, index]
    );
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        if (value.length >= 3) {
          setFormError((prev) => {
            return { ...prev, firstName: false };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, firstName: true };
          });
        }
        break;
      case "lastName":
        if (value.length >= 3) {
          setFormError((prev) => {
            return { ...prev, lastName: false };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, lastName: true };
          });
        }
        break;
      case "password":
        if (value.length >= 3) {
          setFormError((prev) => {
            return { ...prev, password: false };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, password: true };
          });
        }
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(value)) {
          setFormError((prev) => {
            return { ...prev, email: false };
          });
        } else {
          setFormError((prev) => {
            return { ...prev, email: true };
          });
        }
        break;
      default:
        return;
    }
  };

  const handleCheckForm = (form) => {
    const { firstName, lastName, password, email } = form;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let checkCase = false;

    let checkFirstName = false;
    let checkLastName = false;
    let checkPassword = false;
    let checkEmail = false;

    if (firstName.length >= 3) {
      checkFirstName = true;
      setFormError((prev) => ({ ...prev, firstName: false }));
    } else {
      checkFirstName = false;
      setFormError((prev) => ({ ...prev, firstName: true }));
    }

    if (lastName.length >= 3) {
      checkLastName = true;
      setFormError((prev) => ({ ...prev, lastName: false }));
    } else {
      checkLastName = false;
      setFormError((prev) => ({ ...prev, lastName: true }));
    }

    if (password.length >= 3) {
      checkPassword = true;
      setFormError((prev) => ({ ...prev, password: false }));
    } else {
      checkPassword = false;
      setFormError((prev) => ({ ...prev, password: true }));
    }

    if (emailPattern.test(email)) {
      checkEmail = true;
      setFormError((prev) => ({ ...prev, email: false }));
    } else {
      checkEmail = false;
      setFormError((prev) => ({ ...prev, email: true }));
    }

    if (
      !checkFirstName &&
      !checkLastName &&
      !checkPassword &&
      !checkEmail
    ) {
      setFormError((prev) => {
        return {
          ...prev,
          firstName: true,
          lastName: true,
          password: true,
          email: true,
        };
      });
    }

    if (
      checkFirstName &&
      checkLastName &&
      checkPassword &&
      checkEmail
    ) {
      checkCase = true;
    }

    return checkCase;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const isChecked = handleCheckForm(form);

    const { token } = user;

    if (isChecked) {
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
        userType: "customer",
        authType: "basic",
        status: "ACTIVE",
        department: PERMISSIONS.grocery,
      };
      try {
        setLoadingButton(true);
        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.createCustomer}`,
            {
              ...payload,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              setReadDataRefetch((prev) => !prev);
              setForm({
                firstName: "",
                lastName: "",
                password: "",
                email: "",
              });
              setOpenCards([0]);
              // notification
              notification.success({
                message: res.data.message,
                placement: "bottomLeft",
              });

              setTimeout(() => {
                setLoadingButton(false);
              }, 700);
            }
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: err.response.data.message,
              placement: "bottomLeft",
            });
            setLoadingButton(false);
          });
      } catch (error) {
        setLoadingButton(false);
        console.error(error);
      }
    }
  };

  return (
    <div className="customers__table create__table">
      <header className="header">
        <div>Account Settings</div>
      </header>

      <div className="accordion">
        <div
          onClick={() => toggleCard(0)}
          name="existingAccount"
          className="accordion__header topRadius"
        >
          <div className="title">
            <h3>Existing Account</h3>
          </div>
          <div className="arrow">
            <img
              src="/assets/icons/arrow-down-table-filters.svg"
              width={18}
              height={9}
            />
          </div>
        </div>
        <div
          className={`accordion__body ${
            openCards.includes(0) ? "isActive" : ""
          }`}
        >
          <div style={{ padding: "20px 30px" }}>
            <div className="search">
              <form
                className="search-form"
                onSubmit={(e) => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Search"
                    />
                  </span>
                  <input
                    value={searchForm.query || ""}
                    type="text"
                    data-cy="search-input-field"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${
                      !!searchForm.query && searchForm.query.length > 0
                        ? "isActive"
                        : ""
                    }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          </div>

          <div className="customers__container">
            <table className="customer__table">
              <thead>
                <tr>
                  <th></th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Zip Code</th>
                </tr>
              </thead>

              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((customer) => {
                    const defaultZipAddress =
                      customer?.address &&
                      customer.address.length > 0 &&
                      customer.address.find((z) => z.isDefault === true);
                    return (
                      <tr key={customer._id}>
                        <td>
                          <input
                            value={customer._id}
                            type="radio"
                            name="customer"
                            onChange={(e) => handleChooseCustomer(e)}
                          />
                        </td>
                        <td>{`${customer.firstName} ${customer.lastName}`}</td>
                        <td>{customer.email}</td>
                        <td>{customer.phone[0]}</td>
                        <td>{defaultZipAddress.zip_code || null}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {data && data.length > 0 && <Pagination {...paginationProps} />}
          </div>
        </div>
      </div>

      <div className="accordion">
        <div
          name="newAccount"
          onClick={() => toggleCard(1)}
          className={`accordion__header ${
            openCards.includes(1) ? "" : "bottomRadius"
          }`}
        >
          <div className="title">
            <h3>Create New Account</h3>
          </div>
          <div className="arrow">
            <img
              src="/assets/icons/arrow-down-table-filters.svg"
              width={18}
              height={9}
            />
          </div>
        </div>
        <div
          className={`accordion__body ${
            openCards.includes(1) ? "isActive borderBottom" : ""
          }`}
        >
          <form onSubmit={(e) => handleSubmitForm(e)} className="newAcc">
            <div className="row">
              <label htmlFor="firstName">First Name:</label>
              <input
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChangeInput(e)}
                name="firstName"
                value={form.firstName}
                id="firstName"
                type="text"
                placeholder="Enter First Name"
              />
              <p className={`errorMsg ${formError.firstName ? "active" : ""}`}>
                *First Name is required.
              </p>
            </div>

            <div className="row">
              <label htmlFor="lastName">Last Name:</label>
              <input
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChangeInput(e)}
                name="lastName"
                value={form.lastName}
                id="lastName"
                type="text"
                placeholder="Enter Last Name"
              />
              <p className={`errorMsg ${formError.lastName ? "active" : ""}`}>
                *Last Name is required.
              </p>
            </div>

            <div className="row">
              <label htmlFor="password">Password:</label>
              <div>
                <input
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChangeInput(e)}
                  name="password"
                  value={form.password}
                  id="password"
                  type="text"
                  placeholder="Enter Password"
                />
                <img
                  name="password"
                  onClick={(e) => handleCopy(e)}
                  alt="copy icon"
                  width={20}
                  height={20}
                  src={`/assets/icons/${window.location.href.includes('grocery') ? 'copyIconBlue' : 'copyIconGreen'}.svg`}
                  style={{color:'red !important',}}
                />
              </div>
              <p className={`errorMsg ${formError.password ? "active" : ""}`}>
                *Password is required.
              </p>
            </div>

            <div className="row">
              <label htmlFor="email">Email:</label>
              <div>
                <input
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handleChangeInput(e)}
                  name="email"
                  value={form.email}
                  id="email"
                  type="text"
                  placeholder="example@gmail.com"
                />
                <img
                  name="email"
                  onClick={(e) => handleCopy(e)}
                  alt="copy icon"
                  width={20}
                  height={20}
                  src={`/assets/icons/${window.location.href.includes('grocery') ? 'copyIconBlue' : 'copyIconGreen'}.svg`}
                />
              </div>
              <p className={`errorMsg ${formError.email ? "active" : ""}`}>
                *Email is required
              </p>
            </div>

            <div className="row">
              <button
                onClick={() => generatePassword(15)}
                type="button"
                className="generateBtn btn btn-primary"
              >
                Auto-Generate Password
              </button>
            </div>

            <div
              style={{ height: "1px", background: "lightgray", width: "50%" }}
            />

            <div className="row">
              {loadingButton ? (
                <button type="button" className="btn btn-primary-outline">
                  Loading...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add Account
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateOrderCustomers;
