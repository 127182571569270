import React from "react";
import { useProducts } from "../../../../../../../context/products";

export const VariationWeights = () => {
  const { form, onChange, formError } = useProducts();

  return (
    <form name="variant-weight" className="form form-variant-price">
      <div className="form-group">
        <label className="label" htmlFor="productWeightVariant">
          <b>Weight:</b>
        </label>
        <input
          onChange={onChange}
          name="productWeightVariant"
          type="text"
          value={form.productWeightVariant || ""}
          placeholder="Weight"
          id="productWeightVariant"
          inputMode="numeric"
          className={`input ${
            formError.productWeightVariant?.empty ? "input-error" : ""
          }`}
        />
        {formError.productWeightVariant?.empty && (
          <p data-cy="" className="relative-error error__onblur error">
            * This field is required!
          </p>
        )}
      </div>
    </form>
  );
};
