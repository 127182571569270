import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Section, Main } from '../../components/content';

const title = {
  color: '#777',
  fontWeight: '100',
  fontSize: '36px',
  textAlign: 'center',
  paddingBlock: '20px'
};

const NotFoundError = () => {
  return (
    <>
      <Section className="section__not-found-error">
        <Main className="section__content p-4 bg-red">
          <h1 style={title}>Not Found Error | 404</h1>
        </Main>
      </Section>
    </>
  );
};

export default NotFoundError;
