import React, { useId } from "react";

const ImageHeader = ({ imageKey, onImageUpload, selectedImage }) => {
  if (selectedImage) {
    return null;
  }

  const inputId = useId();

  return (
    <div className="upload-header">
      <label htmlFor={inputId} className="btn">
        Upload screenshot of your WIC benefits
      </label>

      <input
        key={imageKey}
        type="file"
        id={inputId}
        name="imageInput"
        accept="image/*"
        onChange={onImageUpload}
      />
    </div>
  );
};

export default ImageHeader;
