import React, { useState, useEffect, Fragment, useRef } from "react";
import CustomInput from "../customInput/CustomInput";

export default function CartProductsSelect({
  parentProduct,
  parentVariation,
  product,
  disabled,
  name,
  onClick,
}) {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const lastElement = options.length;
  const [value, setValue] = useState(+product.currentQty);
  const [isChanged, setIsChanged] = useState(false);

  const buttonRef = useRef(null);

  useEffect(() => {
    setValue(+product.currentQty);
  }, [product.currentQty]); // TODO: check dependecy array

  useEffect(() => {
    const updateQty = +value;
    const currentQty = +product.currentQty;

    if (updateQty === currentQty) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [value, product.currentQty]);

  if (+value > 9) {
    return (
      <CustomInput
        quantity={value}
        setQuantity={setValue}
        product={product}
        parentProduct={parentProduct}
        parentVariation={parentVariation}
        disabled={disabled}
        name={name}
        onClick={onClick}
      />
    );
  }

  return (
    <Fragment>
      <select
        value={value}
        disabled={disabled}
        name="select-number-quantity"
        className={`select select-amount`}
        onChange={(e) => setValue(+e.target.value)}
      >
        <option value="0">0 (Delete)</option>

        {options.map((option) => {
          return (
            <option value={option} key={option}>
              {option}
              {lastElement === option && "+"}
            </option>
          );
        })}
      </select>

      {isChanged && (
        <button
          ref={buttonRef}
          onClick={(e) => {
            onClick({
              state: product,
              action: {
                type: name + "SelectUpdate",
                value: value,
                event: e,
                payload: {
                  parentProduct: parentProduct,
                  parentVariation: parentVariation,
                },
              },
            });
          }}
          type="button"
          disabled={disabled}
          className="btn-update"
        >
          <span className="text">Update</span>
        </button>
      )}
    </Fragment>
  );
}
