import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Modal from '../../modal/Modal';

export default function FormOverrideList({ data, onRefetch }) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    const [groceryPermissions, setGroceryPermissions] = useState([]);

    // GET USER TOKEN
    useEffect(() => {
        const { token } = user;
        if (user && token) {
            const decodeToken = jwt_decode(token);
            const permissions = decodeToken.roleData?.permissions;

            if (pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
                if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
                    setGroceryPermissions(permissions.grocery);
                }
            }
        }
    }, [user, pathname]);

    const [modal, setModal] = useState(false);
    const [deleteOverrideId, setDeleteOverrideId] = useState(null);

    const handleToggleModal = (id) => {
        setModal(p => !p);
        setDeleteOverrideId(id);
    }

    const deleteDeliveryOverrideRule = useCallback(async (id) => {
        const { token } = user;

        await axios
            .delete(`${SERVER_URL}/delivery-slot-override-rule/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (res.status === 200) {
                    const errorMessage = res.data.message;

                    onRefetch(p => !p);
                    setModal(p => !p);

                    if (errorMessage) {
                        notification.success({
                            message: errorMessage,
                            placement: 'bottomLeft'
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
            });
    }, [user]);

    const handleSubmitDelete = (event) => {
        event.preventDefault();
        deleteDeliveryOverrideRule(deleteOverrideId);
    }

    const deleteProps = {
        active: modal,
        onToggle: setModal,
    }

    return (
        <div className="override-list mt-2">
            <ul>
                {data.map((override) => {
                    return (
                        <li key={override._id}>
                            <div>
                                <p>Date: <span className="value">{override.date}</span></p>
                                <p>Day: <span className="value">{override.day}</span></p>
                                <p>Slot: <span className="value">{override.maximum_slots}</span></p>
                            </div>

                            <button onClick={() => handleToggleModal(override._id)} className="btn btn-danger-outline" type="button">
                                <span className="icon">
                                    <img src="/assets/icons/delete.svg" alt="Remove" />
                                </span>
                            </button>
                        </li>
                    )
                })}
            </ul>


            <Modal {...deleteProps} className="sidebar__modal--center" title="">
                <form
                    name="delete"
                    className="ant-form ant-form-horizontal"
                    onSubmit={handleSubmitDelete}
                >
                    <h2 className="text-center mb-4">
                        Are you sure you want to delete the selected item ?
                    </h2>

                    <div className="form-group form-group-modal mb-4">
                        <button
                            data-cy="modal-delete-submit-btn"
                            type="submit"
                            className="btn btn-primary-link"
                        >
                            <span className="text">Yes</span>
                        </button>

                        <button
                            data-cy="modal-delete-cancel-btn"
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setModal(false)}
                        >
                            <span className="text">No</span>
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}
