import React, { memo, useState, useEffect, useCallback, Fragment } from "react";
import axios from "axios";
import LoadingPlaceholder from "../../loadingPlaceholder/LoadingPlaceholder";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { SERVER_URL } from "../../../config";

const RenderDeliveryServices = ({
  user,
  products,
  resetDelivery,
  benjaminsDelivery,
  isServicesConfirmed,
  isAddressConfirmed,
  onSelected,
  renderAddress,
  singleShipmentRatesError,
  zipArrayValidation,
  readAddress,
}) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const findAddress = readAddress.find((p) => p._id === products._id);

  const getMultiShippingRates = useCallback(
    async (data) => {
      const token = user.token;

      const products = data?.products.map((product) => {
        return {
          product_id: product._id,
          quantity: product.currentQty,
          weight: product.weight,
        };
      });

      const body = {
        customer_id: data.user_id,
        addresses: [
          {
            address_id: data._id,
            products: products,
          },
        ],
      };

      setLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getShipmentRates}`,
          { ...body },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const singleShipmentRates = res.data?.rates[0]?.rate?.rates;

            if (singleShipmentRates && singleShipmentRates.length > 0) {
              setServices([benjaminsDelivery, ...singleShipmentRates]);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    },
    [user, resetDelivery, singleShipmentRatesError]
  );

  useEffect(() => {
    if (isAddressConfirmed) {
      getMultiShippingRates(products);
    }
  }, [isAddressConfirmed, resetDelivery, products]);

  useEffect(() => {
    setServices([]);
  }, [resetDelivery]);

  const errorBorderClass =
    zipArrayValidation &&
    zipArrayValidation.length > 0 &&
    zipArrayValidation.includes(findAddress.zip_code)
      ? "hsl(354deg, 82%, 61%)"
      : "";
  const errorBoxShadowClass =
    zipArrayValidation &&
    zipArrayValidation.length > 0 &&
    zipArrayValidation.includes(findAddress.zip_code)
      ? "0 0 0 3px rgba(237, 74, 90, 0.3)"
      : "none";

  return (
    <Fragment>
      <div className="delivery-services">
        <div>{renderAddress(products._id)}</div>

        <div className="form-group">
          {!loading ? (
            <Fragment>
              {!isServicesConfirmed ? (
                <select
                  style={{
                    borderColor: `${errorBorderClass}`,
                    boxShadow: `${errorBoxShadowClass}`,
                  }}
                  disabled={false}
                  onChange={(e) =>
                    onSelected({
                      id: products._id,
                      service: services.find(
                        (item) => item.id === e.target.value
                      ),
                    })
                  }
                  className="select"
                >
                  <option value="none">Select Service</option>

                  {services.map((item) => {
                    const {
                      id,
                      carrier,
                      service,
                      est_delivery_days,
                      rate,
                    } = item;

                    return (
                      <option value={id} key={id}>
                        {service}, {carrier},{" "}
                        {est_delivery_days && est_delivery_days + ", "} ${rate}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select disabled={true} className="select">
                  <option value="none">Select Service</option>

                  {services.map((item) => {
                    const {
                      id,
                      carrier,
                      service,
                      est_delivery_days,
                      rate,
                    } = item;
                    return (
                      <option value={id} key={id}>
                        {service}, {carrier},{" "}
                        {est_delivery_days && est_delivery_days + ", "} ${rate}
                      </option>
                    );
                  })}
                </select>
              )}
            </Fragment>
          ) : (
            <LoadingPlaceholder style={{ width: "300px", height: "40px" }} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export const DeliveryServices = memo(RenderDeliveryServices);
