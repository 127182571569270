import { BENJAMINS_DELIVERY_ID, BENJAMINS_DELIVERY_KEY } from "./constant";

const slugify = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const slugifyReplaceAll = (str) => {
  return str
    .toLowerCase()
    .replaceAll("-", " ")
    .replace(/\s+/g, "-");
};

const formatTime = (item) => {
  const time = new Date(item.createdAt).toTimeString().slice(0, 8);
  return `${time}`;
};

const formatDate = (item) => {
  const date = new Date(item.createdAt);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

const emailIsValid = (str) => {
  const reg = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return reg.test(str.toLowerCase());
};

const numberIsValid = (num) => {
  const reg = new RegExp("[0-9]");
  return reg.test(num);
};

const upperCaseIsValid = (str) => {
  const reg = new RegExp(/.*[A-Z].*/);
  return reg.test(str);
};

const specialCharIsValid = (str) => {
  const reg = new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
  return reg.test(str);
};

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const validateExpiryDate = (str) => {
  const regex = new RegExp("^(0[1-9]|1[0-2])( / )([0-9]{2})$");
  return regex.test(str);
};

const validatePaymentCardNumber = (str) => {
  const regex = new RegExp("^[0-9]{8,19}$");
  return regex.test(str);
};

const validateCvv = (str) => {
  const regex = new RegExp("^[0-9]{3,4}$");
  return regex.test(str);
};

function detectCardType(cardNumber) {
  const patterns = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MasterCard: /^5[1-5][0-9]{14}$/,
    Amex: /^3[47][0-9]{13}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    Diners: /^3(?:0[0-5]|[68][0-9])?[0-9]{11}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (const [cardType, pattern] of Object.entries(patterns)) {
    if (pattern.test(cardNumber)) {
      return cardType;
    }
  }

  return null;
}

/**
 * Group list data from array object
 * @param {*} data
 * @returns
 */
const getGroupList = (data) => {
  if (!data) {
    return false;
  }

  try {
    const results = data.reduce(function(acc, cur) {
      acc[cur.group] = acc[cur.group] || [];
      acc[cur.group].push(cur);

      return acc;
    }, Object.create(null));

    return results;
  } catch (err) {
    throw Error("Error");
  }
};

const getImageUrl = (URL) => {
  return URL;
};

/**
 *  Capitalize first letter of char
 * @param {*} string
 * @returns
 */
const capitalizeFirstLetter = (string) => {
  const dangerouslySetInnerHTML = {}; // disabled this line

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Generated OTP id
 * @returns
 */
const generateId = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

/**
 * Flatten function for array
 * @param {*} arr
 * @returns
 */
function flatten(arr) {
  return arr.reduce(function(flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

// REPLACE ALL (node version < 15)
const replaceAll = (string, search, replace) => {
  return string.split(search).join(replace);
};

// Print image for postage label
function printImage(id) {
  const divContents = document.getElementById(id);
  const a = window.open("", "", "height=700, width=700");
  a.document.write(
    '<html><body ><img src="' +
      divContents.src +
      '" alt="image" width="90%"/></body></html>'
  );
  a.document.close();
  a.print();
}

// Remove duplicates from array
function removeDuplicates(arr) {
  return [...new Set(arr)];
}

// Formating price for correct USD currency display
function formatCurrencyPrice(price) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);
}

// scroll freeze
const scrollFreeze = () => {
  const html = document.documentElement;
  let position = html.style.position;
  let scrollXPosition = html.scrollTop;

  if (!position) {
    html.style.position = "fixed";
    html.style.width = "100%";
    html.style.height = "100%";
    html.style.top = "-" + scrollXPosition + "px";
    html.style.overflowY = "scroll";
  }
};

// scroll unfreeze
const scrollUnfreeze = () => {
  const html = document.documentElement;
  let position = html.style.position;

  if (position === "fixed") {
    html.style.position = "static";
    html.scrollTop = -parseInt(html.style.top);
    html.style.position = "";
    html.style.top = "";
    html.style.width = "";
    html.style.height = "";
    html.style.overflowY = "";
  }
};

// format price variable product
const formatPrice = (item) => {
  if (typeof item === "undefined") {
    return null;
  }

  const isOnSale = item && item.hasOwnProperty("isOnSale") && item.isOnSale;
  const isOnVariable =
    item &&
    item.hasOwnProperty("productType") &&
    item.productType.includes("variable_product");

  if (isOnSale) {
    const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");

    if (checkRegularPrice && checkSalePrice) {
      const regularPrice = +item.RegularPrice;
      const salePrice = +item.SalePrice;
      return (
        <>
          <small className="regular-price line-through small-lg">
            ${regularPrice.toFixed(2)}
          </small>
          <span> - </span>
          <span className="sale-price">${salePrice.toFixed(2)}</span>
        </>
      );
    }
  }

  if (isOnVariable) {
    const isCheckMinimumPrice = item && item.hasOwnProperty("minimumPrice");
    const isCheckMaximumPrice = item && item.hasOwnProperty("maximumPrice");

    if (isCheckMinimumPrice && isCheckMaximumPrice) {
      const priceRage = item && item.priceRange;
      if (
        item &&
        item.minimumPrice &&
        item.minimumPrice === item.maximumPrice
      ) {
        const minimumPrice = item && +item.minimumPrice;
        return <span>${minimumPrice.toFixed(2)}</span>;
      } else {
        return <span>{priceRage}</span>;
      }
    }

    const regularPrice = +item.RegularPrice;
    return <span className="price-regular">${regularPrice.toFixed(2)}</span>;
  }

  const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");

  if (checkRegularPrice) {
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");

    if (checkSalePrice) {
      const isCheckSalePriceLength = item && item.SalePrice !== "";
      const isCheckSalePriceValue = item && item.SalePrice !== 0;
      const isCheckSalePriceNotNull = item && item.SalePrice !== null;

      if (
        isCheckSalePriceLength &&
        isCheckSalePriceValue &&
        isCheckSalePriceNotNull
      ) {
        const salePrice = +item.SalePrice;
        const regularPrice = +item.RegularPrice;

        return (
          <>
            <small className="regular-price line-through small-lg">
              ${regularPrice.toFixed(2)}
            </small>
            <span> - </span>
            <span className="sale-price">${salePrice.toFixed(2)}</span>
          </>
        );
      }
    }

    const regularPrice = +item.RegularPrice;
    return <span className="sale-price">${regularPrice.toFixed(2)}</span>;
  }
};

const sumFormatPrice = (item) => {
  const isOnSale = item && item.isOnSale;
  const isOnVariable =
    item &&
    item.hasOwnProperty("productType") &&
    item.productType.includes("variable_product");

  if (isOnSale) {
    const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");

    if (checkRegularPrice && checkSalePrice) {
      const qty = item && item.qty;
      const regularPrice = +item.RegularPrice * qty;
      const salePrice = +item.SalePrice * qty;

      return (
        <>
          <small className="regular-price line-through small-lg">
            ${regularPrice.toFixed(2)}
          </small>
          <span> - </span>
          <span className="sale-price">${salePrice.toFixed(2)}</span>
        </>
      );
    }
  }

  if (isOnVariable) {
    const isCheckMinimumPrice = item && item.hasOwnProperty("minimumPrice");
    const isCheckMaximumPrice = item && item.hasOwnProperty("maximumPrice");

    if (isCheckMinimumPrice && isCheckMaximumPrice) {
      const priceRage = item && item.priceRange;
      if (
        item &&
        item.minimumPrice &&
        item.minimumPrice === item.maximumPrice
      ) {
        const minimumPrice = item && +item.minimumPrice;
        return <span>${minimumPrice.toFixed(2)}</span>;
      } else {
        return <span>{priceRage}</span>;
      }
    }

    const regularPrice = +item.RegularPrice;
    const qty = item.qty;
    const sumPrice = regularPrice * qty;

    return <span className="price-regular">${sumPrice.toFixed(2)}</span>;
  }

  const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");

  if (checkRegularPrice) {
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");
    const qty = item.qty;

    if (checkSalePrice) {
      const isCheckSalePriceLength = item && item.SalePrice !== "";
      const isCheckSalePriceValue = item && item.SalePrice !== 0;
      const isCheckSalePriceNull = item && item.SalePrice !== null;

      if (
        isCheckSalePriceLength &&
        isCheckSalePriceValue &&
        isCheckSalePriceNull
      ) {
        const salePrice = +item.SalePrice * qty;
        const regularPrice = 10;
        return (
          <span>
            <small className="regular-price line-through small-lg">
              ${regularPrice.toFixed(2)}
            </small>{" "}
            - ${salePrice.toFixed(2)}
          </span>
        );
      }
    }

    const regularPrice = +item.RegularPrice * qty;
    return <span>${regularPrice.toFixed(2)}</span>;
  }
};

const sumCurrentFormatPrice = (item) => {
  const isOnSale = item && item.isOnSale;

  if (isOnSale) {
    const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");

    if (checkRegularPrice && checkSalePrice) {
      const qty = item && item.currentQty;
      const regularPrice = +item.RegularPrice * qty;
      const salePrice = +item.SalePrice * qty;

      return (
        <span>
          <span className="sale-price">${salePrice.toFixed(2)}</span>
        </span>
      );
    }
  }

  const isOnVariable =
    item &&
    item.hasOwnProperty("productType") &&
    item.productType.includes("variable_product");
  if (isOnVariable) {
    return 0;
  }

  const checkRegularPrice = item && item.hasOwnProperty("RegularPrice");

  if (checkRegularPrice) {
    const checkSalePrice = item && item.hasOwnProperty("SalePrice");
    const qty = item.currentQty;

    if (checkSalePrice) {
      const isCheckSalePriceLength = item && item.SalePrice !== "";
      const isCheckSalePriceValue = item && item.SalePrice !== 0;
      const isCheckSalePriceNull = item && item.SalePrice !== null;

      if (
        isCheckSalePriceLength &&
        isCheckSalePriceValue &&
        isCheckSalePriceNull
      ) {
        const salePrice = +item.SalePrice * qty;
        const regularPrice = +item.RegularPrice * qty;

        return (
          <span>
            <span>${salePrice.toFixed(2)}</span>
          </span>
        );
      }
    }

    const regularPrice = +item.RegularPrice * qty;
    return <span>${regularPrice.toFixed(2)}</span>;
  }
};

const isWic = (eligible) => {
  const isAllowed = "WIC";
  if (eligible.includes(isAllowed)) {
    return (
      <span>
        (<span style={{ color: "hsl(230, 76%, 59%)" }}>{isAllowed}</span>)
      </span>
    );
  }
};

function isCheckImage({ action, payload }) {
  switch (action) {
    case "simple":
      const isCheckFeatureImage =
        payload.data.hasOwnProperty("featureImage") &&
        payload.data.featureImage;

      if (!isCheckFeatureImage) {
        const featureImage = payload.data.featureImage;

        if (featureImage === null) {
          return (
            <img
              // priority={true}
              // loader={false}
              src="/assets/images/product-placeholder.svg"
              alt="No product"
              width={100}
              height={100}
            />
          );
        }

        return (
          <img
            // priority={true}
            // loader={false}
            src="/assets/images/product-placeholder.svg"
            alt="No product"
            width={100}
            height={100}
          />
        );
      }

      const { url, originalname } = payload.data.featureImage;
      return (
        <img
          // priority={true}
          // loader={false}
          src={url || payload.data.featureImage}
          alt={originalname}
          width={100}
          height={100}
        />
      );

    case "variable":
      const isCheckProductImage = payload.data.hasOwnProperty("image");
      if (isCheckProductImage) {
        const { url, originalname } = payload.data && payload.data.image;
        return (
          <img
            // priority={true}
            // loader={false}
            src={url}
            alt={originalname}
            width={100}
            height={100}
          />
        );
      }

      return (
        <img
          // priority={true}
          // loader={false}
          src="/assets/images/product-placeholder.svg"
          alt="No product"
          width={100}
          height={100}
        />
      );
    default:
      console.warn("Problem in isCheckImage function!");
  }
}

export const getTotalPrice = (rest) => {
  if (rest.length < 1) {
    return;
  }

  let products = [];
  let productsData = [];

  const isRestLength = rest && rest.length > 0;
  if (isRestLength) {
    for (const product of rest) {
      const isSimpleProduct = product.productType === "simple_product";

      if (isSimpleProduct) {
        const isSelected = product.isSelected;

        if (isSelected) {
          products.push(product);
        }

        productsData.push(product);

        const isChildrenLength =
          product.children && product.children.length > 0;

        if (isChildrenLength && isSelected) {
          const children = product.children;

          for (const child of children) {
            products.push(child);
            productsData.push(child);
          }
        }
      }

      const isVariationsLength =
        product.variations && product.variations.length > 0;

      if (isVariationsLength) {
        const variations = product.variations;

        for (const variation of variations) {
          const isSelectedVariation = variation.isSelected;

          productsData.push(variation);

          if (isSelectedVariation) {
            products.push(variation);
          }

          const isVariationChildrenLength =
            variation.children && variation.children.length > 0;

          if (isVariationChildrenLength) {
            const variationChildren = variation.children;

            for (const child of variationChildren) {
              if (isSelectedVariation) {
                products.push(child);
              }

              productsData.push(child);
            }
          }
        }
      }
    }
  }

  const totalSalePrice = products.reduce((acc, item) => {
    const price =
      item.hasOwnProperty("SalePrice") && item.SalePrice !== null
        ? parseFloat(item.SalePrice)
        : parseFloat(item.RegularPrice);
    const subtotal = price * item.currentQty;
    return acc + subtotal;
  }, 0);

  const totalDeliveryPrice = productsData.reduce((acc, item) => {
    const price =
      item.hasOwnProperty("SalePrice") && item.SalePrice !== null
        ? parseFloat(item.SalePrice)
        : parseFloat(item.RegularPrice);
    const subtotal = price * item.currentQty;
    return acc + subtotal;
  }, 0);

  const totalRegularPrice = products.reduce((acc, item) => {
    const price = parseFloat(item.RegularPrice);
    const subtotal = price * item.currentQty;
    return acc + subtotal;
  }, 0);

  return {
    products: products,
    productsData: productsData,
    totalDeliveryPrice: totalDeliveryPrice,
    totalSalePrice: totalSalePrice || 0,
    totalRegularPrice: totalRegularPrice || 0,
  };
};

const getTotalShipment = (wic, rest) => {
  let products = [];

  const isWicLength = wic && wic.length > 0;
  if (isWicLength) {
    for (const product of wic) {
      const isSimpleProduct = product.productType === "simple_product";

      if (isSimpleProduct) {
        const isSelected = product.isSelected;

        if (isSelected) {
          products.push(product);
        }
      }

      const isVariationsLength =
        product.variations && product.variations.length > 0;

      if (isVariationsLength) {
        const variations = product.variations;

        for (const variation of variations) {
          const isSelectedVariation = variation.isSelected;

          if (isSelectedVariation) {
            products.push(variation);
          }
        }
      }
    }
  }

  const isRestLength = rest && rest.length > 0;
  if (isRestLength) {
    for (const product of rest) {
      const isSimpleProduct = product.productType === "simple_product";

      if (isSimpleProduct) {
        const isSelected = product.isSelected;

        if (isSelected) {
          products.push(product);
        }

        const isChildrenLength =
          product.children && product.children.length > 0;

        if (isChildrenLength) {
          const children = product.children;

          for (const child of children) {
            products.push(child);
          }
        }
      }

      const isVariationsLength =
        product.variations && product.variations.length > 0;

      if (isVariationsLength) {
        const variations = product.variations;

        for (const variation of variations) {
          const isSelectedVariation = variation.isSelected;

          if (isSelectedVariation) {
            products.push(variation);

            const isVariationChildrenLength =
              variation.children && variation.children.length > 0;

            if (isVariationChildrenLength) {
              const variationChildren = variation.children;

              for (const child of variationChildren) {
                products.push(child);
              }
            }
          }
        }
      }
    }
  }

  const totalSalePrice = products.reduce((acc, item) => {
    const price =
      item.hasOwnProperty("SalePrice") && item.SalePrice !== null
        ? parseFloat(item.SalePrice)
        : parseFloat(item.RegularPrice);
    const subtotal = price * item.currentQty;
    return acc + subtotal;
  }, 0);

  const totalRegularPrice = products.reduce((acc, item) => {
    const price = parseFloat(item.RegularPrice);
    const subtotal = price * item.currentQty;
    return acc + subtotal;
  }, 0);

  const uniqueAddresses = [
    ...new Set(
      products.map(
        (item) => item.hasOwnProperty("item") && item.defaultAddress._id
      )
    ),
  ];
  const addresses =
    uniqueAddresses && uniqueAddresses[0] !== false ? uniqueAddresses : [];

  return {
    products: products,
    totalSalePrice: totalSalePrice || 0,
    totalRegularPrice: totalRegularPrice || 0,
    uniqueAddresses: addresses,
  };
};

const getProductItems = (products) => {
  const results = [];

  for (const product of products) {
    const hasSimpleProduct = product.productType === "simple_product";

    if (hasSimpleProduct) {
      const payload = {
        product_id: product._id,
        quantity: product.currentQty,
      };

      results.push(payload);
    } else {
      const payload = {
        product_id: product.productId,
        variation_id: product._id,
        quantity: product.currentQty,
      };

      results.push(payload);
    }
  }

  return results;
};

const getAddressWithProducts = (products, rates) => {
  const updatedProducts = products.map((product) => {
    const rateEntry = rates.find((rate) => rate.hasOwnProperty(product._id));

    if (rateEntry) {
      const rateKey = Object.keys(rateEntry)[0];
      const rateData = rateEntry[rateKey];

      if (rateData.id === BENJAMINS_DELIVERY_ID) {
        return {
          rate_id: rateData.id,
          shipment_id: rateData.shipment_id,
          address_id: product._id,
          products: getProductItems(product.products),
          benjaminsDelivery: false,
          use_shipment_service: BENJAMINS_DELIVERY_KEY,
        };
      }

      return {
        rate_id: rateData.id,
        shipment_id: rateData.shipment_id,
        address_id: product._id,
        products: getProductItems(product.products),
        benjaminsDelivery: false,
      };
    } else {
      return {
        rate_id: "",
        shipment_id: "",
        address_id: product._id,
        products: getProductItems(product.products),
        benjaminsDelivery: true,
        use_shipment_service: BENJAMINS_DELIVERY_KEY,
      };
    }
  });

  return updatedProducts;
};

const getProductItemsPrice = (products) => {
  const results = [];

  for (const product of products) {
    const hasWicSelected = product.WIC_SELECTED;
    const hasSimpleProduct = product.productType === "simple_product";

    let price = 0;

    if (!hasWicSelected) {
      const isSalePrice =
        product &&
        product.hasOwnProperty("SalePrice") &&
        product.SalePrice !== null;

      if (isSalePrice) {
        price = product.SalePrice;
      } else {
        price = product.RegularPrice;
      }

      const totalPrice = price * product.currentQty;

      if (hasSimpleProduct) {
        const payload = {
          product_id: product._id,
          quantity: product.currentQty,
          price: totalPrice,
        };

        results.push(payload);
      } else {
        const payload = {
          product_id: product.productId,
          variation_id: product._id,
          quantity: product.currentQty,
          price: totalPrice,
        };

        results.push(payload);
      }
    } else {
      // ! This code is calc all value from cart for shipment (isSalePrice, price, totalPrice)
      const isSalePrice =
        product &&
        product.hasOwnProperty("SalePrice") &&
        product.SalePrice !== null;

      if (isSalePrice) {
        price = product.SalePrice;
      } else {
        price = product.RegularPrice;
      }

      const totalPrice = price * product.currentQty;

      if (hasSimpleProduct) {
        const payload = {
          product_id: product._id,
          quantity: product.currentQty,
          price: totalPrice, // ! Return (price) variable if not calc value from WIC Purchase
        };

        results.push(payload);
      } else {
        const payload = {
          product_id: product.productId,
          variation_id: product._id,
          quantity: product.currentQty,
          price: totalPrice, // ! Return (price) variable if not calc value from WIC Purchase
        };

        results.push(payload);
      }
    }
  }

  return results;
};

const getTotalPriceFromAddress = (
  address,
  products,
  delivery,
  freeDelivery
) => {
  const total = products.reduce((sum, item) => sum + parseFloat(item.price), 0);

  if (total >= freeDelivery) {
    return 0;
  }

  return +delivery;
};

const getTotalPriceWithAddress = (product, delivery, freeDelivery) => {
  const total = product.reduce((sum, item) => sum + parseFloat(item.price), 0);

  if (total >= freeDelivery) {
    return 0;
  }

  return +delivery;
};

const getAddressWithProductsPrice = (
  products,
  rates,
  delivery,
  freeDelivery
) => {
  const updatedProducts = products.map((product) => {
    const rateEntry = rates.find((rate) => rate.hasOwnProperty(product._id));

    if (rateEntry) {
      const rateKey = Object.keys(rateEntry)[0];
      const rateData = rateEntry[rateKey];

      if (
        rateData.id !== "" &&
        rateData.id === "rate_700benjaminsandpharmacydeliveryc"
      ) {
        const productItems = getProductItemsPrice(product.products);
        const shippingPrice = getTotalPriceFromAddress(
          rateData,
          productItems,
          +delivery,
          +freeDelivery
        );

        return {
          shippingPrice: shippingPrice,
        };
      }

      return {
        shippingPrice: +rateData.rate,
      };
    } else {
      const productItems = getProductItemsPrice(product.products);
      const shippingPrice = getTotalPriceWithAddress(
        productItems,
        +delivery,
        +freeDelivery
      );

      return {
        shippingPrice: shippingPrice,
      };
    }
  });

  const totalAmount = updatedProducts.reduce(
    (sum, item) => sum + parseFloat(item.shippingPrice),
    0
  );

  return totalAmount;
};

const getProducts = (wic, rest, address, isSplitShipping) => {
  const products = [];

  const isWicLength = wic && wic.length > 0;
  if (isWicLength) {
    for (const product of wic) {
      const isSimpleProduct = product.productType === "simple_product";

      if (isSimpleProduct) {
        const isSelected = product.isSelected;

        if (isSelected) {
          const {
            RegularPrice,
            SalePrice,
            currentQty,
            productType,
            defaultAddress,
            eligible,
            featureImage,
            isChecked,
            isOnSale,
            isSelected,
            tags,
            title,
            upccode,
            weight,
            _id,
          } = product;

          // #1 PAYLOAD
          const payload = {
            WIC_SELECTED: true,
            RegularPrice: +RegularPrice || null,
            SalePrice: +SalePrice || null,
            eligible: eligible,
            featureImage: featureImage || null,
            isChecked: isChecked,
            isOnSale: isOnSale,
            isSelected: isSelected,
            tags: tags,
            productType: productType,
            upccode: upccode,
            weight: weight || null,
            currentQty: currentQty,
            title: title,
            _id: _id,
          };

          products.push(payload);
        }
      }

      const isVariationsLength =
        product.variations && product.variations.length > 0;

      if (isVariationsLength) {
        const variations = product.variations;

        for (const variation of variations) {
          const isSelectedVariation = variation.isSelected;

          if (isSelectedVariation) {
            const {
              defaultAddress,
              productType,
              eligible,
              isOnSale,
              tags,
              title,
              upccode,
            } = product;
            const {
              _id,
              RegularPrice,
              SalePrice,
              featureImage,
              isChecked,
              isSelected,
              variationId,
              currentQty,
              units,
              weight,
            } = variation;

            // #2 PAYLOAD
            const payload = {
              WIC_SELECTED: true,
              RegularPrice: +RegularPrice || null,
              SalePrice: +SalePrice || null,
              eligible: eligible,
              featureImage: featureImage || null,
              isChecked: isChecked,
              isOnSale: isOnSale,
              isSelected: isSelected,
              tags: tags,
              upccode: upccode,
              weight: weight || null,
              currentQty: currentQty,
              productType: productType,
              title: title,
              variationId: variationId,
              productId: product._id, // TODO: check this out
              units: units,
              _id: _id,
            };

            products.push(payload);
          }
        }
      }
    }
  }

  const isRestLength = rest && rest.length > 0;
  if (isRestLength) {
    for (const product of rest) {
      const isSimpleProduct = product.productType === "simple_product";

      if (isSimpleProduct) {
        const isSelected = product.isSelected;

        if (isSelected) {
          const {
            RegularPrice,
            SalePrice,
            currentQty,
            productType,
            defaultAddress,
            eligible,
            featureImage,
            isChecked,
            isOnSale,
            isSelected,
            tags,
            title,
            upccode,
            weight,
            _id,
          } = product;

          // #3 PAYLOAD
          const payload = {
            RegularPrice: +RegularPrice || null,
            SalePrice: +SalePrice || null,
            defaultAddress: defaultAddress,
            eligible: eligible,
            featureImage: featureImage || null,
            isChecked: isChecked,
            isOnSale: isOnSale,
            isSelected: isSelected,
            tags: tags,
            upccode: upccode,
            weight: weight || null,
            currentQty: currentQty,
            productType: productType,
            title: title,
            _id: _id,
          };

          products.push(payload);
        }

        const isChildrenLength =
          product.children && product.children.length > 0;

        if (isChildrenLength && isSelected) {
          const children = product.children;

          for (const child of children) {
            const {
              eligible,
              isChecked,
              productType,
              isOnSale,
              isSelected,
              tags,
              title,
              upccode,
              weight,
            } = product;
            const {
              RegularPrice,
              SalePrice,
              currentQty,
              defaultAddress,
              featureImage,
              splitId,
              _id,
            } = child;

            // #4 PAYLOAD
            const payload = {
              RegularPrice: +RegularPrice || null,
              SalePrice: +SalePrice || null,
              currentQty: currentQty,
              defaultAddress: defaultAddress,
              featureImage: featureImage,

              productType: productType,
              eligible: eligible,
              isChecked: isChecked,
              isOnSale: isOnSale,
              isSelected: isSelected,
              title: title,
              tags: tags,
              weight: weight || null,
              _id: _id,
            };

            products.push(payload);
          }
        }
      }

      const isVariationsLength =
        product.variations && product.variations.length > 0;

      if (isVariationsLength) {
        const variations = product.variations;

        for (const variation of variations) {
          const isSelectedVariation = variation.isSelected;

          if (isSelectedVariation) {
            const {
              eligible,
              isOnSale,
              productType,
              tags,
              title,
              upccode,
            } = product;
            const {
              _id,
              defaultAddress,
              RegularPrice,
              SalePrice,
              weight,
              featureImage,
              isChecked,
              isSelected,
              variationId,
              currentQty,
              units,
            } = variation;

            // #5 PAYLOAD
            const payload = {
              RegularPrice: +RegularPrice || null,
              SalePrice: +SalePrice || null,
              defaultAddress: defaultAddress,
              eligible: eligible,
              featureImage: featureImage || null,
              isChecked: isChecked,
              isOnSale: isOnSale,
              isSelected: isSelected,
              tags: tags,
              upccode: upccode,
              weight: weight || null,
              currentQty: currentQty,
              productType: productType,
              title: title,
              variationId: variationId,
              productId: product._id, // TODO: check this out
              units: units,
              _id: _id,
            };

            products.push(payload);

            const isVariationChildrenLength =
              variation.children && variation.children.length > 0;

            if (isVariationChildrenLength) {
              const variationChildren = variation.children;

              // #6 PAYLOAD
              for (const child of variationChildren) {
                const {
                  RegularPrice,
                  SalePrice,
                  currentQty,
                  defaultAddress,
                  featureImage,
                  splitId,
                  _id,
                } = child;

                const payload = {
                  RegularPrice: +RegularPrice || null,
                  SalePrice: +SalePrice || null,
                  currentQty: currentQty,
                  defaultAddress: defaultAddress,
                  featureImage: featureImage || null,
                  splitId: splitId,
                  units: units,
                  _id: _id,

                  productId: product._id,
                  productType: productType,
                  eligible: eligible,
                  isChecked: isChecked,
                  isOnSale: isOnSale,
                  isSelected: isSelected,
                  title: title,
                  variationId: variationId,
                  tags: tags,
                  weight: weight || null,
                };

                products.push(payload);
              }
            }
          }
        }
      }
    }
  }
  let defaultAddressId = address.defAddress._id;
  let defaultAddressUserId = address.defAddress.user_id;

  if (isSplitShipping) {
    defaultAddressId = address.wicAddress._id;
    defaultAddressUserId = address.wicAddress.user_id;
  }

  let restResult = {};

  for (const item of products) {
    const isWicSelected = item && item.hasOwnProperty("WIC_SELECTED");

    if (!isWicSelected) {
      const addressId = item && item.defaultAddress && item.defaultAddress._id;
      const addressUserId =
        item && item.defaultAddress && item.defaultAddress.user_id;

      if (addressId) {
        if (!restResult[addressId]) {
          restResult[addressId] = {
            _id: isSplitShipping ? addressId : defaultAddressId,
            user_id: isSplitShipping ? addressUserId : defaultAddressUserId,
            products: [],
          };
        }

        restResult[addressId].products.push(item);
      }
    }
  }

  const reviewCart = Object.values(restResult);
  let wicResult = {};
  const wicProduct = [];

  if (defaultAddressId) {
    for (const item of products) {
      const isWicSelected = item && item.hasOwnProperty("WIC_SELECTED");

      if (isWicSelected) {
        const findProduct = reviewCart.find(
          (product) => product._id === defaultAddressId
        );

        if (findProduct) {
          findProduct.products.push(item);
        } else {
          wicProduct.push(item);

          const payload = {
            _id: defaultAddressId,
            user_id: defaultAddressUserId,
            products: wicProduct,
          };

          wicResult = payload;
        }
      }
    }
  }

  let productsReview = [];

  if (wicResult && Object.keys(wicResult).length > 0) {
    productsReview = [wicResult, ...reviewCart];
  } else {
    productsReview = [...reviewCart];
  }

  const uniqueAddresses = [...productsReview.map((item) => item._id)];

  return {
    products: products,
    productsReview: productsReview,
    uniqueAddresses: uniqueAddresses,
  };
};

const getTotalValue = (total, rate) => {
  const value = total + rate;

  if (!value || value === NaN) {
    return 0;
  }

  return +value.toFixed(2);
};

const sumPrice = (price) => {
  if (price.selectedWic.length > 0) {
    if (price.totalDeliveryPrice >= price.freeDelivery) {
      return price.discountPrice;
    }

    const total = price.discountPrice + price.deliveryPrice;

    return total;
  }

  const total = price.discountPrice;

  return total;
};

const getLeftTotalPay = (total, payments) => {
  return total - payments;
};

const isDisabled = (selected) => {
  return !selected;
};

const getProductsListSplit = (products) => {
  let results = [];
  let wic = [];

  for (const product of products) {
    const items = product.products;

    for (const item of items) {
      const hasWicSelected = item && item.hasOwnProperty("WIC_SELECTED");
      const hasSimpleProduct = item && item.productType === "simple_product";

      if (!hasWicSelected) {
        if (hasSimpleProduct) {
          const payload = {
            product_id: item._id,
            quantity: item.currentQty,
          };

          results.push(payload);
        } else {
          if (item.productId) {
            const payload = {
              product_id: item.productId,
              variation_id: item._id,
              quantity: item.currentQty,
            };

            results.push(payload);
          } else {
            const payload = {
              product_id: item._id,
              quantity: item.currentQty,
            };

            results.push(payload);
          }
        }
      } else {
        if (hasSimpleProduct) {
          const payload = {
            product_id: item._id,
            quantity: item.currentQty,
          };

          wic.push(payload);
        } else {
          if (item.productId) {
            const payload = {
              product_id: item.productId,
              variation_id: item._id,
              quantity: item.currentQty,
            };

            wic.push(payload);
          } else {
            const payload = {
              product_id: item._id,
              quantity: item.currentQty,
            };

            wic.push(payload);
          }
        }
      }
    }
  }

  return {
    products: results,
    wicProducts: wic,
  };
};

const getUserSplitOrders = ({
  user,
  payment,
  splitPayment,
  shipment: { rate_id, shipment_id },
  amount,
  addresses,
  address,
  products: { rest, wic },
  selectedWic,
  uploadWicImage,
  hideUploadWicImage,
}) => {
  let hasUser = true;
  let hasPayment = true;
  let hasMultiPayment = true;
  let hasSelectedWic = true;
  let hasShipment = true;
  let hasAddress = true;
  let hasAddressComment = true;
  let hasAmount = true;
  let hasRest = true;
  let hasWic = true;
  let hasProducts = true;
  let hasUploadWicImage = false;

  if (user && Object.keys(user).length > 0) {
    hasUser = false;
  }

  if (!splitPayment) {
    if (payment && payment.length > 0) {
      hasPayment = false;
    }
  } else {
    if (payment && payment.length > 0) {
      hasMultiPayment = false;
    }
  }

  if (address && Object.keys(address).length > 0) {
    const isComment = address.hasOwnProperty("comment");

    if (isComment) {
      hasAddressComment = false;
    } else {
      hasAddressComment = true;
    }
  }

  if (rest.length > 0) {
    hasRest = false;
  }

  if (wic.length > 0) {
    hasWic = false;
  }

  if (!hasRest || !hasWic) {
    hasProducts = false;
  }

  if (!hasWic) {
    if (
      uploadWicImage &&
      uploadWicImage.blob &&
      uploadWicImage.blob.length > 0
    ) {
      hasUploadWicImage = true;
    } else {
      hasUploadWicImage = false;
    }
  }

  if (rate_id !== "" && shipment_id !== "") {
    hasShipment = false;
  }

  let payload = {
    products: [...rest, ...wic],
    payment: [],
    shipping: {
      addresses: addresses,
    },
    customer_id: user.id,
    send_everything_to_same_address: false,
  };

  if (!hasPayment && hasMultiPayment) {
    if (+amount > 0) {
      payload = {
        ...payload,
        payment: [
          {
            paymentType: "Credit or Debit Card",
            card: {
              PaymentMethodId: payment,
            },
            amount: +amount.toFixed(2),
          },
        ],
      };
    }
  }

  if (!hasMultiPayment) {
    const payments = payment.map((pay) => {
      return {
        paymentType: "Credit or Debit Card",
        card: {
          PaymentMethodId: pay.PaymentMethodId,
        },
        amount: +pay.value,
      };
    });

    const filteredPayments = payments.filter(({ amount }) => amount > 0);

    payload = {
      ...payload,
      payment: filteredPayments,
    };
  }

  if (selectedWic && selectedWic.length > 0) {
    hasSelectedWic = false;
  }

  if (!hasWic) {
    payload = {
      ...payload,
      payment: [
        ...payload.payment,
        {
          paymentType: "WIC",
          products: wic,
        },
      ],
    };
  }

  if (!hasAddressComment) {
    if (address && address.comment && address.comment.length > 0) {
      payload = {
        ...payload,
        comment: address.comment,
      };
    }
  }

  if (hasUploadWicImage && !hideUploadWicImage) {
    payload = {
      ...payload,
      wic_card_photo_data_uri: uploadWicImage.blob,
    };
  }

  if (!hasSelectedWic && hideUploadWicImage) {
    payload = {
      ...payload,
      send_wic_photo_to_phone_number: true,
    };
  }

  const paymentError = !splitPayment ? hasPayment : hasMultiPayment;
  const uploadWicError = hideUploadWicImage
    ? hideUploadWicImage
    : hasUploadWicImage;

  const errors = {
    user: hasUser,
    payment: paymentError,
    selectedWic: hasSelectedWic,
    shipment: hasShipment,
    address: hasAddress,
    amount: hasAmount,
    comment: hasAddressComment,
    products: hasProducts,
    uploadWicImage: uploadWicError,
  };

  return {
    payload: payload,
    errors: errors,
  };
};

const getUserOrders = ({
  user,
  payment,
  splitPayment,
  shipment: { rate_id, shipment_id },
  address,
  amount,
  products: { rest, wic },
  selectedWic,
  uploadWicImage,
  hideUploadWicImage,
}) => {
  let hasUser = true;
  let hasPayment = true;
  let hasMultiPayment = true;
  let hasSelectedWic = true;
  let hasShipment = true;
  let hasAddress = true;
  let hasAddressComment = true;
  let hasAmount = true;
  let hasRest = true;
  let hasWic = true;
  let hasProducts = true;
  let hasUploadWicImage = false;
  let hasBenjaminsDelivery = false;

  if (user && Object.keys(user).length > 0) {
    hasUser = false;
  }

  if (!splitPayment) {
    if (payment && payment.length > 0) {
      hasPayment = false;
    }
  } else {
    if (payment && payment.length > 0) {
      hasMultiPayment = false;
    }
  }

  if (selectedWic && selectedWic.length > 0) {
    hasSelectedWic = false;
  }

  if (rate_id !== "" && shipment_id !== "") {
    hasShipment = false;
  }

  if (address && Object.keys(address).length > 0) {
    hasAddress = false;

    if (address.comment && address.comment !== "") {
      hasAddressComment = false;
    }
  }

  if (!hasShipment && rate_id === BENJAMINS_DELIVERY_ID) {
    hasBenjaminsDelivery = true;
  }

  if (amount !== "") {
    hasAmount = false;
  }

  if (rest.length > 0) {
    hasRest = false;
  }

  if (wic.length > 0) {
    hasWic = false;
  }

  if (!hasRest || !hasWic) {
    hasProducts = false;
  }

  if (!hasWic) {
    if (
      uploadWicImage &&
      uploadWicImage.blob &&
      uploadWicImage.blob.length > 0
    ) {
      hasUploadWicImage = true;
    } else {
      hasUploadWicImage = false;
    }
  }

  if (address && Object.keys(address).length > 0) {
    hasAddress = false;

    if (address.comment && address.comment !== "") {
      hasAddressComment = false;
    }
  }

  if (amount !== "") {
    hasAmount = false;
  }

  let payload = {
    products: [...rest, ...wic],
    payment: [],
    shipping: {
      addresses: [
        {
          rate_id: rate_id,
          shipment_id: shipment_id,
          address_id: address._id,
          products: [...rest, ...wic],
        },
      ],
    },
    customer_id: user.id,
    send_everything_to_same_address: false,
  };

  if (hasBenjaminsDelivery) {
    payload = {
      ...payload,
      shipping: {
        addresses: [
          {
            rate_id: rate_id,
            shipment_id: shipment_id,
            address_id: address._id,
            products: [...rest, ...wic],
            use_shipment_service: BENJAMINS_DELIVERY_KEY,
          },
        ],
      },
    };
  }

  if (!hasPayment) {
    if (+amount > 0) {
      payload = {
        ...payload,
        payment: [
          {
            paymentType: "Credit or Debit Card",
            card: {
              PaymentMethodId: payment,
            },
            amount: +amount.toFixed(2),
          },
        ],
      };
    }
  }

  if (!hasMultiPayment) {
    const payments = payment.map((pay) => {
      return {
        paymentType: "Credit or Debit Card",
        card: {
          PaymentMethodId: pay.PaymentMethodId,
        },
        amount: +pay.value,
      };
    });

    const filteredPayments = payments.filter(({ amount }) => amount > 0);

    payload = {
      ...payload,
      payment: filteredPayments,
    };
  }

  if (!hasAddressComment) {
    payload = {
      ...payload,
      comment: address.comment,
    };
  }

  if (!hasWic) {
    payload = {
      ...payload,
      payment: [
        ...payload.payment,
        {
          paymentType: "WIC",
          products: wic,
        },
      ],
    };
  }

  if (hasUploadWicImage && !hideUploadWicImage) {
    payload = {
      ...payload,
      wic_card_photo_data_uri: uploadWicImage.blob,
    };
  }

  if (!hasSelectedWic && hideUploadWicImage) {
    payload = {
      ...payload,
      send_wic_photo_to_phone_number: true,
    };
  }

  if (amount === 0) {
    hasPayment = false;
  }

  const paymentError = !splitPayment ? hasPayment : hasMultiPayment;
  const shipmentError = hasShipment && hasBenjaminsDelivery;
  const uploadWicError = hideUploadWicImage
    ? hideUploadWicImage
    : hasUploadWicImage;

  const errors = {
    user: hasUser,
    payment: paymentError,
    selectedWic: hasSelectedWic,
    shipment: shipmentError,
    address: hasAddress,
    amount: hasAmount,
    products: hasProducts,
    uploadWicImage: uploadWicError,
  };

  return {
    payload: payload,
    errors: errors,
  };
};

export {
  slugify,
  slugifyReplaceAll,
  formatTime,
  formatDate,
  emailIsValid,
  numberIsValid,
  upperCaseIsValid,
  specialCharIsValid,
  uuidv4,
  getGroupList,
  getImageUrl,
  generateId,
  replaceAll,
  printImage,
  removeDuplicates,
  formatCurrencyPrice,
  formatPrice,
  sumFormatPrice,
  isWic,
  isCheckImage,
  isDisabled,
  sumCurrentFormatPrice,
  getProducts,
  getTotalShipment,
  getTotalValue,
  getAddressWithProducts,
  getAddressWithProductsPrice,
  scrollFreeze,
  scrollUnfreeze,
  detectCardType,
  validateCvv,
  validatePaymentCardNumber,
  validateExpiryDate,
  sumPrice,
  getLeftTotalPay,
  getProductsListSplit,
  getUserSplitOrders,
  getUserOrders,
};
