const AXIOS_API_CALL = {
  // ROLES
  getRole: "get-role",
  getRoles: "get-roles",
  createRole: "create-role",
  updateRole: "update-role",
  deleteRole: "delete-role",
  bulkDeleteRole: "bulk-delete-role",

  // CUSTOMERS
  getCustomers: "dashboard/customers",
  deleteCustomers: "dashboard/customers/delete-account",
  updateCustomers: "dashboard/customers/edit-customer",
  bulkUpdateStatus: "dashboard/bulk-deactivate-customer",
  createCustomer: "dashboard/customers/create-customer",

  // EMPLOYEES
  usersEmployees: "users/employees",
  usersGetEmoloyee: "users/get-employee",
  createEmployee: "create-employee",
  usersUpdateEmoloyee: "users/update-employee",
  usersBulkDeactivateEmployee: "users/bulk-dectivate-employee",
  usersDeleteEmployee: "users/delete-employee",

  //ADDRESS
  getAddresses: "addresses/get-address",

  // GALLERY
  galleryImages: "images",
  galleryImagesSearch: "images?searchTerm=",
  galleryUploadLocalImage: "upload-local-image",
  updateGalleryImage: "images-update",
  deleteGalleryImage: "images-delete",
  uploadLocalImage: "upload-local-image",
  getImagesById: "images",

  productGalleryImages: "product-gallery-images",

  // ADDRESSES - CUSTOMERS
  createDashboardAddresses: "dashboard/addresses/create-address",
  deleteDashboardAddresses: "dashboard/addresses/delete-address",

  // PRODUCTS
  getProduct: "get-product-data",
  getProducts: "products",
  deleteProduct: "delete-product",
  deleteProductBulk: "bulk-delete-product",
  updateProduct: "update-product",

  // IMPORT FROM NETSUITE
  importProductFromNetSuite: "import-product-from-netsuite",
  importCategoriesFromNetSuite: "get-netsuite-categories",

  // ORDERS
  orders: "orders",
  updateOrder: "update-order",
  orderDetails: "order-details",

  // PAYMENT METHODS
  listPaymantMethods: "list-payment-methods",
  getSinglePaymentMethod: "get-payment-method",
  createPaymentMethod: "create-payment-method",
  updatePaymentMethod: "update-payment-method",
  removePaymentMethod: "remove-payment-method",

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: "employee-email-verification",

  // ACTIVITY LOG
  logs: "logs",
  logsDownload: "logs/download",
  logsCreateFile: "logs/create-file",

  // CATEGORIES
  categories: "categories",
  categoriesGetAll: "categories-get-all",
  checkCategorySlug: "check-category-slug",
  checkCategoryName: "check-category-name",

  // ATTRIBUTES
  attributes: "attributes",
  createAttribute: "create-attribute",

  // UNITS
  units: "units",
  addUnitToAttribute: "add-unit-to-attribute",

  // LOGIN
  login: "login",

  // FORGOT PASSWORD
  forgotPassword: "forgot-password",

  // PASSWORD RESET
  passwordReset: "password-reset",

  // PROFILE
  updateEmployee: "users/update-employee",

  // USERS
  usersGetEmployee: "users/get-employee",
  employeeLogs: "employee/logs",

  // PERMISSIONS
  getPermissions: "get-permissions",

  // USER CHECK TOKEN
  userCheckToken: "users/check-token",

  // USER CHECK VERIFY PERMISSIONS
  userCheckPermissions: "verify-employee-permissions",

  // GROCERY
  grocery: "grocery",
  departmentGrocery: "grocery",

  // PHARMACY
  pharmacy: "pharmacy",
  departmentPharmacy: "pharmacy",

  // VARIATIONS
  deleteVariation: "delete-variation",

  // UPC PRODUCT DATA
  pullProductData: "pull-product-data-from-item-db",

  // COUPONS
  createCoupons: "coupon",
  updateCoupon: "coupon",
  getCoupons: "coupon",
  deleteCoupon: "coupon",
  deleteBulkCoupons: "coupon/bulk-delete",

  // GET ALL COUPONS
  validateCoupon: "coupon/validate",

  // GEO LOCATION
  createGeoLocations: "allowed-zip-code",
  updateGeoLocation: "allowed-zip-code",
  getGeoLocations: "allowed-zip-code",
  deleteGeoLocation: "allowed-zip-code",
  deleteBulkGeoLocations: "allowed-zip-code/bulk-delete",

  // SHIPMENT
  getShipmentRates: "get-shipment-rates",

  // GET GEO LOCATIONS (ZIP CODES)
  allowedZipCodes: "allowed-zip-codes",

  // CHECKOUT PAYMENT
  createOrders: "orders/create-order",
};

export { AXIOS_API_CALL };
