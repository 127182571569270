import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { slugify, slugifyReplaceAll } from '../../../helpers/helpers';
import { notification } from 'antd';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import SidebarModal from '../../../components/sidebarModal/SidebarModal';
import Modal from '../../../components/modal/Modal';
import { getGroceryPermissions } from '../../../middleware';
import { notificationMessages } from '../../../helpers/notificationMessages';

const options = [5, 10, 20, 50, 100];

const Attributes = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${currentPage}/${limitPage}/${search || null}/null`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => console.error(err))
        .finally();

      return response;
    } catch (err) {}
  }, [user, currentPage, limitPage, search]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);

      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [createAttUnitBtnLoader, setCreateAttUnitBtnLoader] = useState(false);
  const [createUnitBtnLoader, setCreateUnitBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // CREATE - (attribute)
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({ name: '', slug: '' }); // FORM data
  const [createFormError, setCreateFormError] = useState({ name: false, slug: false }); // ERROR handling

  // CREATE - (unit in attribute)
  const createAttributeUnitFormRef = useRef(null);
  const [toggleCreateUnitAttribute, setToggleCreateUnitAttribute] = useState(false);
  const [createUnitAttributeForm, setCreateUnitAttributeForm] = useState({ name: '' }); // FORM data
  const [createUnitAttribute, setCreateUnitAttribute] = useState([]);
  const [createUnitAttributeFormError, setCreateUnitAttributeFormError] = useState({ name: false }); // ERROR handling

  // CREATE - (unit)
  const createUnitFormRef = useRef(null);
  const [toggleCreateUnit, setToggleCreateUnit] = useState(false);
  const [createUnitForm, setCreateUnitForm] = useState({ name: '' }); // FORM data
  const [createUnitFormError, setCreateUnitFormError] = useState({ name: false }); // ERROR handling
  const [createUnitFormId, setCreateUnitFormId] = useState({ id: '' }); // ID for fetch

  // UPDATE - (attribute)
  const updateFormRef = useRef(null);
  const updateCreateUnitFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateForm, setUpdateForm] = useState({ name: '', slug: '', unit: '' }); // FORM data
  const [updateUnit, setUpdateUnit] = useState([]);
  const [updateFormError, setUpdateFormError] = useState({ name: false, slug: false, unit: false }); // ERROR handling
  const [updateFormId, setUpdateFormId] = useState({ id: '' }); // ID for fetch
  const [updateLoadingForm, setUpdateLoadingForm] = useState(true);
  const [toggleUpdateUnit, setToggleUpdateUnit] = useState(false);

  // DELETE - (attribute)
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: '' }); // ID for fetch

  useEffect(() => {
    if (!!toggleCreate) {
      setToggleCreateUnitAttribute(false);
    }
  }, [toggleCreate]);

  useEffect(() => {
    if (!!toggleUpdate) {
      setToggleUpdateUnit(false);
    }
  }, [toggleUpdate]);

  /**
   * Handle remove unit
   * @param {*} props
   */
  const handleOnRemoveUnit = (props) => {
    const { name, data } = props;

    switch (name) {
      case 'create-attribute-unit':
        const removedattributeUnit = createUnitAttribute.filter((unit) => !unit._id.startsWith(data._id));
        setCreateUnitAttribute(removedattributeUnit);

        // try {
        //   axios
        //     .delete(`${SERVER_URL}/${AXIOS_API_CALL.units}/${data._id}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.token}` } })
        //     .then((res) => {
        //       if (res.status === 200) {
        //         setRefetchCreateUnitRemoved((prevState) => !prevState);
        //       }
        //     })
        //     .catch((err) => console.error(err))
        //     .finally(setTimeout(() => {}, 700));
        // } catch (err) {}

        break;
      case 'update-attribute-unit':
        const removedUnit = updateUnit.filter((unit) => !unit._id.startsWith(data._id));
        setUpdateUnit(removedUnit);

        // try {
        //   axios
        //     .delete(`${SERVER_URL}/${AXIOS_API_CALL.units}/${data._id}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.token}` } })
        //     .then((res) => {
        //       if (res.status === 200) {
        //         setRefetchUpdateUnitRemoved((prevState) => !prevState);
        //       }
        //     })
        //     .catch((err) => console.error(err))
        //     .finally(setTimeout(() => {}, 700));
        // } catch (err) {}

        break;
      default:
        console.warn('Default of: handleOnRemoveUnit function');
    }
  };

  /**
   * Toggle modal function
   * @param {*} props
   */
  const handleToggle = (props) => {
    const { name, data } = props;

    switch (name) {
      case 'create':
        setToggleCreate(true);
        break;
      case 'create-attribute-unit':
        setToggleCreateUnitAttribute(true);
        break;
      case 'create-unit':
        setToggleCreateUnit(true);
        setCreateUnitFormId({ id: data._id });
        break;
      case 'update':
        setToggleUpdate(true);
        setUpdateFormId({ id: data._id });
        break;
      case 'delete':
        setToggleDelete(true);
        setDeleteFormId({ id: data._id });
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  };

  /**
   * Handle on change function
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        const slug = slugify(value);
        setCreateForm({ ...createForm, name: value, slug: slug });
        break;
      case 'createSlug':
        const createSlug = slugifyReplaceAll(value);
        setCreateForm({ ...createForm, slug: createSlug });
        break;
      case 'createAttributeUnitName':
        setCreateUnitAttributeForm({ ...createUnitAttributeForm, name: value });
        break;
      case 'updateName':
        setUpdateForm({ ...updateForm, name: value });
        break;
      case 'updateSlug':
        const updateSlug = slugifyReplaceAll(value);
        setUpdateForm({ ...updateForm, slug: updateSlug });
        break;
      case 'updateCreateUnitName':
        setUpdateForm({ ...updateForm, unit: value });
        break;
      case 'createUnitName':
        setCreateUnitForm({ ...createUnitForm, name: value });
        break;
      default:
        console.warn('Default of: handleOnChange function!');
    }
  };

  /**
   * Handle on blur function
   * @param {*} event
   */
  const handleOnBlur = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, name: false });
        } else {
          toggleCreate ? setCreateFormError({ ...createFormError, name: true }) : setCreateFormError({ ...createFormError, name: false });
        }
        break;
      case 'createSlug':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, slug: false });
        } else {
          toggleCreate ? setCreateFormError({ ...createFormError, slug: true }) : setCreateFormError({ ...createFormError, slug: false });
        }
        break;
      case 'createAttributeUnitName':
        if (value.length >= 2) {
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        } else {
          toggleCreate && toggleCreateUnitAttribute ? setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true }) : setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        }
        break;
      case 'updateName':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          toggleUpdate ? setUpdateFormError({ ...updateFormError, name: true }) : setUpdateFormError({ ...updateFormError, name: false });
        }
        break;
      case 'updateSlug':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, slug: false });
        } else {
          toggleUpdate ? setUpdateFormError({ ...updateFormError, slug: true }) : setUpdateFormError({ ...updateFormError, slug: false });
        }
        break;
      case 'updateCreateUnitName':
        if (value.length >= 1) {
          setUpdateFormError({ ...updateFormError, unit: false });
        } else {
          toggleUpdate && toggleUpdateUnit ? setUpdateFormError({ ...updateFormError, unit: true }) : setUpdateFormError({ ...updateFormError, unit: false });
        }
        break;
      case 'createUnitName':
        if (value.length >= 1) {
          setCreateUnitFormError({ ...createUnitFormError, name: false });
        } else {
          toggleCreateUnit ? setCreateUnitFormError({ ...createUnitFormError, name: true }) : setCreateUnitFormError({ ...createUnitFormError, name: false });
        }
        break;
      default:
        console.warn('Default of: handleOnBlur function!');
    }
  };

  /**
   * Handle validate function
   * @param {name}
   * @param {form}
   */
  const handleOnValidate = ({ name, form }) => {
    let checkCase = false;

    let checkCreateName = false;
    let checkCreateSlug = false;

    let checkCreateAttributeUnitName = false;

    let checkCreateUnitName = false;

    let checkUpdateName = false;
    let checkUpdateSlug = false;

    let checkCreateUpdateUnit = false;

    switch (name) {
      case 'create':
        if (form.data.name.length >= 2) {
          checkCreateName = true;
          setCreateFormError({ ...createFormError, name: false });
        } else {
          checkCreateName = false;
          toggleCreate ? setCreateFormError({ ...createFormError, name: true }) : setCreateFormError({ ...createFormError, name: true });
        }

        if (form.data.slug.length >= 2) {
          checkCreateSlug = true;
          setCreateFormError({ ...createFormError, slug: false });
        } else {
          checkCreateSlug = false;
          toggleCreate ? setCreateFormError({ ...createFormError, slug: true }) : setCreateFormError({ ...createFormError, slug: true });
        }

        if (!checkCreateName && !checkCreateSlug) {
          setCreateFormError({ ...createFormError, name: true, slug: true });
        }

        if (checkCreateName && checkCreateSlug) {
          checkCase = true;
        }
        break;
      case 'create-attribute-unit':
        if (form.data.name.length >= 1) {
          checkCreateAttributeUnitName = true;
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        } else {
          checkCreateAttributeUnitName = false;
          toggleCreate ? setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true }) : setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: false });
        }

        if (!checkCreateAttributeUnitName) {
          setCreateUnitAttributeFormError({ ...createUnitAttributeFormError, name: true });
        }

        if (checkCreateAttributeUnitName) {
          checkCase = true;
        }
        break;
      case 'create-unit':
        if (form.data.name.length >= 1) {
          checkCreateUnitName = true;
          setCreateUnitFormError({ ...createUnitFormError, name: false });
        } else {
          checkCreateUnitName = false;
          toggleCreateUnit ? setCreateUnitFormError({ ...createUnitFormError, name: true }) : setCreateUnitFormError({ ...createUnitFormError, name: false });
        }

        if (!checkCreateUnitName) {
          setCreateUnitFormError({ ...createUnitFormError, name: true });
        }

        if (checkCreateUnitName) {
          checkCase = true;
        }
        break;
      case 'update':
        if (form.data.name.length >= 2) {
          checkUpdateName = true;
          setUpdateFormError({ ...updateFormError, name: false });
        } else {
          checkUpdateName = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, name: true }) : setUpdateFormError({ ...updateFormError, name: false });
        }

        if (form.data.slug.length >= 2) {
          checkUpdateSlug = true;
          setUpdateFormError({ ...updateFormError, slug: false });
        } else {
          checkUpdateSlug = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, slug: true }) : setUpdateFormError({ ...updateFormError, slug: false });
        }

        if (!checkUpdateName && !checkUpdateSlug) {
          setUpdateFormError({ ...updateFormError, name: true, slug: true });
        }

        if (checkUpdateName && checkUpdateSlug) {
          checkCase = true;
        }
        break;
      case 'update-create-unit':
        if (form.data.unit.length >= 1) {
          checkCreateUpdateUnit = true;
          setUpdateFormError({ ...updateFormError, unit: false });
        } else {
          checkCreateUpdateUnit = false;
          toggleUpdate ? setUpdateFormError({ ...updateFormError, unit: true }) : setUpdateFormError({ ...updateFormError, unit: false });
        }

        if (!checkCreateUpdateUnit) {
          setUpdateFormError({ ...updateFormError, unit: true });
        }

        if (checkCreateUpdateUnit) {
          checkCase = true;
        }
        break;
      case 'delete':
        checkCase = true;
        break;
      default:
        console.warn('Default of: handleOnValidate function!');
    }

    return checkCase;
  };

  /**
   * Form submit function
   * @param {*} props
   */
  async function handleOnSubmit({ event, form }) {
    event.preventDefault();

    const { name } = event.target;

    const { token } = user;

    switch (name) {
      case 'create':
        setCreateBtnLoader(true);
        const createValidate = handleOnValidate({ name: name, form: form });

        if (createValidate) {
          let createPayload = {
            department: PERMISSIONS.grocery,
            name: createForm.name,
            units: []
          };

          if (createUnitAttribute.length > 0) {
            const units = createUnitAttribute.map((unit) => unit._id);
            createPayload = {
              ...createPayload,
              units: units
            };
          }

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.createAttribute}`, { ...createPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  setReadDataRefetch((prevState) => !prevState);
                  setCreateUnitAttribute([]);
                  form.onToggle(false);
                  form.onData({ name: '' });
                  form.formRef.current.reset();

                  // notification
                  notification.success({
                    message: notificationMessages.successCreateAttribute,
                    placement: 'bottomLeft'
                  });
                  setTimeout(() => {
                    setCreateBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateBtnLoader(false);
        }
        break;
      case 'create-attribute-unit':
        setCreateAttUnitBtnLoader(true);
        const createAttributeUnitValidate = handleOnValidate({ name: name, form: form });
        if (createAttributeUnitValidate) {
          const createAttributeUnitPayload = {
            value: createUnitAttributeForm.name,
            department: PERMISSIONS.grocery
          };

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createAttributeUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  const newUnitData = {
                    _id: res.data.message._id,
                    value: res.data.message.value
                  };

                  setCreateUnitAttribute([...createUnitAttribute, newUnitData]);
                  setToggleCreateUnitAttribute(false);
                  form.onData({ name: '' });
                  setTimeout(() => {
                    setCreateAttUnitBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateAttUnitBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateAttUnitBtnLoader(false);
        }
        break;
      case 'create-unit':
        setCreateUnitBtnLoader(true);
        const createUnitValidate = handleOnValidate({ name: name, form: form });

        if (createUnitValidate) {
          const createUnitID = createUnitFormId.id;

          const createUnitPayload = {
            value: createUnitForm.name,
            department: PERMISSIONS.grocery
          };

          const fetchUnitData = await axios
            .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
              setTimeout(() => {
                setCreateUnitBtnLoader(false);
              }, 400);
              return res;
            })
            .catch((err) => {
              console.error(err);
              setCreateUnitBtnLoader(false);
            })
            .finally(setTimeout(() => {}, 700));

          let unitID = '';

          if (fetchUnitData.status === 201) {
            unitID = fetchUnitData.data.message._id;

            if (!!unitID) {
              await axios
                .put(`${SERVER_URL}/${AXIOS_API_CALL.addUnitToAttribute}/${createUnitID}`, { units: [unitID] }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
                .then((res) => {
                  if (res.status === 200) {
                    setReadDataRefetch((prevState) => !prevState);
                    form.onData({ name: '' });
                    form.onToggle(false);
                    form.formRef.current.reset();

                    // notification
                    notification.success({
                      message: res.data.message,
                      placement: 'bottomLeft'
                    });
                  }
                })
                .catch((err) => console.error(err))
                .finally(setTimeout(() => {}, 700));
            }
          }
        } else {
          setCreateUnitBtnLoader(false);
        }
        break;
      case 'update':
        setEditBtnLoader(true);
        const updateValidate = handleOnValidate({ name: name, form: form });

        if (updateValidate) {
          const updateID = updateFormId.id;

          let updatePayload = {
            name: updateForm.name,
            slug: updateForm.slug,
            department: PERMISSIONS.grocery,
            units: []
          };

          if (updateUnit.length > 0) {
            updatePayload = {
              ...updatePayload,
              units: updateUnit
            };
          }

          try {
            axios
              .put(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${updateID}`, { ...updatePayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setUpdateFormId({ id: '' });
                  setUpdateUnit([]);
                  setToggleUpdateUnit(false);
                  form.onData({ name: '', slug: '' });
                  form.onToggle(false);

                  notification.success({
                    message: res.data.message,
                    placement: 'bottomLeft'
                  });
                  setTimeout(() => {
                    setEditBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setEditBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setEditBtnLoader(false);
        }
        break;
      case 'update-create-unit':
        setCreateUnitBtnLoader(true);
        const updateCreateUnitValidate = handleOnValidate({ name: name, form: form });

        if (updateCreateUnitValidate) {
          const createUpdateUnitPayload = {
            value: updateForm.unit,
            department: PERMISSIONS.grocery
          };

          try {
            axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.units}`, { ...createUpdateUnitPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 201) {
                  const updateNewUnitData = {
                    _id: res.data.message._id,
                    value: res.data.message.value
                  };

                  setUpdateUnit([...updateUnit, updateNewUnitData]);
                  setUpdateForm({ ...updateForm, unit: '' });
                  setToggleUpdateUnit(false);
                  setTimeout(() => {
                    setCreateUnitBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                console.error(err);
                setCreateUnitBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {}
        } else {
          setCreateUnitBtnLoader(false);
        }
        break;
      case 'delete':
        setDeleteBtnLoader(true);
        const deleteValidate = handleOnValidate({ name: name, form: form });

        if (deleteValidate) {
          const deleteID = deleteFormId.id;

          try {
            await axios
              .delete(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${deleteID}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
              .then((res) => {
                if (res.status === 200) {
                  setReadDataRefetch((prevState) => !prevState);
                  setDeleteFormId({ id: '' });
                  form.onToggle(false);
                  form.formRef.current.reset();

                  notification.success({
                    message: res.data.message,
                    placement: 'bottomLeft'
                  });
                  // setDeleteBtnLoader(false);
                  setTimeout(() => {
                    setDeleteBtnLoader(false);
                  }, 400);
                }
              })
              .catch((err) => {
                setDeleteBtnLoader(false);
              })
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        } else {
          setDeleteBtnLoader(false);
        }
        break;
      default:
        console.warn('Default of: handleOnSubmit function!');
    }
  }

  const getUpdateAttribute = useCallback(
    (props) => {
      const { id } = props;

      const { token } = user;

      try {
        const response = axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.attributes}/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setTimeout(() => {
              setUpdateLoadingForm(false);
            }, 700);
            return res;
          })
          .catch((err) => {});

        return response;
      } catch (err) {}
    },
    [user]
  );

  useEffect(() => {
    const updateID = updateFormId.id;

    let isMounted = true;

    if (!!updateID) {
      new Promise((resolve, reject) => {
        setUpdateLoadingForm(true);
        setTimeout(() => {
          resolve(getUpdateAttribute({ id: updateID }));
        }, 200);
      }).then((res) => {
        if (isMounted) {
          const responseUpdateData = {
            name: res.data.name,
            slug: res.data.name + '-slug'
          };

          const responseUnitData = res.data.units;

          setUpdateForm((prevState) => {
            return { ...prevState, ...responseUpdateData };
          });
          setUpdateUnit(responseUnitData);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [updateFormId, setUpdateForm, setUpdateUnit, getUpdateAttribute]);

  // CREATE ATTRIBUTE PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
    data: createForm,
    onData: setCreateForm
  };

  // CREATE ATTRIBUTE UNIT PROPS
  const createAttributeUnitProps = {
    active: toggleCreateUnitAttribute,
    onToggle: setToggleCreateUnitAttribute,
    formRef: createAttributeUnitFormRef,
    data: createUnitAttributeForm,
    onData: setCreateUnitAttributeForm
  };

  // CREATE UNIT PROPS
  const createUnitProps = {
    active: toggleCreateUnit,
    onToggle: setToggleCreateUnit,
    formRef: createUnitFormRef,
    data: createUnitForm,
    onData: setCreateUnitForm
  };

  // UPDATE ATTRIBUTES PROPS
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    data: updateForm,
    onData: setUpdateForm,
    loading: updateLoadingForm
  };

  // UPDATE CREATE UNIT
  const updateCreateUnitProps = {
    active: toggleUpdateUnit,
    onToggle: setToggleUpdateUnit,
    formRef: updateCreateUnitFormRef,
    data: updateForm,
    onData: setUpdateForm
  };

  // DELETE PROPS
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData?.data?.pagesCount,
    totalCount: readData?.data?.totalCount
  };

  // TABLE PROPS
  const tableProps = {};

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,

    createUnitProps: createUnitProps,
    updateProps: updateProps,
    deleteProps: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions
  };

  return (
    <>
      <h2 className="page-title">Attributes</h2>
      <Section className="section__wrapper section__attributes">
        {/* Header */}
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
            <>
              <div className="actions">
                {!readDataLoading ? (
                  <button type="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-attribute-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Create Attribute</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '191px', height: '50px' }} />
                )}
              </div>
            </>
          )}
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData.data?.Data} {...tableProps} {...tableActionsProps} pagination={paginationProps} />}</Main>

        {/* Create Attributes and Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
          <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Attribute">
            {/* Create Attribute */}
            <form name="create" ref={createFormRef} className="ant-form ant-form-horizontal mb-2" onSubmit={(event) => handleOnSubmit({ event: event, form: createProps })}>
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createName">
                  <b className="required">Name:</b>
                </label>

                <input type="text" placeholder="Name" name="createName" id="createName" data-cy="create-attribute-name-input" value={createForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createFormError.name ? 'input-error' : ''}`} />

                <p data-cy="modal-create-attribute-name-error" className={`error__onblur ${createFormError.name ? 'error' : ''}`}>
                  * This field is required!
                </p>
              </div>
              {/* 
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createSlug">
                  <b className="required">Slug:</b>
                </label>

                <input type="text" placeholder="Slug" name="createSlug" id="createSlug" data-cy="create-attribute-slug-input" value={createForm.slug || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createFormError.slug ? 'input-error' : ''}`} />

                <p data-cy="modal-create-attribute-slug-error" className={`error__onblur ${createFormError.slug ? 'error' : ''}`}>
                  * This field is required!
                </p>
              </div> */}

              <div>
                <label className="label mb-1 d-block">
                  <b>Units:</b>
                </label>
              </div>

              <div className="form-group form-group-units relative">
                <div className="units" data-cy="removed-create-attribute-unit-btn">
                  {createUnitAttribute && createUnitAttribute.length > 0 ? (
                    createUnitAttribute.map((item) => {
                      return (
                        <div className="unit-item" key={item._id} data-cy="create-unit-item-btn" onClick={() => handleOnRemoveUnit({ name: 'create-attribute-unit', data: item })}>
                          <span className="icon">
                            <img src="/assets/icons/times-small.svg" alt="Close" />
                          </span>
                          <span className="text">{item.value}</span>
                        </div>
                      );
                    })
                  ) : (
                    <p className="units-area">Units</p>
                  )}
                </div>

                {toggleCreateUnitAttribute ? (
                  <button type="button" className="btn btn-default" data-cy="toggle-create-minus-attribute-unit-btn" onClick={() => setToggleCreateUnitAttribute(false)}>
                    <span className="icon">
                      <img alt="Create new Unit" src="/assets/icons/minus.svg" />
                    </span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-default" data-cy="toggle-create-plus-attribute-unit-btn" onClick={() => setToggleCreateUnitAttribute(true)}>
                    <span className="icon">
                      <img alt="Create new Unit" src="/assets/icons/plus.svg" />
                    </span>
                  </button>
                )}
              </div>

              {!toggleCreateUnitAttribute && (
                <div className="form-group form-group-sidebar">
                  <button data-cy="modal-create-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
                    <span className="text">Cancel</span>
                  </button>

                  {!createBtnLoader ? (
                    <button data-cy="modal-create-submit-btn" type="submit" className="btn btn-primary">
                      <span className="text">Create Attribute</span>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary-outline">
                      <span className="text">Creating...</span>
                    </button>
                  )}
                </div>
              )}
            </form>

            {/* Create Unit in Attribute */}
            {toggleCreateUnitAttribute && (
              <form name="create-attribute-unit" ref={createAttributeUnitFormRef} className="ant-form ant-form-horizontal mb-2 p-2" onSubmit={(event) => handleOnSubmit({ event: event, form: createAttributeUnitProps })} style={{ backgroundColor: '#f2f2f2', borderRadius: '6px' }}>
                <div className="form-group relative">
                  <label className="label mb-1 d-block" htmlFor="createAttributeUnitName">
                    <b className="required">Unit:</b>
                  </label>

                  <input type="text" placeholder="Unit" name="createAttributeUnitName" id="createAttributeUnitName" data-cy="create-attribute-unit-name-input" value={createUnitAttributeForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createUnitAttributeFormError.name ? 'input-error' : ''}`} />

                  <p data-cy="modal-create-attribute-unit-name-error" className={`error__onblur ${createUnitAttributeFormError.name ? 'error' : ''}`}>
                    * This field is required!
                  </p>
                </div>

                <div className="form-group form-group-sidebar">
                  <button data-cy="modal-create-attribute-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreateUnitAttribute(false)}>
                    <span className="text">Cancel</span>
                  </button>

                  {!createAttUnitBtnLoader ? (
                    <button data-cy="modal-create-attribute-unit-submit-btn" type="submit" className="btn btn-primary">
                      <span className="text">Create Unit</span>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary-outline">
                      <span className="text">Creating Unit...</span>
                    </button>
                  )}
                </div>
              </form>
            )}
          </SidebarModal>
        )}

        {/* Create Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
          <SidebarModal {...createUnitProps} className="sidebar__modal--right" title="Create Unit">
            <form name="create-unit" ref={createUnitFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, form: createUnitProps })}>
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createUnitName">
                  <b className="required">Name:</b>
                </label>

                <input type="text" placeholder="Name" name="createUnitName" id="createUnitName" data-cy="create-unit-name-input" value={createUnitForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createUnitFormError.name ? 'input-error' : ''}`} />

                <p data-cy="modal-create-unit-name-error" className={`error__onblur ${createUnitFormError.name ? 'error' : ''}`}>
                  * This field is required!
                </p>
              </div>

              <div className="form-group form-group-sidebar">
                <button data-cy="modal-create-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreateUnit(false)}>
                  <span className="text">Cancel</span>
                </button>

                {!createUnitBtnLoader ? (
                  <button data-cy="modal-create-unit-submit-btn" type="submit" className="btn btn-primary">
                    <span className="text">Create Unit</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Creating Unit...</span>
                  </button>
                )}
              </div>
            </form>
          </SidebarModal>
        )}

        {/* Update Attributes and Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateAttributes) && (
          <SidebarModal {...updateProps} className="sidebar__modal--right" title="Update Attribute">
            {!updateLoadingForm ? (
              <>
                <form name="update" ref={updateFormRef} className="ant-form ant-form-horizontal mb-2" onSubmit={(event) => handleOnSubmit({ event: event, form: updateProps })}>
                  <div className="form-group relative">
                    <label className="label mb-1 d-block" htmlFor="updateName">
                      <b className="required">Name:</b>
                    </label>

                    <input type="text" placeholder="Name" name="updateName" id="updateName" data-cy="update-attribute-name-input" value={updateForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.name ? 'input-error' : ''}`} />

                    <p data-cy="modal-update-attribute-name-error" className={`error__onblur ${updateFormError.name ? 'error' : ''}`}>
                      * This field is required!
                    </p>
                  </div>

                  {/* <div className="form-group relative">
                    <label className="label mb-1 d-block" htmlFor="updateSlug">
                      <b className="required">Slug:</b>
                    </label>

                    <input type="text" placeholder="Slug" name="updateSlug" id="updateSlug" data-cy="update-attribute-slug-input" value={updateForm.slug || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.slug ? 'input-error' : ''}`} />

                    <p data-cy="modal-update-attribute-slug-error" className={`error__onblur ${updateFormError.slug ? 'error' : ''}`}>
                      * This field is required!
                    </p>
                  </div> */}

                  <div>
                    <label className="label mb-1 d-block">
                      <b>Units:</b>
                    </label>
                  </div>

                  <div className="form-group form-group-units relative">
                    <div className="units" data-cy="removed-update-attribute-unit-btn">
                      {updateUnit && updateUnit.length > 0 ? (
                        updateUnit.map((item) => {
                          return (
                            <div className="unit-item" key={item._id} data-cy="update-unit-item-btn" onClick={() => handleOnRemoveUnit({ name: 'update-attribute-unit', data: item })}>
                              <span className="icon">
                                <img src="/assets/icons/times-small.svg" alt="Close" />
                              </span>
                              <span className="text">{item.value}</span>
                            </div>
                          );
                        })
                      ) : (
                        <p className="units-area">Units</p>
                      )}
                    </div>

                    {toggleUpdateUnit ? (
                      <button type="button" className="btn btn-default" data-cy="toggle-update-minus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(false)}>
                        <span className="icon">
                          <img alt="Create new Unit" src="/assets/icons/minus.svg" />
                        </span>
                      </button>
                    ) : (
                      <button type="button" className="btn btn-default" data-cy="toggle-update-plus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(true)}>
                        <span className="icon">
                          <img alt="Create new Unit" src="/assets/icons/plus.svg" />
                        </span>
                      </button>
                    )}
                  </div>

                  {!toggleUpdateUnit && (
                    <div className="form-group form-group-sidebar">
                      <button data-cy="modal-update-attribute-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
                        <span className="text">Cancel</span>
                      </button>

                      {!editBtnLoader ? (
                        <button data-cy="modal-update-attribute-unit-submit-btn" type="submit" className="btn btn-primary">
                          <span className="text">Save Changes</span>
                        </button>
                      ) : (
                        <button type="button" className="btn btn-primary-outline">
                          <span className="text">Saving...</span>
                        </button>
                      )}
                    </div>
                  )}
                </form>

                {/* Create Unit in Attribute in Update */}
                {toggleUpdateUnit && (
                  <form name="update-create-unit" ref={updateCreateUnitFormRef} className="ant-form ant-form-horizontal mb-2 p-2" onSubmit={(event) => handleOnSubmit({ event: event, form: updateCreateUnitProps })} style={{ backgroundColor: '#f2f2f2', borderRadius: '6px' }}>
                    <div className="form-group relative">
                      <label className="label mb-1 d-block" htmlFor="updateCreateUnitName">
                        <b className="required">Name:</b>
                      </label>

                      <input type="text" placeholder="Name" name="updateCreateUnitName" id="updateCreateUnitName" value={updateForm.unit || ''} data-cy="update-create-attribute-unit-name-input" onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.unit ? 'input-error' : ''}`} />

                      <p data-cy="modal-update-create-unit-name-error" className={`error__onblur ${updateFormError.unit ? 'error' : ''}`}>
                        * This field is required!
                      </p>
                    </div>

                    <div className="form-group form-group-sidebar">
                      <button data-cy="modal-update-create-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdateUnit(false)}>
                        <span className="text">Cancel</span>
                      </button>

                      {!createUnitBtnLoader ? (
                        <button data-cy="modal-update-create-unit-submit-btn" type="submit" className="btn btn-primary">
                          <span className="text">Create Unit</span>
                        </button>
                      ) : (
                        <button type="button" className="btn btn-primary-outline">
                          <span className="text">Creating Unit...</span>
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </>
            ) : (
              <Loading />
            )}
          </SidebarModal>
        )}

        {/* Delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteAttributes) && (
          <Modal {...deleteProps} className="sidebar__modal--center" title="">
            <form name="delete" ref={deleteFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, form: deleteProps })}>
              <h2 className="text-center mb-4">Are you sure you want to delete the selected item ?</h2>

              <div className="form-group form-group-modal mb-4">
                {!deleteBtnLoader ? (
                  <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
                    <span className="text">Yes</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Deleting...</span>
                  </button>
                )}

                <button data-cy="modal-delete-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
                  <span className="text">No</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
      </Section>
    </>
  );
};

export default Attributes;
