import React, { Fragment, useCallback, useEffect, useState } from "react";
import SimpleProduct from "./cartProducts/CartSimpleProduct";
import VariableProduct from "./cartProducts/CartVariableProduct";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { PERMISSIONS } from "../../utils/permissions";
import { useNavigate } from "react-router-dom";

const getNoDataFound = (products) => {
  let isEmptyArr = [];

  for (const product of products) {
    if (product.productType !== "simple_product") {
      for (const variation of product.variations) {
        isEmptyArr.push(variation.isSelected);
      }
    } else {
      isEmptyArr.push(product.isSelected);
    }
  }

  let isEmpty = isEmptyArr.every((empty) => !empty);

  return isEmpty;
};

const RestCart = ({
  readAddress,
  onClick,
  products,
  isSplitShipping,
  cart,
  isAddressConfirmed,
  setShowPayment,
  allowPaymentStep,
}) => {
  const navigate = useNavigate();

  function NextStepButton() {
    const departmentBackStep = window.location.href.includes('grocery') ? PERMISSIONS.grocery : PERMISSIONS.pharmacy
    
    if (typeof window !== "undefined") {
      if (window.location.pathname.endsWith("shipping-address")) {
        return (
          <div
            style={{
              display: "grid",
              columnGap: "20px",
              gridTemplateColumns: "repeat(2,1fr)",
            }}
          >
            <button
              onClick={() =>
                navigate(
                  `/${PERMISSIONS.dashboard}/${departmentBackStep}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`
                )
              }
              className="btn btn-primary-outline"
            >
              Back
            </button>

            <button
              className={"btn btn-primary"}
              onClick={() => setShowPayment(true)}
              disabled={!allowPaymentStep}
            >
              Next Step
            </button>
          </div>
        );
      }
    }
  }
  const hasNoDataFound = getNoDataFound(products);

  const restProductProps = {
    onClick: onClick,
    isAddressConfirmed,
    isSplitShipping,
    readAddress,
  };

  return (
    <div className="cart__order">
      <header className="cart__header">
        <span>Your cart</span>
      </header>

      <div className="cartItems">
        {hasNoDataFound ? (
          <div className="emptyCart">
            <div>
              <ShoppingCartOutlined
                style={{
                  fontSize: "25px",
                  padding: "8px 9px 8px 8px",
                  borderRadius: "8px",
                  color: window.location.href.includes('grocery') ? "#4762E6" : 'hsl(165, 150%, 35%)',
                }}
              />
              <span style={{color: window.location.href.includes('grocery') ? "#4762E6" : 'hsl(165, 150%, 35%)'}}>Cart is Empty</span>
            </div>
          </div>
        ) : (
          products.map((product) => {
            const { children, variations, productType } = product;
            return (
              <Fragment key={product._id}>
                {productType === "simple_product" ? (
                  <SimpleProduct
                    from="rest"
                    product={product}
                    children={children}
                    variations={[]}
                    {...restProductProps}
                  />
                ) : (
                  <VariableProduct
                    from="rest"
                    product={product}
                    children={children}
                    variations={variations}
                    {...restProductProps}
                  />
                )}
              </Fragment>
            );
          })
        )}
      </div>

      <div className="step__button">
        <NextStepButton />
      </div>
    </div>
  );
};

export default RestCart;
