import SelectYear from "./SelectYear";
import SelectDay from "./SelectDay";
import SelectOverride from "./SelectOverride";

const Delivery = {
    Year: SelectYear,
    Day: SelectDay,
    Override: SelectOverride
}

export { Delivery };