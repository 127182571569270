import React, { Fragment } from "react";
import {
  isWic,
  isCheckImage,
  isDisabled,
  sumFormatPrice,
  sumCurrentFormatPrice,
} from "../../../helpers/helpers";
import { stateUS } from "../../../helpers/state";
import CartProductsSelect from "../../customSelect/CartProductsSelect";

const CartVariableProduct = (props) => {
  if (props.from === "wic") {
    return <WicProduct {...props} />;
  }

  return <RestProduct {...props} />;
};

function WicProduct(props) {
  const {
    variations,
    product,
    selected,
    onClick,
    isAddressConfirmed,
    isSplitShipping,
  } = props;

  function isCheckPriceSum(price) {
    return <span className="price">{sumFormatPrice(price)}</span>;
  }

  function attributesUnits(data) {
    if (data.length < 1) {
      return;
    }

    return data.map((attr) => {
      return (
        <div className="attribute" key={attr._id}>
          <h6 className="attribute-name">{attr.name}: </h6>
          <div className="attribute-unit">{attr.units.value}</div>
        </div>
      );
    });
  }

  return (
    <div className="product-item">
      {variations.map((variation, idx) => {
        const { variationId } = variation;

        return (
          <div key={variationId} className="product product-variable">
            <div className="item">
              <div className="item-wrapper">
                <div className="product-info">
                  {isAddressConfirmed ? (
                    <Fragment>
                      <label
                        htmlFor="checkboxProductWicVariableDisable"
                        style={{ display: "none" }}
                      >
                        <span>Checkbox</span>
                      </label>
                      <input
                        disabled={true}
                        id="checkboxProductWicVariableDisable"
                        name="checkbox"
                        className="checkbox"
                        checked={selected.includes(variationId)}
                        value={variationId}
                        type="checkbox"
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <label
                        htmlFor={variationId + "_wic_variable"}
                        style={{ display: "none" }}
                      >
                        <span>Checkbox</span>
                      </label>
                      <input
                        disabled={false}
                        name="selectedVariable"
                        id={variationId + "_wic_variable"}
                        className="checkbox"
                        checked={selected.includes(variationId)}
                        value={variationId}
                        type="checkbox"
                        onChange={(e) =>
                          onClick({
                            state: product,
                            action: {
                              type: "selectedVariable",
                              event: e,
                              payload: { variationId: variation.variationId },
                            },
                          })
                        }
                      />
                    </Fragment>
                  )}

                  <div className="product-wrapper">
                    <div className="product-image">
                      <div className="image">
                        {isCheckImage({
                          action: "variable",
                          payload: { data: variation },
                        })}
                      </div>
                    </div>

                    <div className="product-group">
                      <div className="product-details">
                        <div className="details">
                          <h4>
                            {product.title} {isWic(product.eligible)}
                          </h4>

                          <div className="product-attributes">
                            {attributesUnits(variation.units)}
                          </div>
                        </div>

                        <span className="price-wrapper">
                          <span className="text-right">
                            <b className="d-block">Subtotal:</b>
                            {isCheckPriceSum(variation)}
                          </span>
                        </span>
                      </div>
                      <div className="product-actions">
                        <div className="controls">
                          {isAddressConfirmed ? (
                            <Fragment>
                              <CartProductsSelect
                                disabled={true}
                                product={variation}
                                parentProduct={product}
                                productVariation={variation}
                                name="quantityWicVariable"
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <CartProductsSelect
                                disabled={isDisabled(
                                  selected.includes(variation.variationId)
                                )}
                                product={variation}
                                parentProduct={product}
                                productVariation={variation}
                                onClick={onClick}
                                name="quantityWicVariable"
                              />
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function RestProduct(props) {
  const {
    product,
    onClick,
    variations,
    readAddress,
    isSplitShipping,
    isAddressConfirmed,
  } = props;

  if (variations && variations.length < 1) {
    return null;
  }

  const filteredVariations = variations.filter(
    (variation) => variation.isSelected
  );

  if (filteredVariations && filteredVariations.length < 1) {
    return null;
  }

  const getAddressStateName = (state) => {
    const stateResult = stateUS.find((item) => item.abbreviation === state);
    return stateResult.name;
  };

  const checkDefaultAddress = (isDefault) => {
    if (isDefault) {
      return "(default)";
    } else {
      return "";
    }
  };

  function isCheckPriceSum(price) {
    return <span className="price">{sumCurrentFormatPrice(price)}</span>;
  }

  function attributesUnits(data) {
    if (data.length < 1) {
      return;
    }

    return data.map((attr) => {
      return (
        <div className="attribute" key={attr._id}>
          <h6 className="attribute-name">{attr.name}: </h6>
          <div className="attribute-unit">{attr.units.value}</div>
        </div>
      );
    });
  }

  return (
    <div className="product-item">
      <div className="product product-variable--rest">
        <div className="item item-rest">
          {filteredVariations.map((variation) => {
            const { children, variationId } = variation;

            const isChildrenLength = children && children.length > 0;
            const defaultAddress = variation?.defaultAddress?._id;

            return (
              <Fragment key={variationId}>
                <div className="parent">
                  <div className="item-wrapper">
                    <div className="product-info">
                      <div className="product-wrapper">
                        <div className="product-image">
                          <div className="image">
                            {isCheckImage({
                              action: "variable",
                              payload: { data: variation },
                            })}
                          </div>
                        </div>

                        <div className="product-group">
                          <div className="product-details">
                            <div className="details">
                              <h4>
                                {product.title} {isWic(product.eligible)}
                              </h4>

                              <div className="product-attributes">
                                {attributesUnits(variation.units)}
                              </div>
                            </div>

                            <span className="price-wrapper">
                              <span className="text-right">
                                <b className="d-block">Subtotal:</b>
                                {isCheckPriceSum(variation)}
                              </span>
                            </span>
                          </div>
                          <div className="product-actions">
                            <div className="controls">
                              {isSplitShipping && (
                                <Fragment>
                                  {isAddressConfirmed ? (
                                    <button
                                      disabled={true}
                                      className="btn-link-info"
                                      title="Split Product"
                                    >
                                      <span className="text">Split Items</span>
                                    </button>
                                  ) : (
                                    <button
                                      disabled={false}
                                      className="btn-link-info"
                                      onClick={(e) =>
                                        onClick({
                                          state: variation,
                                          action: {
                                            type: "createRestVariableChildren",
                                            event: e,
                                            payload: {
                                              _id: product._id,
                                              variationId: variationId,
                                              product: product,
                                            },
                                          },
                                        })
                                      }
                                      title="Split Product"
                                    >
                                      <span className="text">Split Items</span>
                                    </button>
                                  )}
                                </Fragment>
                              )}

                              <CartProductsSelect
                                product={variation}
                                parentProduct={product}
                                disabled={isAddressConfirmed}
                                onClick={onClick}
                                name="quantityRestVariable"
                              />

                              {isSplitShipping && (
                                <div className="shipping-actions">
                                  <div className="group">
                                    {isAddressConfirmed ? (
                                      <select
                                        disabled={true}
                                        name="defaultAddressDisabled"
                                        value={defaultAddress || ""}
                                        className="select select-address"
                                      >
                                        {readAddress.map((item) => {
                                          const {
                                            address,
                                            city,
                                            state,
                                            isDefault,
                                            _id,
                                            zip_code,
                                          } = item;
                                          return (
                                            <option value={_id} key={_id}>
                                              {address}, {city}, {zip_code},{" "}
                                              {getAddressStateName(state)}{" "}
                                              {checkDefaultAddress(isDefault)}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : (
                                      <select
                                        disabled={false}
                                        name="variableRestAddressChanged"
                                        value={defaultAddress || ""}
                                        onChange={(e) =>
                                          onClick({
                                            state: variation,
                                            action: {
                                              type:
                                                "variableRestAddressChanged",
                                              event: e,
                                            },
                                          })
                                        }
                                        className="select select-address"
                                      >
                                        {readAddress.map((item) => {
                                          const {
                                            address,
                                            city,
                                            state,
                                            isDefault,
                                            _id,
                                            zip_code,
                                          } = item;
                                          return (
                                            <option value={_id} key={_id}>
                                              {address}, {city}, {zip_code},{" "}
                                              {getAddressStateName(state)}{" "}
                                              {checkDefaultAddress(isDefault)}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    )}
                                  </div>
                                </div>
                              )}
                              {isAddressConfirmed ? (
                                <button
                                  disabled={true}
                                  title="Remove item"
                                  className="btn-remove"
                                  type="button"
                                >
                                  <span className="icon">
                                    <img
                                      src="/assets/icons/trash-icon-dark.svg"
                                      alt="Trash Cart"
                                      width={22}
                                      height={22}
                                    />
                                  </span>
                                </button>
                              ) : (
                                <button
                                  disabled={false}
                                  title="Remove item"
                                  onClick={(e) =>
                                    onClick({
                                      state: product,
                                      action: {
                                        type: "removeRestVariable",
                                        event: e,
                                        payload: {
                                          _id: product._id,
                                          variationId: variationId,
                                        },
                                      },
                                    })
                                  }
                                  className="btn-remove"
                                  type="button"
                                >
                                  <span className="icon">
                                    <img
                                      src="/assets/icons/trash-icon-dark.svg"
                                      alt="Trash Cart"
                                      width={22}
                                      height={22}
                                    />
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isChildrenLength && (
                    <div className="children">
                      {children.map((child) => {
                        const { splitId } = child;
                        const defaultAddressChildren = child.defaultAddress._id;

                        const childProduct = {
                          ...variation,
                          currentQty: child.currentQty,
                        };

                        return (
                          <div className="child" key={splitId}>
                            <div className="item-wrapper">
                              <div className="product-info">
                                <div className="product-wrapper">
                                  <div className="product-image">
                                    <div className="image">
                                      {isCheckImage({
                                        action: "variable",
                                        payload: { data: variation },
                                      })}
                                    </div>
                                  </div>
                                  <div className="product-group">
                                    <div className="product-details">
                                      <div className="details">
                                        <h4>
                                          {product.title}{" "}
                                          {isWic(product.eligible)}
                                        </h4>

                                        <div className="product-attributes">
                                          {attributesUnits(variation.units)}
                                        </div>
                                      </div>

                                      <span className="price-wrapper">
                                        <span className="text-right">
                                          <b className="d-block">Subtotal:</b>
                                          {isCheckPriceSum(childProduct)}
                                        </span>
                                      </span>
                                    </div>

                                    <div className="product-actions">
                                      <div className="controls">
                                        <CartProductsSelect
                                          product={child}
                                          parentProduct={product}
                                          parentVariation={variation}
                                          disabled={isAddressConfirmed}
                                          onClick={onClick}
                                          name="quantityRestVariationChildren"
                                        />

                                        {isSplitShipping && (
                                          <div className="shipping-actions">
                                            <div className="group">
                                              {isAddressConfirmed ? (
                                                <select
                                                  disabled={true}
                                                  name="variableRestChildrenAddressChangedDisabled"
                                                  value={
                                                    defaultAddressChildren || ""
                                                  }
                                                  className="select select-address"
                                                >
                                                  {readAddress.map((item) => {
                                                    const {
                                                      address,
                                                      city,
                                                      state,
                                                      isDefault,
                                                      _id,
                                                      zip_code,
                                                    } = item;
                                                    return (
                                                      <option
                                                        value={_id}
                                                        key={_id}
                                                      >
                                                        {address}, {city},{" "}
                                                        {zip_code},{" "}
                                                        {getAddressStateName(
                                                          state
                                                        )}{" "}
                                                        {checkDefaultAddress(
                                                          isDefault
                                                        )}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              ) : (
                                                <select
                                                  disabled={false}
                                                  name="variableRestChildrenAddressChanged"
                                                  value={
                                                    defaultAddressChildren || ""
                                                  }
                                                  onChange={(e) =>
                                                    onClick({
                                                      state: child,
                                                      action: {
                                                        type:
                                                          "variableRestChildrenAddressChanged",
                                                        event: e,
                                                      },
                                                    })
                                                  }
                                                  className="select select-address"
                                                >
                                                  {readAddress.map((item) => {
                                                    const {
                                                      address,
                                                      city,
                                                      state,
                                                      isDefault,
                                                      _id,
                                                      zip_code,
                                                    } = item;
                                                    return (
                                                      <option
                                                        value={_id}
                                                        key={_id}
                                                      >
                                                        {address}, {city},{" "}
                                                        {zip_code},{" "}
                                                        {getAddressStateName(
                                                          state
                                                        )}{" "}
                                                        {checkDefaultAddress(
                                                          isDefault
                                                        )}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              )}
                                            </div>
                                          </div>
                                        )}

                                        {isAddressConfirmed ? (
                                          <button
                                            disabled={true}
                                            title="Remove item"
                                            className="btn-remove"
                                            type="button"
                                          >
                                            <span className="icon">
                                              <img
                                                src="/assets/icons/trash-icon-dark.svg"
                                                alt="Trash Cart"
                                                width={22}
                                                height={22}
                                              />
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            disabled={false}
                                            title="Remove item"
                                            onClick={(e) =>
                                              onClick({
                                                state: variation,
                                                action: {
                                                  type:
                                                    "removeRestVariableChildren",
                                                  event: e,
                                                  payload: {
                                                    product: product,
                                                    _id: child.splitId,
                                                    variationId:
                                                      variation.variationId,
                                                    variation: variation,
                                                  },
                                                },
                                              })
                                            }
                                            className="btn-remove"
                                            type="button"
                                          >
                                            <span className="icon">
                                              <img
                                                src="/assets/icons/trash-icon-dark.svg"
                                                alt="Trash Cart"
                                                width={22}
                                                height={22}
                                              />
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CartVariableProduct;
