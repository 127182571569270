import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { Button, Form, Row, Col, notification } from 'antd';
import { useAuth } from '../../../context/useAuth';
import Modal from '../../../components/modal/Modal';
import SidebarModal from '../../../components/sidebarModal/SidebarModal';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Section, Main } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import jwt_decode from 'jwt-decode';
import { getPharmacyPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';

const options = [5, 10, 20, 50, 100];

const Roles = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [pharmacyPermissions, setPharmacyPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.pharmacy))) {
          setPharmacyPermissions(permissions.pharmacy);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const [loadingGrocery, setLoadingGrocery] = useState(false);
  const [loadingPharmacy, setLoadingPharmacy] = useState(false);

  const getTableData = useCallback(async () => {
    const { token } = user;

    try {
      setLoading(true);

      await axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getRoles}/${currentPage}/${limitPage}/${search || null}`, { department: PERMISSIONS.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setList(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [refetch, getTableData]);

  // RESET CURRENT PAGE TO FIRST ON CHANGE FILTERS
  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage, setCurrentPage]);

  const [toggleSwitchSite, setToggleSwitchSite] = useState('grocery');
  const [toggleSwitchUpdate, setToggleSwitchUpdate] = useState('grocery');

  const handleToggleSwitch = (name) => {
    switch (name) {
      case 'grocery':
        setToggleSwitchSite('grocery');
        break;
      case 'pharmacy':
        setToggleSwitchSite('pharmacy');
        break;
      case 'grocery_update':
        setToggleSwitchUpdate('grocery');
        break;
      case 'pharmacy_update':
        setToggleSwitchUpdate('pharmacy');
        break;
      default:
        console.error('Error toggle switch');
    }
  };

  // GROCERY CHECKBOX FOR FORM
  const [grocery, setGrocery] = useState([]);

  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getPermissions}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          let data = res.data.message.sort((a, b) => (a._id === b._id ? 0 : a._id < b._id ? -1 : 1));
          setGrocery(data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // PHARMACY CHECKBOX FOR FORM
  const [pharmacy, setPharmacy] = useState([]);

  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getPermissions}`, { department: PERMISSIONS.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          let data = res.data.message.sort((a, b) => (a._id === b._id ? 0 : a._id < b._id ? -1 : 1));
          setPharmacy(data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  // TABLE
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows
  };

  // FORM
  // Refs
  const createFormRef = useRef(null);
  const updateFormRef = useRef(null);
  const deleteSingleFormRef = useRef(null);
  const deleteMultiFormRef = useRef(null);

  // State
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleSingleDelete, setToggleSingleDelete] = useState(false);
  const [toggleMultiDelete, setToggleMultiDelete] = useState(false);

  // CREATE
  const [createRole, setCreateRole] = useState({
    name: '',
    roles: []
  });

  // UPDATE
  const [updateRoleId, setUpdateRoleId] = useState({ id: '' });
  const [updateRole, setUpdateRole] = useState({
    roleName: '',
    permissions: []
  });

  const [updateRoleGroceryData, setUpdateRoleGroceryData] = useState([]);
  const [updateRolePharmacyData, setUpdateRolePharmacyData] = useState([]);

  // DELETE
  const [deleteSingleRole, setDeleteSingleRole] = useState({ id: '' });

  const [isCheckboxGrocery, setIsCheckboxGrocery] = useState([]);
  const [rowCheckedGrocery, setRowCheckedGrocery] = useState([]);

  const [isCheckboxPharmacy, setIsCheckboxPharmacy] = useState([]);
  const [rowCheckedPharmacy, setRowCheckedPharmacy] = useState([]);

  const [isCheckboxUpdateGrocery, setIsCheckboxUpdateGrocery] = useState([]);
  const [isCheckboxUpdatePharmacy, setIsCheckboxUpdatePharmacy] = useState([]);

  const handleFormValidation = (form, status = null) => {
    let findinputElements = form.current.querySelectorAll('input[type="text"]');

    if (!findinputElements) {
      return;
    }

    for (const [key] in findinputElements) {
      let checkFindInput = findinputElements[key];

      if (checkFindInput !== undefined) {
        if (findinputElements[key]) {
          let name = findinputElements[key].name;

          if (status === 'length_error') {
            showErrorMessages(findinputElements[key], name);
          }

          if (status === 'status_error') {
            showErrorMessages(findinputElements[key], name);
          }

          if (status === 'length_success') {
            hideErrorMessages(findinputElements[key], name);
          }
        }
      }
    }
  };

  useEffect(() => {
    const roleId = updateRoleId.id;
    const roleLength = roleId.length;
    const { token } = user;

    if (roleLength > 0) {
      try {
        setLoadingGrocery(true);
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.getRole}/${roleId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setUpdateRoleGroceryData(res.data.message);
          })
          .catch((err) => console.error(err))
          .finally(
            setTimeout(() => {
              setLoadingGrocery(false);
            }, 700)
          );
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, updateRoleId]);

  useEffect(() => {
    const roleId = updateRoleId.id;
    const roleLength = roleId.length;
    const { token } = user;

    if (roleLength > 0) {
      setLoadingPharmacy(true);
      try {
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.getRole}/${roleId}`, { department: PERMISSIONS.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setUpdateRolePharmacyData(res.data.message);
          })
          .catch((err) => console.error(err))
          .finally(
            setTimeout(() => {
              setLoadingPharmacy(false);
            }, 700)
          );
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, updateRoleId]);

  useEffect(() => {
    if (typeof updateRoleGroceryData.permissions !== 'undefined') {
      let getUpdateRoleGroceryData = [];

      for (const grocery in updateRoleGroceryData?.permissions) {
        const data = updateRoleGroceryData?.permissions[grocery];

        for (const d of data) {
          if (d.checked === true) {
            getUpdateRoleGroceryData.push(d._id);
          }
        }
      }

      setIsCheckboxUpdateGrocery(getUpdateRoleGroceryData);
    }
  }, [updateRoleGroceryData]);

  useEffect(() => {
    if (typeof updateRolePharmacyData.permissions !== 'undefined') {
      let getUpdateRolePharmacyData = [];

      for (const pharmacy in updateRolePharmacyData?.permissions) {
        const data = updateRolePharmacyData?.permissions[pharmacy];

        for (const d of data) {
          if (d.checked === true) {
            getUpdateRolePharmacyData.push(d._id);
          }
        }
      }

      setIsCheckboxUpdatePharmacy(getUpdateRolePharmacyData);
    }
  }, [updateRolePharmacyData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token } = user;

    switch (e.target.name) {
      case 'create':
        try {
          const createDate = {
            roleName: createRole.name,
            permissions: createRole.roles,
            department: PERMISSIONS.pharmacy
          };

          if (createRole.name.length < 3) {
            handleFormValidation(createFormRef, 'length_error');
            return;
          } else {
            await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.createRole}`, { ...createDate }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

            handleFormValidation(createFormRef, 'length_success');
            setRefetch((prevState) => !prevState);

            setCreateRole({
              name: '',
              roles: []
            });

            setIsCheckboxGrocery([]);
            setRowCheckedGrocery([]);

            setIsCheckboxPharmacy([]);
            setRowCheckedPharmacy([]);

            setToggleSwitchSite('grocery');

            setCurrentPage(1);
            setToggleCreate(false);

            createFormRef.current.reset();

            notification.success({
              message: notificationMessages.successCreateRole,
              placement: 'bottomLeft'
            });
          }
        } catch (err) {
          if (!err.status) {
            notification.error({
              message: notificationMessages.failedCreateRole,
              placement: 'bottomLeft'
            });
          } else {
            handleFormValidation(createFormRef, 'status_error');
          }
        }
        break;
      case 'update':
        try {
          const roleId = updateRoleId.id;
          const updateData = {
            id: roleId,
            roleName: updateRole.roleName,
            permissions: updateRole.roles,
            department: PERMISSIONS.pharmacy
          };

          if (updateRole.roleName.length < 3) {
            handleFormValidation(updateFormRef, 'length_error');
            return;
          } else {
            await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.updateRole}`, { ...updateData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

            handleFormValidation(updateFormRef, 'length_success');
            setRefetch((prevState) => !prevState);
            setUpdateRole({
              roleName: '',
              permissions: []
            });

            setIsCheckboxUpdateGrocery([]);
            setIsCheckboxUpdatePharmacy([]);

            setToggleSwitchUpdate('grocery');
            setCurrentPage(1);
            setToggleUpdate(false);

            updateFormRef.current.reset();

            notification.success({
              message: notificationMessages.successUpdateRole,
              placement: 'bottomLeft'
            });
          }
        } catch (err) {
          handleFormValidation(updateFormRef, 'status_error');
          console.error(err);
        }
        break;
      case 'delete_single':
        try {
          const roleId = deleteSingleRole.id;
          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.deleteRole}/${roleId}`, { department: AXIOS_API_CALL.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleSingleDelete(false);

          // reset form for delete role
          deleteSingleFormRef.current.reset();

          // notification success for delete role
          notification.success({
            message: notificationMessages.successDeleteRole,
            placement: 'bottomLeft'
          });
        } catch (err) {
          console.error(err);

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleSingleDelete(false);

          // reset form for delete role
          deleteSingleFormRef.current.reset();

          // notification error for delete role
          notification.error({
            message: `Don't delete Super Admin role!`,
            placement: 'bottomLeft'
          });
        }
        break;
      case 'delete_multi':
        try {
          const rolesId = checkedRow;

          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.bulkDeleteRole}`, { roles: rolesId, department: PERMISSIONS.pharmacy }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          // reset form for delete multi role
          deleteMultiFormRef.current.reset();

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleMultiDelete(false);

          // notification success for delete role
          notification.success({
            message: notificationMessages.successDeleteMultiRole,
            placement: 'bottomLeft'
          });
        } catch (err) {
          console.error(err);
        }
        break;
      default:
        console.error('Error toggle switch');
    }
  };

  const handleOnChange = (e) => {
    e.target.name === 'name_role' && setCreateRole({ ...createRole, name: e.target.value });
    e.target.name === 'name_update_role' && setUpdateRole({ ...updateRole, roleName: e.target.value });
  };

  const handleValidation = (e) => {
    if (e.target.name === 'name_role') e.target.value.length < 3 ? showErrorMessages(e.target, e.target.name) : hideErrorMessages(e.target, e.target.name);
    if (e.target.name === 'name_update_role') e.target.value.length < 3 ? showErrorMessages(e.target, e.target.name) : hideErrorMessages(e.target, e.target.name);
  };

  const showErrorMessages = (elem, name) => {
    elem.style.borderColor = '#ff4d4f';
    document.querySelector(`.${name}-error__required`).style.opacity = '1';
    document.querySelector(`.${name}-error__required`).style.visibility = 'visible';
    document.querySelector(`.${name}-error__required`).style.bottom = '-20px';
  };

  const hideErrorMessages = (elem, name) => {
    elem.style.borderColor = '';
    document.querySelector(`.${name}-error__required`).style.opacity = '0';
    document.querySelector(`.${name}-error__required`).style.visibility = 'hidden';
    document.querySelector(`.${name}-error__required`).style.bottom = '-10px';
  };

  // CHECKBOX
  const handleSelectCheckbox = (e) => {
    const { value, name, checked } = e.target;

    if (name.includes('update_grocery_roles_')) {
      setIsCheckboxUpdateGrocery([...isCheckboxUpdateGrocery, value]);
      if (!checked) {
        setIsCheckboxUpdateGrocery(isCheckboxUpdateGrocery.filter((item) => item !== value));
      }
    }

    if (name.includes('update_pharmacy_roles_')) {
      setIsCheckboxUpdatePharmacy([...isCheckboxUpdatePharmacy, value]);
      if (!checked) {
        setIsCheckboxUpdatePharmacy(isCheckboxUpdatePharmacy.filter((item) => item !== value));
      }
    }

    if (name.includes('grocery_checkbox_')) {
      setIsCheckboxGrocery([...isCheckboxGrocery, value]);

      if (!checked) {
        setIsCheckboxGrocery(isCheckboxGrocery.filter((item) => item !== value));
      }
    }

    if (name.includes('pharmacy_checkbox_')) {
      setIsCheckboxPharmacy([...isCheckboxPharmacy, value]);
      if (!checked) {
        setIsCheckboxPharmacy(isCheckboxPharmacy.filter((item) => item !== value));
      }
    }
  };

  const handleSelectCheckboxRows = (e) => (data) => {
    const { value, name } = e.target;

    if (name.includes('grocery_')) {
      const status = data.map((item) => item._id);
      const result = status.some((check) => isCheckboxGrocery.includes(check));

      if (result) {
        let check = isCheckboxGrocery;
        check = check.filter((item) => !status.includes(item));

        let row = rowCheckedGrocery;
        row = row.filter((item) => !value.includes(item));

        setIsCheckboxGrocery(check);
        setRowCheckedGrocery(row);
      } else {
        setIsCheckboxGrocery([...isCheckboxGrocery, ...status]);
        setRowCheckedGrocery([...rowCheckedGrocery, value]);
      }
    }

    if (name.includes('pharmacy_')) {
      const status = data.map((item) => item._id);
      const result = status.some((check) => isCheckboxPharmacy.includes(check));

      if (result) {
        let check = isCheckboxPharmacy;
        check = check.filter((item) => !status.includes(item));

        let row = rowCheckedPharmacy;
        row = row.filter((item) => !value.includes(item));

        setIsCheckboxPharmacy(check);
        setRowCheckedPharmacy(row);
      } else {
        setIsCheckboxPharmacy([...isCheckboxPharmacy, ...status]);
        setRowCheckedPharmacy([...rowCheckedPharmacy, value]);
      }
    }
  };

  const handleToggleSelectAll = (e) => {
    if (e.target.name === 'all_update_grocery') {
      let allRowsGrocery = grocery.map((item) => {
        return `update_grocery_${item._id}`;
      });

      let allValuesGrocery = [];

      for (const allCheckboxes of grocery) {
        let checkbox = allCheckboxes.permissions;

        for (const [index] in checkbox) {
          allValuesGrocery.push(checkbox[index]._id);
        }
      }

      if (e.target.checked) {
        setIsCheckboxGrocery(allValuesGrocery);
        setRowCheckedGrocery(allRowsGrocery);
      } else {
        setIsCheckboxGrocery([]);
        setRowCheckedGrocery([]);
      }
    }

    if (e.target.name === 'all_grocery') {
      let allRowsGrocery = grocery.map((item) => {
        return `grocery_${item._id}`;
      });

      let allValuesGrocery = [];

      for (const allCheckboxes of grocery) {
        let checkbox = allCheckboxes.permissions;

        for (const [index] in checkbox) {
          allValuesGrocery.push(checkbox[index]._id);
        }
      }

      if (e.target.checked) {
        setIsCheckboxGrocery(allValuesGrocery);
        setRowCheckedGrocery(allRowsGrocery);
      } else {
        setIsCheckboxGrocery([]);
        setRowCheckedGrocery([]);
      }
    }

    if (e.target.name === 'all_pharmacy') {
      let allRowsPharmacy = pharmacy.map((item) => {
        return `pharmacy_${item._id}`;
      });

      let allValuesPharmacy = [];

      for (const allCheckboxes of pharmacy) {
        let checkbox = allCheckboxes.permissions;

        for (const [index] in checkbox) {
          allValuesPharmacy.push(checkbox[index]._id);
        }
      }

      if (e.target.checked) {
        setIsCheckboxPharmacy(allValuesPharmacy);
        setRowCheckedPharmacy(allRowsPharmacy);
      } else {
        setIsCheckboxPharmacy([]);
        setRowCheckedPharmacy([]);
      }
    }
  };

  // REMOVE VALUE FROM CHECKBOX
  useEffect(() => {
    if (!!toggleUpdate) {
      setIsCheckboxGrocery([]);
      setIsCheckboxPharmacy([]);
    }
  }, [toggleUpdate]);

  useEffect(() => {
    if (!!toggleCreate) {
      setIsCheckboxGrocery([]);
      setIsCheckboxPharmacy([]);
    }
  }, [toggleCreate]);

  const handleToggle = (props) => {
    const { name, data } = props; // status in options

    switch (name) {
      case 'create':
        setToggleCreate((prevState) => !prevState);
        break;
      case 'update':
        setToggleUpdate((prevState) => !prevState);
        setUpdateRoleId({ id: data });
        break;
      case 'delete':
        setToggleSingleDelete((prevState) => !prevState);
        setDeleteSingleRole({ id: data });
        break;
      case 'deleteMulti':
        setToggleMultiDelete((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // CREATE GROCERY
  const [createRoleGroceryNew, setCreateRoleGroceryNew] = useState([]);
  useEffect(() => {
    if (toggleCreate && isCheckboxGrocery && isCheckboxGrocery.length > 0) {
      setCreateRoleGroceryNew(isCheckboxGrocery);
    }
  }, [toggleCreate, createRoleGroceryNew, setCreateRoleGroceryNew, isCheckboxGrocery]);

  // CREATE PHARMACY
  const [createRolePharmacyNew, setCreateRolePharmacyNew] = useState([]);
  useEffect(() => {
    if (toggleCreate && isCheckboxPharmacy && isCheckboxPharmacy.length > 0) {
      setCreateRolePharmacyNew(isCheckboxPharmacy);
    }
  }, [toggleCreate, createRolePharmacyNew, setCreateRolePharmacyNew, isCheckboxPharmacy]);

  useEffect(() => {
    if (!!toggleCreate) {
      const allChecboxesConcat = createRoleGroceryNew.concat(createRolePharmacyNew);
      if (allChecboxesConcat && allChecboxesConcat.length > 0) {
        setCreateRole((c) => {
          return { ...c, roles: allChecboxesConcat };
        });
      }
    }
  }, [toggleCreate, createRoleGroceryNew, createRolePharmacyNew]);

  // UPDATE GROCERY
  const [updateRoleGorceryNew, setUpdateRoleGroceryNew] = useState([]);
  useEffect(() => {
    if (toggleUpdate) {
      setUpdateRoleGroceryNew(isCheckboxUpdateGrocery);
    }
  }, [toggleUpdate, isCheckboxUpdateGrocery, setUpdateRoleGroceryNew, updateRoleGorceryNew]);

  // UPDATE PHARMACY
  const [updateRolePharmacyNew, setUpdateRolePharmacyNew] = useState([]);
  useEffect(() => {
    if (toggleUpdate) {
      setUpdateRolePharmacyNew(isCheckboxUpdatePharmacy);
    }
  }, [toggleUpdate, isCheckboxUpdatePharmacy, setUpdateRolePharmacyNew, updateRolePharmacyNew]);

  useEffect(() => {
    if (!!toggleUpdate) {
      const allChecboxesConcat = updateRoleGorceryNew.concat(updateRolePharmacyNew);
      if (allChecboxesConcat && allChecboxesConcat.length > 0) {
        setUpdateRole((u) => {
          return { ...u, roles: allChecboxesConcat };
        });
      }
    }
  }, [toggleUpdate, updateRoleGorceryNew, updateRolePharmacyNew]);

  useEffect(() => {
    if (toggleUpdate) {
      const roleName = updateRoleGroceryData?.roleName;

      setUpdateRole((r) => {
        return { ...r, roleName: roleName };
      });
    }
  }, [toggleUpdate, updateRoleGroceryData]);

  // PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    loading: loadingGrocery || loadingPharmacy
  };

  const deleteSingleProps = {
    active: toggleSingleDelete,
    onToggle: setToggleSingleDelete,
    formRef: deleteSingleFormRef
  };

  const deleteMultiProps = {
    active: toggleMultiDelete,
    onToggle: setToggleMultiDelete,
    formRef: deleteMultiFormRef
  };

  const tableActionsProps = {
    user: user,
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,
    location: location,
    pharmacyPermissions: pharmacyPermissions,
    getPharmacyPermissions: getPharmacyPermissions
  };

  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list.data?.pagesCount,
    totalCount: list.data?.totalCount
  };

  return (
    <>
      <h2 className="page-title">Roles</h2>

      <Section className="section section__wrapper section__roles">
        {/* Main Header */}
        <header className={`section__header`}>
          {!loading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!loading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                  </span>
                  <input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          <div className="actions">
            {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`) && (
              <>
                {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.createRoles) && (
                  <>
                    {!loading ? (
                      <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'create' })} className="btn btn-primary pl-4 pr-4" data-cy="create-roles-btn">
                        <span className="text">Create Role</span>
                      </Button>
                    ) : (
                      <LoadingPlaceholder style={{ width: '144.5px', height: '50px' }} />
                    )}
                  </>
                )}

                {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.deleteRoles) && (
                  <>
                    {!loading ? (
                      <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'deleteMulti' })} className="btn btn-primary-outline pl-4 pr-4" disabled={disabledButton ? false : true} data-cy="bulk-delete-roles-btn">
                        <span className="text">Delete Roles</span>
                      </Button>
                    ) : (
                      <LoadingPlaceholder style={{ width: '144.5px', height: '50px' }} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data?.Data} {...tableProps} {...tableActionsProps} pagination={paginationProps} />}</Main>

        {/* Create Role */}
        {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.createRoles) && (
          <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Role">
            <form ref={createFormRef} className={`ant-form ant-form-horizontal`} name="create" onSubmit={(e) => handleSubmit(e)}>
              <Row className="mb-4">
                <Col span={24}>
                  <div className="ant-form-item-control-input-content" style={{ position: 'relative' }}>
                    <label className="label mb-1 d-block" htmlFor="nameCreateRole">
                      <b className="required">Name:</b>
                    </label>

                    <input id="nameCreateRole" data-cy="modal-create-role-name" type="text" style={{ width: '100%' }} value={createRole.name || ''} placeholder="Name:" onChange={(e) => handleOnChange(e)} onBlur={(e) => handleValidation(e)} name="name_role" />
                    <p data-cy="modal-create-role-name-error" className={`error__onblur name_role-error__required`}>
                      This field is required!
                    </p>
                  </div>
                </Col>
              </Row>

              <Row gutter={16} className="mb-4">
                <Col span={12}>
                  <button type="button" data-cy-attribute={toggleSwitchSite === 'grocery' ? 'grocery-create-tab-active' : ''} data-cy="grocery-create-tab" className={`btn btn-block btn-no-focus ${toggleSwitchSite === 'grocery' ? 'btn-primary' : 'btn-primary-link'}`} onClick={() => handleToggleSwitch('grocery')}>
                    <span>Grocery</span>
                  </button>
                </Col>

                <Col span={12}>
                  <button type="button" data-cy-attribute={toggleSwitchSite === 'pharmacy' ? 'pharmacy-create-tab-active' : ''} data-cy="pharmacy-create-tab" className={`btn btn-block btn-no-focus ${toggleSwitchSite === 'pharmacy' ? 'btn-primary' : 'btn-primary-link'}`} onClick={() => handleToggleSwitch('pharmacy')}>
                    <span>Pharmacy</span>
                  </button>
                </Col>
              </Row>

              {/* GROCERY */}
              <div style={{ display: `${toggleSwitchSite === 'grocery' ? 'block' : 'none'}` }}>
                <hr className="hr" />

                <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                  <Col span={12}>
                    <p className="mb-0">Permissions</p>
                  </Col>
                  <Col span={12}>
                    <div className="select-all-checkbox">
                      <input data-cy="checkbox-select-all-create-grocery" type="checkbox" id="all_grocery" name="all_grocery" onClick={(e) => handleToggleSelectAll(e)} />
                      <label data-cy="label-select-all-create-grocery" htmlFor="all_grocery">
                        Select all
                      </label>
                    </div>
                  </Col>
                </Row>

                <hr className="hr mb-4" />

                {grocery.length > 0 &&
                  grocery.map((item, idx) => {
                    return (
                      <Form.Item key={idx} className="checkbox-rows">
                        <div className="row-checkbox" key={item._id}>
                          <input data-cy="checkbox-select-rows-create-grocery" type="checkbox" id={`grocery_${item._id}`} value={`grocery_${item._id}`} name={`grocery_${item._id}`} onChange={(e) => handleSelectCheckboxRows(e)(item.permissions)} checked={rowCheckedGrocery.includes(`grocery_${item._id}`)} />
                          <label data-cy="label-select-rows-create-grocery" htmlFor={`grocery_${item._id}`} style={{ textTransform: 'capitalize' }}>
                            {item._id}
                          </label>
                        </div>
                        <Row className="checkbox-cols" data-cy="checkbox-grocery-create-permissions">
                          {item?.permissions.map((check) => {
                            return (
                              <div className="checkbox-permission" key={check._id}>
                                <input type="checkbox" id={check._id} value={check._id} name={`grocery_checkbox_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} checked={isCheckboxGrocery.includes(check._id)} />
                                <label htmlFor={check._id}>{check.label}</label>
                              </div>
                            );
                          })}
                        </Row>
                      </Form.Item>
                    );
                  })}
              </div>

              {/* PHARMACY */}
              <div style={{ display: `${toggleSwitchSite === 'pharmacy' ? 'block' : 'none'}` }}>
                <hr className="hr" />

                <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                  <Col span={12}>
                    <p className="mb-0">Permissions</p>
                  </Col>
                  <Col span={12}>
                    <div className="select-all-checkbox">
                      <input data-cy="checkbox-select-all-create-pharmacy" type="checkbox" id="all_pharmacy" name="all_pharmacy" onClick={(e) => handleToggleSelectAll(e)} />
                      <label data-cy="label-select-all-create-pharmacy" htmlFor="all_pharmacy">
                        Select all
                      </label>
                    </div>
                  </Col>
                </Row>

                <hr className="hr mb-4" />

                {pharmacy.length > 0 &&
                  pharmacy.map((item, idx) => {
                    return (
                      <Form.Item key={idx} className="checkbox-rows">
                        <div className="row-checkbox" key={item._id}>
                          <input data-cy="checkbox-select-rows-create-pharmacy" type="checkbox" id={`pharmacy_${item._id}`} value={`pharmacy_${item._id}`} name={`pharmacy_${item._id}`} onChange={(e) => handleSelectCheckboxRows(e)(item.permissions)} checked={rowCheckedPharmacy.includes(`pharmacy_${item._id}`)} />
                          <label data-cy="label-select-rows-create-pharmacy" htmlFor={`pharmacy_${item._id}`}>
                            {item._id}
                          </label>
                        </div>

                        <Row data-cy="checkbox-pharmacy-create-permissions" className="checkbox-cols">
                          {item?.permissions.map((check) => {
                            return (
                              <div className="checkbox-permission" key={check._id}>
                                <input type="checkbox" id={check._id} value={check._id} name={`pharmacy_checkbox_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} checked={isCheckboxPharmacy.includes(check._id)} />
                                <label htmlFor={check._id}>{check.label}</label>
                              </div>
                            );
                          })}
                        </Row>
                      </Form.Item>
                    );
                  })}
              </div>

              <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
                <Row gutter={16}>
                  <Col span={12}>
                    <button data-cy="modal-create-cancel-role-btn" type="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                      <span>Cancel</span>
                    </button>
                  </Col>
                  <Col span={12}>
                    <button data-cy="modal-create-submit-role-btn" type="submit" className="btn btn-primary btn-block">
                      <span>Add Role</span>
                    </button>
                  </Col>
                </Row>
              </Form.Item>
            </form>
          </SidebarModal>
        )}

        {/* Update Role */}
        {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.updateRoles) && (
          <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Role">
            {!loadingGrocery && !loadingPharmacy ? (
              <form name="update" ref={updateFormRef} className={`ant-form ant-form-horizontal`} onSubmit={(e) => handleSubmit(e)}>
                <Row className="mb-4">
                  <Col span={24}>
                    <div className="ant-form-item-control-input-content" style={{ position: 'relative' }}>
                      <label className="label mb-1 d-block" htmlFor="nameUpdateRole">
                        <b className="required">Name:</b>
                      </label>
                      <input id="nameUpdateRole" data-cy="modal-edit-role-name" type="text" style={{ width: '100%' }} defaultValue={updateRoleGroceryData.roleName} placeholder="Name:" onChange={(e) => handleOnChange(e)} onBlur={(e) => handleValidation(e)} name="name_update_role" />
                      <p data-cy="modal-edit-role-name-error" className={`error__onblur name_update_role-error__required`}>
                        This field is required!
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row gutter={16} className="mb-4">
                  <Col span={12}>
                    <button type="button" data-cy-attribute={toggleSwitchUpdate === 'grocery' ? 'grocery-update-tab-active' : ''} data-cy="grocery-update-tab" className={`btn btn-block btn-no-focus ${toggleSwitchUpdate === 'grocery' ? 'btn-primary' : 'btn-primary-link'}`} onClick={() => handleToggleSwitch('grocery_update')}>
                      <span>Grocery</span>
                    </button>
                  </Col>

                  <Col span={12}>
                    <button type="button" data-cy-attribute={toggleSwitchUpdate === 'pharmacy' ? 'pharmacy-update-tab-active' : ''} data-cy="pharmacy-update-tab" className={`btn btn-block btn-no-focus ${toggleSwitchUpdate === 'pharmacy' ? 'btn-primary' : 'btn-primary-link'}`} onClick={() => handleToggleSwitch('pharmacy_update')}>
                      <span>Pharmacy</span>
                    </button>
                  </Col>
                </Row>

                {!loadingGrocery && (
                  <>
                    {!!updateRoleGroceryData.permissions && (
                      <div style={{ display: `${toggleSwitchUpdate === 'grocery' ? 'block' : 'none'}` }}>
                        <hr className="hr" />

                        <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                          <Col span={12}>
                            <p className="mb-0">Permissions</p>
                          </Col>
                          <Col span={12}>
                            <div className="select-all-checkbox">
                              <input data-cy="checkbox-select-all-update-grocery" type="checkbox" id="all_update_grocery" name="all_update_grocery" onClick={(e) => handleToggleSelectAll(e)} />
                              <label data-cy="label-select-all-update-grocery" htmlFor="all_update_grocery">
                                Select all
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <hr className="hr mb-4" />

                        {updateRoleGroceryData &&
                          Object.entries(updateRoleGroceryData?.permissions).map((item, idx) => {
                            const label = Object.values(item)[0];
                            const items = Object.values(item)[1];

                            return (
                              <Form.Item className="checkbox-rows" key={idx}>
                                <div className="row-checkbox" key={label}>
                                  <input data-cy="checkbox-select-rows-update-grocery" type="checkbox" id={`update_grocery_${label}`} value={`update_grocery_${label}`} name={`update_grocery_${label}`} onChange={(e) => handleSelectCheckboxRows(e)(items)} />
                                  <label data-cy="label-select-rows-update-grocery" htmlFor={`update_grocery_${label}`} style={{ textTransform: 'capitalize' }}>
                                    {label}
                                  </label>
                                </div>

                                <Row className="checkbox-cols" data-cy="checkbox-grocery-edit-permissions">
                                  {items?.map((check) => {
                                    return (
                                      <div className="checkbox-permission" key={check._id}>
                                        <input data-cy="checkbox-update-grocery-permissions" type="checkbox" id={`U_G_${check._id}`} value={check._id} name={`update_grocery_roles_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} defaultChecked={check.checked} />
                                        <label data-cy="label-update-grocery-permissions" htmlFor={`U_G_${check._id}`}>
                                          {check.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </Row>
                              </Form.Item>
                            );
                          })}
                      </div>
                    )}
                  </>
                )}

                {!loadingPharmacy && (
                  <>
                    {!!updateRolePharmacyData.permissions && (
                      <div style={{ display: `${toggleSwitchUpdate === 'pharmacy' ? 'block' : 'none'}` }}>
                        <hr className="hr" />

                        <Row className="mt-2 mb-2" style={{ alignItems: 'center' }}>
                          <Col span={12}>
                            <p className="mb-0">Permissions</p>
                          </Col>
                          <Col span={12}>
                            <div className="select-all-checkbox">
                              <input data-cy="checkbox-select-all-update-pharmacy" type="checkbox" id="all_update_pharmacy" name="all_update_pharmacy" onClick={(e) => handleToggleSelectAll(e)} />
                              <label data-cy="label-select-all-update-pharmacy" htmlFor="all_update_pharmacy">
                                Select all
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <hr className="hr mb-4" />

                        {updateRolePharmacyData &&
                          Object.entries(updateRolePharmacyData?.permissions).map((item, idx) => {
                            const label = Object.values(item)[0];
                            const items = Object.values(item)[1];

                            return (
                              <Form.Item className="checkbox-rows" key={idx}>
                                <div className="row-checkbox" key={label}>
                                  <input data-cy="checkbox-select-rows-update-pharmacy" type="checkbox" id={`update_pharmacy_${label}`} value={`update_pharmacy_${label}`} name={`update_pharmacy_${label}`} onChange={(e) => console.warn('test')} />
                                  <label data-cy="label-select-rows-update-pharmacy" htmlFor={`update_pharmacy_${label}`} style={{ textTransform: 'capitalize' }}>
                                    {label}
                                  </label>
                                </div>

                                <Row className="checkbox-cols" data-cy="checkbox-pharmacy-edit-permissions">
                                  {items?.map((check) => {
                                    return (
                                      <div className="checkbox-permission" key={check._id}>
                                        <input data-cy="checkbox-update-pharmacy-permissions" type="checkbox" id={`U_P_${check._id}`} value={check._id} name={`update_pharmacy_roles_${check._id}`} onChange={(e) => handleSelectCheckbox(e)} defaultChecked={check.checked} />
                                        <label data-cy="label-update-pharmacy-permissions" htmlFor={`U_P_${check._id}`}>
                                          {check.label}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </Row>
                              </Form.Item>
                            );
                          })}
                      </div>
                    )}
                  </>
                )}

                <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
                  <Row gutter={16}>
                    <Col span={12}>
                      <button data-cy="modal-edit-cancel-role-btn" type="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                        <span>Cancel</span>
                      </button>
                    </Col>
                    <Col span={12}>
                      <button data-cy="modal-edit-submit-role-btn" type="submit" className="btn btn-primary btn-block">
                        <span>Edit Role</span>
                      </button>
                    </Col>
                  </Row>
                </Form.Item>
              </form>
            ) : (
              <Loading />
            )}
          </SidebarModal>
        )}

        {/* Delete Single Role */}
        {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.deleteRoles) && (
          <Modal {...deleteSingleProps} className="sidebar__modal--center" title="">
            <form data-cy="modal-delete" name="delete_single" ref={deleteSingleFormRef} onSubmit={(e) => handleSubmit(e)}>
              <h2 className="text-center mb-4">Are you sure you want to delete the selected item ?</h2>

              <Form.Item>
                <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
                  <Button data-cy="modal-delete-yes-btn" type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                    <span>Yes</span>
                  </Button>

                  <Button data-cy="modal-delete-no-btn" type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleSingleDelete(false)}>
                    <span>No</span>
                  </Button>
                </Row>
              </Form.Item>
            </form>
          </Modal>
        )}

        {/* Delete Multi Role */}
        {getPharmacyPermissions(pharmacyPermissions, PERMISSIONS.deleteRoles) && (
          <Modal {...deleteMultiProps} className="sidebar__modal--center" title="">
            <form data-cy="modal-delete-bulk" name="delete_multi" ref={deleteMultiFormRef} onSubmit={(e) => handleSubmit(e)}>
              <h2 className="text-center mb-4">Are you sure you want to delete the selected items ?</h2>

              <Form.Item>
                <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
                  <Button data-cy="modal-delete-bulk-yes-btn" type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                    <span>Yes</span>
                  </Button>

                  <Button data-cy="modal-delete-bulk-no-btn" type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleMultiDelete(false)}>
                    <span>No</span>
                  </Button>
                </Row>
              </Form.Item>
            </form>
          </Modal>
        )}
      </Section>
    </>
  );
};

export default Roles;
