import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Section, Main } from '../../../components/content';
import { Delivery } from '../../../components/deliveryComponent';
import { daysDate } from '../../../config';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import { useAuth } from '../../../context/useAuth';


const DeliveryPage = () => {
    const { user } = useAuth();
    const { pathname } = useLocation();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentDay = currentDate.getDay();
    const yearQuery = searchParams.get('year') ?? currentYear.toString();
    const dayQuery = searchParams.get('day') ?? daysDate[currentDay].name.toLowerCase();

    const [groceryPermissions, setGroceryPermissions] = useState([]);
    const [slots, setSlots] = useState([]);
    const [overrideRules, setOverrideRules] = useState([]);
    const [refetchOverrideRules, setRefetchOverrideRules] = useState(false);

    // GET USER TOKEN
    useEffect(() => {
        const { token } = user;
        if (user && token) {
            const decodeToken = jwt_decode(token);
            const permissions = decodeToken.roleData?.permissions;

            if (pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
                if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
                    setGroceryPermissions(permissions.grocery);
                }
            }
        }
    }, [user, pathname]);

    const onChangeDate = (event) => {
        const { name, value } = event.target;
        const params = new URLSearchParams(searchParams);

        if (value) {
            params.set(name, value.toString());
        } else {
            params.delete(name);
        }

        void navigate(`${pathname}?${params.toString()}`, { replace: true, scroll: false });
    }

    const fetchSlotsByDate = useCallback(async ({ day, year }) => {
        const { token } = user;

        await axios
            .get(`${SERVER_URL}/delivery-slot/${day}/${year}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (res.status === 200) {
                    const slots = res.data.data;
                    setSlots(slots);

                    const overrideRules = res.data.override_rules;
                    setOverrideRules(overrideRules);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
            });
    }, [user]);

    useEffect(() => {
        fetchSlotsByDate({ day: dayQuery, year: yearQuery });
    }, [user, dayQuery, yearQuery, refetchOverrideRules]);

    const date = {
        year: yearQuery,
        day: dayQuery
    }

    return (
        <>
            <h2 className="page-title">Delivery Pick-ups</h2>
            <Section className="section__wrapper section__delivery">
                <Main className="section__content">
                    <div className='item item-left'>
                        <Delivery.Year value={yearQuery} onChange={onChangeDate} />
                    </div>
                    <div className='item item-center'>
                        <Delivery.Day data={slots} value={dayQuery} onChange={onChangeDate} onRefetch={setRefetchOverrideRules} options={daysDate} queryDate={date} />
                    </div>
                    <div className='item item-right'>
                        <Delivery.Override data={overrideRules} refetch={refetchOverrideRules} onRefetch={setRefetchOverrideRules} />
                    </div>
                </Main>
            </Section>
        </>
    );
};

export default DeliveryPage;
