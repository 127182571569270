import React, { useEffect, useState } from "react";
import { Section, Col, Row, Wrapper } from "../../../../components/content";
import { ProductProvider, useProducts } from "../../../../context/products";
import { PERMISSIONS } from "../../../../utils/permissions";
import { ProductBreadcrumb } from "./product-breadcrumb/ProductBreadcrumb";
import { ProductGallery } from "./product-gallery/ProductGallery";
import { ProductInfo } from "./product-info/ProductInfo";
import { ProductOptions } from "./product-options/ProductOptions";
import { ProductTags } from "./product-tags/ProductTags";
import { ProductType } from "./product-type/ProductType";
import LoadingPlaceholder from "../../../../components/loadingPlaceholder/LoadingPlaceholder";
import Loading from "../../../../components/loading/Loading";
import ProductUpcCode from "./product-upccode/ProductUpcCode";

const CreateProductLoader = (props) => {
  const { children } = props;
  const { productCategoriesLoading, setProductEdit } = useProducts();

  useEffect(() => {
    setProductEdit(false);
  }, [])

  if (productCategoriesLoading) {
    return <Loading className="loading-full loading-relative" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const CreateProductSubmit = () => {
  const { form, onCancel, onSubmit, productCategoriesLoading } = useProducts();

  return (
    <footer className="product-save">
      <div className="actions">
        {!productCategoriesLoading ? (
          <button
            onClick={() => onCancel(form)}
            className="btn btn-primary-outline"
            type="button"
          >
            <span className="text">Cancel</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: "176px", height: "50px" }} />
        )}

        {!productCategoriesLoading ? (
          <button
            onClick={() =>
              onSubmit(
                `${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.products}`
              )
            }
            className="btn btn-primary"
            type="button"
          >
            <span className="text">Create Product</span>
          </button>
        ) : (
          <LoadingPlaceholder style={{ width: "176px", height: "50px" }} />
        )}
      </div>
    </footer>
  );
};

const CreateProduct = () => {
  return (
    <ProductProvider>
      <ProductBreadcrumb />
      <Section className="section__product section__product--wrapper section__product--create">
        <CreateProductLoader>
          <Row>
            <Col>
              <Wrapper className="side-left">
                <ProductInfo className="product__card--info" />
              </Wrapper>
            </Col>
            <Col>
              <Wrapper className="side-right">
                <ProductGallery className="product__card--gallery" />
                <ProductType className="product__card--type" />
                <ProductOptions className="product__card--options" />
                <ProductUpcCode className="product__card--upccode" />
                <ProductTags className="product__card--tags" />
              </Wrapper>
            </Col>
          </Row>
        </CreateProductLoader>

        <CreateProductSubmit />
      </Section>
    </ProductProvider>
  );
};

export default CreateProduct;
