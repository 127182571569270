import React from 'react'
import { useProducts } from '../../context/products'
import InputCheckboxField from '../inputCheckboxField/InputCheckboxField';
import InputRadioField from '../inputRadioField/InputRadioField';

const InputTypeSelect = ({ item, idx, checked, setChecked }) => {
  const { gallerySelect } = useProducts();

  const inputProps = {
    item,
    idx,
    checked,
    setChecked
  }

  return ( gallerySelect === 'multiselect' ?  
    <InputCheckboxField {...inputProps} /> : gallerySelect === 'singleselect' && <InputRadioField {...inputProps} />
  )
}

export default InputTypeSelect