import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, Input, Button, Form, Row, Col, Checkbox, notification } from 'antd';
import Modal from '../../../components/modal/Modal';
import SidebarModal from '../../../components/sidebarModal/SidebarModal';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { stateUS } from '../../../helpers/state';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import jwt_decode from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const Employees = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deactivateBtnLoader, setDeactivateBtnLoader] = useState(false);
  const [deactivateBlkBtnLoader, setDeactivateBlkBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const getTableData = useCallback(async () => {
    const { token } = user;

    setLoading(true);
    try {
      await axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.usersEmployees}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);

      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage]);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  // Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  // const [employeeUpdateId, setEmployeeUpdateId] = useState('');
  const [employeeTestUpdateId, setEmployeeTestUpdateId] = useState({
    id: ''
  });
  const [employeeDeleteId, setEmployeeDeleteId] = useState('');
  const [employeeStatusSingleId, setEmployeeStatusSingleId] = useState({
    id: '',
    status: ''
  });

  const [employeeStatusMultiId, setEmployeeStatusMultiId] = useState([]);

  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'create':
        state.onToggle((prevState) => !prevState);
        break;
      case 'update':
        // setEmployeeUpdateId(data);
        setEmployeeTestUpdateId({ id: data });
        state.onToggle((prevState) => !prevState);
        break;
      case 'status':
        setEmployeeStatusSingleId({ id: data.id, status: data.status });
        state.onToggle((prevState) => !prevState);
        break;
      case 'updateStatusMulti':
        setEmployeeStatusMultiId(data);
        state.onToggle((prevState) => !prevState);
        break;
      case 'delete':
        setEmployeeDeleteId(data);
        state.onToggle((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // Refs
  const createModalFormRef = useRef(null);
  const updateModalFormRef = useRef(null);
  const deleteModalFormRef = useRef(null);
  const statusModalSingleFormRef = useRef(null);
  const statusModalMultiFormRef = useRef(null);

  // State
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [toggleStatusSingle, setToggleStatusSingle] = useState(false);
  const [toggleStatusMulti, setToggleStatusMulti] = useState(false);

  // Store
  const [createEmployee, setCreateEmployee] = useState([]);
  const [updateEmployee, setUpdateEmployee] = useState([]);
  const [deleteEmployee, setDeleteEmployee] = useState([]);
  const [statusSingleEmployee, setStatusSingleEmoloyee] = useState([]);
  const [statusMultiEmployee, setStatusMultiEmoloyee] = useState([]);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);

  const onChange = (e) => {
    if (e.target.checked) {
      setToggleNewPassword(true);
    } else {
      setToggleNewPassword(false);
    }
  };

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const [loadingEmployee, setLoadingEmployee] = useState(false);

  // FETCH UPDATE DATA
  useEffect(() => {
    const employeeUpdateId = employeeTestUpdateId.id;
    const { token } = user;

    if (!!employeeUpdateId) {
      try {
        setLoadingEmployee(true);
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmoloyee}/${employeeUpdateId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setUpdateEmployee(res.data);
            setTimeout(() => {
              setLoadingEmployee(false);
            }, 700);
          })
          .catch((err) => {
            setLoadingEmployee(false);
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, employeeTestUpdateId, refetch]);

  const handleOnFinish = async (props) => {
    const { name, data } = props;

    const { token } = user;

    switch (name) {
      case 'create':
        setCreateBtnLoader(true);
        try {
          const createData = {
            firstName: data?.firstName || '',
            lastName: data?.lastName || '',
            user_role: data?.role || '',
            address: [
              {
                address: data?.address || '',
                zip_code: data?.zipCode || '',
                state: data?.state || '',
                city: data?.city || ''
              }
            ],
            password: data?.password || '',
            phone: data?.phone || '',
            email: data?.email || '',
            userType: 'dashboard',
            department: PERMISSIONS.grocery
          };

          let fetchData = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.createEmployee}`, { ...createData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          if (fetchData.status === 201) {
            setRefetch((prevState) => !prevState);
            setCurrentPage(1);
            setToggleCreate(false);
            setCreateEmployee([]);

            createModalFormRef.current.resetFields();

            // notification
            notification.success({
              message: notificationMessages.successCreateEmployee,
              placement: 'bottomLeft'
            });
          }
          setTimeout(() => {
            setCreateBtnLoader(false);
          }, 700);
        } catch (err) {
          setCreateBtnLoader(false);
          console.error(err);

          notification.error({
            message: notificationMessages.userAlreadyExists,
            placement: 'bottomLeft'
          });
        }
        break;
      case 'update':
        setEditBtnLoader(true);
        try {
          const newPassword = Object.keys(data).includes('newPassword');
          const userId = data?.updateUserId;

          let updateData = [];

          // Date to backend --
          if (!newPassword) {
            updateData = updateData = {
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              user_role: data?.role || '',
              address: [
                {
                  address: data?.address || '',
                  zip_code: data?.zip_code || '',
                  state: data?.state || '',
                  city: data?.city || ''
                }
              ],
              phone: data?.phone || '',
              email: data?.email || '',
              department: PERMISSIONS.grocery
            };
          } else {
            updateData = updateData = {
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              user_role: data?.role || '',
              password: data?.newPassword || '',
              address: [
                {
                  address: data?.address || '',
                  zip_code: data?.zip_code || '',
                  state: data?.state || '',
                  city: data?.city || ''
                }
              ],
              phone: data?.phone || '',
              email: data?.email || '',
              department: PERMISSIONS.grocery
            };
          }

          const employeeUpdateResponse = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.usersUpdateEmoloyee}/${userId}`, { ...updateData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });
          const isEmailChanged = employeeUpdateResponse.data.message.isEmailChanged;
          if (employeeUpdateResponse.request.status === 200) {
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleUpdate(false);
          setUpdateEmployee([]);
          setEmployeeTestUpdateId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
          }, 750);

          isEmailChanged
            ? notification.success({
                message: notificationMessages.successUpdateEmployeeEmail,
                placement: 'bottomLeft'
              })
            : notification.success({
                message: notificationMessages.successUpdateEmployee,
                placement: 'bottomLeft'
              });
        } catch (err) {
          console.error(err);
          setEditBtnLoader(false);
        }
        break;
      case 'status':
        setDeactivateBtnLoader(true);
        try {
          const userId = data?.statusSingleUserId;

          const userStatusData = {
            status: data?.statusSingleUser === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
            department: PERMISSIONS.grocery
          };

          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.usersUpdateEmoloyee}/${userId}`, { ...userStatusData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleStatusSingle(false);
          setStatusSingleEmoloyee([]);
          setFilterStatus(null);
          setEmployeeStatusSingleId({
            id: '',
            status: ''
          });

          statusModalSingleFormRef.current.resetFields();

          notification.success({
            message: notificationMessages.successUpdateStatusEmployee,
            placement: 'bottomLeft'
          });
          setTimeout(() => {
            setDeactivateBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setDeactivateBtnLoader(false);
        }
        break;
      case 'updateStatusMulti':
        setDeactivateBlkBtnLoader(true);
        try {
          const deactiveMultiData = {
            employees: data?.statusMultiUser,
            department: PERMISSIONS.grocery
          };

          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.usersBulkDeactivateEmployee}`, { ...deactiveMultiData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleStatusMulti(false);
          setStatusMultiEmoloyee([]);
          setFilterStatus(null);

          statusModalMultiFormRef.current.resetFields();

          notification.success({
            message: notificationMessages.successUpdateStatusMultiEmployee,
            placement: 'bottomLeft'
          });
          setTimeout(() => {
            setDeactivateBlkBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setDeactivateBlkBtnLoader(false);
        }
        break;
      case 'delete':
        setDeleteBtnLoader(true);
        try {
          const userId = data?.deleteUserId;
          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.usersDeleteEmployee}/${userId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleDelete(false);
          setDeleteEmployee([]);
          setEmployeeDeleteId('');

          deleteModalFormRef.current.resetFields();

          notification.success({
            message: notificationMessages.successDeleteEmployee,
            placement: 'bottomLeft'
          });
          setTimeout(() => {
            setDeleteBtnLoader(false);
          }, 700);
        } catch (err) {
          notification.error({
            message: notificationMessages.failedDeleteEmployee,
            placement: 'bottomLeft'
          });
          setDeleteBtnLoader(false);
        }
        break;
      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // Create Employee
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    data: createEmployee,
    onData: setCreateEmployee
  };

  // Update Employee
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: updateEmployee,
    onData: setUpdateEmployee,
    loading: loadingEmployee
  };

  // Delete Employee
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
    data: deleteEmployee,
    onData: setDeleteEmployee
  };

  // Status Single Update Employee
  const statusSingleProps = {
    active: toggleStatusSingle,
    onToggle: setToggleStatusSingle,
    formRef: statusModalSingleFormRef,
    data: statusSingleEmployee,
    onData: setStatusSingleEmoloyee
  };

  // Status Multi Update Employee
  const statusMultiProps = {
    active: toggleStatusMulti,
    onToggle: setToggleStatusMulti,
    formRef: statusModalMultiFormRef,
    data: statusMultiEmployee,
    onData: setStatusMultiEmoloyee
  };

  // TABLE
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows
  };

  const tableActionsProps = {
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,

    updateActions: updateProps,
    deleteActions: deleteProps,
    statusActions: statusSingleProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter
  };

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: list.data?.pagesCount,
    totalCount: list.data?.totalCount
  };

  const createFormFields = [
    {
      name: ['role'],
      value: ''
    },
    {
      name: ['state'],
      value: ''
    },
    {
      name: 'createUserId',
      value: user.id
    },
    {
      name: 'createUserType',
      value: 'dashboard'
    }
  ];

  const updateFormFields = [
    {
      name: 'role',
      value: (!!updateEmployee?.user_role && updateEmployee?.user_role?._id) || ''
    },
    {
      name: 'updateUserId',
      value: updateEmployee._id
    },
    {
      name: 'firstName',
      value: updateEmployee.firstName
    },
    {
      name: 'lastName',
      value: updateEmployee.lastName
    },
    {
      name: 'email',
      value: updateEmployee.email
    },
    {
      name: ['phone'],
      value: updateEmployee.phone
    },
    {
      name: 'newPassword',
      value: ''
    },
    {
      name: 'state',
      value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].state) || ''
    },
    {
      name: 'address',
      value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].address) || ''
    },
    {
      name: 'city',
      value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].city) || ''
    },
    {
      name: 'zip_code',
      value: (!!updateEmployee?.address?.[0] && updateEmployee.address?.[0].zip_code) || ''
    }
  ];

  const deleteFormFields = [
    {
      name: 'deleteUserId',
      value: employeeDeleteId
    }
  ];

  const statusSingleFormFields = [
    {
      name: 'statusSingleUserId',
      value: employeeStatusSingleId.id
    },
    {
      name: 'statusSingleUser',
      value: employeeStatusSingleId.status
    }
  ];

  const statusMultiFormFields = [
    {
      name: 'statusMultiUser',
      value: employeeStatusMultiId
    }
  ];

  return (
    <>
      <h2 className="page-title">Employees</h2>

      <Section className="section section__wrapper section__employees">
        {/* Main Header */}
        <header className="section__header">
          {!loading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!loading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                  </span>
                  <input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          <div className="actions">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createEmployees) && (
              <>
                {!loading ? (
                  <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-employee-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Create Employee</span>
                  </Button>
                ) : (
                  <LoadingPlaceholder style={{ width: '180.5px', height: '50px' }} />
                )}
              </>
            )}

            {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
              <>
                {!loading ? (
                  <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'updateStatusMulti', state: statusMultiProps, data: checkedRow })} data-cy="bulk-deactive-employees-btn" className="btn btn-primary-outline pl-4 pr-4" disabled={disabledButton ? false : true}>
                    <span className="text">Deactivate Employee</span>
                  </Button>
                ) : (
                  <LoadingPlaceholder style={{ width: '207px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data?.Data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

        {/* Create Employee - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createEmployees) && (
          <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Employee">
            <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
              {/* label="First Name:" */}

              <Form.Item name="firstName" label="First Name:" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                <Input placeholder="First Name:" />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name:" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                <Input placeholder="Last Name:" />
              </Form.Item>
              <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Please input your email!' }]}>
                <Input placeholder="Email:" />
              </Form.Item>
              <Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
                <Select>
                  <Option key={''}>Select State</Option>

                  {stateUS.map((item) => {
                    return (
                      <Option key={item.name} value={item.abbreviation}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
                <Input placeholder="City:" />
              </Form.Item>
              <Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
                <Input placeholder="Address:" />
              </Form.Item>
              <Form.Item name="zipCode" label="Zip Code:" rules={[{ required: false, message: 'Please input your zip code!' }]}>
                <Input placeholder="Zip Code:" />
              </Form.Item>
              <Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
                <Input placeholder="Phone:" />
              </Form.Item>
              <Form.Item name="password" label="Password:" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password placeholder="Password:" />
              </Form.Item>
              <Form.Item name="role" label="Select Roles:" rules={[{ required: true, message: 'Please input your role!' }]}>
                <Select>
                  <Option key={''}>Select Roles</Option>

                  {roles.map((item) => {
                    return (
                      <Option key={item.name} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="createUserId" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="createUserType" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
                <Row gutter={16}>
                  <Col span={12}>
                    <Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                      <span>Cancel</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    {!createBtnLoader ? (
                      <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                        <span>Add Employee</span>
                      </Button>
                    ) : (
                      <Button type="button" className="btn btn-primary-outline btn-block">
                        <span>Adding...</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </SidebarModal>
        )}

        {/* Update Employee - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
          <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Employee">
            {!loadingEmployee ? (
              <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
                <Form.Item name="firstName" label="First Name:" rules={[{ required: true, message: 'Please input your first name!' }]}>
                  <Input placeholder="First Name:" />
                </Form.Item>
                <Form.Item name="lastName" label="Last Name:" rules={[{ required: true, message: 'Please input your last name!' }]}>
                  <Input placeholder="Last Name:" />
                </Form.Item>

                <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Please input your email!' }]}>
                  <Input placeholder="Email:" value="" />
                </Form.Item>

                <Form.Item name="state" label="Select State:" rules={[{ required: false, message: 'Please input your state!' }]}>
                  <Select>
                    <Option value="">Select State</Option>

                    {stateUS.map((item) => {
                      return (
                        <Option key={item.name} value={item.abbreviation}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item name="address" label="Address:" rules={[{ required: false, message: 'Please input your address!' }]}>
                  <Input placeholder="Address:" />
                </Form.Item>

                <Form.Item name="city" label="City:" rules={[{ required: false, message: 'Please input your city!' }]}>
                  <Input placeholder="City:" />
                </Form.Item>

                <Form.Item name="zip_code" label="Zip Code:" rules={[{ required: false, message: 'Please input your zip code!' }]}>
                  <Input placeholder="Zip Code:" />
                </Form.Item>

                <Form.Item name="phone" label="Phone:" rules={[{ required: false, message: 'Please input your phone!' }]}>
                  <Input placeholder="Phone:" />
                </Form.Item>

                <Form.Item className="initial-height">
                  <Checkbox onChange={onChange} checked={toggleNewPassword}>
                    Create new password
                  </Checkbox>
                </Form.Item>

                {toggleNewPassword === true && (
                  <Form.Item name="newPassword" label="Password:" initialValue={''} rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password placeholder="Password:" />
                  </Form.Item>
                )}

                <Form.Item name="role" label="Select Roles:" rules={[{ required: true, message: 'Please input your role!' }]}>
                  <Select>
                    <Option value="">Select Roles</Option>

                    {roles.map((item) => {
                      return (
                        <Option key={item.name} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item name="updateUserId" style={{ display: 'none' }}>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                        <span>Cancel</span>
                      </Button>
                    </Col>
                    <Col span={12}>
                      {!editBtnLoader ? (
                        <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                          <span>Edit Employee</span>
                        </Button>
                      ) : (
                        <Button type="button" className="btn btn-primary-outline btn-block">
                          <span>Editing...</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            ) : (
              <Loading />
            )}
          </SidebarModal>
        )}

        {/* Update Employee - status single changes */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
          <Modal {...statusSingleProps} className="sidebar__modal--center" title="">
            <Form name="updateStatusSingle" ref={statusModalSingleFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'status', data: data })} fields={statusSingleFormFields}>
              <h2 className="text-center mb-4">
                <span className="d-block">Are you sure you want to change </span>
                <span className="d-block">status of selected employee?</span>
              </h2>

              <Form.Item name="statusSingleUserId" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item name="statusSingleUser" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item>
                <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
                  {!deactivateBtnLoader ? (
                    <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                      <span>Yes</span>
                    </Button>
                  ) : (
                    <Button type="button" className="btn btn-primary-link btn-block">
                      <span>Updating...</span>
                    </Button>
                  )}

                  <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleStatusSingle(false)}>
                    <span>No</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        )}

        {/* Update Employee - status multi changes */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
          <Modal {...statusMultiProps} className="sidebar__modal--center" title="">
            <Form name="updateStatusMulti" ref={statusModalMultiFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'updateStatusMulti', data: data })} fields={statusMultiFormFields}>
              <h2 className="text-center mb-4">
                <span className="d-block">Are you sure you want to change </span>
                <span className="d-block">status of selected employees ?</span>
              </h2>

              <Form.Item name="statusMultiUser" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item>
                <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
                  {!deactivateBlkBtnLoader ? (
                    <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                      <span>Yes</span>
                    </Button>
                  ) : (
                    <Button type="button" className="btn btn-primary-link btn-block">
                      <span>Deactivating...</span>
                    </Button>
                  )}

                  <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleStatusMulti(false)}>
                    <span>No</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        )}

        {/* Delete Employee - delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
          <Modal {...deleteProps} className="sidebar__modal--center" title="">
            <Form name="delete" ref={deleteModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'delete', data: data })} fields={deleteFormFields}>
              <h2 className="text-center mb-4">Are you sure you want to delete the selected item ?</h2>

              <Form.Item name="deleteUserId" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item>
                <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
                  {!deleteBtnLoader ? (
                    <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                      <span>Yes</span>
                    </Button>
                  ) : (
                    <Button type="button" className="btn btn-primary-link btn-block">
                      <span>Deleting...</span>
                    </Button>
                  )}

                  <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleDelete(false)}>
                    <span>No</span>
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Section>
    </>
  );
};

export default Employees;
