import React, { useState, useRef, useEffect } from 'react';
import { PERMISSIONS } from '../../utils/permissions';
import { NavLink } from 'react-router-dom';
import { getGroupList } from '../../helpers/helpers';

const NavDropdown = (props) => {
  const { className, item } = props;

  return (
    <li className={`item ${className !== undefined ? className : ''}`} onClick={item.onClick}>
      <NavLink to={item.path} className={`navlink`} data-cy={item.dataCy}>
        <span className="text">{item.label}</span>
      </NavLink>
    </li>
  );
};

const NavList = (props) => {
  const { className, item } = props;
  const { children, onClick } = item;
  const [toggleSubmenu, setToggleSubmenu] = useState(item.expanded);

  // DROPDOWN
  const dropdownRef = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (toggleSubmenu && dropdownRef.current !== null) {
      setDropdownHeight(dropdownRef.current.scrollHeight);
    } else {
      setDropdownHeight(0);
    }
  }, [toggleSubmenu, dropdownRef]);

  if (children.length > 0) {
    return (
      <li className={`item dropdown ${toggleSubmenu ? 'isActive' : ''} ${className !== undefined ? className : ''}`}>
        <button
          className="btn"
          data-cy={item.dataCy}
          onClick={() => {
            onClick(item);
            setToggleSubmenu((prevState) => !prevState);
          }}
        >
          <span className="icon">{item.icon && item.icon ? <img src={item.icon} className="img" alt="Customers" /> : null}</span>
          <span className="text">{item.label}</span>
          <span className="icon icon-arrow">
            <img src="/assets/icons/arrow-white.svg" className="img" alt="Customers" />
          </span>
        </button>
        <ul className={`submenu ${toggleSubmenu ? 'isActive' : ''}`} ref={dropdownRef} style={{ height: toggleSubmenu ? `${dropdownHeight}px` : `0px`, opacity: toggleSubmenu ? 1 : 0, visibility: toggleSubmenu ? 'visible' : 'hidden' }}>
          {children
            .filter((i) => i.visible)
            .map((item, idx) => {
              return <NavDropdown key={idx} item={item} />;
            })}
        </ul>
      </li>
    );
  }

  if (item.type === 'button') {
    return (
      <li className={`item ${className !== undefined ? className : ''}`}>
        <button className="btn" onClick={item.onClick} data-cy={item.dataCy}>
          <span className="icon">{item.icon && item.icon ? <img src={item.icon} className="img" alt="Customers" /> : null}</span>
          <span className="text">{item.label}</span>
        </button>
      </li>
    );
  }

  return (
    <li className={`item ${className !== undefined ? className : ''}`} onClick={item.onClick}>
      <NavLink to={item.path} className={`navlink`} data-cy={item.dataCy}>
        <span className="icon">{item.icon && item.icon ? <img src={item.icon} className="img" alt="Customers" /> : null}</span>
        <span className="text">{item.label}</span>
      </NavLink>
    </li>
  );
};

const NavItems = (props) => {
  const { className, items } = props;

  return (
    <ul className={`group ${className !== undefined ? className : ''}`}>
      {items &&
        items
          .filter((i) => i.visible)
          .map((item, idx) => {
            return <NavList key={idx} item={item} className={`item-${item.group && item.group}`} />;
          })}
    </ul>
  );
};

const Navbar = (props) => {
  const { className, menus, location } = props;

  let navbar = null;

  if (location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
    const { grocery } = menus;
    const results = getGroupList(grocery);

    navbar = (
      <div className={`${className !== undefined ? className : ''}`}>
        {Object.values(results).map((item, idx) => {
          return <NavItems key={idx} items={item} />;
        })}
      </div>
    );
  }

  if (location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}`)) {
    const { pharmacy } = menus;
    const results = getGroupList(pharmacy);

    navbar = (
      <div className={`${className !== undefined ? className : ''}`}>
        {Object.values(results).map((item, idx) => {
          return <NavItems key={idx} items={item} />;
        })}
      </div>
    );
  }

  return navbar;
};

export default Navbar;
