import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PERMISSIONS } from "../../../utils/permissions";
import { Section, Main } from "../../../components/content";
import Loading from "../../../components/loading/Loading";
import CreateOrderTable from "../../../components/createOrderComponents/table/CreateOrderTable";
import { useAuth } from "../../../context/useAuth";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import VariableProductModal from "../../../components/modal/VariableProductModal";
import VariationProduct from "../../../components/createOrderComponents/VariationProduct";
import Cart from "../../../components/createOrderComponents/Cart";
import { AXIOS_API_CALL } from "../../../utils/endpoint";

function CreateOrderPharmacy() {
  const { user } = useAuth();

  // limit data per page in table
  const limitPage = 7;

  // search data in table
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({
    query: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  // get data for the table
  const [readData, setReadData] = useState([]);
  const [readDataLoading, setReadDataLoading] = useState(true);
  const [readDataRefetch, setReadDataRefetch] = useState(false);

  // open variation product modal
  const [openModal, setOpenModal] = useState(false);
  const [productModal, setProductModal] = useState({});

  // ---------------- useCallback start

  const getReadData = useCallback(async () => {
    const { token } = user;

    const payload = {
      department: PERMISSIONS.pharmacy,
      searchTerm: search,
    };

    try {
      const response = await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getProducts}/${currentPage}/${limitPage}/null`,
          { ...payload },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200)
        );

      return response;
    } catch (err) { }
  }, [user, limitPage, search, currentPage]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  // ---------------- useCallback end

  // ---------------- functions start

  const priceShow = (item) => {
    let price = 0;
    if (item?.RegularPrice) {
      price = `$${item?.RegularPrice}`;
    } else if (item?.SalePrice) {
      price = `$${item?.SalePrice}`;
    } else if (item?.priceRange) {
      price = item?.priceRange;
    }
    return price;
  };

  const handleOnSubmitSearch = async (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };
  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };
  const handleOnClearSearch = () => {
    setSearchForm({ query: "" });
    setSearch(null);
    setReadDataRefetch((prev) => !prev);
  };
  const handleVariation = (item) => {
    setOpenModal(true);
    setProductModal(item);
  };
  // ---------------- functions end

  // ---------------- props start

  const tableProps = {
    handleOnSubmitSearch: handleOnSubmitSearch,
    handleOnChangeSearch: handleOnChangeSearch,
    handleOnClearSearch: handleOnClearSearch,
    readDataLoading: readDataLoading,
    searchForm: searchForm,
    search: search,
    data: readData?.data,
    priceShow,
  };

  const modalProps = {
    handleVariation,
    active: openModal,
    onToggle: setOpenModal,
    product: productModal,
  };

  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount,
  };

  // ---------------- props end
  const activeClass = window.location.href.includes('create-order') ? 'active' : ''

  return (
    <>
      <div className="orders__navigation">
        <Link
          className="page-title"
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}`}
        >
          Orders
        </Link>
        <Link
          className={`page-title ${activeClass}`}
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`}
        >
          Create Order
        </Link>
      </div>

      <Section className={`section__wrapper section__createOrder`}>
        <header className="section__header">
          <div className="title">Create Order</div>
          <div className="right__side">
            <div className="number__row">
              <div style={{background:window.location.href.endsWith('create-order') ? '#D0EDED' : ''}} className="number">1</div>
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`}
                className="link"
              >
                Cart
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div className="number">2</div>
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`}
                className="link"
              >
                Account Settings
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div className="number">3</div>
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}/${PERMISSIONS.shippingAddress}`}
                className="link"
              >
                Shipping Address
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div className="number">4</div>
              <div className="link">PaymentMethod</div>
            </div>
          </div>
        </header>

        <Main className="section__content section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <div className="create__order">
              <CreateOrderTable
                modalProps={modalProps}
                tableProps={tableProps}
                paginationProps={paginationProps}
              />
              <Cart />
            </div>
          )}
        </Main>

        <VariableProductModal
          {...modalProps}
          className="sidebar__modal--center"
          title="test"
        >
          <VariationProduct
            {...modalProps}
            productModal={productModal}
            id={productModal._id}
          />
        </VariableProductModal>
      </Section>
    </>
  );
}

export default CreateOrderPharmacy;
