import React, { useEffect, useCallback } from "react";
import { scrollFreeze, scrollUnfreeze } from "../../helpers/helpers";

const ModalCard = (props) => {
  const { className, title, children, active = true, onToggle } = props;

  const claseToggleModalEscape = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onToggle(false);
      }
    },
    [active]
  );

  useEffect(() => {
    document.addEventListener("keydown", claseToggleModalEscape, false);
    return () => {
      document.removeEventListener("keydown", claseToggleModalEscape, false);
    };
  }, [claseToggleModalEscape]);

  useEffect(() => {
    if (active) {
      scrollFreeze();
    } else {
      scrollUnfreeze();
    }
  }, [active]);

  return (
    <>
      <div
        className={`modal ${className !== undefined ? className : ""} ${
          active ? "isActive" : ""
        }`}
      >
        <header className="header">
          <div>
            <h4>{title}</h4>
          </div>

          <div>
            <button
              type="button"
              className="btn close-button"
              onClick={() => onToggle(false)}
              data-cy="modal-close-btn"
            >
              <span className="icon">
                <img src="/assets/icons/times-icon.svg" alt="Close" />
              </span>
            </button>
          </div>
        </header>

        <div className="content">{children}</div>
      </div>

      <div
        className={`modal-overlay ${active ? "isActive" : ""}`}
        onClick={() => onToggle(false)}
        data-cy="modal-close-overlay"
      />
    </>
  );
};

export default ModalCard;
