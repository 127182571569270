import React, { memo } from "react";

const RenderCartTable = ({ products: { data }, renderAddress }) => {
  if (data && data.length < 1) {
    return null;
  }

  if (!data) {
    return (
      <div className="left">
        <p>No Data Found</p>
      </div>
    );
  }

  return (
    <div className="left">
      {data.map((product, idx) => {
        const items = product.products;
        const index = idx + 1;

        return (
          <div className="group" key={product._id + idx.toString()}>
            <h3>
              <b>#{index}</b> - {renderAddress(product._id)}
            </h3>

            <div className="table-overflow">
              <table className="table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>WIC</th>
                    <th>Weight</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>

                <tbody>
                  {items.map((item, idx) => {
                    const isWic = item.WIC_SELECTED;

                    let price = 0;

                    const isSalePrice =
                      item &&
                      item.hasOwnProperty("SalePrice") &&
                      item.SalePrice !== null;

                    if (isSalePrice) {
                      price += item.SalePrice;
                    } else {
                      price += item.RegularPrice;
                    }

                    const totalPrice = price * item.currentQty;

                    const isLengthUnits =
                      item && item.units && item.units.length > 0;

                    return (
                      <tr key={item._id + idx.toString()}>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              gap: "15px",
                              flexWrap: "wrap",
                            }}
                          >
                            {item.title}
                            {isLengthUnits && (
                              <div style={{ display: "flex", gap: "15px" }}>
                                {item.units.map((unit) => {
                                  const value = unit.units.value;

                                  return (
                                    <div
                                      key={unit._id}
                                      className="product-attributes"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "15px",
                                        }}
                                        className="attribute"
                                      >
                                        <p className="attribute-name">
                                          {unit.name}:
                                        </p>
                                        <p className="attribute-unit">
                                          {value}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {isWic ? (
                            <span className="eligible eligible-wic">WIC</span>
                          ) : (
                            <span className="eligible eligible-empty">~</span>
                          )}
                        </td>
                        <td>{item.weight} oz</td>
                        <td>{item.currentQty}</td>
                        <td>${totalPrice.toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const CartTable = memo(RenderCartTable);
