import React, { useEffect, useCallback } from "react";

const Modal = (props) => {
  const {
    className,
    title,
    children,
    active,
    onToggle,
    zIndex,
    setChecked,
    currentForm,
    uncheckRadio = false,
  } = props;

  const handleCloseModal = () => {
    onToggle(false);
    if (uncheckRadio) {
      setTimeout(() => {
        if (
          currentForm &&
          currentForm.variantImage &&
          currentForm.variantImage._id
        ) {
          setChecked({ isChecked: currentForm.variantImage._id });
        } else {
          setChecked({ isChecked: "" });
        }
      }, 500);
    }
  };

  const claseToggleModalEscape = useCallback(
    (event) => {
      if (event.key === "Escape" && active) {
        handleCloseModal();
      }
    },
    [onToggle]
  );

  useEffect(() => {
    document.addEventListener("keydown", claseToggleModalEscape, false);
    return () => {
      document.removeEventListener("keydown", claseToggleModalEscape, false);
    };
  }, [claseToggleModalEscape]);

  useEffect(() => {
    // active
    //   ? document.documentElement.classList.add("overflow-hidden")
    //   : document.documentElement.classList.remove("overflow-hidden");
  }, [active]);

  return (
    <>
      <div
        style={{ zIndex: `${zIndex !== undefined ? zIndex + 1 : ""}` }}
        className={`modal ${className !== undefined ? className : ""} ${
          active ? "isActive" : ""
        }`}
      >
        <header className="header">
          <div>
            <h4>{title}</h4>
          </div>

          <div>
            <button
              type="button"
              className="btn close-button"
              onClick={handleCloseModal}
              data-cy="modal-close-btn"
            >
              <span className="icon">
                <img src="/assets/icons/times.svg" alt="Close" />
              </span>
            </button>
          </div>
        </header>

        <main className="content">{children}</main>
      </div>

      <div
        style={{ zIndex: `${zIndex !== undefined ? zIndex : ""}` }}
        className={`modal-overlay ${active ? "isActive" : ""}`}
        onClick={handleCloseModal}
        data-cy="modal-close-overlay"
      />
    </>
  );
};

export default Modal;
