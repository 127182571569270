import React from "react";
import Pagination from "../../pagination/Pagination";
import LoadingPlaceholder from "../../loadingPlaceholder/LoadingPlaceholder";
import { ShoppingCartOutlined, MoreOutlined } from "@ant-design/icons";
import { useCart } from "../../../context/cart";

function CreateOrderTable(props) {
  const { paginationProps, tableProps, modalProps } = props;
  const { handleVariation } = modalProps;
  const {
    readDataLoading,
    searchForm,
    handleOnChangeSearch,
    handleOnClearSearch,
    handleOnSubmitSearch,
    data,
    priceShow,
  } = tableProps;

  const { handleAddToCart } = useCart();

  return (
    <div className="create__table">
      {!readDataLoading ? (
        <div className="search">
          <div style={{ fontSize: "20px" }}>All items</div>
          <form
            className="search-form"
            onSubmit={(e) => handleOnSubmitSearch(e)}
            data-cy="search-roles"
          >
            <div className="form-group m-0">
              <span className="icon icon-search">
                <img
                  src="/assets/icons/search.svg"
                  alt="Search"
                  title="Search"
                />
              </span>
              <input
                value={searchForm.query || ""}
                type="text"
                data-cy="search-input-field"
                className="input"
                placeholder="Search"
                onChange={(e) => handleOnChangeSearch(e.target.value)}
              />
              <span
                className={`icon icon-close ${
                  !!searchForm.query && searchForm.query.length > 0
                    ? "isActive"
                    : ""
                }`}
                onClick={() => handleOnClearSearch()}
              >
                <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
              </span>
            </div>
            <button className="form-submit" data-cy="search-submit-btn">
              Search
            </button>
          </form>
        </div>
      ) : (
        <LoadingPlaceholder
          style={{ width: "100%", maxWidth: "850px", height: "50px" }}
        />
      )}

      <div className="table__container">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Product Id</th>
              <th>Eligible</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.Data &&
              data.Data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.featureImage ? (
                        <img
                          alt={item.title}
                          src={
                            item?.featureImage?.url
                              ? item.featureImage.url
                              : item.featureImage
                          }
                          width={60}
                        />
                      ) : (
                        <img
                          alt="placeholder"
                          src="/assets/images/product-placeholder.svg"
                          width={60}
                        />
                      )}
                    </td>
                    <td>{item.title}</td>
                    <td>{item._id}</td>
                    <td>
                      <div className="badge-area">
                        {item &&
                          item.eligible.length > 0 &&
                          item.eligible.map((item, idx) => {
                            return (
                              <span
                                key={idx}
                                className={`badge badge-single badge-single-${item?.toLowerCase()}`}
                              >
                                {item}
                              </span>
                            );
                          })}
                      </div>
                    </td>
                    <td>{priceShow(item)}</td>
                    <td>
                      {item.priceRange ? (
                        <MoreOutlined
                          onClick={() => handleVariation(item)}
                          className="shopIcon"
                        />
                      ) : (
                        <ShoppingCartOutlined
                          className="shopIcon"
                          onClick={() => handleAddToCart(item)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <Pagination {...paginationProps} />
    </div>
  );
}

export default CreateOrderTable;
