import { Wrapper } from "../../../../../components/content";
import { useProducts } from "../../../../../context/products";
import { Categories } from "./categories/Categories";
import { Description } from "./description/Description";
import { Eligible } from "./eligible/Eligible";

export const ProductInfo = (props) => {
  const { form, onChange, formError } = useProducts();
  const { className } = props;

  return (
    <Wrapper className={`product__card ${className}`}>
      <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>Product Info</h4>
        </header>

        <main className="card-content relative">
          <form name="product-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="productName">
                <b>Product Name:</b>
              </label>
              <input
                value={form.productName || ""}
                onChange={onChange}
                name="productName"
                placeholder="Product Name"
                type="text"
                id="productName"
                className={`input ${formError.productName ? "error" : ""}`}
              />
              <p
                data-cy=""
                className={`error__onblur ${
                  formError.productName ? "error" : ""
                }`}
              >
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="productSlug">
                <b>Product Slug:</b>
              </label>

              <input
                value={form.productSlug || ""}
                onChange={onChange}
                name="productSlug"
                placeholder="Product Slug"
                type="text"
                id="productSlug"
                className={`input ${formError.productSlug ? "error" : ""}`}
              />
              <p
                data-cy=""
                className={`error__onblur ${
                  formError.productSlug ? "error" : ""
                }`}
              >
                * This field is required!
              </p>
            </div>

            <div className="form-group">
              <span className="label">
                <b>Visibility:</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input
                    checked={form.productFeatured || false}
                    onChange={onChange}
                    name="productFeatured"
                    type="checkbox"
                    id="productFeatured"
                    className="checkbox"
                  />
                  <label className="label" htmlFor="productFeatured">
                    <b>Featured product:</b>
                  </label>
                </div>

                <div className="item">
                  <input
                    checked={form.productVisible || false}
                    onChange={onChange}
                    name="productVisible"
                    type="checkbox"
                    id="productVisible"
                    className="checkbox"
                  />
                  <label className="label" htmlFor="productVisible">
                    <b>Visible in the store:</b>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <span className="label">
                <b>Availability</b>
              </span>

              <div className="checkbox-group">
                <div className="item">
                  <input
                    checked={form.productNotAvailable}
                    onChange={onChange}
                    name="productNotAvailable"
                    type="checkbox"
                    id="productNotAvailable"
                    className="checkbox"
                  />
                  <label className="label" htmlFor="productNotAvailable">
                    <b>Product not available:</b>
                  </label>
                </div>
              </div>
            </div>  

            <div className="form-group">
              <Eligible label="Eligible" className="product-eligible" />
            </div>

            <div className="form-group">
              <Categories
                label="Product Categories"
                className="product-categories"
              />
            </div>

            <div className="form-group">
              <Description
                label="Product Content"
                className="product-description"
              />
            </div>
          </form>
        </main>
      </div>
    </Wrapper>
  );
};
