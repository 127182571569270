import React from "react";

const PaymentFormDelete = (props) => {
  const { className, name, onSubmit, onToggle, loading } = props;

  return (
    <form onSubmit={onSubmit} name={name} className={className}>
      <h2 className="text-center mb-4">
        Are you sure you want to remove the selected item ?
      </h2>

      <div className="actions">
        {!loading ? (
          <button className="btn btn-primary-link" type="submit">
            <span>Yes</span>
          </button>
        ) : (
          <button className="btn btn-primary-link" type="button">
            <span>Loading...</span>
          </button>
        )}

        <button
          className="btn btn-primary"
          onClick={() => onToggle(false)}
          type="button"
        >
          <span>No</span>
        </button>
      </div>
    </form>
  );
};

export default PaymentFormDelete;
