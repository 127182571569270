export default function SelectYear({ value, onChange }) {
    const getYear = new Date();
    const year = getYear.getFullYear();

    const options = [year, year + 1, year + 2];

    return (
        <div>
            <header>
                <h3>Choose Year</h3>
            </header>

            <div className='content'>
                <div className='group'>
                    <label htmlFor='year'>Select Year:</label>
                    <select value={value} name='year' onChange={onChange} className='select' id='year'>
                        {options.map((option) => {
                            return <option key={option} value={option}>{option}</option>
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}