import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCart } from "../../../context/cart";
import { Link, useNavigate } from "react-router-dom";
import { Main, Section } from "../../../components/content";
import Loading from "../../../components/loading/Loading";
import { PERMISSIONS } from "../../../utils/permissions";
import CreateOrderCart from "../../../components/createOrderComponents/CreateOrderCart";
import AddressesTable from "../../../components/createOrderComponents/table/AddressesTable";
import { useAuth } from "../../../context/useAuth";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { stateUS } from "../../../helpers/state";
import { notification } from "antd";
import { Payment } from "../../../components/createOrderComponents/paymentComponents";
import SidebarCard from "../../../components/sidebar/SidebarCard";
import CreateButton from "../../../components/createOrderComponents/createCardButton/CreateButton";
import { CartReview } from "../../../components/createOrderComponents/cartReview/CartReview";
import {
  getAddressWithProducts,
  getAddressWithProductsPrice,
  getProducts,
  getTotalPrice,
  getTotalShipment,
  getTotalValue,
  uuidv4,
  sumPrice,
  getLeftTotalPay,
  scrollUnfreeze,
  getProductsListSplit,
  getUserSplitOrders,
  getUserOrders,
} from "../../../helpers/helpers";
import {
  BENJAMINS_DELIVERY,
  BENJAMINS_DELIVERY_ID,
  BENJAMINS_DELIVERY_PRICE,
  BENJAMINS_DELIVERY_PRICE_FREE,
  FREE_DELIVERY,
  BENJAMINS_PHONE_NUMBER,
} from "../../../helpers/constant";
import ModalCard from "../../../components/modal/ModalCard";
import { ChoosePaymentMethod } from "../../../components/createOrderComponents/choosePaymentMethod/ChoosePaymentMethod";
import CheckMark from "../../../components/checkmark/CheckMark";

function ShippingAddress() {
  const navigate = useNavigate();
  const {
    cartProducts,
    isSplitShipping,
    cart,
    setReadDataRefetch,
    readAddress,
    setCustomerAddresses,
    readDataLoading,
    handleClearCart,
    setIsSplitShipping,
    setCart,
    customerId,
    customer,
    refetchCard,
    setRefetchCard,
  } = useCart();

  const initialItems = {
    wic: [],
    rest: [],
  };
  const { user } = useAuth();
  // set shipping option
  const [shippingOption, setShippingOption] = useState("");
  // Selected Wic Product
  const [selectedWic, setSelectedWic] = useState([]);
  const [isWicCart, setIsWicCart] = useState(false);
  const [isRestCart, setIsRestCart] = useState(true);
  const [items, setItems] = useState(initialItems);

  // Addresses
  const [address, setAddress] = useState({
    defAddress: { _id: "", user_id: "" },
    wicAddress: { _id: "", user_id: "" },
  });
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
  const [singleAddress, setSingleAddress] = useState(null);

  const [selected, setSelected] = useState([]);
  const [calculatePrice, setCalculatePrice] = useState(false);
  const [confirmActions, setConfirmActions] = useState(false);
  const [selectSingleShipment, setSelectSingleShipment] = useState({
    id: "",
    rate: "",
    shipment_id: "",
  });
  // Shippment Rates
  const [isServicesConfirmed, setIsServicesConfirmed] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [serviceConfirmedError, setServiceConfirmedError] = useState(false);
  const [singleShipmentRates, setSingleShipmentRates] = useState([]);
  const [singleShipmentRatesError, setSingleShipmentRatesError] = useState(
    false
  );
  const [selectedShipmentRates, setSelectedShipmentRates] = useState([]);
  const [multiShipmentRatesError, setMultiShipmentRatesError] = useState(false);
  const [toggleDeliveryServices, setToggleDeliveryServices] = useState(false);
  const [resetDelivery, setResetDelivery] = useState(false);
  const [loadingSingleShipmentRates, setLoadingSingleShipmentRates] = useState(
    false
  );
  const [confirmSplitPayment, setConfirmSplitPayment] = useState(false);
  const [isSplitPayment, setIsSplitPayment] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState({
    multiPayment: false,
  });
  const [splitedDeliveryPrice, setSplitedDeliveryPrice] = useState(null);
  const [splitedProducts, setSplitedProducts] = useState([]);
  const [deliveryPriceFree, setDeliveryPriceFree] = useState(false);
  const [allowPaymentStep, setAllowPaymentStep] = useState(false);

  // GET GEO LOCATIONS (ZIP CODES)
  const [geoLocations, setGeoLocations] = useState([]);

  // GEO LOCATION ERROR
  const [geoLocationError, setGeoLocationError] = useState(false);
  const [geoLocationErrorWIC, setGeoLocationErrorWIC] = useState(false);
  const [zipArrayValidation, setZipArrayValidation] = useState([]);

  // show payment method
  const [showPayment, setShowPayment] = useState(false);
  // Read payment
  const [readPayment, setReadPayment] = useState([]);

  const [readPaymentRefetch, setReadPaymentRefetch] = useState(false);
  const [readPaymentLoading, setReadPaymentLoading] = useState(false);
  // Create payment
  const [toggleCreatePayment, setToggleCreatePayment] = useState(false);
  const [checkoutLoggedOutLoading, setCheckoutLoggedOutLoading] = useState(
    false
  );
  // Update Payment
  const [updatePaymentId, setUpdatePaymentId] = useState({ _id: "" });
  const [updatePayment, setUpdatePayment] = useState({});
  const [updatePaymentLoading, setUpdatePaymentLoading] = useState(false);
  const [toggleUpdatePayment, setToggleUpdatePayment] = useState(false);
  // Delete Payment
  const [deletePaymentId, setDeletePaymentId] = useState({ _id: "" });
  const [deletePaymentLoading, setDeletePaymentLoading] = useState(false);
  const [deletePaymentFlag, setDeletePaymentFlag] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);

  const [correctLeftToPay, setCorrectLeftToPay] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState({});

  // Search coupon
  const [couponCheck, setCouponCheck] = useState("");
  // Get coupon
  const [coupon, setCoupon] = useState({});
  // COUPON DISABLE
  const [couponDisabled, setCouponDisabled] = useState(false);
  const [removeCoupon, setRemoveCoupon] = useState(false);
  const [couponMessage, setCouponMessage] = useState(false);

  const [paymentErrorNoFound, setPaymentErrorNoFound] = useState(false);

  useEffect(() => {
    if (!customerId) {
      navigate(
        `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`
      );
    }

    if (cart && cart.length < 1) {
      navigate(
        `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}`
      );
    }
  }, [customerId]);

  useEffect(() => {
    setAddress({
      defAddress: { _id: "", user_id: "" },
      wicAddress: { _id: "", user_id: "" },
    });
  }, [refetchCard]);

  const getGeoLocations = useCallback(async () => {
    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.allowedZipCodes}`)
        .then((res) => {
          if (res.status === 200) {
            const zipCodes = res.data.data.map((item) => item.zip_code);
            setGeoLocations(zipCodes);
          }
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: "Failed to fetch geo locations.",
            placement: "bottomLeft",
          });
        });
    } catch (error) {
      notification.error({
        message: "Failed to fetch geo locations",
        placement: "bottomLeft",
      });
      console.error(error);
    }
  }, []);

  const getPaymentCardById = useCallback(
    async ({ id }) => {
      if (user && Object.keys(user).length > 0) {
        const token = user.token;

        setUpdatePaymentLoading(true);

        await axios
          .get(
            `${SERVER_URL}/dashboard/${AXIOS_API_CALL.getSinglePaymentMethod}/${id}/${customerId}`,
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              const {
                Exp,
                MaskedCardNumber,
                Name,
                Street,
                Zip,
              } = res.data.data;

              const payload = {
                cardNumber: MaskedCardNumber,
                cardName: Name,
                cardExpired: Exp,
                cardStreet: Street,
                cardZip: Zip,
                cardCvv: "",
              };

              setUpdatePayment(payload);
              setUpdatePaymentLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setUpdatePaymentLoading(true);
          });
      }
    },
    [user]
  );

  const getPaymentCard = useCallback(async () => {
    const token = user.token;

    setReadPaymentLoading(true);
    await axios
      .post(
        `${SERVER_URL}/${AXIOS_API_CALL.listPaymantMethods}`,
        {
          Filters: {
            IsDeleted: false,
            CustomerId: customerId,
          },
        },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setReadPayment(res.data.response.PaymentMethods);
          setReadPaymentLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setReadPaymentLoading(false);
      });
  }, [customerId]);

  const handleChangeCoupon = (event) => {
    const { name, value } = event.target;
    if (name === "coupon" && !value.includes(" ")) {
      setCouponCheck(value);
    } else {
      return;
    }
  };

  const handleBlurCoupon = (event) => {
    const { value } = event.target;

    if (!value) {
      setCoupon({});
      setRemoveCoupon(false);
      setCouponMessage(false);
    }
  };

  const handleRemoveCoupon = () => {
    setRemoveCoupon(false);
    setCoupon({});
    setCouponCheck("");
    setCouponDisabled(false);
  };

  const checkCoupon = async () => {
    const payload = couponCheck;
    if (payload) {
      try {
        const response = await axios.get(
          `${SERVER_URL}/${AXIOS_API_CALL.validateCoupon}/${payload}`
        );
        if (response.status === 200) {
          if (+total?.totalSalePrice <= 0 && response.data.type === "product") {
            notification.info({
              message: "Cannot apply coupon on $0 product price.",
              placement: "bottomLeft",
            });
            setCouponMessage(false);
            setCoupon({});
            return;
          }
          if (
            totalAmountValue.delivery === "benjaminsFree" &&
            response.data.type === "shipping"
          ) {
            notification.info({
              message:
                "Cannot apply coupon for shipment price on Free Delivery.",
              placement: "bottomLeft",
            });
            setCouponMessage(false);
            setCoupon({});
            return;
          }
          if (isSplitShipping) {
            if (
              splitedDeliveryPrice === null &&
              response.data.type === "shipping"
            ) {
              notification.info({
                message:
                  "Cannot apply coupon for shipment price on Free Delivery.",
                placement: "bottomLeft",
              });
              setCouponMessage(false);
              setCoupon({});
              return;
            }
          }
          if (selectedWic && selectedWic.length > 0) {
            if (
              total.totalDeliveryPrice > +BENJAMINS_DELIVERY_PRICE_FREE &&
              response.data.type === "shipping"
            ) {
              notification.info({
                message:
                  "Cannot apply coupon for shipment price on Free Delivery.",
                placement: "bottomLeft",
              });
              setCouponMessage(false);
              setCoupon({});
              return;
            }
          }
          if (response.data.valid === true) {
            setRemoveCoupon(true);
            setCouponDisabled(true);
            setCouponMessage(false);
          } else {
            setRemoveCoupon(false);
            setCouponDisabled(false);
            setCouponMessage(true);
          }
          setCoupon(response.data);
        }
      } catch (err) {
        console.error(err);
        setRemoveCoupon(false);
        notification.error({
          message: "Failed to check coupon.",
          placement: "bottomLeft",
        });
      }
    } else {
      setCoupon({});
      setRemoveCoupon(false);
      notification.info({
        message: "Enter a coupon name first.",
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    getPaymentCard();
  }, [getPaymentCard, readPaymentRefetch]);

  useEffect(() => {
    getGeoLocations();

    return () => {};
  }, []);

  useEffect(() => {
    if (updatePaymentId && updatePaymentId._id !== "") {
      getPaymentCardById({
        id: updatePaymentId._id,
      });
    }
  }, [getPaymentCardById, updatePaymentId._id]);

  useEffect(() => {
    if (isSplitShipping) {
      const findAddress = readAddress.find((item) => item.isDefault);
      setAddress((prev) => {
        return {
          ...prev,
          defAddress: {
            _id: findAddress._id,
            user_id: findAddress.user_id,
          },
          wicAddress: {
            _id: findAddress._id,
            user_id: findAddress.user_id,
          },
        };
      });
    } else {
      setAddress({
        defAddress: { _id: "", user_id: "" },
        wicAddress: { _id: "", user_id: "" },
      });
    }
  }, [isSplitShipping]);

  useEffect(() => {
    const isCheckWic = cartProducts.wic && cartProducts.wic.length > 0;
    const isCheckRest = cartProducts.rest && cartProducts.rest.length > 0;

    if (isCheckWic || isCheckRest) {
      const payload = {
        wic: cartProducts.wic,
        rest: cartProducts.rest,
      };

      setItems((prev) => {
        return {
          ...prev,
          ...payload,
        };
      });
    }
  }, [cartProducts, isSplitShipping, window.location.href]);

  const renderBenjaminsDelivery = useCallback(() => {
    const data = {
      id: BENJAMINS_DELIVERY.id,
      carrier: BENJAMINS_DELIVERY.carrier,
      service: BENJAMINS_DELIVERY.service,
      est_delivery_days: BENJAMINS_DELIVERY.est_delivery_days,
      rate: BENJAMINS_DELIVERY.rate,
      shipment_id: BENJAMINS_DELIVERY.shipment_id,
    };

    return (
      <Fragment>
        {data.service}, {data.carrier}, {data.est_delivery_days}, ${data.rate}
      </Fragment>
    );
  }, [address, BENJAMINS_DELIVERY]);

  const onToggleAction = ({ action }) => {
    switch (action.type) {
      case "action-update":
        setToggleUpdatePayment((p) => !p);
        setUpdatePaymentId({
          _id: action.payload.PaymentMethodId,
        });
        break;
      case "action-delete":
        setToggleDelete((p) => !p);
        setDeletePaymentId({
          _id: action.payload.PaymentMethodId,
        });
        break;
      default:
        console.warn("Default of onToggleAction function!");
    }
  };

  const getSingleShippingRates = useCallback(async (products, address) => {
    const token = user.token;

    const body = {
      customer_id: address.user_id,
      addresses: [
        {
          address_id: address._id,
          products: products,
        },
      ],
    };

    setLoadingSingleShipmentRates(true);

    await axios
      .post(
        `${SERVER_URL}/${AXIOS_API_CALL.getShipmentRates}`,
        { ...body },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const singleShipmentRates = res.data?.rates[0]?.rate?.rates;
          setLoadingSingleShipmentRates(false);

          if (singleShipmentRates && singleShipmentRates.length > 0) {
            setSingleShipmentRatesError(false);
            setSingleShipmentRates([
              BENJAMINS_DELIVERY,
              ...singleShipmentRates,
            ]);
          } else {
            setSingleShipmentRatesError(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingSingleShipmentRates(false);
      });
  }, []);

  const currencyFormatterPrice = (amount) => {
    if (!amount) {
      return;
    }

    const numberFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return numberFormatter.format(amount);
  };

  const total = useMemo(() => {
    const isRestLength = items && items.rest.length > 0;

    if (isRestLength) {
      const rest = items.rest;
      const data = getTotalPrice(rest);

      return {
        totalDeliveryPrice:
          data && data?.totalDeliveryPrice ? data?.totalDeliveryPrice : 0,
        totalRegularPrice:
          data && data?.totalRegularPrice ? data?.totalRegularPrice : 0,
        totalSalePrice: data && data?.totalSalePrice ? data?.totalSalePrice : 0,
      };
    }

    return {
      totalDeliveryPrice: 0,
      totalRegularPrice: 0,
      totalSalePrice: 0,
    };
  }, [items, items.wic, items.rest, calculatePrice]);

  const totalProducts = useMemo(() => {
    const isWicLength = items && items.wic.length > 0;
    const isRestLength = items && items.rest.length > 0;

    if (isWicLength || isRestLength) {
      const wic = items.wic;
      const rest = items.rest;
      const data = getTotalShipment(wic, rest);

      const products = data?.products.map((product) => {
        return {
          product_id: product._id,
          quantity: product.currentQty,
        };
      });

      return products;
    }

    return [];
  }, [items, items.wic, items.rest, calculatePrice, resetDelivery]);

  const products = useMemo(() => {
    const isWicLength = items && items.wic.length > 0;
    const isRestLength = items && items.rest.length > 0;

    if (isWicLength || isRestLength) {
      const wic = items.wic;
      const rest = items.rest;
      const getProductsData = getProducts(wic, rest, address, isSplitShipping);

      return {
        data: getProductsData.productsReview,
        addresses: getProductsData.uniqueAddresses,
      };
    }

    return {};
  }, [user, items, address, isSplitShipping]);

  const totalDelivery = useMemo(() => {
    let totalRate = 0;

    for (const select of selected) {
      const key = Object.keys(select)[0];
      const data = select[key];
      totalRate += parseFloat(data.rate);
    }

    return totalRate;
  }, [selected]);

  const totalAmountValue = useMemo(() => {
    const price =
      coupon.type === "product"
        ? +total?.totalSalePrice -
          +total?.totalSalePrice * (coupon.discount_percentage / 100)
        : +total?.totalSalePrice;
    const rate =
      coupon.type === "shipping"
        ? +selectSingleShipment?.rate -
          +selectSingleShipment?.rate * (coupon.discount_percentage / 100)
        : +selectSingleShipment?.rate;

    if (
      selectSingleShipment.id !== "" &&
      selectSingleShipment.id === BENJAMINS_DELIVERY_ID
    ) {
      if (price >= +BENJAMINS_DELIVERY_PRICE_FREE) {
        return {
          value: price,
          delivery: "benjaminsFree",
        };
      }

      const totalValue = getTotalValue(price, rate);

      return {
        value: totalValue,
        delivery: "benjaminsCost",
      };
    }

    const totalValue = getTotalValue(price, rate);

    if (total) {
      return {
        value: totalValue,
        delivery: "easypost",
      };
    }

    return null;
  }, [
    isSplitShipping,
    total,
    BENJAMINS_DELIVERY_PRICE_FREE,
    BENJAMINS_DELIVERY_PRICE,
    getTotalValue,
    coupon,
  ]);

  const totalMultiAmountValue = useMemo(() => {
    if (total || selectSingleShipment) {
      const price =
        coupon.type === "product"
          ? +total?.totalSalePrice -
            +total?.totalSalePrice * (coupon.discount_percentage / 100)
          : +total?.totalSalePrice;
      const deliveryPrice =
        coupon.type === "shipping"
          ? splitedDeliveryPrice -
            splitedDeliveryPrice * (coupon.discount_percentage / 100)
          : splitedDeliveryPrice;
      const value = price + deliveryPrice;

      return value;
    }
  }, [
    isSplitShipping,
    splitedDeliveryPrice,
    total,
    totalDelivery,
    getTotalValue,
    coupon,
  ]);

  const totalLeftAmount = useMemo(() => {
    if (isSplitShipping) {
      const amountValue =
        coupon.type === "shipping"
          ? totalAmountValue.value +
            (splitedDeliveryPrice -
              splitedDeliveryPrice * (coupon.discount_percentage / 100))
          : totalAmountValue.value + splitedDeliveryPrice;

      const payments = paymentMethod.multiPaymentAmount;
      const sumTotalPayments =
        payments && payments.length > 0
          ? payments.reduce((sum, item) => sum + +item.value, 0)
          : 0;
      const totalLeftToPayValue = getLeftTotalPay(
        amountValue,
        sumTotalPayments
      );

      return +totalLeftToPayValue.toFixed(2);
    }

    if (selectedWic && selectedWic.length > 0) {
      const payments = paymentMethod.multiPaymentAmount;
      const sumTotalPayments =
        payments && payments.length > 0
          ? payments.reduce((sum, item) => sum + +item.value, 0)
          : 0;
      const discountPrice =
        coupon.type === "product"
          ? total?.totalSalePrice -
            total?.totalSalePrice * (coupon.discount_percentage / 100)
          : total?.totalSalePrice || 0;
      const totalDeliveryPrice = total?.totalDeliveryPrice || 0;
      const deliveryPrice =
        coupon.type === "shipping"
          ? +BENJAMINS_DELIVERY_PRICE -
            +BENJAMINS_DELIVERY_PRICE * (coupon.discount_percentage / 100)
          : +BENJAMINS_DELIVERY_PRICE;
      const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;

      let priceValue = 0;

      if (totalDeliveryPrice >= freeDelivery) {
        priceValue += discountPrice;
      } else {
        priceValue += discountPrice + deliveryPrice;
      }

      const totalLeftToPayValue = getLeftTotalPay(priceValue, sumTotalPayments);

      return +totalLeftToPayValue.toFixed(2);
    }

    const amountValue = +totalAmountValue.value;
    const payments = paymentMethod.multiPaymentAmount;
    const sumTotalPayments =
      payments && payments.length > 0
        ? payments.reduce((sum, item) => sum + +item.value, 0)
        : 0;
    const totalLeftToPayValue = getLeftTotalPay(amountValue, sumTotalPayments);

    return +totalLeftToPayValue.toFixed(2);
  }, [
    total,
    isSplitShipping,
    splitedDeliveryPrice,
    paymentMethod,
    sumPrice,
    getLeftTotalPay,
    coupon,
  ]);

  const totalAmountDeliveryValue = useMemo(() => {
    const price =
      coupon.type === "product"
        ? +total?.totalDeliveryPrice -
          +total?.totalDeliveryPrice * (coupon.discount_percentage / 100)
        : +total?.totalDeliveryPrice;
    const rate =
      coupon.type === "shipping"
        ? +selectSingleShipment?.rate -
          +selectSingleShipment?.rate * (coupon.discount_percentage / 100)
        : +selectSingleShipment?.rate;

    const totalValue = getTotalValue(price, rate);

    if (total) {
      return totalValue;
    }
  }, [isSplitShipping, total, getTotalValue, coupon]);

  useEffect(() => {
    if (isSplitPayment) {
      if (totalLeftAmount < 0) {
        setCorrectLeftToPay('error');
      } else if (totalLeftAmount === 0) {
        setCorrectLeftToPay('success');
      } else {
        setCorrectLeftToPay(null);
      }
    }
  }, [isSplitPayment, totalLeftAmount]);

  const renderPrice = () => {
    const discountPrice = total?.totalSalePrice || 0;

    let priceWithCoupon;
    if (coupon.type === "product") {
      priceWithCoupon =
        discountPrice - discountPrice * (coupon.discount_percentage / 100);

      return (
        <Fragment>
          <span>
            {priceWithCoupon
              ? currencyFormatterPrice(+priceWithCoupon)
              : "$0.00"}
          </span>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <span>
          {discountPrice ? currencyFormatterPrice(+discountPrice) : "$0.00"}
        </span>
      </Fragment>
    );
  };

  const renderDeliveryPrice = () => {
    const discountPrice = total?.totalDeliveryPrice || 0;
    const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;
    const deliveryPrice = +BENJAMINS_DELIVERY_PRICE;

    if (discountPrice >= freeDelivery) {
      return FREE_DELIVERY;
    }
    if (coupon.type === "shipping") {
      let priceWithCoupon =
        deliveryPrice - deliveryPrice * (coupon.discount_percentage / 100);
      if (priceWithCoupon === 0) {
        return FREE_DELIVERY;
      }
      return currencyFormatterPrice(+priceWithCoupon);
    }

    return currencyFormatterPrice(+deliveryPrice);
  };

  const renderTotalPrice = () => {
    const discountPrice =
      coupon.type === "product"
        ? total?.totalSalePrice -
          total?.totalSalePrice * (coupon.discount_percentage / 100)
        : total?.totalSalePrice || 0;
    const totalDeliveryPrice = total?.totalDeliveryPrice || 0;
    const deliveryPrice =
      coupon.type === "shipping"
        ? +BENJAMINS_DELIVERY_PRICE -
          +BENJAMINS_DELIVERY_PRICE * (coupon.discount_percentage / 100)
        : +BENJAMINS_DELIVERY_PRICE;
    const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;

    if (totalDeliveryPrice >= freeDelivery) {
      return discountPrice ? currencyFormatterPrice(+discountPrice) : "$0.00";
    }

    let totalValue = discountPrice + deliveryPrice;
    return totalValue ? currencyFormatterPrice(+totalValue) : "$0.00";
  };

  const getRenderShipmentPrice = ({ totalAmount, delivery, shipment }) => {
    if (!shipment.rate) {
      return "~";
    }

    if (shipment.id !== "" && shipment.id === BENJAMINS_DELIVERY_ID) {
      const total = +totalAmount;

      if (delivery === "benjaminsFree") {
        return FREE_DELIVERY;
      }
      if (coupon.type === "shipping") {
        let priceWithCoupon =
          shipment.rate - shipment.rate * (coupon.discount_percentage / 100);
        if (priceWithCoupon === 0) {
          return FREE_DELIVERY;
        }
        return currencyFormatterPrice(+priceWithCoupon);
      }

      if (delivery === "benjaminsCost") {
        const benjaminsDelivery = +BENJAMINS_DELIVERY_PRICE;

        return currencyFormatterPrice(benjaminsDelivery);
      }

      if (total >= BENJAMINS_DELIVERY_PRICE_FREE) {
        return FREE_DELIVERY;
      }

      return currencyFormatterPrice(+shipment.rate);
    }
    if (coupon.type === "shipping") {
      let priceWithCoupon =
        shipment.rate - shipment.rate * (coupon.discount_percentage / 100);
      if (priceWithCoupon === 0) {
        return FREE_DELIVERY;
      }
      return currencyFormatterPrice(+priceWithCoupon);
    }

    return currencyFormatterPrice(+shipment.rate);
  };

  const getRenderTotalPrice = ({ totalAmount }) => {
    return currencyFormatterPrice(+totalAmount);
  };

  const getRenderShipment = ({ totalAmount }) => {
    if (totalAmount === 0) {
      return "~";
    }

    if (totalAmount === null) {
      return FREE_DELIVERY;
    }

    if (coupon.type === "shipping") {
      let priceWithCoupon =
        totalAmount - totalAmount * (coupon.discount_percentage / 100);
      if (priceWithCoupon === 0) {
        return FREE_DELIVERY;
      }
      return currencyFormatterPrice(+priceWithCoupon);
    }

    return currencyFormatterPrice(+totalAmount);
  };

  const allProducts = (items) => {
    if (!items) return;

    let allInWic = [];

    for (const entry of items) {
      const products = entry.products || [];

      for (const product of products) {
        const wicSelected = product.WIC_SELECTED;
        allInWic.push(!!wicSelected);
      }
    }

    return allInWic;
  };

  useEffect(() => {
    const checkAllWic = allProducts(products.data);

    if (checkAllWic && checkAllWic.length > 0) {
      const isEveryCheck = checkAllWic.every((key) => key);
      setPaymentErrorNoFound(isEveryCheck);
    }
  }, [products]);

  const onConfirmSplitAddress = () => {
    setToggleDeliveryServices(true);
    setIsAddressConfirmed(true);
  };

  const onConfirmSplitService = async () => {
    if (singleShipmentRatesError && multiShipmentRatesError) {
      return;
    }

    const productsData = products.data.filter(
      (p) => !p.products.some((k) => k.WIC_SELECTED)
    );
    const wicProductsData = products.data.filter((p) =>
      p.products.some((k) => k.WIC_SELECTED)
    );

    if (productsData && productsData.length < 1) {
      if (wicProductsData && wicProductsData.length > 0) {
        const findAddressWic = readAddress.find(
          (p) => p._id === address.wicAddress._id
        );
        if (!geoLocations.includes(findAddressWic.zip_code)) {
          setGeoLocationError(true);
          setGeoLocationErrorWIC(true);
          notification.info({
            message: `We can't deliver to ${findAddressWic.zip_code} zip code address.`,
            description: "Please check BenjaminsDelivery addresses",
            placement: "bottomLeft",
          });
          return;
        }
      }
      setPaymentMethodError((prev) => {
        return {
          ...prev,
          multiPayment: false,
        };
      });
      setIsServicesConfirmed(true);
      setConfirmSplitPayment(true);
      setAllowPaymentStep(true);

      const mergeProducts = [...productsData, ...wicProductsData];
      const allProducts = getAddressWithProducts(
        mergeProducts,
        selectedShipmentRates
      );

      setSplitedProducts(allProducts);

      const allProductsDelivery = getAddressWithProductsPrice(
        mergeProducts,
        selectedShipmentRates,
        BENJAMINS_DELIVERY_PRICE,
        BENJAMINS_DELIVERY_PRICE_FREE
      );

      if (allProductsDelivery === 0) {
        setDeliveryPriceFree(true);
      } else if (allProductsDelivery > 0) {
        setDeliveryPriceFree(false);
      }

      const splitedDeliveryPrice =
        allProductsDelivery !== 0 ? allProductsDelivery : null;
      setSplitedDeliveryPrice(splitedDeliveryPrice);

      return;
    }

    if (selectedShipmentRates && selectedShipmentRates.length < 1) {
      setPaymentMethodError((prev) => {
        return {
          ...prev,
          multiPayment: true,
        };
      });

      setConfirmSplitPayment(false);

      // Notification
      notification.error({
        message: "Please select a Shipment Service for each address!",
        placement: "bottomLeft",
      });

      return;
    }

    const ids = selectedShipmentRates.map((obj) => Object.keys(obj)[0]);

    if (isSplitShipping) {
      if (selectedWic && selectedWic.length > 0) {
        const findAddressWic = readAddress.find(
          (p) => p._id === address.wicAddress._id
        );

        if (!geoLocations.includes(findAddressWic.zip_code)) {
          setGeoLocationError(true);
          setGeoLocationErrorWIC(true);
          notification.info({
            message: `We can't deliver to ${findAddressWic.zip_code} zip code address.`,
            description: "Please check BenjaminsDelivery addresses",
            placement: "bottomLeft",
          });
          return;
        }
      }
      const validation = products.data.map((item) => {
        let items = [];
        const findSelected = selectedShipmentRates.filter((i) => {
          const itemId = Object.values(i)[0].id;
          return itemId === BENJAMINS_DELIVERY.id;
        });
        if (findSelected) {
          const selectedKeys = findSelected.reduce((keys, obj) => {
            const objKeys = Object.keys(obj)[0];
            keys.push(objKeys);
            return keys;
          }, []);
          const findAddress = readAddress.find((p) => p._id === item._id);
          if (selectedKeys.includes(item._id)) {
            if (!geoLocations.includes(findAddress.zip_code)) {
              items.push(findAddress.zip_code);
              notification.info({
                message: `We can't deliver to ${findAddress.zip_code} zip code address.`,
                description: "Please check BenjaminsDelivery addresses",
                placement: "bottomLeft",
              });
            }
          }
        }
        return items;
      });
      const validLength = validation.filter((item) => item.length > 0);
      const test = [];
      validLength.forEach((item) => test.push(item[0]));
      setZipArrayValidation(test);
      if (validation && validLength.length > 0) {
        setGeoLocationError(true);
        return;
      }
    }

    if (productsData.length !== ids.length) {
      setMultiShipmentRatesError(true);

      // Notification
      notification.error({
        message: "Please select all services!",
        placement: "bottomLeft",
      });

      setPaymentMethodError((prev) => {
        return {
          ...prev,
          multiPayment: true,
        };
      });

      setConfirmSplitPayment(false);

      return;
    }

    setPaymentMethodError((prev) => {
      return {
        ...prev,
        multiPayment: false,
      };
    });

    setGeoLocationErrorWIC(false);
    setGeoLocationError(false);
    setIsServicesConfirmed(true);
    setConfirmSplitPayment(true);
    setAllowPaymentStep(true);

    const mergeProducts = [...productsData, ...wicProductsData];
    const allProducts = getAddressWithProducts(
      mergeProducts,
      selectedShipmentRates
    );

    setSplitedProducts(allProducts);

    const allProductsDelivery = getAddressWithProductsPrice(
      mergeProducts,
      selectedShipmentRates,
      BENJAMINS_DELIVERY_PRICE,
      BENJAMINS_DELIVERY_PRICE_FREE
    );

    if (allProductsDelivery === 0) {
      setDeliveryPriceFree(true);
    } else if (allProductsDelivery > 0) {
      setDeliveryPriceFree(false);
    }

    const splitedDeliveryPrice =
      allProductsDelivery !== 0 ? allProductsDelivery : null;

    setSplitedDeliveryPrice(splitedDeliveryPrice);
  };

  const onDisproveSplitAddress = async () => {
    setIsAddressConfirmed(false);
    setToggleDeliveryServices(false);
    setIsServicesConfirmed(false);
    setSelectedShipmentRates([]);
    setSelected([]);
    setConfirmSplitPayment(false);
    setIsSplitPayment(false);
    setZipArrayValidation([]);
    setGeoLocationErrorWIC(false);
    setGeoLocationError(false);
    setAllowPaymentStep(false);
    setCorrectLeftToPay(null)

    setMultiShipmentRatesError(false);
    setSingleShipmentRatesError(false);

    setDeliveryPriceFree(false);
    setSplitedDeliveryPrice(null);

    setPaymentMethodError((prev) => {
      return {
        ...prev,
        multiPayment: false,
      };
    });
  };

  const onConfirmServices = async () => {
    const findCurrentAddress = readAddress.find(
      (p) => p._id === address.defAddress._id
    );
    const id = selectSingleShipment.id;

    if (id === "" || id === "none") {
      setServiceConfirmedError(true);

      // Notification
      notification.error({
        message: "Shipping service required!",
        placement: "bottomLeft",
      });

      return;
    }

    if (id === BENJAMINS_DELIVERY.id) {
      if (!geoLocations.includes(findCurrentAddress.zip_code)) {
        setGeoLocationError(true);

        notification.info({
          message: "We cannot deliver to your address!",
          description:
            "We are able to deliver only for certain zip addresses if you want to use BenjaminsDelivery.",
          placement: "bottomLeft",
        });
        return;
      }
    }

    setGeoLocationError(false);
    setServiceConfirmedError(false);
    setIsAddressConfirmed(true);
    setIsServicesConfirmed(true);
    setAllowPaymentStep(true);
  };

  const onConfirmAddresses = async () => {
    if (address && address.defAddress._id === "") {
      notification.error({
        message: "Please choose address for delivery.",
        placement: "bottomLeft",
      });
      return;
    }
    const findCurrentAddress = readAddress.find(
      (p) => p._id === address.defAddress._id
    );

    if (selectedWic && selectedWic.length > 0) {
      if (!geoLocations.includes(findCurrentAddress.zip_code)) {
        setGeoLocationError(true);

        notification.info({
          message: "We cannot deliver to your address!",
          description:
            "We are able to deliver only for certain zip addresses if you want to use BenjaminsDelivery.",
          placement: "bottomLeft",
        });
        return;
      }
    }

    if (!isSplitShipping && selectedWic && selectedWic.length > 0) {
      setAllowPaymentStep(true);
    } else {
      setAllowPaymentStep(false);
    }

    setGeoLocationError(false);
    setIsAddressConfirmed(true);
    setIsServicesConfirmed(false);
    setServiceConfirmedError(false);
    setConfirmSplitPayment(true);

    if (totalProducts && totalProducts.length > 0) {
      getSingleShippingRates(totalProducts, address.defAddress);
    } else {
      console.error("no product included");
    }
  };

  const onDisproveAddresses = async () => {
    setIsAddressConfirmed(false);
    setIsServicesConfirmed(false);
    setServiceConfirmedError(false);
    setIsPaymentConfirmed(false);
    setConfirmSplitPayment(false);
    setIsSplitPayment(false);
    setGeoLocationError(false);
    setSelectSingleShipment({
      id: "",
      rate: "",
      shipment_id: "",
    });
    setSingleShipmentRates([]);
    setSingleShipmentRatesError(false);
    setAllowPaymentStep(false);
    setCorrectLeftToPay(null)
  };

  const onSelected = ({ id, service }) => {
    setSelectedShipmentRates((prev) => {
      if (!service) {
        const filteredPrev = prev.filter((p) => Object.keys(p)[0] !== id);
        return [...filteredPrev];
      }

      let findByKey = prev.find((p) => Object.keys(p).includes(id));
      let newPayload = {
        [id]: service,
      };

      if (findByKey) {
        let findOld = prev.filter((p) => !Object.keys(p).includes(id));
        return [...findOld, newPayload];
      }

      return [...prev, newPayload];
    });
  };

  const getAddressStateName = (state) => {
    const stateResult = stateUS.find((item) => item.abbreviation === state);
    return stateResult.name;
  };

  const checkDefaultAddress = (isDefault) => {
    if (isDefault) {
      return "(default)";
    } else {
      return "";
    }
  };

  const ratePrice = useCallback(
    ({ rate }) => {
      if (rate.id === BENJAMINS_DELIVERY_ID) {
        if (totalAmountValue.delivery === "benjaminsFree") {
          return FREE_DELIVERY;
        }

        const deliveryPrice = +BENJAMINS_DELIVERY_PRICE;
        return currencyFormatterPrice(deliveryPrice);
      }

      return `$${rate.rate}`;
    },
    [totalAmountValue]
  );

  const onChangeToggleSwitch = ({ name, value }) => {
    switch (name) {
      case "use_split_shipment":
        setIsSplitShipping(value);
        setSelectSingleShipment({
          id: "",
          rate: "",
          shipment_id: "",
        });
        setIsServicesConfirmed(false);
        setServiceConfirmedError(false);
        setSingleShipmentRates([]);
        setIsAddressConfirmed(false);
        break;
      case "wic_purchase":
        setIsWicCart(value);
        break;
      case "pay_with_credit_/_debit_card":
        setIsRestCart(value);
        break;
      default:
        console.error("Default of onChangeToggleSwitch function!");
    }
  };

  const onChangeToggleButton = ({ action }) => {
    switch (action.type) {
      case "createCreditCard":
        setToggleCreatePayment((p) => !p);
        break;
      default:
        console.error("Default of onChangeToggleButton function!");
    }
  };

  const isNumber = (val) => {
    const numberRegex = /^[0-9]+$/;

    if (typeof val !== "number" || !numberRegex.test(val)) {
      notification.warn({
        message: "This value is not a number! Please enter the number.",
        placement: "bottomLeft",
      });

      return false;
    }

    return true;
  };

  const [uploadWicImage, setUploadWicImage] = useState({
    blob: null,
    type: null,
    name: null,
  });
  const [hideUploadWicImage, setHideUploadWicImage] = useState(false);
  const handleImageSelect = ({ blob, name, type }) => {
    setUploadWicImage({ blob, name, type });
  };

  const handleOnRemovePayment = async (event) => {
    event.preventDefault();

    if (readPayment && readPayment.length > 0) {
      setDeletePaymentLoading(true);

      await axios
        .delete(
          `${SERVER_URL}/dashboard/customers/${AXIOS_API_CALL.removePaymentMethod}/${customerId}/${deletePaymentId._id}`,
          {
            withCredentials: false,
            headers: {
              department: PERMISSIONS.grocery,
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setDeletePaymentLoading(false);
            setReadPaymentRefetch((p) => !p);
            setToggleDelete(false);
            setDeletePaymentFlag(true);
            setRefetchCard((prev) => !prev);
            
            // notification
            notification.success({
              message: "User Credit / Debit card deleted successfully!",
              placement: "bottomLeft",
            });

            // Reset delete payment flag
            setTimeout(() => {
              setDeletePaymentFlag(false);
            }, 300);

            // reset after deleting the card
            setTimeout(() => {
              window.location.reload()
            },1000)
          }
        })
        .catch((err) => {
          console.error(err);
          setDeletePaymentLoading(false);
          setDeletePaymentFlag(false);

          // notification
          notification.error({
            message: "Default payment method cannot be removed!",
            placement: "bottomLeft",
          });
        });
    }
  };

  const onClick = useCallback(
    ({ state, action }) => {
      setCalculatePrice((p) => !p);
      setConfirmActions((p) => !p);
      const { value, checked } = action.event.target;
      switch (action.type) {
        case "selectedSimple":
          setSelectedWic((prev) => {
            if (checked) {
              return [...prev, value];
            }
            return [...prev.filter((p) => p !== value)];
          });
          setItems((prev) => {
            let updateRest = prev.rest.find((p) => p._id === state._id);
            let updateWic = prev.wic.find((p) => p._id === state._id);
            if (checked) {
              updateRest.isChecked = false;
              updateRest.isSelected = false;
              updateWic.isChecked = true;
              updateWic.isSelected = true;
              return {
                ...prev,
              };
            }
            updateRest.isChecked = true;
            updateRest.isSelected = true;
            updateWic.isChecked = false;
            updateWic.isSelected = false;
            return {
              ...prev,
            };
          });
          break;
        case "selectedVariable":
          setSelectedWic((prev) => {
            if (checked) {
              return [...prev, value];
            }
            return [...prev.filter((p) => p !== value)];
          });
          setItems((prev) => {
            let findRest = prev.rest.find((p) => p._id === state._id);
            let findWic = prev.wic.find((p) => p._id === state._id);
            let findRestVariation = findRest.variations.find(
              (p) => p.variationId === action.payload.variationId
            );
            let findWicVariation = findWic.variations.find(
              (p) => p.variationId === action.payload.variationId
            );
            if (checked) {
              findRestVariation.isChecked = false;
              findRestVariation.isSelected = false;
              findWicVariation.isChecked = true;
              findWicVariation.isSelected = true;
              return {
                ...prev,
              };
            }
            findRestVariation.isChecked = true;
            findRestVariation.isSelected = true;
            findWicVariation.isChecked = false;
            findWicVariation.isSelected = false;
            return {
              ...prev,
            };
          });
          break;
        case "removeRest":
          setSelectedWic((prev) => {
            return [...prev.filter((p) => p !== state._id)];
          });
          setItems((prev) => {
            let filtredRest = prev.rest.filter((p) => p._id !== state._id);
            let filtredWic = prev.wic.filter((p) => p._id !== state._id);
            return {
              ...prev,
              wic: filtredWic,
              rest: filtredRest,
            };
          });
          break;
        case "removeRestVariable":
          setSelectedWic((prev) => {
            return [...prev.filter((p) => p !== state.variationId)];
          });
          setItems((prev) => {
            let findRest = prev.rest.find((p) => p._id === action.payload._id);
            let filtredRestVariations = findRest
              ? findRest.variations.filter(
                  (p) => p.variationId !== action.payload.variationId
                )
              : [];
            let findWic = prev.wic.find((p) => p._id === action.payload._id);
            let filtredWicVariations = findWic
              ? findWic?.variations.filter(
                  (p) => p.variationId !== action.payload.variationId
                )
              : [];
            if (
              filtredRestVariations.length > 0 &&
              filtredWicVariations.length > 0
            ) {
              findRest.variations = filtredRestVariations;
              findWic.variations = filtredWicVariations;
              return {
                ...prev,
              };
            }
            if (filtredRestVariations.length > 0) {
              findRest.variations = filtredRestVariations;
              return {
                ...prev,
              };
            }
            let filteredRest = prev.rest.filter((p) => p._id !== state._id);
            let filteredWic = prev.wic.filter((p) => p._id !== state._id);
            return {
              ...prev,
              rest: filteredRest,
              wic: filteredWic,
            };
          });
          break;
        case "removeRestVariableChildren":
          setItems((prev) => {
            let prevRest = prev.rest;
            let findRest = prevRest.find(
              (p) => p._id === action.payload.product._id
            );
            let findRestVariation = findRest.variations.find(
              (p) => p.variationId === action.payload.variationId
            );
            let findRestVariationChildren = findRestVariation.children.find(
              (p) => p.splitId === action.payload._id
            );
            let filtredRestVariationChildren = findRestVariation.children.filter(
              (p) => p.splitId !== action.payload._id
            );
            state.currentQty += findRestVariationChildren.currentQty;
            state.children = filtredRestVariationChildren;
            return {
              ...prev,
            };
          });
          break;
        case "quantityRestInputUpdate":
        case "quantityWicSelectUpdate":
        case "quantityWicInputUpdate":
        case "quantityRestSelectUpdate":
          if (!isNumber(action.value)) {
            return;
          }
          if (isNumber(action.value) && action.value < 1) {
            setSelectedWic((prev) => {
              const filteredSelectedWic = prev.filter((p) => p !== state._id);
              return [...filteredSelectedWic];
            });
          }
          setItems((prev) => {
            let findWic = prev.wic.find((p) => p._id === state._id);
            if (action.value < 1) {
              let filtredRest = prev.rest.filter((p) => p._id !== state._id);
              let filtredWic = prev.wic.filter((p) => p._id !== state._id);
              return {
                ...prev,
                rest: filtredRest,
                wic: filtredWic,
              };
            }
            if (findWic) {
              findWic.currentQty = action.value;
            }
            let findRest = prev.rest.find((p) => p._id === state._id);
            if (findRest) {
              findRest.currentQty = action.value;
            }
            state.currentQty = action.value;
            return {
              ...prev,
            };
          });
          break;
        case "removeRestChildren":
          setItems((prev) => {
            let findParentChildren = state.children.find(
              (p) => p.splitId === action.payload._id
            );
            let filtredParentChildren = state.children.filter(
              (p) => p.splitId !== action.payload._id
            );
            state.currentQty += findParentChildren.currentQty;
            state.children = filtredParentChildren;
            return {
              ...prev,
            };
          });
          break;
        case "quantityRestChildrenInputUpdate":
        case "quantityRestChildrenSelectUpdate":
          if (!isNumber(action.value)) {
            return;
          }
          setItems((prev) => {
            if (action.value < 1) {
              let findParent = action.payload.parentProduct;
              let findParentChildren = findParent.children.find(
                (p) => p.splitId === state.splitId
              );
              let filtredParentChildren = findParent.children.filter(
                (p) => p.splitId !== state.splitId
              );
              findParent.currentQty += findParentChildren.currentQty;
              findParent.children = filtredParentChildren;
              return {
                ...prev,
              };
            }
            state.currentQty = action.value;
            return {
              ...prev,
            };
          });
          break;
        case "simpleRestAddressChanged":
          setItems((prev) => {
            const updateAddress = {
              user_id: state.defaultAddress.user_id,
              _id: value,
            };
            state.defaultAddress = {
              ...updateAddress,
            };
            return {
              ...prev,
            };
          });
          break;
        case "variableRestAddressChanged":
          setItems((prev) => {
            const updateAddress = {
              user_id: state.defaultAddress.user_id,
              _id: value,
            };
            state.defaultAddress = {
              ...updateAddress,
            };
            return {
              ...prev,
            };
          });
          break;
        case "wicAddressChanged":
          setAddress((prev) => {
            const findAddress = readAddress.find((p) => p._id === value);
            const address = {
              _id: findAddress._id,
              user_id: findAddress.user_id,
            };
            return {
              ...prev,
              wicAddress: address,
            };
          });
          break;
        case "simpleRestChildrenAddressChanged":
          setItems((prev) => {
            const updateAddress = {
              user_id: state.defaultAddress.user_id,
              _id: value,
            };
            state.defaultAddress = updateAddress;
            return {
              ...prev,
            };
          });
          break;
        case "variableRestChildrenAddressChanged":
          setItems((prev) => {
            const updateAddress = {
              user_id: state.defaultAddress.user_id,
              _id: value,
            };
            state.defaultAddress = updateAddress;
            return {
              ...prev,
            };
          });
          break;
        case "globalAddressChanged":
          setAddress((prev) => {
            const findAddress = readAddress.find((p) => p._id === value);
            const address = {
              _id: findAddress._id,
              user_id: findAddress.user_id,
            };
            return {
              ...prev,
              defAddress: address,
            };
          });
          break;
        case "quantityWicVariableSelectUpdate":
        case "quantityWicVariableInputUpdate":
        case "quantityRestVariableSelectUpdate":
        case "quantityRestVariableInputUpdate":
          if (!isNumber(action.value)) {
            return;
          }
          if (isNumber(action.value) && action.value < 1) {
            setSelectedWic((prev) => {
              const filteredSelectedWic = prev.filter(
                (p) => p !== state.variationId
              );
              return [...filteredSelectedWic];
            });
          }
          setItems((prev) => {
            let prevRest = prev.rest;
            let prevWic = prev.wic;
            let findRest = prevRest.find(
              (p) => p._id === action.payload.parentProduct._id
            );
            let findWic = prevWic.find(
              (p) => p._id === action.payload.parentProduct._id
            );
            if (action.value < 1) {
              let filtredRestVariation = findRest
                ? findRest.variations.filter(
                    (p) => p.variationId !== state.variationId
                  )
                : [];
              let filtredWicVariation = findWic
                ? findWic.variations.filter(
                    (p) => p.variationId !== state.variationId
                  )
                : [];
              if (
                filtredRestVariation.length < 1 ||
                filtredWicVariation.length < 1
              ) {
                let filtredRest = prevRest.filter(
                  (p) => p._id !== findRest._id
                );
                let filtredWic = prevWic.filter((p) => p._id !== findRest._id);
                return {
                  ...prev,
                  rest: filtredRest,
                  wic: filtredWic,
                };
              }
              findRest.variations = filtredRestVariation;
              findWic.variations = filtredWicVariation;
              return {
                ...prev,
              };
            }
            let findRestVariation = findRest.variations.find(
              (p) => p.variationId === state.variationId
            );
            findRestVariation.currentQty = action.value;
            if (findWic) {
              let findWicVariation = findWic.variations.find(
                (p) => p.variationId === state.variationId
              );
              findWicVariation.currentQty = action.value;
            }
            return {
              ...prev,
            };
          });
          break;
        case "createRestVariableChildren":
        case "createRestSimpleChildren":
          setItems((prev) => {
            const regularPrice = state.RegularPrice;
            let children = {
              qty: 1,
              _id: state._id,
              currentQty: 1,
              splitId: uuidv4(),
              featureImage: state.featureImage,
              RegularPrice: regularPrice,
              defaultAddress: address.defAddress,
            };
            if (state.hasOwnProperty("SalePrice")) {
              const salePrice = state.SalePrice;
              children = {
                ...children,
                SalePrice: salePrice,
              };
            }
            delete children.children;
            let currentQtyLength = state.currentQty - 1;
            let currentChildLength = state.children.length + 1;
            if (currentChildLength >= readAddress.length) {
              return prev;
            }
            if (currentQtyLength < 1) {
              return prev;
            }
            state.currentQty -= 1;
            state.children = [...state.children, children];
            return {
              ...prev,
            };
          });
          break;
        case "quantityRestVariationChildrenInputUpdate":
        case "quantityRestVariationChildrenSelectUpdate":
          if (!isNumber(action.value)) {
            return;
          }
          setItems((prev) => {
            if (action.value < 1) {
              let filteredRestVariatonChildren = action.payload.parentVariation.children.filter(
                (p) => p.splitId !== state.splitId
              );
              action.payload.parentVariation.children = filteredRestVariatonChildren;
              action.payload.parentVariation.currentQty = action.payload.parentVariation.currentQty +=
                state.currentQty;
              return {
                ...prev,
              };
            }
            state.currentQty = action.value;
            return {
              ...prev,
            };
          });
          break;
        case "selectSingleShipmentRates":
          setSelectSingleShipment((prev) => {
            if (value === "" || value === "none") {
              return {
                ...prev,
                id: "none",
              };
            }
            if (value === "benjaminsPharmacyDelivery") {
              return {
                ...prev,
              };
            }
            const findShipmentRate = singleShipmentRates.find(
              (p) => p.id === value
            );
            const payload = {
              id: findShipmentRate.id,
              rate: findShipmentRate.rate,
              shipment_id: findShipmentRate.shipment_id,
            };
            return {
              ...payload,
            };
          });
          break;
        case "selectSingleShipmentRatesLoggedOut":
          setSelectSingleShipment((prev) => {
            if (value === "" || value === "none") {
              return {
                ...prev,
                id: "none",
              };
            }
            const findShipmentRate = singleShipmentRates.find(
              (p) => p.id === value
            );
            const payload = {
              id: findShipmentRate.id,
              rate: findShipmentRate.rate,
              shipment_id: findShipmentRate.shipment_id,
            };
            return {
              ...payload,
            };
          });
          break;
        default:
          console.error("Default of onClick function!");
      }
    },
    [readAddress, isSplitShipping, singleShipmentRates]
  );

  const getLengthhWic = () => {
    return selectedWic.length;
  };
  const isLengthSelectedWic = getLengthhWic();

  const renderAddress = (id) => {
    const findAddress = readAddress.find((p) => p._id === id);
    if (findAddress) {
      const { address, city, zip_code, state, isDefault } = findAddress;

      return (
        <Fragment>
          {address}, {city}, {zip_code}, {getAddressStateName(state)}{" "}
          {checkDefaultAddress(isDefault)}
        </Fragment>
      );
    }
  };

  const handleCloseUpdate = (value) => {
    setToggleUpdatePayment(value);
    setUpdatePaymentId({ _id: "" });
    setUpdatePayment({});
    setUpdatePayment([]);
  };

  const COUPON_CLASS =
    Object.keys(coupon).length > 0
      ? coupon.valid === true
        ? ""
        : coupon.valid === false
        ? "notValid"
        : ""
      : "";

  const checkPaymentErrorMessage =
    singleShipmentRatesError && multiShipmentRatesError;

  useEffect(() => {
    setSelectSingleShipment({
      id: "",
      rate: "",
      shipment_id: "",
    });
    setIsServicesConfirmed(false);
    setServiceConfirmedError(false);
    setSingleShipmentRates([]);
    setIsAddressConfirmed(false);
    setIsSplitPayment(false);
    setCorrectLeftToPay(null);
    setToggleDeliveryServices(false);
    setSelectedShipmentRates([]);
    setSelected([]);
    setConfirmSplitPayment(false);
    setPaymentMethodError((prev) => {
      return {
        ...prev,
        multiPayment: false,
      };
    });
  }, [readPaymentRefetch]);

  useEffect(() => {
    setIsAddressConfirmed(false);
    setAllowPaymentStep(false);
    setSelectedWic([]);
    setSelectedShipmentRates([]);
    setIsAddressConfirmed(false);
    setResetDelivery((p) => !p);
    setCorrectLeftToPay(null)
  }, [isSplitShipping]);

  useEffect(() => {
    const isWicLength = items && items.wic && items.wic.length === 0;

    if (isWicLength) {
      setIsWicCart(false);
    }
  }, [items]);

  const handleShippingOption = (e) => {
    setShippingOption(e.target.value);
  };

  const isCheckTotal = () => {
    if (!isSplitShipping) {
      const discountPrice = total?.totalSalePrice || 0;
      const totalDeliveryPrice = total?.totalDeliveryPrice || 0;
      const deliveryPrice = +BENJAMINS_DELIVERY_PRICE;
      const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;

      if (totalDeliveryPrice >= freeDelivery) {
        return discountPrice === 0;
      }

      const totalValue = discountPrice + deliveryPrice;
      return totalValue === 0;
    } else {
      return totalMultiAmountValue === 0;
    }
  };

  const checkCreateOrder = (...rest) => {
    const [row, event] = rest;
    console.log({ row, payload: event.payload, errors: event.errors });
  };

  const createNewOrder = async (payload) => {
    setCheckoutLoggedOutLoading(true);
    await axios
      .post(
        `${SERVER_URL}/${AXIOS_API_CALL.createOrders}`,
        { ...payload },
        { withCredentials: false }
      )
      .then((res) => {
        if (res.status === 200) {
          setCheckoutLoggedOutLoading(false);
          // Notification
          notification.success({
            message: res.data.message,
            placement: "bottomLeft",
          });

          // Unfreeze scroll
          scrollUnfreeze();

          // Remove cart items
          setCart([]);
          window.localStorage.removeItem("dashboardCustomerId");

          // Navigate
          navigate(
            `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`
          );
        }
      })
      .catch((err) => {
        console.error("🚀 ~ file: index.js:1566 ~ createNewOrder ~ err:", err);
        setCheckoutLoggedOutLoading(false);

        const errorStatus = err.response?.status;

        if (errorStatus === 500) {
          setCorrectLeftToPay('error');
          
          const errorMessage = err.response.data?.error?.message;
          window.location.reload()
          // Notification
          notification.error({
            message: errorMessage || "Error with payment method",
            placement: "bottomLeft",
          });

          const isRedirect =
            err.response?.data &&
            err.response?.data?.error &&
            err.response.data.error.hasOwnProperty("failed_payment") &&
            err.response.data.error.hasOwnProperty("failed_order_number");

          if (isRedirect) {
            const orderNumber = err.response.data.error.failed_order_number;

            // Notification
            notification.error({
              message: "There was an issue while trying to create your order!",
              placement: "bottomLeft",
            });

            // Unfreeze scroll
            scrollUnfreeze();

            // Remove cart items
            setCart([]);
          }
        }
      });
  };

  const handleCheckout = async () => {
    if (!isSplitShipping && isSplitPayment) {
      const isLengthPayment = paymentMethod.multiPayment.length > 0;

      const payments = paymentMethod.multiPaymentAmount;
      const totalValue = isLengthPayment
        ? payments.reduce((sum, item) => sum + parseFloat(item.value), 0)
        : 0;

      let checkAmount = false;

      if (selectedWic && selectedWic.length > 0) {
        const discountPriceMulti = parseFloat(totalAmountValue.value);
        const deliveryPriceMulti =
          coupon.type === "shipping"
            ? +BENJAMINS_DELIVERY_PRICE -
              +BENJAMINS_DELIVERY_PRICE * (coupon.discount_percentage / 100)
            : +BENJAMINS_DELIVERY_PRICE;
        const freeDeliveryMulti = +BENJAMINS_DELIVERY_PRICE_FREE;
        const totalDeliveryPrice = parseFloat(totalAmountDeliveryValue);
        const sumAmountMulti = sumPrice({
          discountPrice: discountPriceMulti,
          totalDeliveryPrice: totalDeliveryPrice,
          deliveryPrice: deliveryPriceMulti,
          freeDelivery: freeDeliveryMulti,
          selectedWic,
        });

        if (+sumAmountMulti.toFixed(2) !== +totalValue.toFixed(2)) {
          setPaymentMethodError((prev) => {
            return {
              ...prev,
              paymentMethod: true,
            };
          });

          setCorrectLeftToPay("error");

          notification.info({
            message:
              "The amount you entered for Credit / Debit card payment does not match the total cost of your order!",
            placement: "bottomLeft",
          });

          checkAmount = false;
        } else {
          setCorrectLeftToPay("success");

          checkAmount = true;
        }
      } else {
        if (+totalLeftAmount.toFixed(2) !== 0) {
          setPaymentMethodError((prev) => {
            return {
              ...prev,
              paymentMethod: true,
            };
          });

          setCorrectLeftToPay("error");

          notification.info({
            message:
              "The amount you entered for Credit / Debit card payment does not match the total cost of your order!",
            placement: "bottomLeft",
          });

          checkAmount = false;
        } else {
          setCorrectLeftToPay("success");

          checkAmount = true;
        }
      }

      if (!checkAmount) {
        return;
      }

      const productsData = getProductsListSplit(products.data);
      const discountPrice = parseFloat(totalAmountValue.value);
      const totalDeliveryPrice = parseFloat(totalAmountDeliveryValue);
      const deliveryPrice =
        coupon.type === "shipping"
          ? +BENJAMINS_DELIVERY_PRICE -
            +BENJAMINS_DELIVERY_PRICE * (coupon.discount_percentage / 100)
          : +BENJAMINS_DELIVERY_PRICE;
      const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;

      const sumAmount = sumPrice({
        discountPrice,
        totalDeliveryPrice,
        deliveryPrice,
        freeDelivery,
        selectedWic,
      });

      const userSplitPaymentOrders = getUserOrders({
        user: user,
        payment: paymentMethod.multiPaymentAmount,
        splitPayment: isSplitPayment,
        shipment: {
          rate_id: selectSingleShipment.id,
          shipment_id: selectSingleShipment.shipment_id,
        },
        address: { ...address.defAddress, comment: customer[0].comment },
        amount: +sumAmount.toFixed(2),
        products: {
          rest: productsData.products,
          wic: productsData.wicProducts,
        },
        selectedWic: selectedWic,
        uploadWicImage: uploadWicImage,
        hideUploadWicImage: hideUploadWicImage,
      });

      const errors = userSplitPaymentOrders.errors;
      checkCreateOrder("222", userSplitPaymentOrders);

      if (errors.products) {
        // Notification
        notification.error({
          message: "Your cart is empty!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && errors.selectedWic && errors.payment) {
        // Notification
        notification.error({
          message: "Please create your Payment Method!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && errors.selectedWic && errors.shipment) {
        // Notification
        notification.error({
          message: "Not selected your service!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && !errors.selectedWic && !errors.uploadWicImage) {
        // Notification
        return notification.error({
          message:
            "It is required to upload you WIC card image before completing the order!",
          placement: "bottomLeft",
        });
      }

      if (errors.products || errors.payment) {
        return;
      }

      const payload = userSplitPaymentOrders.payload;

      return await createNewOrder({ ...payload, coupon: coupon.value });
    }

    // User data and error without split Shipping
    if (!isSplitShipping && !isSplitPayment) {
      const productsData = getProductsListSplit(products.data);
      const discountPrice = parseFloat(totalAmountValue.value);
      const totalDeliveryPrice = parseFloat(totalAmountDeliveryValue);
      const deliveryPrice =
        coupon.type === "shipping"
          ? +BENJAMINS_DELIVERY_PRICE -
            +BENJAMINS_DELIVERY_PRICE * (coupon.discount_percentage / 100)
          : +BENJAMINS_DELIVERY_PRICE;
      const freeDelivery = +BENJAMINS_DELIVERY_PRICE_FREE;

      const sumAmount = sumPrice({
        discountPrice,
        totalDeliveryPrice,
        deliveryPrice,
        freeDelivery,
        selectedWic,
      });

      const userOrders = getUserOrders({
        user: user,
        payment: paymentMethod.simplePayment,
        splitPayment: isSplitPayment,
        shipment: {
          rate_id: selectSingleShipment.id,
          shipment_id: selectSingleShipment.shipment_id,
        },
        address: { ...address.defAddress, comment: customer[0].comment },
        amount: +sumAmount.toFixed(2),
        products: {
          rest: productsData.products,
          wic: productsData.wicProducts,
        },
        selectedWic: selectedWic,
        uploadWicImage: uploadWicImage,
        hideUploadWicImage: hideUploadWicImage,
      });

      const errors = userOrders.errors;
      checkCreateOrder("333", userOrders);

      if (errors.products) {
        // Notification
        notification.error({
          message: "Your cart is empty!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && errors.selectedWic && errors.payment) {
        // Notification
        notification.error({
          message: "Please create your Payment Method!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && errors.selectedWic && errors.shipment) {
        // Notification
        notification.error({
          message: "Not selected your service!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && !errors.selectedWic && errors.payment) {
        // Notification
        notification.error({
          message: "Please create your Payment Method!",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && !errors.selectedWic && errors.shipment) {
        // Notification
        notification.error({
          message:
            "There was a problem while trying to calculate shipment service cost! Please check you address and zip code.",
          placement: "bottomLeft",
        });
      }

      if (!errors.products && !errors.selectedWic && !errors.uploadWicImage) {
        // Notification
        return notification.error({
          message:
            "It is required to upload you WIC card image before completing the order!",
          placement: "bottomLeft",
        });
      }

      if (errors.products || errors.payment) {
        return;
      }

      const payload = userOrders.payload;

      return await createNewOrder({ ...payload, coupon: coupon.value });
    }

    if (isSplitShipping && isSplitPayment) {
      let checkAmount = false;

      if (+totalLeftAmount.toFixed(2) !== 0) {
        setPaymentMethodError((prev) => {
          return {
            ...prev,
            paymentMethod: true,
          };
        });

        setCorrectLeftToPay("error");

        notification.info({
          message:
            "The amount you entered for Credit / Debit card payment does not match the total cost of your order!",
          placement: "bottomLeft",
        });

        checkAmount = false;
      } else {
        setCorrectLeftToPay("success");

        checkAmount = true;
      }

      if (!checkAmount) {
        return;
      }

      const productsData = getProductsListSplit(products.data);

      const userSplitShippingPayment = getUserSplitOrders({
        user: user,
        payment: paymentMethod.multiPaymentAmount,
        splitPayment: isSplitPayment,
        shipment: {
          rate_id: selectedShipmentRates.id,
          shipment_id: selectedShipmentRates.shipment_id,
        },
        address: { ...address.defAddress, comment: customer[0].comment },
        amount: null,
        addresses: splitedProducts,
        products: {
          rest: productsData.products,
          wic: productsData.wicProducts,
        },
        selectedWic: selectedWic,
        uploadWicImage: uploadWicImage,
        hideUploadWicImage: hideUploadWicImage,
      });

      const errors = userSplitShippingPayment.errors;
      checkCreateOrder("444", userSplitShippingPayment);

      if (!errors.products && !errors.selectedWic && !errors.uploadWicImage) {
        // Notification
        return notification.error({
          message:
            "It is required to upload you WIC card image before completing the order!",
          placement: "bottomLeft",
        });
      }

      const payload = userSplitShippingPayment.payload;

      return await createNewOrder({ ...payload, coupon: coupon.value });
    }

    const productsData = getProductsListSplit(products.data);

    let rateId = "";
    let shipmentId = "";

    if (selectedShipmentRates && selectedShipmentRates.length > 0) {
      const singleRateId = Object.values(selectedShipmentRates[0])[0].id;
      const singleShipmentId = Object.values(selectedShipmentRates[0])[0]
        .shipment_id;

      rateId = singleRateId;
      shipmentId = singleShipmentId;
    }

    const userSplitShipping = getUserSplitOrders({
      user: user,
      payment: paymentMethod.simplePayment,
      splitPayment: isSplitPayment,
      shipment: {
        rate_id: rateId,
        shipment_id: shipmentId,
      },
      products: {
        rest: productsData.products,
        wic: productsData.wicProducts,
      },
      amount: +totalMultiAmountValue.toFixed(2),
      addresses: splitedProducts,
      address: { ...address.defAddress, comment: customer[0].comment },
      selectedWic: selectedWic,
      uploadWicImage: uploadWicImage,
      hideUploadWicImage: hideUploadWicImage,
    });

    const errors = userSplitShipping.errors;
    checkCreateOrder("555", userSplitShipping);

    if (!errors.products && !errors.selectedWic && !errors.uploadWicImage) {
      // Notification
      return notification.error({
        message:
          "It is required to upload you WIC card image before completing the order!",
        placement: "bottomLeft",
      });
    }

    if (errors.products || errors.payment) {
      return;
    }

    const payload = userSplitShipping.payload;

    return await createNewOrder({ ...payload, coupon: coupon.value });
  };

  const hasCheckSplitPayment = isCheckTotal();

  const HAS_ALL_CONFIRM = !isServicesConfirmed && !isPaymentConfirmed;
  const HAS_SELECTED_WIC_DISABLED =
    selectedWic.length < 1 ? HAS_ALL_CONFIRM : !confirmSplitPayment;

  const choosePaymentMethodProps = {
    readPayment: readPayment,
    readPaymentRefetch: readPaymentRefetch,
    readPaymentLoading: readPaymentLoading,
    deletePaymentId: deletePaymentId,
    deletePaymentFlag: deletePaymentFlag,
    isSplitPayment: isSplitPayment,
    isDisabled: HAS_SELECTED_WIC_DISABLED || hasCheckSplitPayment,
    isSplitShipping: HAS_SELECTED_WIC_DISABLED || hasCheckSplitPayment,
    totalAmountValue: totalLeftAmount,
    setIsSplitPayment: setIsSplitPayment,
    onToggleAction: onToggleAction,
    setPaymentMethod: setPaymentMethod,
    correctLeftToPay: correctLeftToPay,
    paymentErrorNoFound: paymentErrorNoFound,
  };

  // open sidebar
  const createPaymentSidebarProps = {
    toggle: toggleCreatePayment,
    onToggle: setToggleCreatePayment,
  };
  // create payment card
  const createPaymentProps = {
    toggle: true,
    user,
    onToggle: setToggleCreatePayment,
    active: toggleCreatePayment,
    loading: readPaymentLoading,
    onLoading: setReadPaymentLoading,
    refetch: readPaymentRefetch,
    onRefetch: setReadPaymentRefetch,
    customerId,
    setShowPayment,
    setAllowPaymentStep,
    setRefetchCard,
  };
  // update payment card
  const updatePaymentSidebarProps = {
    toggle: toggleUpdatePayment,
    onToggle: handleCloseUpdate,
    onRefetch: setReadPaymentRefetch,
  };

  const updatePaymentProps = {
    data: updatePayment,
    user: user,
    loading: updatePaymentLoading,
    updatePaymentId: updatePaymentId,
    onRefetch: setReadPaymentRefetch,
    onToggle: handleCloseUpdate,
    customerId,
    setShowPayment,
    setAllowPaymentStep,
    setRefetchCard,
  };

  // create card props
  const createCreditCardProps = {
    text: "Add Credit or Debit card",
    className: "btn btn-primary-link",
    isDisabled: false,
    isSplitShipping: HAS_SELECTED_WIC_DISABLED,
    onToggle: onChangeToggleButton,
  };

  // delete card props
  const deleteModalProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    className: "delete-payment-modal",
  };

  const deletePaymentProps = {
    loading: deletePaymentLoading,
    onSubmit: handleOnRemovePayment,
    onToggle: setToggleDelete,
  };

  // cart props
  const cartProps = {
    readAddress: readAddress,
    onClick: onClick,
    isSplitShipping: isSplitShipping,
    handleClearCart,
    cart,
    selected: selectedWic,
    handleSplitCheck: () => {},
    isAddressConfirmed: isAddressConfirmed,
    isServicesConfirmed,
    showPayment,
    setShowPayment,
    allowPaymentStep,
  };

  // shipping props
  const shippingProps = {
    isSplitShipping,
    shippingOption: shippingOption,
    setShippingOption: setShippingOption,
    handleShippingOption: handleShippingOption,
    stateUS: stateUS,
    customerId: customerId,
    setReadDataRefetch: setReadDataRefetch,
    setCustomerAddresses: setCustomerAddresses,
    items: items,
    onClick: onClick,
    selectedWic: selectedWic,
    onChangeToggleSwitch,
    readAddress,
    isWicCart,
    isAddressConfirmed: isAddressConfirmed,
    setToggleDeliveryServices,
    toggleDeliveryServices,
    onConfirmSplitAddress,
    isServicesConfirmed,
    isLengthSelectedWic,
    onConfirmAddresses,
    loadingSingleShipmentRates,
    onConfirmServices,
    singleShipmentRatesError,
    address,
    getAddressStateName,
    checkDefaultAddress,
    selectSingleShipment,
    serviceConfirmedError,
    singleShipmentRates,
    ratePrice,
    totalAmountValue,
    currencyFormatterPrice,
    onDisproveAddresses,
    renderBenjaminsDelivery,
    products,
    onSelected,
    renderAddress,
    BENJAMINS_DELIVERY,
    BENJAMINS_PHONE_NUMBER,
    resetDelivery,
    singleShipmentRatesError,
    multiShipmentRatesError,
    multiShipmentRatesError,
    selectedShipmentRates,
    onConfirmSplitService,
    paymentMethodError,
    checkPaymentErrorMessage,
    onDisproveSplitAddress,
    zipArrayValidation,
    geoLocationError,
    geoLocationErrorWIC,
    geoLocations,
    setSingleShipmentRatesError,
    setHideUploadWicImage,
    hideUploadWicImage,
    handleImageSelect,
  };

  const activeClass = window.location.href.includes('create-order') ? 'active' : ''

  return (
    <>
      <div className="orders__navigation">
        <Link
          className="page-title"
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`}
        >
          Orders
        </Link>
        <Link
          className={`page-title ${activeClass}`}
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}`}
        >
          Create Order
        </Link>
      </div>

      <Section className={`section__wrapper section__createOrder`}>
        <header className="section__header">
          <div className="title">Create Order</div>
          <div className="right__side">
            <div className="number__row">
              {window.location.href.endsWith('shipping-address') ? <CheckMark/> : <div className="number">1</div>}
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}`}
                className="link"
              >
                Cart
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              {window.location.href.endsWith('shipping-address') ? <CheckMark/> : <div className="number">2</div>}
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`}
                className="link"
              >
                Account Settings
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              {showPayment ? <CheckMark/> : <div style={{background:window.location.href.endsWith('shipping-address') ? '#D0EDED' : ''}} className="number">3</div>}
              {showPayment ? (
                <button
                  style={{
                    background: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Shipping Address
                </button>
              ) : (
                <Link
                  to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}/${PERMISSIONS.shippingAddress}`}
                  className="link"
                >
                  Shipping Address
                </Link>
              )}
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div style={{background: showPayment ? '#D0EDED' : ''}} className="number">4</div>
              {allowPaymentStep ? (
                <button
                  style={{
                    background: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPayment(true)}
                >
                  Payment Method
                </button>
              ) : (
                <div className="link">Payment Method</div>
              )}
            </div>
          </div>
        </header>

        {showPayment ? (
          <Main className="section__content section__content relative min-h-table-content">
            {readDataLoading ? (
              <Loading />
            ) : (
              <div
                className="create__order"
                style={{ flexDirection: "column", margin: "20px", gap: "40px" }}
              >
                <div className="payment__preview">
                  <h3 style={{ textAlign: "center", fontSize: "24px" }}>
                    Review Cart
                  </h3>
                  <CartReview
                    benjaminsDeliveryPrice={BENJAMINS_DELIVERY_PRICE}
                    total={total}
                    coupon={coupon}
                    products={products}
                    renderAddress={renderAddress}
                    renderPrice={renderPrice}
                    renderDeliveryPrice={renderDeliveryPrice}
                    renderTotalPrice={renderTotalPrice}
                    deliveryPriceFree={deliveryPriceFree}
                    totalMultiAmountValue={totalMultiAmountValue}
                    splitedDeliveryPrice={splitedDeliveryPrice}
                    hasSelectedWicDisabled={HAS_SELECTED_WIC_DISABLED}
                    selectedWic={selectedWic}
                    selectSingleShipment={selectSingleShipment}
                    totalAmountValue={totalAmountValue.value}
                    isLengthSelectedWic={isLengthSelectedWic}
                    isSplitShipping={isSplitShipping}
                  />
                </div>
                
                <div className="payment__summary">
                  <div className="coupon-row">
                    <h3>Insert Coupon</h3>
                    <div className="coupon-search">
                      <input
                        disabled={couponDisabled}
                        id="couponValidator"
                        name="coupon"
                        value={couponCheck}
                        onBlur={(event) => handleBlurCoupon(event)}
                        onChange={(event) => handleChangeCoupon(event)}
                        className={`coupon-input ${COUPON_CLASS}`}
                        type="text"
                        placeholder="Coupon name"
                      />
                      <div className="button-container">
                        <button
                          disabled={couponDisabled}
                          type="button"
                          onClick={checkCoupon}
                          className="btn btn-primary"
                        >
                          Apply
                        </button>
                        {removeCoupon && (
                          <button
                            type="button"
                            onClick={handleRemoveCoupon}
                            className="btn btn-primary-link"
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                    {couponMessage && (
                      <p style={{ color: "red" }} className="mt-1">
                        * This coupon is not valid or doesn't exist.
                      </p>
                    )}
                  </div>

                  <div className="calculate-total">
                    {isSplitShipping ? (
                      <div className="calculate total-split-shipping">
                        {coupon && coupon?.type === "product" && (
                          <h5
                            style={{
                              textDecoration: "line-through 2px",
                              color: "hsl(354deg, 60%, 61%)",
                            }}
                          >
                            <span>Subtotal:</span>
                            <span>
                              {currencyFormatterPrice(
                                +total?.totalSalePrice || 0
                              )}
                            </span>
                          </h5>
                        )}

                        <h5>
                          <span>
                            {coupon && coupon.type === "product"
                              ? "Discounted "
                              : null}
                            Subtotal:
                          </span>
                          <span>{renderPrice()}</span>
                        </h5>

                        {selectedWic && selectedWic.length > 0 ? (
                          <Fragment>
                            {coupon && coupon?.type === "shipping" && (
                              <h5
                                style={{
                                  textDecoration: "line-through 2px",
                                  color: "hsl(354deg, 60%, 61%)",
                                }}
                              >
                                <span>Shipment price:</span>
                                <span>
                                  {splitedDeliveryPrice
                                    ? currencyFormatterPrice(
                                        +splitedDeliveryPrice
                                      )
                                    : "~"}
                                </span>
                              </h5>
                            )}

                            <h5>
                              <span>
                                {coupon && coupon.type === "shipping"
                                  ? "Discounted "
                                  : null}
                                Shipment price:
                              </span>

                              {deliveryPriceFree ? (
                                <Fragment>
                                  <span>Free delivery</span>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <span>
                                    {splitedDeliveryPrice
                                      ? getRenderShipment({
                                          totalAmount: splitedDeliveryPrice,
                                        })
                                      : "~"}
                                  </span>
                                </Fragment>
                              )}
                            </h5>

                            <h4>
                              <span>Total:</span>
                              <span>
                                {totalMultiAmountValue
                                  ? currencyFormatterPrice(
                                      +totalMultiAmountValue
                                    )
                                  : "$0.00"}
                              </span>
                            </h4>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {coupon && coupon?.type === "shipping" && (
                              <h5
                                style={{
                                  textDecoration: "line-through 2px",
                                  color: "hsl(354deg, 60%, 61%)",
                                }}
                              >
                                <span>Shipment price:</span>
                                <span>
                                  {currencyFormatterPrice(
                                    +splitedDeliveryPrice
                                  )}
                                </span>
                              </h5>
                            )}

                            <h5>
                              <span>
                                {coupon && coupon.type === "shipping"
                                  ? "Discounted "
                                  : null}
                                Shipment price:
                              </span>
                              <span>
                                {getRenderShipment({
                                  totalAmount: splitedDeliveryPrice,
                                })}
                              </span>
                            </h5>

                            <span className="separator" />

                            <h4>
                              <span>Total:</span>
                              <span>
                                {totalMultiAmountValue
                                  ? currencyFormatterPrice(
                                      +totalMultiAmountValue
                                    )
                                  : "$0.00"}
                              </span>
                            </h4>
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      <div className="calculate total-shipping">
                        {coupon && coupon?.type === "product" && (
                          <h5
                            style={{
                              textDecoration: "line-through 2px",
                              color: "hsl(354deg, 60%, 61%)",
                            }}
                          >
                            <span>Subtotal:</span>
                            <span>
                              {currencyFormatterPrice(
                                +total?.totalSalePrice || 0
                              )}
                            </span>
                          </h5>
                        )}
                        <h5>
                          <span>
                            {coupon && coupon.type === "product"
                              ? "Discounted "
                              : null}
                            Subtotal:
                          </span>
                          <span>{renderPrice()}</span>
                        </h5>

                        {selectedWic && selectedWic.length > 0 ? (
                          <Fragment>
                            {coupon && coupon?.type === "shipping" && (
                              <h5
                                style={{
                                  textDecoration: "line-through 2px",
                                  color: "hsl(354deg, 60%, 61%)",
                                }}
                              >
                                <span>Shipment price:</span>
                                <span>
                                  {currencyFormatterPrice(
                                    +BENJAMINS_DELIVERY_PRICE
                                  )}
                                </span>
                              </h5>
                            )}

                            <h5>
                              <span>
                                {coupon && coupon.type === "shipping"
                                  ? "Discounted "
                                  : null}
                                Shipment price:
                              </span>
                              <span>{renderDeliveryPrice()}</span>
                            </h5>

                            <span className="separator" />

                            <h4>
                              <span>Total:</span>
                              <span>{renderTotalPrice()}</span>
                            </h4>
                          </Fragment>
                        ) : (
                          <Fragment>
                            {coupon && coupon?.type === "shipping" && (
                              <h5
                                style={{
                                  textDecoration: "line-through 2px",
                                  color: "hsl(354deg, 60%, 61%)",
                                }}
                              >
                                <span>Shipment price:</span>
                                <span>
                                  {currencyFormatterPrice(
                                    +selectSingleShipment.rate
                                  )}
                                </span>
                              </h5>
                            )}

                            <h5>
                              <span>
                                {coupon && coupon.type === "shipping"
                                  ? "Discounted "
                                  : null}
                                Shipment price:
                              </span>
                              <span>
                                {getRenderShipmentPrice({
                                  totalAmount: totalAmountValue.value,
                                  delivery: totalAmountValue.delivery,
                                  shipment: selectSingleShipment,
                                })}
                              </span>
                            </h5>

                            <span className="separator" />

                            <h4>
                              <span>Total:</span>
                              <span>
                                {getRenderTotalPrice({
                                  totalAmount: totalAmountValue.value,
                                })}
                              </span>
                            </h4>
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="rest-payment-content">
                    <div className="payment-method">
                      <header className="payment-header">
                        <div className="toggle-payment-wrapper">
                          <p>Payment Method</p>

                          <CreateButton {...createCreditCardProps} />
                        </div>
                      </header>

                      <div
                        className={`payment-method-content ${
                          HAS_SELECTED_WIC_DISABLED ? "disabled" : ""
                        }`}
                      >
                        <div className="payment-wrapper">
                          <ChoosePaymentMethod {...choosePaymentMethodProps} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="checkout__container">
                    <button
                      onClick={() => {
                        window.location.reload();
                      }}
                      type="button"
                      className="btn btn-primary-outline"
                    >
                      Back
                    </button>
                    {!checkoutLoggedOutLoading ? (
                      <button
                        disabled={HAS_SELECTED_WIC_DISABLED}
                        onClick={() => handleCheckout("user-checkout")}
                        type="button"
                        className="btn btn-success"
                      >
                        <span className="text">Checkout</span>
                      </button>
                    ) : (
                      <button
                        disabled={true}
                        type="button"
                        className="btn btn-success"
                      >
                        <span className="text">Processing</span>
                      </button>
                    )}
                  </div>
                </div>

                <SidebarCard
                  title="Create Credit / Debit card"
                  className="gl__sidebar--right"
                  {...createPaymentSidebarProps}
                >
                  <Payment.Create
                    className="create-payment-card"
                    {...createPaymentProps}
                  />
                </SidebarCard>

                <SidebarCard
                  title="Edit Credit / Debit card"
                  className="gl__sidebar--right"
                  {...updatePaymentSidebarProps}
                >
                  <Payment.Update
                    className="update-payment-card"
                    {...updatePaymentProps}
                  />
                </SidebarCard>

                <ModalCard
                  title="Remove Credit / Debit card"
                  {...deleteModalProps}
                >
                  <Payment.Delete
                    className="form-delete"
                    name="delete"
                    {...deletePaymentProps}
                  />
                </ModalCard>
              </div>
            )}
          </Main>
        ) : (
          <Main className="section__content section__content relative min-h-table-content">
            {readDataLoading ? (
              <Loading />
            ) : (
              <div className="create__order">
                <AddressesTable
                  data={readAddress}
                  shippingProps={shippingProps}
                />
                <CreateOrderCart products={items.rest} {...cartProps} />
              </div>
            )}
          </Main>
        )}
      </Section>
    </>
  );
}

export default ShippingAddress;
