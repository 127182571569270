import { useState } from "react";
import { notification } from "antd";
import ImageHeader from "./ImageHeader";
import ImagePreview from "./ImagePreview";

const ImageUpload = ({ className, onImageSelect }) => {
  const [selectedImage, setSelectedImage] = useState({
    blob: null,
    type: null,
    name: "",
  });

  const [key, setKey] = useState(0);

  const validateImage = (file) => {
    if (file) {
      // Check file type by examining the file extension
      const allowedExtensions = ["jpg", "jpeg", "png", "gif", "webp", "heic"];
      const fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        notification.error({
          message: "Invalid file type. Please upload a valid image.",
          placement: "bottomLeft",
        });

        return "Invalid file type. Please upload a valid image.";
      }

      // Check MIME type
      if (!file.type.startsWith("image/")) {
        notification.error({
          message: "Invalid MIME type. Please upload a valid image.",
          placement: "bottomLeft",
        });

        return "Invalid MIME type. Please upload a valid image.";
      }

      // Check file size
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        notification.error({
          message:
            "File size exceeds the allowed limit. Please upload a smaller image.",
          placement: "bottomLeft",
        });

        return "File size exceeds the allowed limit. Please upload a smaller image.";
      }
    }

    return null;
  };

  const onImageUpload = async (event) => {
    const file = event.target.files[0];

    const isValidImage = validateImage(file);

    if (file && !isValidImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileBlob = reader.result;
        const fileName = file.name;
        const fileType = file.type;

        setSelectedImage((prev) => {
          return {
            ...prev,
            blob: fileBlob,
            type: fileType,
            name: fileName,
          };
        });

        onImageSelect({ blob: fileBlob, name: fileName, type: fileType });
      };
      reader.readAsDataURL(file);
    }
  };

  const onRemoveImage = () => {
    setSelectedImage({
      blob: null,
      type: null,
      name: "",
    });
    setKey((prev) => prev + 1);
    onImageSelect({ blob: null, type: null, name: null });
  };

  return (
    <div className={className}>
      <ImagePreview
        selectedImage={selectedImage.blob}
        onRemoveImage={onRemoveImage}
      />
      <ImageHeader
        selectedImage={selectedImage.blob}
        imageKey={key}
        onImageUpload={onImageUpload}
      />
    </div>
  );
};

export default ImageUpload;
