import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../loadingPlaceholder/LoadingPlaceholder";
import { notification } from "antd";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import {
  validateExpiryDate,
  validatePaymentCardNumber,
  validateCvv,
} from "../../../helpers/helpers";
import { SERVER_URL } from "../../../config";
import { PERMISSIONS } from "../../../utils/permissions";

const PaymentFormUpdate = (props) => {
  const {
    data,
    loading,
    onToggle,
    onRefetch,
    className,
    user,
    updatePaymentId,
    customerId,
    setShowPayment,
    setAllowPaymentStep,
    setRefetchCard,
  } = props;

  const initialState = {
    cardNumber: "",
    cardName: "",
    cardExpired: "",
    cardStreet: "",
    cardZip: "",
    cardCvv: "",
  };
  const [form, setForm] = useState(initialState);

  const initialErrorState = {
    cardNumber: null,
    cardName: null,
    cardExpired: null,
    cardStreet: null,
    cardZip: null,
    cardCvv: null,
  };

  const [formError, setFormError] = useState(initialErrorState);

  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const formPayload = {
        cardNumber: data.cardNumber,
        cardName: data.cardName,
        cardExpired: data.cardExpired,
        cardStreet: data.cardStreet,
        cardZip: data.cardZip,
        cardCvv: data.cardCvv,
      };
      setForm(formPayload);
    }
  }, [data, setForm]);

  const onValid = ({ form, userType }) => {
    const {
      cardNumber,
      cardName,
      cardExpired,
      cardStreet,
      cardZip,
      cardCvv,
    } = form;

    let checkForm = false;

    let checkcardName = false;
    let checkCardStreet = false;
    let checkCardZip = false;

    let checkCardNumber = false;
    let checkCardExpired = false;
    let checkCardCvv = false;

    // check card number
    if (userType === "guest") {
      if (cardNumber.length >= 2 && validatePaymentCardNumber(cardNumber)) {
        checkCardNumber = true;
        setFormError((prevState) => ({ ...prevState, cardNumber: false }));
      } else {
        checkCardNumber = false;
        setFormError((prevState) => ({ ...prevState, cardNumber: true }));
      }
    }

    // check card expired
    if (userType === "guest") {
      if (cardExpired.length >= 2 && validateExpiryDate(cardExpired)) {
        checkCardExpired = true;
        setFormError((prevState) => ({ ...prevState, cardExpired: false }));
      } else {
        checkCardExpired = false;
        setFormError((prevState) => ({ ...prevState, cardExpired: true }));
      }
    }

    // check card cvv
    if (userType === "guest") {
      if (cardCvv.length >= 2 && validateCvv(cardCvv)) {
        checkCardCvv = true;
        setFormError((prevState) => ({ ...prevState, cardCvv: false }));
      } else {
        checkCardCvv = false;
        setFormError((prevState) => ({ ...prevState, cardCvv: true }));
      }
    }

    // check card name
    if (cardName.length >= 2) {
      checkcardName = true;
      setFormError((prevState) => ({ ...prevState, cardName: false }));
    } else {
      checkcardName = false;
      setFormError((prevState) => ({ ...prevState, cardName: true }));
    }

    // check card street
    if (cardStreet.length >= 2) {
      checkCardStreet = true;
      setFormError((prevState) => ({ ...prevState, cardStreet: false }));
    } else {
      checkCardStreet = false;
      setFormError((prevState) => ({ ...prevState, cardStreet: true }));
    }

    // check card zip
    if (cardZip.length >= 2) {
      checkCardZip = true;
      setFormError((prevState) => ({ ...prevState, cardZip: false }));
    } else {
      checkCardZip = false;
      setFormError((prevState) => ({ ...prevState, cardZip: true }));
    }

    if (userType === "guest") {
      if (
        !checkCardNumber ||
        !checkcardName ||
        !checkCardExpired ||
        !checkCardStreet ||
        !checkCardZip ||
        !checkCardCvv
      ) {
        checkForm = false;
      } else {
        checkForm = true;
      }
    } else {
      if (!checkcardName || !checkCardStreet || !checkCardZip) {
        checkForm = false;
      } else {
        checkForm = true;
      }
    }

    return checkForm;
  };

  const onChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onCancel = ({ action }) => {
    onToggle(false);

    if (action.type === "user") {
      setTimeout(() => {
        setForm(initialState);
        setFormError(initialErrorState);
      }, 200);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const isValidUserPayment = onValid({ form, userType: "user" });

    if (isValidUserPayment) {
      const formPayload = {
        name: form.cardName,
        street: form.cardStreet,
        zip: form.cardZip,
        setAsDefault: false,
      };

      if (user && Object.keys(user).length > 0) {
        const token = user.token;
        const payment_id = updatePaymentId._id;

        setUpdateLoading(true);

        await axios
          .put(
            `${SERVER_URL}/dashboard/customers/${AXIOS_API_CALL.updatePaymentMethod}/${customerId}/${payment_id}`,
            { ...formPayload },
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              onCancel({ action: { type: "user" } });
              onRefetch((p) => !p);
              setUpdateLoading(false);
              setRefetchCard((prev) => !prev);

              // notification
              notification.success({
                message: res.data.message,
                placement: "bottomLeft",
              });
              
              // reset after deleting the card
              setTimeout(() => {
                window.location.reload()
              },1000)
            }
          })
          .catch((err) => {
            console.error(err);
            setUpdateLoading(false);
          });
      }
    }
  };

  if (!data) {
    return;
  }

  if (loading) {
    return (
      <Loading
        className="gl__sidebar-loading"
        children={
          <div className="wrapper">
            <span className="loader" />
            <h4 className="text">Loading...</h4>
          </div>
        }
      />
    );
  }

  return (
    <form
      onSubmit={onSubmit}
      name="update"
      className={`form form-update-card ${className}`}
    >
      <div className="mb-4">
        <h3 className="title">Update Credit or Debit Card</h3>
        <img
          src="/assets/images/cards.png"
          alt="Cards"
          width={488}
          height={35}
        />
      </div>

      <div className="form-group-wrapper">
        <div className="form-group">
          <input
            disabled={updateLoading}
            value={form.cardName || ""}
            onChange={onChange}
            type="text"
            className={` ${formError.cardName ? "error" : ""}`}
            name="cardName"
            id="cardName"
            data-cy="create-card-number"
            required={false}
            placeholder=" "
          />
          <label className="required" htmlFor="cardName">
            Name on card:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${formError.cardName ? "error" : ""}`}
          >
            This field is required !
          </p>
        </div>

        <div className="form-group">
          <input
            disabled={updateLoading}
            value={form.cardStreet || ""}
            onChange={onChange}
            type="text"
            className={` ${formError.cardStreet ? "error" : ""}`}
            name="cardStreet"
            id="cardStreet"
            data-cy="create-card-number"
            required={false}
            placeholder=" "
          />
          <label className="required" htmlFor="cardStreet">
            Street:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${formError.cardStreet ? "error" : ""}`}
          >
            This field is required !
          </p>
        </div>

        <div className="form-group">
          <input
            disabled={updateLoading}
            value={form.cardZip || ""}
            onChange={onChange}
            type="text"
            className={` ${formError.cardZip ? "error" : ""}`}
            name="cardZip"
            id="cardZip"
            data-cy="create-card-number"
            required={false}
            placeholder=" "
          />
          <label className="required" htmlFor="cardZip">
            ZIP:
          </label>
          <p
            data-cy=""
            className={`error__onblur ${formError.cardZip ? "error" : ""}`}
          >
            This field is required !
          </p>
        </div>
      </div>

      <div className="actions">
        {!updateLoading ? (
          <button
            onClick={() => onCancel({ action: { type: "user" } })}
            className="btn btn-primary-outline"
            type="button"
          >
            <span className="text">Cancel</span>
          </button>
        ) : (
          <button disabled className="btn btn-primary-outline" type="button">
            <span className="text">Cancel</span>
          </button>
        )}

        {!updateLoading ? (
          <button className="btn btn-primary" type="submit">
            <span className="text">Save changes</span>
          </button>
        ) : (
          <button disabled className="btn btn-primary" type="button">
            <span className="text">Loading...</span>
          </button>
        )}
      </div>
    </form>
  );
};

export default PaymentFormUpdate;
