import jwt_decode from 'jwt-decode';
import { useState, useEffect, useCallback } from "react"
import { useAuth } from "../../../context/useAuth";
import { useLocation } from 'react-router-dom';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import Counter from "./counter";
import moment from "moment";
import axios from 'axios';

export default function FormCreateSlot({ queryDate, onRefetch }) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    const [groceryPermissions, setGroceryPermissions] = useState([]);

    // GET USER TOKEN
    useEffect(() => {
        const { token } = user;
        if (user && token) {
            const decodeToken = jwt_decode(token);
            const permissions = decodeToken.roleData?.permissions;

            if (pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
                if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
                    setGroceryPermissions(permissions.grocery);
                }
            }
        }
    }, [user, pathname]);

    const [createToggle, setCreateToggle] = useState(false);

    const handleToggleCreate = () => {
        setCreateToggle(p => !p);
    }

    const date = new Date();
    const time_from = date.getHours();
    const time_to = date.getHours() + 1;

    const [slotCell, setSlotCell] = useState({
        slot: 0,
        time_from: moment(time_from, "hh:mm A").format('HH:mm'),
        time_to: moment(time_to, "hh:mm A").format('HH:mm'),
    });

    const onChange = (event) => {
        const { name, value } = event.target;
        setSlotCell((prev) => {
            return {
                ...prev,
                [name]: value,
            }
        });
    }


    const createDeliverySlot = useCallback(async (payload) => {
        const { token } = user;

        await axios
            .post(`${SERVER_URL}/delivery-slot`, { ...payload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (res.status === 200) {
                    onRefetch(p => !p);

                    const successMessage = 'Successfully created a new delivery slot.';
                    notification.success({
                        message: successMessage,
                        placement: 'bottomLeft'
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                const errorMessage = err.response?.data?.message;

                if (errorMessage) {
                    notification.error({
                        message: errorMessage,
                        placement: 'bottomLeft'
                    });
                }
            })
            .finally(() => {
            });
    }, [user]);


    const onCreateSlot = async () => {
        const payload = {
            ...queryDate,
            time_from: moment(slotCell.time_from, "HH:mm").format('hh:mm A'),
            time_to: moment(slotCell.time_to, "HH:mm").format('hh:mm A'),
            maximum_slots: slotCell.slot,
            department: "grocery"
        }

        createDeliverySlot(payload);
    }

    return (
        <div className="slots-results">
            {createToggle && (
                <p style={{ marginBottom: '0' }}>Create a new time slot for scheduled delivery:</p>
            )}

            {createToggle && (
                <div className="slot slot-cell">
                    <div className='date-time'>
                        <input onChange={onChange} name="time_from" value={slotCell.time_from} type="time" className='input' />
                        <span className='separator'>-</span>
                        <input onChange={onChange} name="time_to" value={slotCell.time_to} type="time" className='input' />
                    </div>

                    <Counter value={slotCell.slot} setValue={setSlotCell} />

                    <button onClick={onCreateSlot} type='button' className='btn'>Create</button>
                    <button onClick={handleToggleCreate} type='button' className='btn'>Cancel</button>
                </div>
            )}

            {!createToggle && (
                <button onClick={handleToggleCreate} type="button" className="btn btn-primary-outline w-full pl-4 pr-4">
                    <span className="icon">+</span>
                    <span className="text">Add new time</span>
                </button>
            )}
        </div>
    )
}