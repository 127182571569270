import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';
import { Section } from '../../components/content';
import { AXIOS_API_CALL } from '../../utils/endpoint';

const ForgotPassword = () => {
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const [loginLoader, setLoginLoader] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);

  const onFinish = async (values) => {
    setLoginLoader(true);
    try {
      const dataPayload = {
        email: values.email,
        environment: 'dashboard'
      };

      const sendPasswordLink = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.forgotPassword}`, { ...dataPayload }, { withCredentials: false });

      setIsSent(true);
      setEmail(values.email);

      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight'
        });
      }
      setTimeout(() => {
        setLoginLoader(false);
      }, 200);
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight'
      });
      setTimeout(() => {
        setLoginLoader(false);
      }, 200);
    }
  };

  const handleResendEmail = async () => {
    const dataPayload = {
      email: email,
      environment: 'dashboard'
    };

    try {
      const sendPasswordLink = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.forgotPassword}`, { ...dataPayload }, { withCredentials: false });
      setIsSent(true);
      if (sendPasswordLink.data.status) {
        notification.success({
          message: sendPasswordLink.data.status,
          placement: 'bottomRight'
        });
      }
      setTimeout(() => {
        setResendLoader(false);
      }, 500);
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight'
      });
      setTimeout(() => {
        setResendLoader(false);
      }, 500);
    }
  };

  return (
    <Section className="section section__forgot-password">
      <div className="card-wrapper form-wrapper">
        {!isSent ? (
          <div className="title">
            <img className="login-circle" src="/assets/icons/forgot-password.svg" alt="Forgot Password" />
            <h1 className="mb-0">Forgot password</h1>
          </div>
        ) : (
          <div className="title">
            <img className="login-circle" src="/assets/icons/message.svg" alt="Message" />
            <h1 className="mb-0">Check your Email</h1>
          </div>
        )}

        <div className="subtitle">
          {!isSent ? (
            <h3 className="text-center mb-6">No worries, we'll send you reset instructions.</h3>
          ) : (
            <div className="we-sent">
              <h3>We sent a password reset message to</h3>
              <h3>{email}</h3>
            </div>
          )}
        </div>

        <Form name="basic" onFinish={onFinish}>
          {isSent && <p className="subtitle">Didn't receive the email?</p>}
          {!isSent && <label className="label">Email:</label>}
          {!isSent && (
            <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input placeholder="Email" />
            </Form.Item>
          )}

          <Form.Item style={{ marginBottom: 0, paddingTop: '20px' }}>
            {!isSent ? (
              !loginLoader ? (
                <Button type="primary" className="btn btn-block btn-primary" htmlType="submit">
                  Reset Password
                </Button>
              ) : (
                <Button type="primary" className="btn btn-block btn-primary-outline" htmlType="button">
                  Sending...
                </Button>
              )
              
            ) : (
              !resendLoader ? (
                <Button type="primary" className="btn btn-block btn-primary" onClick={handleResendEmail}>
                  Resend Email
                </Button>
              ) : (
                <Button type="primary" className="btn btn-block btn-primary-outline">
                  Resending...
                </Button>
              ) 
              
            )}
          </Form.Item>

          <Form.Item style={{ paddingTop: '40px' }}>
            <Link to="/login" className="link link-primary text-center d-flex items-center gap-x-1">
              <img src="/assets/icons/arrow.svg" alt="Button logo" />
              <span>Back to Login</span>
            </Link>
          </Form.Item>
        </Form>
      </div>
    </Section>
  );
};

export default ForgotPassword;
