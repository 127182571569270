import { useProducts } from '../../context/products';

const InputCheckboxField = (props) => {
  const { idx } = props;
  const { selected, onChangeItems } = useProducts();

  const isChecked = selected.includes(idx);

  return (
    <div className="form-group">
      <input
        checked={isChecked}
        onChange={onChangeItems}
        value={idx}
        name="multiSelect"
        id={`multiSelect-${idx}`}
        type="checkbox"
      />
      <label className="label" htmlFor={`multiSelect-${idx}`}>
        <b>Select item</b>
      </label>
    </div>
  );
};

export default InputCheckboxField;
