import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "../../../config/index";
import { useAuth } from "../../../context/useAuth";
import { Main, Section } from "../../../components/content";
import { notification } from "antd";
import Sorting from "./Sorting";
import Modal from "../../../components/modal/Modal";
import SidebarModal from "../../../components/sidebarModal/SidebarModal";
import Loading from "../../../components/loading/Loading";
import LoadingPlaceholder from "../../../components/loadingPlaceholder/LoadingPlaceholder";
import jwt_decode from "jwt-decode";
import DropdownSelect from "./DropdownSelect";
import { getGroceryPermissions } from "../../../middleware";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { PERMISSIONS } from "../../../utils/permissions";
import { slugify, slugifyReplaceAll, getImageUrl } from "../../../helpers/helpers";
import { notificationMessages } from "../../../helpers/notificationMessages";
import LoadingSpinnerButton from "../../../components/loadingSpinnerButton/LoadingSpinnerButton";

const Categories = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      setReadDataLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.categoriesGetAll}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setReadData(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setReadDataLoading(false);
    }
  }, [user, setReadDataLoading, setReadData]);

  useEffect(() => {
    getReadData();
  }, [getReadData, readDataRefetch]);

  const [attributes, setAttributes] = useState([]);
  const getData = useCallback(
    async (props) => {
      const { token } = user;

      try {
        let response = await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.attributes}`,
            { department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setAttributes(res.data.items);
          })
          .catch((err) => console.error(err))
          .finally(setTimeout(() => { }, 200));

        return response;
      } catch (err) { }
    },
    [user, setAttributes]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  // Selected Attributes
  const [selected, setSelected] = useState([]);
  const handleOnSelect = (data) => {
    setSelected(data);
  };

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [createSubBtnLoader, setCreateSubBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // CREATE - parent
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createFormParent, setCreateFormParent] = useState({ parent: false });
  const [createForm, setCreateForm] = useState({
    createName: "",
    createSlug: "",
  });
  const [isFeatured, setIsFeatured] = useState(false);
  const [isPopular, setIsPopular] = useState(false)
  // ----------------------- IMAGE ----------------------
  const [imageData, setImageData] = useState({
    upload: [],
    image: [],
    blob: "",
  });
  // ------------------ ERROR HANDLING ------------------
  const [createFormError, setCreateFormError] = useState({
    createName: false,
    createSlug: false,
    uniqueName: false,
    uniqueSlug: false,
    imageSize: false,
    imageType: false,
  }); // check

  // CREATE - subcategory
  const createSubcategoryFormRef = useRef(null);
  const [toggleSubcategoryCreate, setToggleSubcategoryCreate] = useState(false);
  const [createSubcategoryFormId, setCreateSubcategoryFormId] = useState({
    id: "",
  });
  const [createSubcategoryForm, setCreateSubcategoryForm] = useState({
    createSubcategoryName: "",
    createSubcategorySlug: "",
  });

  // ----------------------- IMAGE ----------------------
  const [imageSubcategoryData, setImageSubcategoryData] = useState({
    upload: [],
    image: [],
    blob: "",
  });
  // ------------------ ERROR HANDLING ------------------
  const [createSubcategoryFormError, setCreateSubcategoryFormError] = useState({
    createSubcategoryName: false,
    createSubcategorySlug: false,
    uniqueSubcategoryName: false,
    uniqueSubcategorySlug: false,
    imageSize: false,
    imageType: false,
  }); // check

  // UPDATE
  const updateFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateFormId, setUpdateFormId] = useState({ id: "" });
  const [updateParentFormId, setUpdateParentFormId] = useState({ id: "" });
  const [updateData, setUpdateData] = useState([]);
  const [updateForm, setUpdateForm] = useState({
    updateName: "",
    updateSlug: "",
    updateUrl: "",
    image: [],
    parentCategory: null,
  });
  const [updateFormDropdown, setUpdateFormDropdown] = useState([]);

  // ----------------------- IMAGE ----------------------
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [imageUpdateData, setImageUpdateData] = useState({
    upload: [],
    image: [],
    blob: "",
  });
  // ------------------ ERROR HANDLING ------------------
  const [updateFormError, setUpdateFormError] = useState({
    updateName: false,
    updateSlug: false,
    uniqueName: false,
    uniqueSlug: false,
    imageSize: false,
    imageType: false,
  }); // check

  // DELETE
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: "" });

  // IMPORT FROM NETSUITE
  const importNetSuiteModalRef = useRef(null);
  const [toggleImportNetSuite, setToggleImportNetSuite] = useState(false);
  const [showImportNetSuiteLoader, setShowImportNetSuiteLoader] = useState(
    false
  );

  // FUNCTION
  function handleToggle(props) {
    const { name, data } = props;

    switch (name) {
      case "create":
        setToggleCreate(true);
        setCreateFormParent({ parent: true });

        break;
      case "createSubcategory":
        setToggleSubcategoryCreate(true);
        setCreateSubcategoryFormId({ id: data.node._id });
        setCreateFormParent({ parent: false });

        break;
      case "update":
        setToggleUpdate(true);
        setUpdateFormId({ id: data.node._id });
        setUpdateParentFormId({ id: data.node.parent });

        if (data.node.parent) {
          setCreateFormParent({ parent: false });
        } else {
          setCreateFormParent({ parent: true });
        }

        break;
      case "delete":
        setToggleDelete(true);
        setDeleteFormId({ id: data.node._id });
        setCreateFormParent({ parent: false });
        break;
      case "importNetSuite":
        setToggleImportNetSuite(true);
        break;
      default:
        console.warn("Default of: handleToggle function");
    }
  }

  // SETUP UPDATE STATE AFTER FETCH DATA
  const getUpdateData = useCallback(async () => {
    const { token } = user;
    let updateId = updateFormId.id;

    if (!!updateId) {
      try {
        setLoadingUpdate(true);
        await axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.categories}/${updateId}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              setUpdateData(res?.data);
              setIsFeatured(res?.data.categoryData.isFeatured);
              setIsPopular(res?.data.categoryData.isPopular)
            }
          })
          .catch((err) => console.error(err))
          .finally(
            setTimeout(() => {
              setLoadingUpdate(false);
            }, 700)
          );
      } catch (err) {
        console.error(err);
        setLoadingUpdate(false);
      }
    }
  }, [user, updateFormId, setUpdateData, setLoadingUpdate]);

  useEffect(() => {
    getUpdateData();
  }, [getUpdateData]);

  useEffect(() => {
    if (updateData && updateData.length !== 0) {
      let newUpdateFormData = {
        updateName: updateData.categoryData.name,
        updateSlug: updateData.categoryData.slug,
        updateUrl: updateData.categoryData.url,
        parentCategory: updateData.categoryData.parent,
        image: [],
        isFeatured: updateData.categoryData.isFeatured,
        isPopular: updateData.categoryData.isPopular
      };

      if (updateData.categoryData.hasOwnProperty("image")) {
        const image = updateData?.categoryData?.image;

        newUpdateFormData = {
          ...newUpdateFormData,
          image: image,
        };

        setUpdateForm(newUpdateFormData);
      } else {
        setUpdateForm(newUpdateFormData);
      }
    }
  }, [updateData]);

  useEffect(() => {
    if (updateData && updateData.hasOwnProperty("DropDownData")) {
      setUpdateFormDropdown(updateData.DropDownData);
    }
  }, [updateData]);

  function handleOnUpload(props) {
    const { name, img } = props;

    const isJpgOrPng =
      img.type === "image/jpeg" ||
      img.type === "image/jpg" ||
      img.type === "image/png";
    const isLt2M = img.size / 1024 / 1024 < 2;

    if (name === "createImage") {
      if (isJpgOrPng && isLt2M) {
        setImageData({
          ...imageData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setCreateFormError({
          ...createFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setCreateFormError({ ...createFormError, imageType: true });
      }

      if (!isLt2M) {
        setCreateFormError({ ...createFormError, imageSize: true });
      }
    }

    if (name === "createSubcategoryImage") {
      if (isJpgOrPng && isLt2M) {
        setImageSubcategoryData({
          ...imageSubcategoryData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageType: true,
        });
      }

      if (!isLt2M) {
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageSize: true,
        });
      }
    }

    if (name === "updateImage") {
      if (isJpgOrPng && isLt2M) {
        setImageUpdateData({
          ...imageUpdateData,
          upload: img,
          blob: URL.createObjectURL(img),
        });
        setUpdateFormError({
          ...updateFormError,
          imageType: false,
          imageSize: false,
        });
      }

      if (!isJpgOrPng) {
        setUpdateFormError({ ...updateFormError, imageType: true });
      }

      if (!isLt2M) {
        setUpdateFormError({ ...updateFormError, imageSize: true });
      }
    }
  }

  function handleOnRemoveUpload(props) {
    const { name } = props;

    if (name === "create") {
      setImageData({ upload: [], image: [], blob: "" });
      setCreateFormError({
        ...createFormError,
        imageSize: false,
        imageType: false,
      });
    }

    if (name === "createSubcategory") {
      setImageSubcategoryData({ upload: [], image: [], blob: "" });
    }

    if (name === "update") {
      setImageUpdateData({ upload: [], image: [], blob: "" });
    }

    if (name === "updateRemove") {
      setUpdateForm({ ...updateForm, image: [] });
    }
  }

  async function getUploadImage(props) {
    const { name } = props;
    let data = [];

    const { token } = user;

    if (name === "create") {
      if (imageData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append("image", imageData?.upload);

        if (typeof imageData?.upload === "object") {
          try {
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`,
                uploadImage,
                {
                  withCredentials: false,
                  headers: {
                    "form-data": "application/json",
                    uri: "public/images/dashboard/categories/",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                data = res.data.image;
                setImageData({ ...imageData, image: res.data.image });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => { }, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    if (name === "createSubcategory") {
      if (imageSubcategoryData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append("image", imageSubcategoryData?.upload);

        if (typeof imageSubcategoryData?.upload === "object") {
          try {
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`,
                uploadImage,
                {
                  withCredentials: false,
                  headers: {
                    "form-data": "application/json",
                    uri: "public/images/dashboard/categories/",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                data = res.data.image;
                setImageSubcategoryData({
                  ...imageSubcategoryData,
                  image: res.data.image,
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => { }, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    if (name === "update") {
      if (imageUpdateData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append("image", imageUpdateData?.upload);

        if (typeof imageSubcategoryData?.upload === "object") {
          try {
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`,
                uploadImage,
                {
                  withCredentials: false,
                  headers: {
                    "form-data": "application/json",
                    uri: "public/images/dashboard/categories/",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                data = res.data.image;
                setImageUpdateData({
                  ...imageUpdateData,
                  image: res.data.image,
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => { }, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    return data;
  }

  function handleOnBlur(event) {
    const { name, value } = event.target;

    switch (name) {
      case "createName":
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createName: false });
        } else {
          toggleCreate &&
            setCreateFormError({ ...createFormError, createName: true });
        }
        break;
      case "createSlug":
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createSlug: false });
        } else {
          toggleCreate &&
            setCreateFormError({ ...createFormError, createSlug: true });
        }
        break;
      case "createSubcategoryName":
        if (value.length >= 2) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: false,
          });
        } else {
          toggleSubcategoryCreate &&
            setCreateSubcategoryFormError({
              ...createSubcategoryFormError,
              createSubcategoryName: true,
            });
        }
        break;
      case "createSubcategorySlug":
        if (value.length >= 2) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: false,
          });
        } else {
          toggleSubcategoryCreate &&
            setCreateSubcategoryFormError({
              ...createSubcategoryFormError,
              createSubcategorySlug: true,
            });
        }
        break;
      case "updateName":
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateName: false });
        } else {
          toggleUpdate &&
            setUpdateFormError({ ...updateFormError, updateName: true });
        }
        break;
      case "updateSlug":
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateSlug: false });
        } else {
          toggleUpdate &&
            setUpdateFormError({ ...updateFormError, updateSlug: true });
        }
        break;
      default:
        console.warn("Default of: handleOnBlur function");
    }
  }

  async function checkSlug(slug) {
    try {
      const res = await axios.post(
        `${SERVER_URL}/${AXIOS_API_CALL.checkCategorySlug}`,
        {
          slug,
        }
      );
      return res.data.message;
    } catch (e) {
      console.error(e.message);
    }
  }

  async function checkName(name) {
    try {
      const res = await axios.post(
        `${SERVER_URL}/${AXIOS_API_CALL.checkCategoryName}`,
        {
          name,
        }
      );
      return res.data.message;
    } catch (e) {
      console.error(e.message);
    }
  }

  const [disabledCreate, setDisabledCreate] = useState(false);
  const [disabledCreateSub, setDisabledCreateSub] = useState(false);
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const [createUniqueSlug, setCreateUniqueSlug] = useState(false);
  const [createUniqueName, setCreateUniqueName] = useState(false);
  const [createSubUniqueSlug, setCreateSubUniqueSlug] = useState(false);
  const [createSubUniqueName, setCreateSubUniqueName] = useState(false);
  const [updateUniqueSlug, setUpdateUniqueSlug] = useState(false);
  const [updateUniqueName, setUpdateUniqueName] = useState(false);

  useEffect(() => {
    setDisabledCreate(createFormError.uniqueSlug || createFormError.uniqueName);
  }, [createFormError.uniqueSlug, createFormError.uniqueName]);

  useEffect(() => {
    if (
      createSubcategoryFormError.uniqueSubcategorySlug ||
      createSubcategoryFormError.uniqueSubcategoryName
    ) {
      setDisabledCreateSub(true);
    } else {
      setDisabledCreateSub(false);
    }
  }, [
    createSubcategoryFormError.uniqueSubcategorySlug,
    createSubcategoryFormError.uniqueSubcategoryName,
  ]);

  useEffect(() => {
    if (
      updateData.categoryData?.name === updateForm.updateName &&
      updateData.categoryData?.slug === updateForm.updateSlug
    ) {
      setDisabledUpdate(false);
    } else {
      setDisabledUpdate(
        updateFormError.uniqueSlug || updateFormError.uniqueName
      );
    }
  }, [
    updateData.categoryData?.name,
    updateData.updateSlug,
    updateForm.updateName,
    updateForm.updateSlug,
    updateFormError.uniqueSlug,
    updateFormError.uniqueName,
  ]);

  useEffect(() => {
    setCreateFormError((prevState) => {
      return {
        ...prevState,
        uniqueSlug: createUniqueSlug,
        uniqueName: createUniqueName,
      };
    });
  }, [createUniqueSlug, createUniqueName, setCreateFormError]);

  useEffect(() => {
    setCreateSubcategoryFormError((prevState) => {
      return {
        ...prevState,
        uniqueSubcategorySlug: createSubUniqueSlug,
        uniqueSubcategoryName: createSubUniqueName,
      };
    });
  }, [createSubUniqueSlug, createSubUniqueName, setCreateSubcategoryFormError]);

  useEffect(() => {
    setUpdateFormError((prevState) => {
      return {
        ...prevState,
        uniqueName: updateUniqueName,
        uniqueSlug: updateUniqueSlug,
      };
    });
  }, [updateUniqueSlug, updateUniqueName, setUpdateFormError]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "createName":
        let slug = slugify(value);

        checkSlug(slug).then((res) => {
          setCreateUniqueSlug(res);
        });

        checkName(value).then((res) => {
          setCreateUniqueName(res);
        });

        setCreateForm({ ...createForm, [name]: value, createSlug: slug });
        break;
      case "createSlug":
        let createSlug = slugifyReplaceAll(value);

        checkSlug(createSlug).then((res) => {
          setCreateUniqueSlug(res);
        });

        setCreateForm({ ...createForm, [name]: createSlug });
        break;
      case "createSubcategoryName":
        let slugSubcategory = slugify(value);

        checkSlug(slugSubcategory).then((res) => {
          setCreateSubUniqueSlug(res);
        });

        checkName(value).then((res) => {
          setCreateSubUniqueName(res);
        });

        setCreateSubcategoryForm({
          ...createSubcategoryForm,
          [name]: value,
          createSubcategorySlug: slugSubcategory,
        });
        break;
      case "createSubcategorySlug":
        let createSlugSubcategory = slugifyReplaceAll(value);

        checkSlug(createSlugSubcategory).then((res) => {
          setCreateSubUniqueSlug(res);
        });

        setCreateSubcategoryForm({
          ...createSubcategoryForm,
          [name]: createSlugSubcategory,
        });
        break;
      case "updateName":
        setUpdateForm({ ...updateForm, [name]: value });

        if (value !== updateData.categoryData?.name) {
          checkName(value).then((res) => {
            setUpdateUniqueName(res);
          });
        } else {
          setUpdateUniqueName(false);
        }
        break;
      case "updateSlug":
        let updateSlug = slugifyReplaceAll(value);

        setUpdateForm({ ...updateForm, [name]: updateSlug });

        if (updateSlug !== updateData.categoryData?.slug) {
          checkSlug(updateSlug).then((res) => {
            setUpdateUniqueSlug(res);
          });
        } else {
          setUpdateUniqueSlug(false);
        }
        break;
      case "parentCategory":
        if (value !== "none") {
          setUpdateForm({ ...updateForm, [name]: value });
        } else {
          setUpdateForm({ ...updateForm, [name]: null });
        }
        break;
      default:
        console.warn("Default of: handleOnChange function");
    }
  };

  function handleOnValidation(props) {
    const { name, form } = props;

    let checkCase = false;

    let checkCreateName = false;
    let checkCreateSlug = false;

    let checkCreateSubcategoryName = false;
    let checkCreateSubcategorySlug = false;

    let checkUpdateName = false;
    let checkUpdateSlug = false;

    switch (name) {
      case "create":
        if (form.createName.length >= 2) {
          checkCreateName = true;
          setCreateFormError({ ...createFormError, createName: false });
        } else {
          checkCreateName = false;
          setCreateFormError({ ...createFormError, createName: true });
        }

        if (form.createSlug.length >= 2) {
          checkCreateSlug = true;
          setCreateFormError({ ...createFormError, createSlug: false });
        } else {
          checkCreateSlug = false;
          setCreateFormError({ ...createFormError, createSlug: true });
        }

        if (!checkCreateName && !checkCreateSlug) {
          setCreateFormError({
            ...createFormError,
            createName: true,
            createSlug: true,
          });
        }

        if (checkCreateName && checkCreateSlug) {
          checkCase = true;
        }

        break;
      case "createSubcategory":
        if (form.createSubcategoryName.length >= 2) {
          checkCreateSubcategoryName = true;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: false,
          });
        } else {
          checkCreateSubcategoryName = false;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: true,
          });
        }

        if (form.createSubcategorySlug.length >= 2) {
          checkCreateSubcategorySlug = true;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: false,
          });
        } else {
          checkCreateSubcategorySlug = false;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: true,
          });
        }

        if (!checkCreateSubcategoryName && !checkCreateSubcategorySlug) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: true,
            createSubcategorySlug: true,
          });
        }

        if (checkCreateSubcategoryName && checkCreateSubcategorySlug) {
          checkCase = true;
        }

        break;
      case "update":
        if (form.updateName.length >= 2) {
          checkUpdateName = true;
          setUpdateFormError({ ...updateFormError, updateName: false });
        } else {
          checkUpdateName = false;
          setUpdateFormError({ ...updateFormError, updateName: true });
        }

        if (form.updateSlug.length >= 2) {
          checkUpdateSlug = true;
          setUpdateFormError({ ...updateFormError, updateSlug: false });
        } else {
          checkUpdateSlug = false;
          setUpdateFormError({ ...updateFormError, updateSlug: true });
        }

        if (!checkUpdateName && !checkUpdateSlug) {
          setUpdateFormError({
            ...updateFormError,
            updateName: true,
            updateSlug: true,
          });
        }

        if (checkUpdateName && checkUpdateSlug) {
          checkCase = true;
        }
        break;
      default:
        console.warn("Default of: handleOnValidation function");
    }

    return checkCase;
  }

  async function handleOnSubmit(props) {
    const { event, formRef } = props;
    const { name } = event.target;

    event.preventDefault();

    const { token } = user;

    switch (name) {
      case "create":
        setCreateBtnLoader(true);
        let validateCreate = handleOnValidation({
          name: "create",
          form: createForm,
        });

        if (validateCreate) {
          const getImageUpload = await getUploadImage({ name: "create" });

          setTimeout(() => {
            const selectedAttributes =
              selected && selected.map((item) => item._id);

            let createPayload = {
              name: createForm.createName,
              slug: createForm.createSlug,
              department: PERMISSIONS.grocery,
              attributes: selectedAttributes,
              isFeatured: isFeatured,
              isPopular: isPopular,
            };

            if (getImageUpload && getImageUpload.length !== 0) {
              createPayload = {
                ...createPayload,
                image: getImageUpload,
              };
            }

            try {
              axios
                .post(
                  `${SERVER_URL}/${AXIOS_API_CALL.categories}`,
                  { ...createPayload },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((res) => {
                  if (res.status === 201) {
                    setToggleCreate(false);
                    setReadDataRefetch((prevState) => !prevState);
                    setCreateForm({ createName: "", createSlug: "" });
                    setIsFeatured(false);
                    setImageData({ upload: [], image: [], blob: "" });
                    formRef.current.reset();

                    // notification
                    notification.success({
                      message: notificationMessages.successCreateCategory,
                      placement: "bottomLeft",
                    });
                    setTimeout(() => {
                      setCreateBtnLoader(false);
                    }, 700);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  setCreateBtnLoader(false);
                });
            } catch (err) {
              console.error(err);
            }
          }, 150);
        } else {
          setCreateBtnLoader(false);
        }
        break;

      case "createSubcategory":
        setCreateSubBtnLoader(true);
        let validateSubcategory = handleOnValidation({
          name: "createSubcategory",
          form: createSubcategoryForm,
        });

        if (validateSubcategory) {
          let subcategoryID = createSubcategoryFormId.id;
          const getImageUpload = await getUploadImage({
            name: "createSubcategory",
          });

          setTimeout(() => {
            let subcategoryCreatePayload = [];

            const selectedAttributes =
              selected && selected.map((item) => item._id);

            if (getImageUpload && getImageUpload.length === 0) {
              subcategoryCreatePayload = {
                name: createSubcategoryForm.createSubcategoryName,
                slug: createSubcategoryForm.createSubcategorySlug,
                department: PERMISSIONS.grocery,
                parent: subcategoryID,
                attributes: selectedAttributes,
              };
            } else {
              subcategoryCreatePayload = {
                name: createSubcategoryForm.createSubcategoryName,
                slug: createSubcategoryForm.createSubcategorySlug,
                department: PERMISSIONS.grocery,
                parent: subcategoryID,
                image: getImageUpload,
                attributes: selectedAttributes,
              };
            }

            try {
              axios
                .post(
                  `${SERVER_URL}/${AXIOS_API_CALL.categories}`,
                  { ...subcategoryCreatePayload },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((res) => {
                  if (res.status === 201) {
                    setToggleSubcategoryCreate(false);
                    setReadDataRefetch((prevState) => !prevState);
                    setCreateSubcategoryFormId({ id: "" });
                    setCreateSubcategoryForm({ createSubcategoryName: "" });
                    setImageSubcategoryData({
                      upload: [],
                      image: [],
                      blob: "",
                    });
                    formRef.current.reset();

                    // notification
                    notification.success({
                      message: notificationMessages.successCreateSubcategory,
                      placement: "bottomLeft",
                    });
                  }
                  setTimeout(() => {
                    setCreateSubBtnLoader(false);
                  }, 700);
                })
                .catch((err) => console.error(err));
            } catch (err) {
              console.error(err);
            }
          }, 150);
        } else {
          setCreateSubBtnLoader(false);
        }
        break;

      case "update":
        setEditBtnLoader(true);
        let validateUpdate = handleOnValidation({
          name: "update",
          form: updateForm,
        });

        if (validateUpdate) {
          let updateID = updateFormId.id;
          const getImageUpload = await getUploadImage({ name: "update" });

          setTimeout(() => {
            let updatePayload = [];

            const selectedAttributes =
              selected && selected.map((item) => item._id);

            if (getImageUpload && getImageUpload.length === 0) {
              if (!!updateForm.image && updateForm.image._id) {
                updatePayload = {
                  name: updateForm.updateName,
                  slug: updateForm.updateSlug,
                  parent: updateForm.parentCategory,
                  department: PERMISSIONS.grocery,
                  attributes: selectedAttributes,
                  isFeatured: isFeatured,
                  isPopular: isPopular,
                };
              } else {
                updatePayload = {
                  name: updateForm.updateName,
                  slug: updateForm.updateSlug,
                  image: null,
                  parent: updateForm.parentCategory,
                  department: PERMISSIONS.grocery,
                  attributes: selectedAttributes,
                  isFeatured: isFeatured,
                  isPopular: isPopular,
                };
              }
            } else {
              updatePayload = {
                name: updateForm.updateName,
                slug: updateForm.updateSlug,
                image: getImageUpload,
                parent: updateForm.parentCategory,
                department: PERMISSIONS.grocery,
                attributes: selectedAttributes,
                isFeatured: isFeatured,
                isPopular: isPopular,
              };
            }

            axios
              .put(
                `${SERVER_URL}/${AXIOS_API_CALL.categories}/${updateID}`,
                { ...updatePayload },
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  notification.success({
                    message: res.data.message,
                    placement: "bottomLeft",
                  });

                  setToggleUpdate(false);
                  setReadDataRefetch((prevState) => !prevState);
                  setUpdateFormId({ id: "" });
                  setIsFeatured(false);
                  setUpdateForm({ updateName: "" });
                  setImageUpdateData({ upload: [], image: [], blob: "" });
                  formRef.current.reset();
                }
              })
              .catch((err) => console.error(err))
              .finally(
                setTimeout(() => {
                  setEditBtnLoader(false);
                }, 700)
              );
          }, 150);
        } else {
          setEditBtnLoader(false);
        }
        break;

      case "delete":
        setDeleteBtnLoader(true);
        let deleteID = deleteFormId.id;

        try {
          await axios
            .delete(`${SERVER_URL}/${AXIOS_API_CALL.categories}/${deleteID}`, {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                setToggleDelete(false);
                setReadDataRefetch((prevState) => !prevState);
                setDeleteFormId({ id: "" });
                formRef.current.reset();
              }
              setTimeout(() => {
                setDeleteBtnLoader(false);
              }, 700);
            })
            .catch((err) => console.error(err));
        } catch (err) {
          console.error(err);
          setDeleteBtnLoader(false);
        }
        break;

      case "importNetSuite":
        setShowImportNetSuiteLoader(true);
        try {
          await axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.importCategoriesFromNetSuite}`,
              { department: PERMISSIONS.grocery },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                setReadDataRefetch((prevState) => !prevState);
                setToggleImportNetSuite(false);
                // notification
                notification.success({
                  message: res.data.message,
                  placement: "bottomLeft",
                });
              }
              setTimeout(() => {
                setShowImportNetSuiteLoader(false);
              }, 700);
            });
        } catch (err) {
          setShowImportNetSuiteLoader(false);
          setToggleImportNetSuite(false);
          console.error(err.message);
          // notification
          notification.warn({
            message: err.message,
            placement: "bottomLeft",
          });
          console.error("Import categories from NetSuite error: ", err);
        }
        break;
      default:
        console.warn("Default of: handleOnSubmit function");
    }
  }

  // PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
  };

  const createSubcategoryProps = {
    active: toggleSubcategoryCreate,
    onToggle: setToggleSubcategoryCreate,
    formRef: createSubcategoryFormRef,
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    loading: loadingUpdate,
  };

  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef,
  };

  const sortingActionsProps = {
    user: user,
    handleToggle: handleToggle,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  // import NetSuite PROPS
  const importNetSuiteProps = {
    active: toggleImportNetSuite,
    onToggle: setToggleImportNetSuite,
    formRef: importNetSuiteModalRef,
  };

  const [updateDataAttribute, setUpdateDataAttribute] = useState([]);
  useEffect(() => {
    if (
      updateData &&
      updateData.categoryData &&
      updateData.categoryData.length !== 0
    ) {
      setUpdateDataAttribute(updateData.categoryData.attributes);
    }
  }, [updateData]);

  return (
    <>
      <h2 className="page-title">Categories</h2>
      <Section className="section section__wrapper section__categories">
        {/* Header */}
        <header className="section__header">
          <div className="left">{!readDataLoading ? <h3 className="mb-0">Categories</h3> : <LoadingPlaceholder style={{ width: '94.5px', height: '50px' }} />}</div>

          <div className="right actions">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
              <>
                {!readDataLoading ? (
                  <button
                    style={{ opacity: '.2' }}
                    disabled
                    type="button"
                    onClick={() =>
                      handleToggle({
                        name: 'importNetSuite',
                        state: importNetSuiteProps,
                      })
                    }
                    data-cy="import-netsuite-btn"
                    className="btn btn-primary-outline pl-4 pr-4"
                  >
                    <span className="text">Import From NetSuite</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '238px', height: '50px' }} />
                )}
              </>
            )}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
              <>
                {!readDataLoading ? (
                  <button className="btn btn-primary pl-2 pr-2" data-cy="create-category-btn" onClick={() => handleToggle({ name: 'create', type: 'basic' })}>
                    <span className="text">Create Category</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '158.5px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{!readDataLoading ? <Sorting readData={readData} {...sortingActionsProps} /> : <Loading />}</Main>

        {/* Create - Parent category */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
          <SidebarModal {...createProps} className="sidebar__modal--right form-category" title="Create Category">
            <form name="create" ref={createFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: createFormRef })}>
              <div className={`form-group upload-image`}>
                {imageData.blob ? (
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
                    </div>

                    <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                      <span className="icon" data-cy="image-placeholder"></span>
                      <span className="text">Remove Image</span>
                    </button>
                  </div>
                ) : (
                  <div className="upload">
                    <div className="upload-wrapper d-flex gap-x-2">
                      <div>
                        <span className="text">
                          <div className="text-center">
                            <p className="mb-0">No Image</p>
                            <p className="mb-0">selected</p>
                          </div>
                        </span>
                        <input
                          type="file"
                          name="createImage"
                          id="uploadFile"
                          className="input-upload"
                          onChange={(event) =>
                            handleOnUpload({
                              name: event.target.name,
                              img: event.target.files[0],
                            })
                          }
                        />
                      </div>

                      <div className="validation-image">
                        <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                          * You can only upload JPG/PNG file!
                        </p>

                        <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                          * Image must smaller than 2MB!
                        </p>
                      </div>
                    </div>
                    <label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                      <span className="icon">
                        <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                      </span>
                      <span className="text">Upload Image</span>
                    </label>
                  </div>
                )}
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createName">
                  <b className="required">Name:</b>
                </label>

                <input name="createName" id="createName" value={createForm.createName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-input" className={`input ${createFormError.createName || createFormError.uniqueName ? 'input-error' : ''}`} />

                <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? '' : createFormError.createName ? 'error' : ''}`}>
                  * This field is required!
                </p>
                <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? 'error' : ''}`}>
                  * This category name already exists!
                </p>
              </div>
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createSlug">
                  <b className="required">Slug:</b>
                </label>

                <input name="createSlug" id="createSlug" value={createForm.createSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-input" className={`input ${createFormError.createSlug || createFormError.uniqueSlug ? 'input-error' : ''}`} />

                <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createFormError.uniqueSlug ? '' : createFormError.createSlug ? 'error' : ''}`}>
                  * This field is required!
                </p>
                <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueSlug ? 'error' : ''}`}>
                  * This category slug already exists!
                </p>
              </div>

              <DropdownSelect type="create-category" data={attributes} update={[]} toggle={toggleCreate} handleOnSelect={handleOnSelect} setSelected={setSelected} />
              <div className="featured-cat-wrap">
                <input
                  type="checkbox"
                  name="createFeatured"
                  id="createFeatured"
                  required={false}
                  onChange={(event) => {
                    setIsFeatured((prev) => !prev);
                    // onChange(event);
                  }}
                  checked={isFeatured}
                />
                <label className="label mb-1 d-block" htmlFor="createFeatured">
                  <b>Featured category</b>
                </label>
              </div>
              <div className='popular-cat-wrap'>
                <input
                  type='checkbox'
                  name='createPopular'
                  id='createPopular'
                  required={false}
                  checked={isPopular}
                  onChange={() => {
                    setIsPopular(prev => !prev)
                  }}
                />
                <label className="label mb-1 d-block" htmlFor='createPopular'>
                  <b>Popular category</b>
                </label>
              </div>
              <div className="form-group form-group-sidebar">
                <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
                  <span className="text">Cancel</span>
                </button>

                {!createBtnLoader ? (
                  <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreate}>
                    <span className="text">Create Category</span>
                  </button>
                ) : (
                  <button type="button" className="btn  btn-primary-outline">
                    <span className="text">Creating...</span>
                  </button>
                )}
              </div>
            </form>
          </SidebarModal>
        )}

        {/* Create - Subcategory */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
          <SidebarModal {...createSubcategoryProps} className="sidebar__modal--right form-subcategory" title="Create Subcategory">
            <form
              name="createSubcategory"
              ref={createSubcategoryFormRef}
              className="ant-form ant-form-horizontal"
              onSubmit={(event) =>
                handleOnSubmit({
                  event: event,
                  formRef: createSubcategoryFormRef,
                })
              }
            >
              <div className={`form-group upload-image`}>
                {imageSubcategoryData.blob ? (
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={imageSubcategoryData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
                    </div>

                    <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                      <span className="icon" data-cy="image-placeholder"></span>
                      <span className="text">Remove Image</span>
                    </button>
                  </div>
                ) : (
                  <div className="upload">
                    <div className="upload-wrapper d-flex gap-x-2">
                      <div>
                        <span className="text">
                          <div className="text-center">
                            <p className="mb-0">No Image</p>
                            <p className="mb-0">selected</p>
                          </div>
                        </span>
                        <input
                          type="file"
                          name="createSubcategoryImage"
                          id="createSubcategoryImage"
                          className="input-upload"
                          onChange={(event) =>
                            handleOnUpload({
                              name: event.target.name,
                              img: event.target.files[0],
                            })
                          }
                        />
                      </div>

                      <div className="validation-image">
                        <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                          * You can only upload JPG/PNG file!
                        </p>

                        <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                          * Image must smaller than 2MB!
                        </p>
                      </div>
                    </div>
                    <label htmlFor="createSubcategoryImage" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                      <span className="icon">
                        <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                      </span>
                      <span className="text">Upload Image</span>
                    </label>
                  </div>
                )}
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createSubcategoryName">
                  <b className="required">Name:</b>
                </label>

                <input name="createSubcategoryName" id="createSubcategoryName" value={createSubcategoryForm.createSubcategoryName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-subcategory-input" className={`input ${createSubcategoryFormError.createSubcategoryName || createSubcategoryFormError.uniqueSubcategoryName ? 'input-error' : ''}`} />
                <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? '' : createSubcategoryFormError.createSubcategoryName ? 'error' : ''}`}>
                  * This field is required!
                </p>
                <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? 'error' : ''}`}>
                  * This category name already exists!
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="createSubcategorySlug">
                  <b className="required">Slug:</b>
                </label>

                <input name="createSubcategorySlug" id="createSubcategorySlug" value={createSubcategoryForm.createSubcategorySlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-subcategory-input" className={`input ${createSubcategoryFormError.uniqueSubcategorySlug || createSubcategoryFormError.createSubcategorySlug ? 'input-error' : ''}`} />

                <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? '' : createSubcategoryFormError.createSubcategorySlug ? 'error' : ''}`}>
                  * This field is required!
                </p>
                <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? 'error' : ''}`}>
                  * This category slug already exists!
                </p>
              </div>

              <DropdownSelect type="create-subcategory" data={attributes} update={[]} toggle={toggleSubcategoryCreate} handleOnSelect={handleOnSelect} setSelected={setSelected} />

              <div className="form-group form-group-sidebar">
                <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleSubcategoryCreate(false)}>
                  <span className="text">Cancel</span>
                </button>

                {!createSubBtnLoader ? (
                  <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreateSub}>
                    <span className="text">Create Subcategory</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Creating ...</span>
                  </button>
                )}
              </div>
            </form>
          </SidebarModal>
        )}

        {/* Update */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateCategories) && (
          <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Category">
            {!loadingUpdate ? (
              <form name="update" ref={updateFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: updateFormRef })}>
                <div className="form-group upload-image">
                  {!!updateForm.image && updateForm.image.length !== 0 ? (
                    <>
                      <div className="avatar">
                        <div className="image-wrapper">
                          <img src={getImageUrl(updateForm.image.url)} alt="Update category" data-cy="parent-update-category-image" className="img" />
                        </div>

                        <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'updateRemove' })}>
                          <span className="icon"></span>
                          <span className="text">Remove Image</span>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {imageUpdateData.blob ? (
                        <div className="avatar">
                          <div className="image-wrapper">
                            <img src={imageUpdateData.blob} alt="Update category blob" data-cy="update-parent-new-image" className="img" />
                          </div>

                          <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'update' })}>
                            <span className="icon"></span>
                            <span className="text">Remove Image</span>
                          </button>
                        </div>
                      ) : (
                        <div className="upload">
                          <div className="upload-wrapper d-flex gap-x-2">
                            <div>
                              <span className="text" data-cy="update-parent-image-placeholder">
                                <div className="text-center">
                                  <p className="mb-0">No Image</p>
                                  <p className="mb-0">selected</p>
                                </div>
                              </span>
                              <input
                                type="file"
                                name="updateImage"
                                id="updateUploadFile"
                                className="input-upload"
                                onChange={(event) =>
                                  handleOnUpload({
                                    name: event.target.name,
                                    img: event.target.files[0],
                                  })
                                }
                              />
                            </div>

                            <div className="validation-image">
                              <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageType ? 'error' : ''}`}>
                                * You can only upload JPG/PNG file!
                              </p>

                              <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageSize ? 'error' : ''}`}>
                                * Image must smaller than 2MB!
                              </p>
                            </div>
                          </div>
                          <label htmlFor="updateUploadFile" data-cy="update-parent-upload-btn" className="label btn btn-primary-outline btn-no-hover">
                            <span className="icon">
                              <img src="/assets/icons/upload.svg" alt="Update category" className="img" />
                            </span>
                            <span className="text">Upload Image</span>
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="form-group relative">
                  <label className="label mb-1 d-block" htmlFor="updateName">
                    <b className="required">Name:</b>
                  </label>

                  <input name="updateName" id="updateName" defaultValue={updateForm.updateName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy={createFormParent.parent ? 'update-name-category' : 'update-name-subcategory'} className={`input ${updateFormError.updateName || updateFormError.uniqueName ? 'input-error' : ''}`} />

                  <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? '' : updateFormError.updateName ? 'error' : ''}`}>
                    * This field is required!
                  </p>
                  <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? 'error' : ''}`}>
                    * This category name already exists!
                  </p>
                </div>
                <div className="form-group relative">
                  <label className="label mb-1 d-block" htmlFor="updateSlug">
                    <b className="required">Slug:</b>
                  </label>

                  <input name="updateSlug" id="updateSlug" value={updateForm.updateSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-update-input" className={`input ${updateFormError.updateSlug || updateFormError.uniqueSlug ? 'input-error' : ''}`} />

                  <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? '' : updateFormError.updateSlug ? 'error' : ''}`}>
                    * This field is required!
                  </p>
                  <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? 'error' : ''}`}>
                    * This category slug already exists!
                  </p>
                </div>

                <div className="form-group relative">
                  <label className="label mb-1 d-block">
                    <b>Url:</b>
                  </label>

                  <input name="updateUrl" disabled id="updateUrl" value={updateForm.updateUrl} type="text" placeholder="Url" data-cy="url-update-input" className={`input`} />
                </div>

                {/* createFormParent.parent */}

                <div className="form-group">
                  <label className="label mb-1 d-block" htmlFor="parentCategory">
                    <b>Parent Category:</b>
                  </label>

                  <select className="select select-full" id="parentCategory" name="parentCategory" defaultValue={updateParentFormId && !!updateParentFormId.id ? updateParentFormId.id : 'none'} onChange={handleOnChange}>
                    <option value="none">None</option>
                    {updateFormDropdown &&
                      updateFormDropdown.length > 0 &&
                      updateFormDropdown.map((item, idx) => {
                        return (
                          <option key={idx} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <DropdownSelect type="update-category" data={attributes} update={updateDataAttribute} toggle={toggleUpdate} handleOnSelect={handleOnSelect} setSelected={setSelected} />
                {createFormParent.parent && (
                  <>
                    <div className="featured-cat-wrap">
                      <input
                        type="checkbox"
                        name="createFeatured"
                        id="createFeatured"
                        required={false}
                        onChange={(event) => {
                          setIsFeatured((prev) => !prev);
                        }}
                        checked={isFeatured}
                      />
                      <label className="label mb-1 d-block" htmlFor="createFeatured">
                        <b>Featured category</b>
                      </label>
                    </div>

                    <div className='popular-cat-wrap'>
                      <input
                        type="checkbox"
                        name="createPopular"
                        id="createPopular"
                        required={false}
                        checked={isPopular}
                        onChange={() => {
                          setIsPopular(prev => !prev)
                        }}
                      />
                      <label className="label mb-1 d-block" htmlFor="createPopular">
                        <b>Popular category</b>
                      </label>
                    </div>
                  </>
                )}
                <div className="form-group form-group-sidebar">
                  <button data-cy="modal_update_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
                    <span className="text">Cancel</span>
                  </button>

                  {!editBtnLoader ? (
                    <button data-cy="modal_update_submit-btn" type="submit" className="btn btn-primary" disabled={disabledUpdate}>
                      <span className="text">Save Changes</span>
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary-outline">
                      <span className="text">Saving...</span>
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <Loading />
            )}
          </SidebarModal>
        )}

        {/* Delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteCategories) && (
          <Modal {...deleteProps} className="sidebar__modal--center" title="">
            <form name="delete" ref={deleteFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: deleteFormRef })}>
              <h2 className="text-center mb-4">Are you sure you want to delete the selected item ?</h2>

              <div className="form-group form-group-modal mb-4">
                {!deleteBtnLoader ? (
                  <button data-cy="modal_delete_submit-btn" type="submit" className="btn btn-primary-link">
                    <span className="text">Yes</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-link">
                    <span className="text">Deleting...</span>
                  </button>
                )}

                <button data-cy="modal_delete_cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
                  <span className="text">No</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
        {/* Import NetSuite Modal */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
          <Modal {...importNetSuiteProps} className="sidebar__modal--center" title="">
            <form name="importNetSuite" onSubmit={(event) => handleOnSubmit({ event: event, name: 'importNetSuite' })}>
              <h2 className="text-center mb-4">Are you sure you want to import from NetSuite ?</h2>

              <div className="form-group form-group-modal mb-4">
                {!showImportNetSuiteLoader ? (
                  <button data-cy="modal-import-netsuite-submit-btn" type="submit" className="btn btn-primary-link">
                    <span className="text">Yes</span>
                  </button>
                ) : (
                  <button data-cy="modal-import-netsuite-submit-btn" type="button" className="btn btn-primary-link">
                    <span className="text relative" style={{ fontSize: 'smaller' }}>
                      Importing
                      <LoadingSpinnerButton className="loader-button" />
                    </span>
                  </button>
                )}

                <button data-cy="modal-import-netsuite-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleImportNetSuite(false)}>
                  <span className="text">No</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
      </Section>
    </>
  );
};

export default Categories;
