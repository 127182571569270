import { useProducts } from "../../../../../../context/products";
import { useEffect } from "react";

export const General = (props) => {
  const { onChange, formError, form, setTabs } = useProducts();

  useEffect(() => {
    if (
      formError.productRegularPrice.empty ||
      formError.productRegularPrice.format ||
      formError.productSalePrice.bigger ||
      formError.productSalePrice.format ||
      formError.productWeight.empty ||
      formError.productWeight.format
    ) {
      setTabs(1);
    }
  }, [formError.productSalePrice, formError.productRegularPrice]);

  return (
    <form name="product-general" className="form">
      <div className="form-group">
        <label className="label" htmlFor="productWeight">
          <b>Weight:</b>
        </label>

        <input
          onChange={onChange}
          name="productWeight"
          type="text"
          value={form.productWeight}
          placeholder="Weight"
          id="productWeight"
          inputMode="numeric"
          className={`input ${
            formError.productWeight?.empty || formError.productWeight?.format
              ? "error"
              : ""
          }`}
        />

        <p
          data-cy=""
          className={`error__onblur ${
            formError.productWeight?.empty ? "error" : ""
          }`}
        >
          * This field is required!
        </p>
        <p
          data-cy=""
          className={`error__onblur ${
            formError.productWeight?.format ? "error" : ""
          }`}
        >
          * This field must have none or one decimal point!
        </p>
      </div>
      <div className="form-group">
        <label className="label" htmlFor="productRegularPrice">
          <b>Regular Price:</b>
        </label>

        <input
          onChange={onChange}
          name="productRegularPrice"
          type="text"
          value={form.productRegularPrice}
          placeholder="Regular Price"
          id="productRegularPrice"
          inputMode="numeric"
          className={`input ${
            formError.productRegularPrice?.empty ||
            formError.productRegularPrice?.format
              ? "error"
              : ""
          }`}
        />

        <p
          data-cy=""
          className={`error__onblur ${
            formError.productRegularPrice?.empty ? "error" : ""
          }`}
        >
          * This field is required!
        </p>
        <p
          data-cy=""
          className={`error__onblur ${
            formError.productRegularPrice?.format ? "error" : ""
          }`}
        >
          * This field must have none or two decimal points!
        </p>
      </div>

      <div className="form-group">
        <label className="label" htmlFor="productSalePrice">
          <b>Sale Price:</b>
        </label>

        <input
          onChange={onChange}
          name="productSalePrice"
          type="text"
          placeholder="Sale Price"
          id="productSalePrice"
          inputMode="numeric"
          className={`input ${
            formError.productSalePrice?.bigger ||
            formError.productSalePrice?.format
              ? "error"
              : ""
          }`}
          value={form.productSalePrice}
        />
        {formError.productSalePrice.format ? (
          <p data-cy="" className="error__onblur error">
            * This field must have none or two decimal points!
          </p>
        ) : (
          formError.productSalePrice.bigger && (
            <p data-cy="" className="error__onblur error">
              * Sale price must be lower than regular price!
            </p>
          )
        )}
      </div>
    </form>
  );
};
