import React from "react";
import ReactDOM from "react-dom/client";
import MyApp from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/useAuth";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import "./main.scss";
import { CartProvider } from "./context/cart";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <AuthProvider>
        <CartProvider>
          <MyApp />
        </CartProvider>
      </AuthProvider>
    </BrowserRouter>
  </DndProvider>
);

reportWebVitals();
