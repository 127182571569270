import React, { Fragment } from "react";
import SimpleProduct from "./cartProducts/CartSimpleProduct";
import VariableProduct from "./cartProducts/CartVariableProduct";
import { stateUS } from "../../helpers/state";

const WicCart = ({
  products,
  selected,
  onClick,
  readAddress,
  isSplitShipping,
  isAddressConfirmed,
  className,
}) => {
  const getAddressStateName = (state) => {
    const stateResult = stateUS.find((item) => item.abbreviation === state);
    return stateResult.name;
  };

  const checkDefaultAddress = (isDefault) => {
    if (isDefault) {
      return "(default)";
    } else {
      return "";
    }
  };

  const isAddressLength = readAddress && readAddress.length > 0;
  const wicProductProps = {
    onClick: onClick,
    selected: selected,
    isAddressConfirmed: isAddressConfirmed,
  };

  if (products && products.length < 1) {
    return <p className="no-data-found">No WIC Product Found</p>;
  }

  return (
    <Fragment>
      <div className={className}>
        {products.map((product) => {
          const { variations, productType } = product;

          return (
            <Fragment key={product._id}>
              {productType === "simple_product" ? (
                <SimpleProduct
                  from="wic"
                  product={product}
                  variations={variations}
                  {...wicProductProps}
                />
              ) : (
                <VariableProduct
                  from="wic"
                  product={product}
                  variations={variations}
                  {...wicProductProps}
                />
              )}
            </Fragment>
          );
        })}
      </div>

      {isAddressLength && (
        <Fragment>
          {isSplitShipping && (
            <div className="select-address-wrapper">
              <div className="form-group">
                <label htmlFor="wicAddressChanged">Choose your address:</label>

                {isAddressConfirmed ? (
                  <select disabled={true} className="select select-address">
                    {readAddress.map((item) => {
                      const {
                        address,
                        city,
                        state,
                        isDefault,
                        _id,
                        zip_code,
                      } = item;
                      return (
                        <option value={_id} key={_id}>
                          {address}, {city}, {zip_code},{" "}
                          {getAddressStateName(state)}{" "}
                          {checkDefaultAddress(isDefault)}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select
                    disabled={false}
                    id="wicAddressChanged"
                    name="wicAddressChanged"
                    onChange={(e) =>
                      onClick({
                        state: null,
                        action: { type: "wicAddressChanged", event: e },
                      })
                    }
                    className="select select-address"
                  >
                    {readAddress.map((item) => {
                      const {
                        address,
                        city,
                        state,
                        isDefault,
                        _id,
                        zip_code,
                      } = item;
                      return (
                        <option value={_id} key={_id}>
                          {address}, {city}, {zip_code},{" "}
                          {getAddressStateName(state)}{" "}
                          {checkDefaultAddress(isDefault)}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default WicCart;
