import React from 'react';

const CustomSelect = ({ options, label, limit, setLimit,setCurrentPage, setRefetch, loading,setSessionStorage }) => {
  const handleChange = (e) => {
    setLimit(+e.target.value);
    setRefetch((prevState) => !prevState);
    if(setCurrentPage){
      setCurrentPage(1)
    }
    if(setSessionStorage){
      sessionStorage.setItem('showItems',e.target.value)
    }
  };

  return (
    <select onChange={handleChange} disabled={loading} defaultValue={limit} required>
      {options.map((option) => {
        return (
          <option value={option} key={option}>
            {option} {label}
          </option>
        );
      })}
    </select>
  );
};

export default CustomSelect;
