import React, { useEffect, useState } from "react";

export default function CreateButton({
  text,
  className,
  onToggle,
  isDisabled,
  guestValidationZipError,
}) {
  if (isDisabled || guestValidationZipError) {
    return (
      <button
        disabled={true}
        title="Your Payment method is created!"
        type="button"
        className={className}
      >
        <span className="text">{text}</span>
      </button>
    );
  }

  return (
    <button
      disabled={false}
      onClick={() =>
        onToggle({ action: { type: "createCreditCard", payload: "" } })
      }
      type="button"
      className={className}
    >
      <span className="text">{text}</span>
    </button>
  );
}
