import React, { useEffect, useState } from "react";
import Checkbox from "../../../components/customCheckbox/CustomCheckbox";
import { PERMISSIONS } from "../../../utils/permissions";
import Message from "../../../components/message/Message";
import { contentMessage } from "../../../helpers/contentMessage";
import Pagination from "../../../components/pagination/Pagination";

const Table = (props) => {
  // TABLE
  const { data, handleToggle } = props;
  // ACTIONS
  const { groceryPermissions, getGroceryPermissions, handleCheckedRows, handleDisabledButton } = props;
  // PAGINATION
  const { pagination } = props;
  const { createUnitProps, updateProps, deleteProps } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  
  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [handleCheckedRows, isCheck]);

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>
                  {Object.values(groceryPermissions).some((key) =>
                    [PERMISSIONS.updateCustomers].includes(key.name)
                  ) && (
                    <Checkbox
                      type="checkbox"
                      name="select-all-customers"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  )}
                </th>
                <th>Name</th>
                <th style={{ textAlign: "center" }}>Type</th>
                <th style={{ textAlign: "center" }}>Discount %</th>
                <th style={{ textAlign: "center" }}>Expiration Date</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {data && data.length > 0 ? (
                data.map((item, idx) => {
                  const { type, valid_thru, value, discount_percentage } = item;
                  const validDate = new Date(valid_thru);
                  return (
                    <tr key={idx}>
                      <td data-cy={`checkbox-item-products-${idx}`}>
                        {Object.values(groceryPermissions).some((key) =>
                          [PERMISSIONS.updateCustomers].includes(key.name)
                        ) && (
                          <div>
                            <Checkbox
                              key={item._id}
                              type="checkbox"
                              name={item._id}
                              id={item._id}
                              handleClick={handleClick}
                              isChecked={isCheck.includes(item._id)}
                            />
                          </div>
                        )}
                      </td>
                      <td>{value}</td>
                      <td>
                        <div
                          style={{ justifyContent: "center" }}
                          className="d-flex gap-x-2"
                        >
                          <div className="badge badge-silver">
                            {type ? <span className="text">{type}</span> : null}
                          </div>
                        </div>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {discount_percentage}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {validDate.toLocaleDateString()}
                      </td>
                      <td>
                        <div className="actions couponsTable">
                          {getGroceryPermissions(
                            groceryPermissions,
                            PERMISSIONS.updateCoupons
                          ) && (
                            <button
                              className="btn btn-actions"
                              type="button"
                              data-cy="actions-update-attributes"
                              onClick={() =>
                                handleToggle({
                                  name: "update",
                                  state: updateProps,
                                  data: item,
                                })
                              }
                            >
                              <span className="icon">
                                <img
                                  alt="Update"
                                  src="/assets/icons/edit-silver.svg"
                                />
                              </span>
                              <span className="text">
                                <strong></strong>
                              </span>
                            </button>
                          )}

                          {getGroceryPermissions(
                            groceryPermissions,
                            PERMISSIONS.deleteCoupons
                          ) && (
                            <button
                              className="btn btn-actions"
                              type="button"
                              data-cy="actions-delete-attributes"
                              onClick={() =>
                                handleToggle({
                                  name: "delete",
                                  state: deleteProps,
                                  data: item,
                                })
                              }
                            >
                              <span className="icon">
                                <img
                                  alt="Delete"
                                  src="/assets/icons/delete.svg"
                                />
                              </span>
                              <span className="text">
                                <strong></strong>
                              </span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={20}
                    style={{
                      backgroundColor: "#fff",
                      height: "200px",
                      border: "0",
                    }}
                  >
                    <h2 style={{ color: "#666" }}>Data not found!</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination {...pagination} />
        </>
      ) : (
        <Message className="content-message">
          <h2 style={{ color: "#666" }}>{contentMessage.dataNotFound}</h2>
        </Message>
      )}
    </>
  );
};

export default Table;
