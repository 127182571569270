import React, {
  Fragment,
  useEffect,
  useCallback,
  useState,
  useRef,
  forwardRef,
  memo,
} from "react";
import { notification } from "antd";
import LoadingPlaceholder from "../../loadingPlaceholder/LoadingPlaceholder";

function UserPaymentMethodList(props) {
  return <FormPaymentMethod className="form-payment-method" {...props} />;
}

function FormPaymentMethod(props) {
  const {
    readPayment,
    totalAmountValue,
    correctLeftToPay,
    setPaymentMethod,
    className,
    deletePaymentFlag,
    deletePaymentId,
    isDisabled,
    isSplitPayment,
    setIsSplitPayment,
  } = props;

  const [disableSplit, setDisabledSplit] = useState(true);

  useEffect(() => {
    if (readPayment && readPayment.length > 1) {
      setDisabledSplit(false);
    } else {
      setDisabledSplit(true);
    }
  }, [readPayment, setDisabledSplit]);

  const [form, setForm] = useState({
    simplePayment: "",
    simpleGuestPayment: "",
    multiPayment: [],
    multiPaymentAmount: [],
  });

  useEffect(() => {
    if (readPayment && readPayment.length > 0) {
      const payment = readPayment.find((p) => p.isDefault);

      setForm((prev) => {
        const payload = {
          simplePayment: payment.PaymentMethodId,
          simpleGuestPayment: "",
          multiPayment: [],
          multiPaymentAmount: [],
        };

        return {
          ...prev,
          ...payload,
        };
      });
    }
  }, [readPayment]);

  useEffect(() => {
    const removePaymentId = deletePaymentId._id;

    if (removePaymentId && removePaymentId !== "" && deletePaymentFlag) {
      setForm((prev) => {
        let multiPayment = prev.multiPayment.filter(
          (p) => p !== removePaymentId
        );
        let multiPaymentAmount = prev.multiPaymentAmount.filter(
          (p) => p.PaymentMethodId !== removePaymentId
        );

        return {
          ...prev,
          multiPayment: multiPayment,
          multiPaymentAmount: multiPaymentAmount,
        };
      });
    }
  }, [deletePaymentFlag, deletePaymentId._id]);

  const onChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "multiPayment") {
      setForm((prev) => {
        const payments = readPayment;
        let findMulti = prev.multiPayment;
        let prevMulti = prev.multiPaymentAmount;

        const multiPaymentAmount = [...payments]
          .filter((p) => p.PaymentMethodId.includes(value))
          .map((v) => {
            return {
              ...v,
              value: "",
            };
          });

        if (checked) {
          return {
            ...prev,
            [name]: [...prev.multiPayment, value],
            multiPaymentAmount: [...prevMulti, ...multiPaymentAmount],
          };
        }

        return {
          ...prev,
          [name]: [...findMulti].filter((p) => p !== value),
          multiPaymentAmount: [...prevMulti].filter(
            (p) => p.PaymentMethodId !== value
          ),
        };
      });
    } else {
      setForm((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }

    if (name === "simplePayment") {
      setForm((prev) => {
        const payload = {
          simplePayment: value,
        };

        return {
          ...prev,
          ...payload,
        };
      });
    }
  };

  const isNumber = (val) => {
    const numberRegex = /^(?:\d{1,}(\.\d{0,2})?)?$/;

    if (!numberRegex.test(val)) {
      notification.warn({
        message: "This value is not a number! Please enter the number.",
        placement: "bottomLeft",
      });

      return false;
    }

    return true;
  };

  const onChangeAmount = (event, payment) => {
    const { value } = event.target;

    if (!isNumber(value)) {
      return;
    }

    setForm((prev) => {
      let prevPayment = prev.multiPaymentAmount;
      let findPayment = prevPayment.find(
        (p) => p.PaymentMethodId === payment.PaymentMethodId
      );

      if (findPayment) {
        findPayment.value = value;

        return {
          ...prev,
        };
      }

      const payload = {
        ...payment,
        value: value,
      };

      return {
        ...prev,
        multiPaymentAmount: [...prevPayment, payload],
      };
    });
  };

  useEffect(() => {
    if (!isSplitPayment) {
      setForm((prev) => {
        return {
          ...prev,
        };
      });
    }
  }, [isSplitPayment]);

  useEffect(() => {
    if (form && Object.keys(form).length > 0) {
      const payload = {
        simplePayment: form.simplePayment,
        simpleGuestPayment: form.simpleGuestPayment,
        multiPayment: form.multiPayment,
        multiPaymentAmount: form.multiPaymentAmount,
      };

      setPaymentMethod(payload);
    }
  }, [form, isSplitPayment]);

  const paymentCardListProps = {
    form: form,
    onChange: onChange,
    onChangeAmount: onChangeAmount,
  };

  return (
    <form className={`form ${className}`}>
      <div className="form-group form-group-split-payment">
        <div className="left">
          <input
            disabled={disableSplit || isDisabled}
            id="useSplitPayment"
            name="split-payment"
            checked={isSplitPayment}
            onChange={() => setIsSplitPayment((p) => !p)}
            className="checkbox"
            type="checkbox"
          />

          <label className="label" htmlFor="useSplitPayment">
            Use Split Payment (min two card created)
          </label>
        </div>

        <div className="right">
          {isSplitPayment && (
            <div className="total">
              <span className="text">Left to pay: </span>
              <span className="value">${totalAmountValue}</span>
            </div>
          )}
        </div>
      </div>

      <div className={`payment-list ${correctLeftToPay ?? ""}`}>
        <PaymentCardList {...props} {...paymentCardListProps} />
      </div>
    </form>
  );
}

function PaymentCardList(props) {
  const { readPaymentLoading, readPayment, paymentErrorNoFound } = props;

  return (
    <Fragment>
      {!readPaymentLoading ? (
        <Fragment>
          {readPayment && readPayment.length > 0 ? (
            <Fragment>
              {readPayment.map((payment) => {
                const _id = payment.PaymentMethodId;

                return (
                  <PaymentCardItem key={_id} {...props} payment={payment} />
                );
              })}
            </Fragment>
          ) : (
            <div
              className={`no-data-found ${!paymentErrorNoFound ? "error" : ""}`}
            >
              <p>No Payment Method Found</p>
            </div>
          )}
        </Fragment>
      ) : (
        <div className="loading-wrapper">
          <LoadingPlaceholder />
        </div>
      )}
    </Fragment>
  );
}

function PaymentCardItem(props) {
  const {
    payment,
    form,
    onChange,
    onChangeAmount,
    isSplitPayment,
    isDisabled,
    isCheckGuestPayment,
    paymentErrorNoFound,
  } = props;

  const toggleRef = useRef(null);

  const addSlashToExpiryDate = (exp) => {
    return [exp?.slice(0, 2), "/", exp?.slice(2)].join("");
  };

  const handleOnPaste = (event) => {
    const clipboardData = event.clipboardData || window?.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 1) {
      event.preventDefault();
      notification.error({
        message: "Paste is blocked! Please write amount value in input field!",
        placement: "bottomLeft",
      });
    }
  };

  const onKeyDown = (event) => {
    let isNotAllowed = [10, 16, 17, 65, 67, 86];
    if (event.ctrlKey === true || isNotAllowed.includes(event.which)) {
      event.preventDefault();
      return false;
    }
  };

  const isCheckDefault = ({ form, card, type }) => {
    switch (type) {
      case "simplePayment":
        return form.simplePayment === card.PaymentMethodId;
      case "simpleGuestPayment":
        return form.simpleGuestPayment === card.PaymentMethodId;
      case "multiPayment":
        return form.multiPayment.some((p) => p.includes(card.PaymentMethodId));
      case "mulitPaymentEnable":
        return !form.multiPayment.some((p) => p.includes(card.PaymentMethodId));
      default:
        console.warn("Update is default isCheckDefault functionlity");
    }
  };

  const isAmountValue = (payment, id) => {
    const value = payment.find((p) => p.PaymentMethodId.includes(id));

    if (value) {
      return value.value;
    }

    return "";
  };

  if (!isSplitPayment) {
    return (
      <div className={`form-group form-group-selected form-group-radio`}>
        <div className="left">
          <input
            disabled={isDisabled}
            checked={isCheckDefault({
              form: form,
              card: payment,
              type: "simplePayment",
            })}
            onChange={(e) => onChange(e)}
            name="simplePayment"
            value={payment.PaymentMethodId}
            id={payment.PaymentMethodId}
            type="radio"
            className="radio"
          />

          <label htmlFor={payment.PaymentMethodId} className="label">
            <div className="image">
              <img
                src={`/assets/images/${payment.CardType}.png`}
                alt={payment.CardType}
                layout="fill"
              />
            </div>
            <p>
              {payment.MaskedNumber}, {payment.Name},{" "}
              <span style={{ fontStyle: "italic" }}>
                exp: {addSlashToExpiryDate(payment.Exp)}
              </span>{" "}
              {payment.isDefault && "(Default)"}
            </p>
          </label>
        </div>

        <div className="right">
          <DropdownAction
            {...props}
            action="user-payment"
            ref={toggleRef}
            className="action-wrapper"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="form-group form-group-selected form-group-checkbox">
      <div className="left">
        <div className="card-details">
          <input
            disabled={isDisabled}
            checked={isCheckDefault({
              form: form,
              card: payment,
              type: "multiPayment",
            })}
            value={payment.PaymentMethodId}
            onChange={(e) => onChange(e)}
            name="multiPayment"
            id={payment.PaymentMethodId}
            type="checkbox"
            className="checkbox"
          />

          <label
            disabled={isDisabled}
            htmlFor={payment.PaymentMethodId}
            className="label"
          >
            <div className="image">
              <img
                src={`/assets/images/${payment.CardType}.png`}
                alt={payment.CardType}
                layout="fill"
                objectFit="contain"
              />
            </div>
            <p>
              {payment.MaskedNumber}, {payment.Name},{" "}
              <span style={{ fontStyle: "italic" }}>
                exp: {addSlashToExpiryDate(payment.Exp)}
              </span>{" "}
              {payment.isDefault && "(Default)"}
            </p>
          </label>
        </div>
      </div>

      <div className="right">
        <div className="actions">
          <div className="amount-wrapper">
            <label
              htmlFor={payment.PaymentMethodId + "_amount"}
              className="label"
            >
              Payment amount:
            </label>

            <div
              className={`prefix ${
                isCheckDefault({
                  form: form,
                  card: payment,
                  type: "mulitPaymentEnable",
                })
                  ? "prefix-disabled"
                  : ""
              }`}
            >
              <span className="cash">$</span>

              <input
                onChange={(e) => onChangeAmount(e, payment)}
                name="multiPaymentAmount"
                id={payment.PaymentMethodId + "_amount"}
                onPaste={(e) => handleOnPaste(e)}
                onKeyDown={(e) => onKeyDown(e)}
                disabled={
                  isCheckDefault({
                    form: form,
                    card: payment,
                    type: "mulitPaymentEnable",
                  }) || isDisabled
                }
                value={isAmountValue(
                  form.multiPaymentAmount,
                  payment.PaymentMethodId
                )}
                type="text"
                className="number cost-amount"
              />
            </div>
          </div>

          <DropdownAction
            {...props}
            isCheckDefault={isCheckDefault}
            action="user-split-payment"
            ref={toggleRef}
            className="action-wrapper"
          />
        </div>
      </div>
    </div>
  );
}

const DropdownAction = forwardRef((props, ref) => {
  const { className, isDisabled, payment, onToggleAction } = props;

  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((p) => !p);
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setToggle(false);
      }
    },
    [handleToggle, ref]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <div className={className}>
      <button
        disabled={isDisabled}
        ref={ref}
        onClick={() => handleToggle()}
        className="btn btn-action"
        type="button"
      >
        <span className="icon">
          <img
            src="/assets/icons/action-dots.svg"
            alt="Actions"
            width={36}
            height={36}
          />
        </span>
      </button>

      <div className={`dropdown ${toggle ? "isActive" : ""}`}>
        <button
          onClick={() =>
            onToggleAction({
              action: { type: "action-update", payload: payment },
            })
          }
          type="button"
          className="btn btn-action-update"
        >
          <span className="icon">
            <img
              src="/assets/icons/update.svg"
              alt="Account Settings"
              width={20}
              height={20}
            />
          </span>
          <span className="text">Update</span>
        </button>

        <button
          onClick={() =>
            onToggleAction({
              action: { type: "action-delete", payload: payment },
            })
          }
          type="button"
          className="btn btn-action-delete"
        >
          <span className="icon">
            <img
              src="/assets/icons/delete.svg"
              alt="Account Settings"
              width={20}
              height={20}
            />
          </span>
          <span className="text">Delete</span>
        </button>
      </div>
    </div>
  );
});

const RenderChoosePaymentMethod = (props) => {
  return (
    <div className="choose-payemnt-method-wrapper">
      <UserPaymentMethodList {...props} />
    </div>
  );
};

export const ChoosePaymentMethod = memo(RenderChoosePaymentMethod);
