const notificationMessages = {
  // AUTH
  successLogin: "Successfully logged in!",
  failedLogin: "Email or Password is incorrect!",
  successLogout: "Logged out successfully!",
  userAlreadyExists: "User already exists!",
  userMailVerified: "Mail verified!",

  // ROLES - (create, update, delete)
  successCreateRole: "Roles successfully created!",
  successUpdateRole: "Roles successfully modified!",
  successDeleteRole: "Role successfully removed!",
  successDeleteMultiRole: "Roles are successfully removed!",
  successUpdateStatusRole: "Role status updated successfully!",
  successUpdateStatusRoles: "Roles status updated successfully!",

  failedCreateRole: "This role name is already exists!",
  failedUpdateRole: "Failed to update role!",
  failedDeleteRole: "Failed to delete role!",
  failedUpdateStatusRole: "Failed to update status of role!",
  failedUpdateStatusRoles: "Failed to update status of roles!",

  // EMPLOYEES - (create, update, delete)
  successCreateEmployee: "Employee successfully created!",
  successUpdateEmployee: "Employee successfully modified!",
  successUpdateEmployeeEmail: "Check your mail for verification!",
  successDeleteEmployee: "Employee successfully removed!",
  successUpdateStatusEmployee: "Employee status successfully changed!",
  successUpdateStatusMultiEmployee: "Employee status successfully changed!",

  failedCreateEmployee: "Failed to create Employee!",
  failedUpdateEmployee: "Failed to update Employee!",
  failedDeleteEmployee: "Failed to delete Employee!",

  // CATEGORIES
  successCreateCategory: "Category created successfully.",
  successCreateSubcategory: "Subcategory created successfully.",

  // ATTRIBUTES
  successCreateAttribute: "Attribute created successfully.",

  // PROFILE - (update)
  successUpdateProfile: "Profile information successfully updated!",

  // COUPON CREATE
  successCreateCoupon: "Coupon created successfully.",
  successUpdateCoupon: "Coupon updated successfully.",

  // GEO LOCATION CREATE
  successCreateGeoLocation: "Geo Location created successfully",
};

export { notificationMessages };
