import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../../utils/permissions";
import { Section, Main } from "../../../components/content";
import Loading from "../../../components/loading/Loading";
import { useCart } from "../../../context/cart";
import { useAuth } from "../../../context/useAuth";
import axios from "axios";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { SERVER_URL } from "../../../config";
import CreateOrderCustomers from "../../../components/createOrderComponents/CreateOrderCustomers";
import Cart from "../../../components/createOrderComponents/Cart";
import CheckMark from "../../../components/checkmark/CheckMark";

function AccountSettingsPharmacy() {
  const { user } = useAuth();
  const { cart } = useCart();
  const navigate = useNavigate();
  const limitPage = 5;

  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({
    query: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  // choose customer
  const [customer, setCustomer] = useState("");

  // get data for the table
  const [readData, setReadData] = useState([]);
  const [readDataLoading, setReadDataLoading] = useState(true);
  const [readDataRefetch, setReadDataRefetch] = useState(false);

  useEffect(() => {
    if (cart && cart.length < 1) {
      navigate(
        `/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`
      );
    }
  }, [cart]);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const response = await axios
        .post(
          `${SERVER_URL}/${
            AXIOS_API_CALL.getCustomers
          }/${currentPage}/${limitPage}/${search || null}/${null}`,
          { department: PERMISSIONS.pharmacy },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, limitPage, search, currentPage]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  // remove from localStorage customer id
  useEffect(() => {
    window.localStorage.removeItem("dashboardCustomerId");
  }, []);

  // function for setting customer id
  const handleChooseCustomer = (e) => {
    setCustomer(e.target.value);
  };

  // on change customer set id
  useEffect(() => {
    window.localStorage.setItem(
      "dashboardCustomerId",
      JSON.stringify(customer)
    );
  }, [customer]);

  const handleOnSubmitSearch = async (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };
  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };
  const handleOnClearSearch = () => {
    setSearchForm({ query: "" });
    setSearch(null);
    setReadDataRefetch((prev) => !prev);
  };

  const customersProps = {
    handleOnSubmitSearch: handleOnSubmitSearch,
    handleOnChangeSearch: handleOnChangeSearch,
    handleOnClearSearch: handleOnClearSearch,
    handleChooseCustomer: handleChooseCustomer,
    readDataLoading: readDataLoading,
    searchForm: searchForm,
    search: search,
    setReadDataRefetch: setReadDataRefetch,
    readDataRefetch: readDataRefetch,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount,
  };

  const activeClass = window.location.href.includes('create-order') ? 'active' : ''

  return (
    <>
      <div className="orders__navigation">
        <Link
          className="page-title"
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.orders}`}
        >
          Orders
        </Link>
        <Link
          className={`page-title ${activeClass}`}
          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`}
        >
          Create Order
        </Link>
      </div>

      <Section className={`section__wrapper section__createOrder`}>
        <header className="section__header">
          <div className="title">Create Order</div>
          <div className="right__side">
            <div className="number__row">
              {window.location.href.endsWith('account-settings') ? <CheckMark/> : <div className="number">1</div>}
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}`}
                className="link"
              >
                Cart
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div style={{background:window.location.href.endsWith('account-settings') ? '#D0EDED' : ''}} className="number">2</div>
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}`}
                className="link"
              >
                Account Settings
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div className="number">3</div>
              <Link
                to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.pharmacy}/${PERMISSIONS.createOrder}/${PERMISSIONS.accountSettings}/${PERMISSIONS.shippingAddress}`}
                className="link"
              >
                Shipping Address
              </Link>
            </div>
            <div>
              <img src="/assets/icons/Line 93.svg" width={40} />
            </div>
            <div className="number__row">
              <div className="number">4</div>
              <div className="link">PaymentMethod</div>
            </div>
          </div>
        </header>

        <Main className="section__content section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <div className="create__order">
              <CreateOrderCustomers
                data={readData.data?.Data}
                paginationProps={paginationProps}
                customersProps={customersProps}
              />
              <Cart customer={customer} />
            </div>
          )}
        </Main>
      </Section>
    </>
  );
}

export default AccountSettingsPharmacy;
