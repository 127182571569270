import React, { useRef, useState } from "react";
import { useProducts } from "../../../../../../context/products";
import { CustomVariations } from "./custom-variations/CustomVariations";
import { VariationPrices } from "./variation-prices/VariationPrices";
import { generateId } from "../../../../../../helpers/helpers";
import { VariationWeights } from "./variation-weights/VariationWeights";

export const Variations = () => {
  const {
    selectedAttributeUnit,
    setForm,
    form,
    setTabs,
    generateFormFields,
    setFormFields,
    formFields,
    variationForm,
    setVariationForm,
    formError,
  } = useProducts();

  const formRef = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    setVariationForm((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onSubmit = (event) => {
    // TODO: Fix this function

    event.preventDefault();
    if (variationForm.productVariation === "generated") {
      generateFormFields();
    }

    if (variationForm.productVariation === "custom") {
      addField();
    }

    setForm((prevState) => {
      const productVariation = (prevState.productVariation =
        variationForm.productVariation);
      return { ...prevState, productVariation };
    });
  };

  // ADD NEW FIELDS FOR VARIATION FORM
  const addField = () => {
    let object = {
      _id: generateId(),
      variantImage: {
        _id: "",
        __v: 0,
        createdAt: "",
        updatedAt: "",
        forMobile: false,
        localPath: "",
        originalName: "",
        url: "",
      },
      variantSku: "",
      variantRegularPrice: "",
      variantSalePrice: "",
      variantQuantity: "",
      variantType: "custom",
      units: [],
    };

    setFormFields([...formFields, object]);
  };

  return (
    <div className="variations">
      {selectedAttributeUnit && selectedAttributeUnit.length > 0 ? (
        <React.Fragment>
          <VariationPrices />
          <VariationWeights />
          <form
            onSubmit={onSubmit}
            className="form form-create-variations"
            name="product-variations"
          >
            <div className="form-group">
              <label className="label" htmlFor="productVariation">
                <b>Product type:</b>
              </label>
              <select
                value={variationForm.productVariation || "none"}
                onChange={onChange}
                className="select"
                id="productVariation"
                name="productVariation"
              >
                <option value="custom">Add variation</option>
                <option value="generated">
                  Create variations from all attributes
                </option>
              </select>
            </div>

            <button type="submit" className="btn btn-primary">
              <span className="text">Go</span>
            </button>
          </form>
          <CustomVariations formRef={formRef} variationForm={variationForm} />
        </React.Fragment>
      ) : (
        <div className="alert alert-info mb-0">
          <p className="pt-1 pb-1">
            Before you can add a variation you need to add some variation
            attributes on the <b>Attributes</b> tab.
          </p>

          <button
            onClick={() => setTabs(1)}
            type="button"
            className="mt-2 btn btn-info pl-2 pr-2"
          >
            <span className="icon">Go to attributes</span>
          </button>
        </div>
      )}
    </div>
  );
};
