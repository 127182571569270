import React, { Fragment, memo } from "react";
import { BenjaminsDelivery } from "./BenjaminsDelivery";
import { DeliveryServices } from "./DeliveryServices";

const RenderCartDelivery = (props) => {
  const { data } = props;

  if (data && data.length < 1) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      {data &&
        data.map((product) => {
          const isWicSelected = product.products.some((p) => p.WIC_SELECTED);

          if (isWicSelected) {
            return (
              <Fragment key={product._id}>
                <BenjaminsDelivery {...props} products={product} />
              </Fragment>
            );
          }

          return (
            <Fragment key={product._id}>
              <DeliveryServices
                {...props}
                products={product}
                productsLength={data && data.length - 1}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

export const CartDelivery = memo(RenderCartDelivery);
