import React from "react";
import { useProducts } from "../../../../../../../context/products";

export const VariationPrices = () => {
  const { form, onChange, formError } = useProducts();

  return (
    <form name="varaint-price" className="form form-variant-price">
      <div className="form-group">
        <label className="label" htmlFor="productRegularPriceVariant">
          <b>Regular Price:</b>
        </label>
        <input
          value={form.productRegularPriceVariant || ""}
          onChange={onChange}
          name="productRegularPriceVariant"
          id="productRegularPriceVariant"
          type="text"
          inputMode="decimal"
          placeholder="Regular Price"
          className="input"
        />
        {formError.productVariations.productRegularPriceVariant?.format && (
          <p data-cy="" className="relative-error error__onblur error">
            * This field must have none or two decimal points!
          </p>
        )}
      </div>
      {/* <div className="form-group">
        <label className="label" htmlFor="productSalePriceVariant">
          <b>Sale Price:</b>
        </label>
        <input value={form.productSalePriceVariant || ''} onChange={onChange} name="productSalePriceVariant" id="productSalePriceVariant" type="number" placeholder="Sale Price" className="input" />
      </div> */}
    </form>
  );
};
