import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { DatePicker } from 'antd';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import 'antd/dist/antd.css';

export default function FormOverride({ onRefetch }) {
    const { user } = useAuth();
    const { pathname } = useLocation();

    const [groceryPermissions, setGroceryPermissions] = useState([]);

    // GET USER TOKEN
    useEffect(() => {
        const { token } = user;
        if (user && token) {
            const decodeToken = jwt_decode(token);
            const permissions = decodeToken.roleData?.permissions;

            if (pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
                if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
                    setGroceryPermissions(permissions.grocery);
                }
            }
        }
    }, [user, pathname]);

    const today = new Date();
    const dateFrom = moment(today);
    const [selectedRange, setSelectedRange] = useState(dateFrom);

    const handleRangeChange = (date, dateStrings) => {
        setSelectedRange(date);
    };


    const createDeliveryOverrideRule = useCallback(async (payload) => {
        const { token } = user;

        await axios
            .post(`${SERVER_URL}/delivery-slot-override-rule`, { ...payload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (res.status === 200) {
                    const errorMessage = res.data.message;
                    const successMessage = "Override rule successfully created.";


                    if (errorMessage) {
                        notification.error({
                            message: errorMessage,
                            placement: 'bottomLeft'
                        });
                    } else {
                        notification.success({
                            message: successMessage,
                            placement: 'bottomLeft'
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
            });
    }, [user]);

    const onSubmit = (event) => {
        event.preventDefault();
        onRefetch(p => !p);

        // Format date 'mm dd yyyy' or 'dd mm yyyy'
        const date = moment(selectedRange).format('MM-DD-YYYY').replaceAll('-', '/');

        const payload = {
            date: date,
        }

        createDeliveryOverrideRule(payload);
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='group'>
                <label className='mb-1'>Select Date:</label>
                <DatePicker defaultValue={dateFrom} format="MM-DD-YYYY" onChange={handleRangeChange} className='range-picker' />
            </div>

            <div className='group group-actions'>
                <button type='submit' className='btn btn-primary-outline pl-4 pr-4'>Apply</button>
            </div>
        </form>
    )
}


// DATE FORMATE 
// ('mm/dd/yyyy')
// or
// ('dd/mm/yyyy')