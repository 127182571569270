export default function ToggleSwitch({
  disabled,
  checked,
  label,
  text = "",
  onChange,
}) {
  const id = label.toLowerCase().replaceAll(" ", "_");

  return (
    <div className="toggle-switch">
      <input
        disabled={disabled}
        defaultChecked={checked}
        onClick={(e) => onChange({ name: id, value: e.target.checked })}
        type="checkbox"
        id={id}
        className="checkbox"
      />
      <label disabled={disabled} className="label" htmlFor={id}>
        {label} <span dangerouslySetInnerHTML={{ __html: text }} />
      </label>
    </div>
  );
}
