import axios from "axios";
import { Col, Row, Wrapper } from "../../../../../components/content";
import Modal from "../../../../../components/modal/Modal";
import { useProducts } from "../../../../../context/products";
import { SERVER_URL } from "../../../../../config";
import { AXIOS_API_CALL } from "../../../../../utils/endpoint";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../../context/useAuth";
import { PERMISSIONS } from "../../../../../utils/permissions";
import { slugify } from "../../../../../helpers/helpers";

const ProductUpcCode = ({ className }) => {
  const {
    form,
    setForm,
    onChange,
    toggleUpcData,
    setToggleUpcData,
    setDescription,
    setSelected,
    setSelectImagesId,
    setReviewImages,
  } = useProducts();
  const { user } = useAuth();
  const [upcData, setUpcData] = useState({});
  const [upcImages, setUpcImages] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageIds, setImageIds] = useState([]);
  const [images, setImages] = useState([]);
  const [validImages, setValidImages] = useState([]);

  const handleSetToggleUpcData = () => {
    setToggleUpcData((prevState) => !prevState);
  };

  const { token } = user;

  const getUpcData = async () => {
    setLoading(true);
    const { data } = await axios.post(
      `${SERVER_URL}/${AXIOS_API_CALL.pullProductData}/${form.productUpcCode}`,
      { department: PERMISSIONS.grocery },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (data.result) {
      const { code, items } = data.result;
      if (code === "OK") {
        const upcData = items[0];
        const {
          title,
          description,
          highest_recorded_price,
          weight,
          images: imageLinks,
        } = upcData;

        const images = imageLinks.map((item) => ({
          url: item,
          name: item
            .split("/")
            .pop()
            .split("?")[0],
        }));

        setUpcImages(images);
        setUpcData({
          title,
          description,
          price: highest_recorded_price.toString(),
          weight,
        });
      }
    } else {
      setUpcData({});
      setUpcImages([]);
      setLoading(false);
    }
  };

  const handleSelectedProperties = useCallback((e, obj) => {
    if (e.target.checked) {
      setSelectedProperties((prevState) => [...prevState, obj]);
    } else {
      setSelectedProperties((prevState) =>
        prevState.filter((item) => item.property !== obj.property)
      );
    }
  }, []);

  const handleSelectedImages = useCallback((e, obj) => {
    if (e.target.checked) {
      setSelectedImages((prevState) => [...prevState, obj]);
    } else {
      setSelectedImages((prevState) =>
        prevState.filter((item) => item !== obj)
      );
    }
  }, []);

  useEffect(() => {
    if (toggleUpcData && form.productUpcCode) {
      getUpcData();
    }
  }, [toggleUpcData, form.productUpcCode]);

  const handleImportData = useCallback(async () => {
    const findTitle = selectedProperties.find(
      (item) => item.property === "title"
    );
    const findDescription = selectedProperties.find(
      (item) => item.property === "description"
    );
    const findWeight = selectedProperties.find(
      (item) => item.property === "weight"
    );
    const findPrice = selectedProperties.find(
      (item) => item.property === "price"
    );

    filterValidImagesAsBinary(selectedImages)
      .then((validImages) => {
        for (let i = 0; i < validImages.length; i++) {
          const blob = new Blob([validImages[i]]);

          const file = new File([blob], "image.jpg", { type: "image/jpeg" });

          const formData = new FormData();
          formData.append("image", file);

          axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`,

              formData,

              {
                headers: {
                  uri: "public/images/common/",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              setImageIds((prevState) => [...prevState, res.data.image._id]);
              setImages((prevState) => [...prevState, res.data.image]);
            });
        }
      })
      .catch((error) => {
        console.error("Error while fetching images:", error);
      });

    setForm((prev) => ({
      ...prev,
      productName: findTitle ? findTitle.value : prev.productName,
      productSlug: findTitle ? slugify(findTitle?.value) : prev.productSlug,
      productWeight: findWeight ? findWeight.value : prev.productWeight,
      productWeightVariant: findWeight ? findWeight.value : prev.productWeight,
      productRegularPrice: findPrice
        ? findPrice.value
        : prev.productRegularPrice,
      productRegularPriceVariant: findPrice
        ? findPrice.value
        : prev.productRegularPrice,
      productContent: findDescription
        ? findDescription.value
        : prev.productContent,
    }));
    setDescription((prev) => (findDescription ? findDescription.value : prev));
    setUpcData({});
    setUpcImages([]);
    setSelectedProperties([]);
    setSelectedImages([]);
    setImageIds([]);
    setImages([]);
    handleSetToggleUpcData();
  }, [selectedProperties, selectedImages]);

  const downloadImages = async (url) => {
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
      } else {
        console.error(
          `Failed to download image from ${url}. Status code: ${response.status}`
        );
        return null;
      }
    } catch (error) {
      console.error(`Error while downloading image from ${url}:`, error);
      return null;
    }
  };

  // Function to filter valid images
  const filterValidImagesAsBinary = async (imageUrls) => {
    const promises = imageUrls.map((url) => downloadImages(url));
    const imageBuffers = await Promise.all(promises);

    setValidImages(imageBuffers.filter((buffer) => buffer !== null));
    return imageBuffers.filter((buffer) => buffer !== null);
  };

  useEffect(() => {
    if (
      images &&
      images.length > 0 &&
      validImages.length > 0 &&
      validImages.length === images.length
    ) {
      const setImages = new Set(images);
      const imagesArray = [...setImages];
      setReviewImages((prev) => [...prev, ...imagesArray]);
    }
  }, [images, validImages]);

  useEffect(() => {
    if (
      imageIds &&
      imageIds.length > 0 &&
      validImages.length > 0 &&
      validImages.length === imageIds.length
    ) {
      const setImagesIds = new Set(imageIds);
      const imagesIdsArray = [...setImagesIds];
      setSelected((prev) => [...prev, ...imagesIdsArray]);
      setSelectImagesId((prev) => [...prev, ...imagesIdsArray]);
    }
  }, [imageIds, validImages]);

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header card-header-between">
        <h4>Product UPC Code</h4>
        {/* <button
          type="button"
          className="btn btn-primary pl-2 pr-2"
          onClick={() => setToggleUpcData(true)}
        >
          Get UPC Data
        </button> */}
      </header>
      <main className="card-content">
        <form name="product-upc-code" className="form">
          <div className="form-group">
            <label className="label" htmlFor="productUpcCode">
              <b>UPC Code:</b>
            </label>
            <input
              onChange={onChange}
              name="productUpcCode"
              type="text"
              placeholder="UPC Code"
              id="productUpcCode"
              className="input"
              value={form.productUpcCode}
            />
          </div>
        </form>
      </main>
      <Modal
        active={toggleUpcData}
        onToggle={handleSetToggleUpcData}
        title="Product UPC Data"
        className="gallery-view modal-full"
      >
        {Object.entries(upcData).length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
                padding: "16px",
              }}
            >
              {Object.entries(upcData).length > 0 &&
                Object.entries(upcData).map(([property, value], index) => (
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "4px",
                      width: "80%",
                    }}
                    key={index}
                  >
                    <div>
                      <b style={{ textTransform: "capitalize" }}>{property}:</b>
                      &nbsp;
                      {value}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        value={value}
                        onChange={(e) =>
                          handleSelectedProperties(e, { value, property })
                        }
                      />
                      <span>Select</span>
                    </div>
                  </label>
                ))}
            </div>
            <Wrapper className="gallery-images-select">
              <Row>
                {upcImages.map((item, idx) => {
                  return (
                    <Col key={item.url}>
                      <label>
                        <div className="card">
                          <img
                            className="img"
                            src={item.url}
                            alt="Missing image"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={selectedImages.includes(item.url)}
                            name="multiselect"
                            id={`multiselect-${idx + 1}`}
                            onChange={(e) => handleSelectedImages(e, item.url)}
                          />
                          <b className="label">Select</b>
                        </div>
                      </label>
                    </Col>
                  );
                })}
              </Row>
            </Wrapper>
            <Wrapper className="gallery-images-actions pb-2">
              <div className="actions">
                <button
                  onClick={handleImportData}
                  className="btn btn-primary pl-2 pr-2"
                  type="button"
                >
                  <span className="text">Import Data</span>
                </button>
              </div>
            </Wrapper>
          </>
        ) : (
          !loading && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>No Data from UPC, check the entered code</p>
            </div>
          )
        )}
      </Modal>
    </Wrapper>
  );
};

export default ProductUpcCode;
