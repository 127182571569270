import React, { memo } from "react";
import { CartTable } from "./CartTable";

const RenderCartReview = (props) => {
  return (
    <div className="checkout-review-wrapper">
      <div className="checkout-content">
        <CartTable {...props} />
      </div>
    </div>
  );
};

export const CartReview = memo(RenderCartReview);
