import React, { useState } from 'react';
import FormOverride from './form/FormOverride';
import FormOverrideList from './form/FormOverrideList';

export default function SelectOverride({ data, refetch, onRefetch }) {
    return (
        <div>
            <header>
                <h3>Override Rules</h3>
            </header>

            <div className='content'>
                <FormOverride onRefetch={onRefetch} />
                <FormOverrideList data={data} onRefetch={onRefetch} />
            </div>
        </div>
    )
}

