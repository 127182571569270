import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../context/useAuth";
import { SERVER_URL } from "../../../config/index";
import { Section, Main } from "../../../components/content";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { PERMISSIONS } from "../../../utils/permissions";
import SelectCustom from "../../../components/customSelect/CustomSelect";
import Loading from "../../../components/loading/Loading";
import Table from "./Table";
import LoadingPlaceholder from "../../../components/loadingPlaceholder/LoadingPlaceholder";
import Modal from "../../../components/modal/Modal";
import { getGroceryPermissions } from "../../../middleware";
import { notification } from "antd";
import LoadingSpinnerButton from "../../../components/loadingSpinnerButton/LoadingSpinnerButton";

const options = [5, 10, 20, 50, 100];

const Products = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromQuery = searchParams.get('pageFrom') || 1;
  const outOfStockQuery = searchParams.get('outOfStock') || false;

  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET SHOW ITEMS PER PAGE
  let showItems = sessionStorage.getItem("showItems");

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (
        location.pathname.includes(
          `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
        )
      ) {
        if (
          Object.keys(permissions).some((permission) =>
            permission.includes(PERMISSIONS.grocery)
          )
        ) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: "" });
  const [filterStatus, setFilterStatus] = useState(null);

  // FILTERS
  const [toggleFilter, setToggleFilter] = useState(false);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: "" });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [queryFilter, setQueryFilter] = useState({
    currentPage: pageFromQuery,
    outOfStock: outOfStockQuery
  });

  const [currentPage, setCurrentPage] = useState(() => {
    if (queryFilter.currentPage && queryFilter.currentPage !== null) {
      return +queryFilter.currentPage;
    } else {
      return 1;
    }
  });

  const handleChangeFilter = (event) => {
    const { name, checked } = event.target;

    setReadDataRefetch(p => !p);
    setToggleFilter((p) => !p);

    if (name === 'outOfStock') {
      setCurrentPage(1);
      setQueryFilter((prev) => {
        return {
          ...prev, 
          currentPage: 1,
          outOfStock: checked
        }
      });
    }
  };

  // ! IMPORTANT: check this out 
  useEffect(() => {
    setSearchParams({ pageFrom: currentPage, outOfStock: queryFilter.outOfStock });
  }, [currentPage, queryFilter]);


  const [limitPage, setLimitPage] = useState(() => {
    if (showItems && showItems !== null) {
      return Number(showItems);
    } else {
      return 10;
    }
  });

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setReadDataRefetch((prevState) => !prevState);
  };

  // FILTERS
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  const getReadData = useCallback(async () => {
    const { token } = user;
    
    if (!queryFilter.currentPage || queryFilter.currentPage === 0) {
      setReadDataLoading(false);
      return;
    }

    const payload = {
      department: PERMISSIONS.grocery,
      searchTerm: search,
      outOfStockProducts: queryFilter.outOfStock === false || queryFilter.outOfStock === 'false' ? false : true,
    };

    try {
      const response = await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getProducts}/${currentPage}/${limitPage}/null`,
          { ...payload },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200)
        );

      return response;
    } catch (err) { }
  }, [user, limitPage, search, currentPage, queryFilter, filterStatus]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        const readData = res?.data;

        if (readData) {
          setReadData(readData);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      // ! OLD: setCurrentPage(1);
      setQueryFilter((prev) => {
        return {
          ...prev,
          currentPage: 1
        }
      });

      setCurrentPage(1);

      setSearchParams({ pageFrom: 1, outOfStock: queryFilter.outOfStock });
    }
  }, [search, outOfStockQuery, setQueryFilter]); // TODO: deps in useEffect comment 

  // FUNCTION
  function handleToggle(props) {
    const { name, state, data } = props;

    switch (name) {
      case "create":
        state.onToggle(true);
        break;
      case "update":
        state.onToggle(true);
        break;
      case "deleteBulk":
        state.onToggle(true);
        break;
      case "delete":
        state.onToggle(true);
        setDeleteFormId({ id: data });
        break;
      case "importNetSuite":
        state.onToggle(true);
        break;
      default:
        console.warn("Default of: handleToggle function");
    }
  }

  async function onSubmit(props) {
    const { event, name, data } = props;
    event.preventDefault();
    const { token } = user;

    switch (name) {
      case "delete":
        const deleteId = data.id;

        await axios
          .delete(`${SERVER_URL}/${AXIOS_API_CALL.deleteProduct}/${deleteId}`, {
            withCredentials: false,
            headers: {
              department: PERMISSIONS.grocery,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setToggleDelete(false);
              setDeleteFormId({ id: "" });
              setReadDataRefetch((p) => !p);

              // notification
              notification.success({
                message: "Product removed successfully.",
                placement: "bottomLeft",
              });
            }
          })
          .catch((err) => {
            console.error("Error of delete single product:", err);
          })
          .finally(() => { });
        break;
      case "deleteBulk":
        const payloadDeleteBulk = {
          products: checkedRow,
        };

        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.deleteProductBulk}`,
            { ...payloadDeleteBulk },
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              setToggleDeleteBulk(false);
              setReadDataRefetch((p) => !p);
              setCheckedRow([]);

              // notification
              notification.success({
                message: "Products removed successfully.",
                placement: "bottomLeft",
              });
            }
          })
          .catch((err) => {
            console.error("Error of delete bulk products:", err);
          })
          .finally(() => { });
        break;
      case "importNetSuite":
        setShowImportNetSuiteLoader(true);
        await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.importProductFromNetSuite}`,
            { department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setReadDataRefetch((p) => !p);
              setToggleImportNetSuite(false);

              // notification
              notification.success({
                message: res.data.message,
                placement: "bottomLeft",
              });
            }
            setTimeout(() => {
              setShowImportNetSuiteLoader(false);
            }, 700);
          })
          .catch((err) => {
            setShowImportNetSuiteLoader(false);
            setToggleImportNetSuite(false);

            console.error(err.message);

            // notification
            notification.warn({
              message: err.message,
              placement: "bottomLeft",
            });
            console.error("Import from NetSuite error: ", err);
          })
          .finally(() => { });
        break;
      default:
        console.warn("Default of: onSubmit function");
    }
  }

  // CREATE
  const createModalFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({}); // data

  // UPDATE
  const updateModalFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateFormId, setUpdateFormId] = useState({ id: "" });
  const [updateForm, setUpdateForm] = useState({}); // data

  // IMPORT FROM NETSUITE
  const importNetSuiteModalRef = useRef(null);
  const [toggleImportNetSuite, setToggleImportNetSuite] = useState(false);
  const [showImportNetSuiteLoader, setShowImportNetSuiteLoader] = useState(
    false
  );

  // DELETE BULK
  const deleteBlukModalFormRef = useRef(null);
  const [toggleDeleteBulk, setToggleDeleteBulk] = useState(false);
  const [deleteBulkFormId, setDeleteBulkFormId] = useState({ id: "" }); // data

  // DELETE SINGLE
  const deleteModalFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: "" }); // data

  // create
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    data: createForm,
    onData: setCreateForm,
  };

  // update
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: updateForm,
    onData: setUpdateForm,
  };

  // import NetSuite
  const importNetSuiteProps = {
    active: toggleImportNetSuite,
    onToggle: setToggleImportNetSuite,
    formRef: importNetSuiteModalRef,
  };

  // delete bulk
  const deleteBlukProps = {
    active: toggleDeleteBulk,
    onToggle: setToggleDeleteBulk,
    formRef: deleteBlukModalFormRef,
  };

  // delete
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
  };

  // PROPS
  const tableProps = {
    queryParamsValue: { pageFromQuery, outOfStockQuery },
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    handleToggle: handleToggle,

    updateActions: updateProps,
    deleteActions: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount,
  };

  return (
    <>
      <h2 className="page-title">Products</h2>
      <Section className="section__wrapper section__products">
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: "#333", fontWeight: "500" }}>Show:</span>
              <SelectCustom
                options={options}
                label={"Items"}
                limit={limitPage}
                setCurrentPage={setCurrentPage}
                setLimit={setLimitPage}
                setSessionStorage={true}
                setRefetch={setReadDataRefetch}
                loading={readDataLoading}
              />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: "155.5px", height: "50px" }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form
                className="search-form"
                onSubmit={(e) => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Search"
                    />
                  </span>
                  <input
                    value={searchForm.query || ""}
                    type="text"
                    data-cy="search-input-field"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0
                      ? "isActive"
                      : ""
                      }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder
              style={{ width: "100%", maxWidth: "850px", height: "50px" }}
            />
          )}

          <div className="actions">
            {!readDataLoading ? (
              <div className="dropdown">
                <button onClick={() => setToggleFilter(p => !p)} className="btn btn-link btn-filter" type="button">
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"><path d="M21.63 14.75c0 .89-.25 1.73-.69 2.45a4.709 4.709 0 0 1-4.06 2.3 4.73 4.73 0 0 1-4.06-2.3 4.66 4.66 0 0 1-.69-2.45c0-2.62 2.13-4.75 4.75-4.75s4.75 2.13 4.75 4.75ZM18.66 14.73h-3.55M16.88 13v3.55" stroke="#4761e6" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M20.69 4.02v2.22c0 .81-.51 1.82-1.01 2.33l-1.76 1.55a4.42 4.42 0 0 0-1.04-.12c-2.62 0-4.75 2.13-4.75 4.75 0 .89.25 1.73.69 2.45.37.62.88 1.15 1.5 1.53v.34c0 .61-.4 1.42-.91 1.72L12 21.7c-1.31.81-3.13-.1-3.13-1.72v-5.35c0-.71-.41-1.62-.81-2.12L4.22 8.47c-.5-.51-.91-1.42-.91-2.02V4.12C3.31 2.91 4.22 2 5.33 2h13.34c1.11 0 2.02.91 2.02 2.02Z" stroke="#4761e6" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  </span>
                </button>

                {toggleFilter && (
                  <div className="menu">
                    <div className="form-group">
                      <input value={outOfStockQuery} checked={outOfStockQuery === 'false' ? false : true} onChange={(e) => handleChangeFilter(e)} name="outOfStock" type="checkbox" id="outOfStock" />
                      <label htmlFor="outOfStock">Out of Stock</label>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <LoadingPlaceholder
                style={{ width: "50px", height: "50px" }}
              />

            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.createProducts
            ) && (
                <>
                  {!readDataLoading ? (
                    <button
                      style={{ opacity: ".2" }}
                      disabled
                      type="button"
                      onClick={() =>
                        handleToggle({
                          name: "importNetSuite",
                          state: importNetSuiteProps,
                        })
                      }
                      data-cy="import-netsuite-btn"
                      className="btn btn-primary-outline pl-4 pr-4"
                    >
                      <span className="text">Import From NetSuite</span>
                    </button>
                  ) : (
                    <LoadingPlaceholder
                      style={{ width: "238px", height: "50px" }}
                    />
                  )}
                </>
              )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.deleteProducts
            ) && (
                <>
                  {!readDataLoading ? (
                    <button
                      type="button"
                      onClick={() =>
                        handleToggle({
                          name: "deleteBulk",
                          state: deleteBlukProps,
                        })
                      }
                      disabled={disabledButton ? false : true}
                      data-cy="delete-products-btn"
                      className="btn btn-primary-outline pl-4 pr-4"
                    >
                      <span className="text">Delete Products</span>
                    </button>
                  ) : (
                    <LoadingPlaceholder
                      style={{ width: "189px", height: "50px" }}
                    />
                  )}
                </>
              )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.createProducts
            ) && (
                <>
                  {!readDataLoading ? (
                    <Link
                      to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`}
                      className="btn btn-primary pl-4 pr-4 d-flex items-center"
                    >
                      <span className="text d-flex items-center align-center">
                        Add Product
                      </span>
                    </Link>
                  ) : (
                    <LoadingPlaceholder
                      style={{ width: "162.5px", height: "50px" }}
                    />
                  )}
                </>
              )}
          </div>
        </header>

        {/* Main content */}
        <Main className="section__content section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <Table
              data={readData.data?.Data}
              {...tableProps}
              {...tableActionsProps}
              {...tableFiltersProps}
              pagination={paginationProps}
            />
          )}
        </Main>

        {/* Delete Bulk Modal */}
        <Modal {...deleteBlukProps} className="sidebar__modal--center" title="">
          <form
            name="deleteBulk"
            onSubmit={(event) =>
              onSubmit({ event: event, name: "deleteBulk", data: checkedRow })
            }
          >
            <h2 className="text-center mb-4">
              Are you sure you want to remove selected items ?
            </h2>

            <div className="form-group form-group-modal mb-4">
              <button
                data-cy="modal-delete-bulk-submit-btn"
                type="submit"
                className="btn btn-primary-link"
              >
                <span className="text">Yes</span>
              </button>

              <button
                data-cy="modal-delete-bulk-cancel-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => setToggleDeleteBulk(false)}
              >
                <span className="text">No</span>
              </button>
            </div>
          </form>
        </Modal>

        {/* Delete Modal */}
        <Modal {...deleteProps} className="sidebar__modal--center" title="">
          <form
            name="delete"
            onSubmit={(event) =>
              onSubmit({ event: event, name: "delete", data: deleteFormId })
            }
          >
            <h2 className="text-center mb-4">
              Are you sure you want to remove the selected item ?
            </h2>

            <div className="form-group form-group-modal mb-4">
              <button
                data-cy="modal-delete-submit-btn"
                type="submit"
                className="btn btn-primary-link"
              >
                <span className="text">Yes</span>
              </button>

              <button
                data-cy="modal-delete-cancel-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => setToggleDelete(false)}
              >
                <span className="text">No</span>
              </button>
            </div>
          </form>
        </Modal>

        {/* Import NetSuite Modal */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.createProducts
        ) && (
            <Modal
              {...importNetSuiteProps}
              className="sidebar__modal--center"
              title=""
            >
              <form
                name="importNetSuite"
                onSubmit={(event) =>
                  onSubmit({ event: event, name: "importNetSuite" })
                }
              >
                <h2 className="text-center mb-4">
                  Are you sure you want to import from NetSuite ?
                </h2>

                <div className="form-group form-group-modal mb-4">
                  {!showImportNetSuiteLoader ? (
                    <button
                      data-cy="modal-import-netsuite-submit-btn"
                      type="submit"
                      className="btn btn-primary-link"
                    >
                      <span className="text">Yes</span>
                    </button>
                  ) : (
                    <button
                      data-cy="modal-import-netsuite-submit-btn"
                      type="button"
                      className="btn btn-primary-link"
                    >
                      <span
                        className="text relative"
                        style={{ fontSize: "smaller" }}
                      >
                        Importing
                        <LoadingSpinnerButton className="loader-button" />
                      </span>
                    </button>
                  )}

                  <button
                    data-cy="modal-import-netsuite-cancel-btn"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setToggleImportNetSuite(false)}
                  >
                    <span className="text">No</span>
                  </button>
                </div>
              </form>
            </Modal>
          )}
      </Section>
    </>
  );
};

export default Products;
