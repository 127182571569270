const getGroceryPermissions = (permissons, check) => {
  const allowedPermissons = permissons && permissons.filter((permission) => permission.name.includes(check));
  if (allowedPermissons && allowedPermissons.length > 0) {
    return true;
  }
  return false;
};

const getPharmacyPermissions = (permissons, check) => {
  const allowedPermissons = permissons && permissons.filter((permission) => permission.name.includes(check));
  if (allowedPermissons && allowedPermissons.length > 0) {
    return true;
  }
  return false;
};

export { getGroceryPermissions, getPharmacyPermissions };
