import React, { Fragment, memo, useEffect } from "react";

const RenderBenjaminsDelivery = ({
  isServicesConfirmed,
  products,
  readAddress,
  renderAddress,
  renderBenjaminsDelivery,
  setSingleShipmentRatesError,
  geoLocationErrorWIC,
}) => {
  useEffect(() => {
    const isCheckAddress =
      readAddress &&
      readAddress.length > 0 &&
      readAddress.some((item) => item._id === products._id);

    if (isCheckAddress) {
      const findAddress = readAddress.find((item) => item._id === products._id);

      if (findAddress && Object.keys(findAddress).length > 0) {
        const userAddressZipCode = findAddress && findAddress.zip_code;

        if (userAddressZipCode.length === 5 && isNaN(+userAddressZipCode)) {
          setSingleShipmentRatesError(true);
        } else {
          setSingleShipmentRatesError(false);
        }
      }
    }
  }, [readAddress, products]);

  const errorBorderClass = geoLocationErrorWIC ? "hsl(354deg, 82%, 61%)" : "";
  const errorBoxShadowClass = geoLocationErrorWIC
    ? "0 0 0 3px rgba(237, 74, 90, 0.3)"
    : "none";

  return (
    <Fragment>
      <div className="delivery-services">
        <div>{renderAddress(products._id)}</div>

        <div className="form-group">
          {!isServicesConfirmed ? (
            <select
              style={{
                borderColor: `${errorBorderClass}`,
                boxShadow: `${errorBoxShadowClass}`,
              }}
              disabled={false}
              className="select"
            >
              <option value="benjamins">{renderBenjaminsDelivery()}</option>
            </select>
          ) : (
            <select disabled={true} className="select">
              <option value="benjamins">{renderBenjaminsDelivery()}</option>
            </select>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export const BenjaminsDelivery = memo(RenderBenjaminsDelivery);
