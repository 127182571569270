export const BENJAMINS_DELIVERY_PRICE = "10.00";
export const BENJAMINS_DELIVERY_PRICE_FREE = "50.00";
export const FREE_DELIVERY = "Free delivery";

export const BENJAMINS_DELIVERY_ID = "rate_700benjaminsandpharmacydeliveryc";
export const BENJAMINS_DELIVERY_SHIPPMENT_ID =
  "shp_800benjaminspharmacydelivery15de";
export const BENJAMINS_DELIVERY_KEY = "benjamins_pharmacy_shipment_service";
export const BENJAMINS_PHONE_NUMBER = "+17184059111";

export const BENJAMINS_DELIVERY = {
  id: BENJAMINS_DELIVERY_ID,
  carrier: "BPD",
  service: "BenjaminsDelivery",
  est_delivery_days: "1",
  rate: BENJAMINS_DELIVERY_PRICE,
  shipment_id: BENJAMINS_DELIVERY_SHIPPMENT_ID,
};
