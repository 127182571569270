import FormCreateSlot from "./form/FormCreateSlot";
import ListSlots from "./form/ListSlots";

export default function SelectDay({ data, queryDate, value, onChange, options, onRefetch }) {
    return (
        <div>
            <header>
                <h3>Choose Day</h3>
            </header>

            <div className='content'>
                <div className="block block-day">
                    <div className="left">
                        <div className='group'>
                            <label htmlFor='days'>Select Day:</label>
                            <select value={value} name="day" onChange={onChange} className='select' id='days'>
                                {options.map((option) => {
                                    return <option key={option.value} value={option.name.toLowerCase()}>{option.name}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="right">
                        <p className='title mb-1'>All Slots:</p>

                        {data && data.length > 0 && <ListSlots data={data} onRefetch={onRefetch} />}

                        <FormCreateSlot queryDate={queryDate} onRefetch={onRefetch} />
                    </div>
                </div>
            </div>
        </div>
    )
}
